import { StyleSheet } from "react-native";
import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { handleLoading } from "../../store/actions/loadingAction";
import { useEffect } from "react";
import { t } from "i18next";
import { DateInput } from "../Users/CreateUserScreen";
import InstanceIndex from "../../components/UI/AddingData/InstanceIndex";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../src/components/shadui/ui/alert-dialog';
import { buttonVariants } from '../../src/components/shadui/ui/button';
import axios from "axios";
import CommonInputsContainer from "../../components/UI/TableView/CommonInputsContainer";
import { IoTrashOutline } from "react-icons/io5";
import { getAmountByCustomer } from "./SwimmingItemDetails";

const CafeOrderScreen = () => {
  const [currentData, setCurrentData] = useState(false);
  const id = useParams().id;
  const [alertOpen, setAlertOpen] = useState(false)

  const { data, isLoading, error } = useSwr('/orders/' + id, fetcher)
  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentData;
    if (type === 0) {
      obj.name = value;
    } else if (type === 1) {
      obj.iqd = Number(value);
    } else if (type === 2) {
      obj.centerId = value;
    } else if (type === 3) {
      obj.type = value;
    } else if (type === 4) {
      obj.date = value;
      obj.createdAt = dayjs(value).valueOf();

    } else if (type === 5) {
      obj.price = value;
    }

    setCurrentData({ ...obj });
  };

  const onSubmit = async () => {
    try {
      handleLoading();
      await axios.put('/orders/update/' + id, currentData)
      history.goBack();
      handleLoading();
    } catch (e) {
      handleLoading();
      alert(e);
    }
  };

  const setUp = () => {
    let objData = {};
    let check = data && data[0]
    if (check) {
      objData = { ...check };
    }

    setCurrentData(objData);
  };
  useEffect(() => {
    setUp();
  }, [data]);

  const onDeleteItem = async () => {
    try {
      handleLoading();
      await axios.delete(`/orders/delete/${currentData._id}`);
      history.goBack();
      handleLoading();

    } catch (e) {
      console.log(e);
      handleLoading();
      alert(e);
    }
  };

  if (error) return <Error />

  if (isLoading) return <Loading />
  return (
    <div className='flex flex-col items-center flex-1 w-full '>
      <AlertDialog open={alertOpen} >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('thisActionCannotBeUndone_swimming')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
            <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <CommonInputsContainer onSave={onSubmit} title={t('item')} customIcon={<IoTrashOutline color="red" onClick={() => setAlertOpen(true)} size={30} />} warning={t("pleaseBeCarefulOfChangingMembership")}>
        {currentData && <>
          <InstanceIndex label={t("idNumber")} value={`#${currentData._id}`} />
          <InstanceIndex
            label={t("name")}
            value={currentData.name}
          />
          {currentData.paymentDetails && <InstanceIndex label={t("amountByCustomer")} value={getAmountByCustomer(currentData.paymentDetails, currentData.price)} />}

          {(
            <DateInput
              label={`${t("date")}`}
              placeHolder={t("date")}
              value={currentData?.date}
              onChangeValue={onChangeValue}
              type={4}
            />
          )}
        </>}

      </CommonInputsContainer>
    </div>
  );
};


export default CafeOrderScreen;

const styles = StyleSheet.create({});
