import { store } from "../../App";

export const onSocketAction = (res) => {
  const { data, crudType, actionType } = res;
  let token = localStorage.getItem("Auth");

  if (!token || !data) return;

  if (actionType === 20 && data.type == 0) {
    store.dispatch({ type: "UPDATE_DOLLAR_PRICE", data: data.price });
    return
  }


};
