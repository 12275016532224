import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData'
import { IoAdd } from 'react-icons/io5'

function NutritionStorage() {
    const currentCenterId = '62d3de07e4b5f62ba339b22b'
    const { columns, id, label, url, href } = {
        id: 'nutrition-storage', label: 'nutritionStorage',
        href: '/nutrition-storage',
        columns: [{ label: '#', value: a => a._id.slice(0, 10) },
        { label: 'by', classnames: () => 'font-[Montserrat]', value: (a) => a.admin?.name || 'unknown' },
        { label: 'items', value: (a) => a.items.length },
        { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') },
        ],
        url: (centerId) => `/nutrition-storage-activities/${currentCenterId}?` + centerId,
    }
    const history = useHistory()

    const onAddStorage = () => {
        history.push(`/nutrition-storage/storage/1`)
    }

    return (
        <div className="flex flex-col items-center flex-1 w-full bg-white">
            <div className='flex flex-1 w-full h-full mt-10' >
                <ShowPaginationData hrefId={null} onClickItem={(data) => {
                    history.push(`/nutrition-storage/storage/view/${data._id}`, {
                        data
                    })
                }} rightButton={<div className="cursor-pointer" >
                    <IoAdd onClick={onAddStorage} size={30} />
                </div>} columns={columns} href={href} id={id} label={label} url={url} />
            </div>
        </div >
    )
}

export default NutritionStorage
