import i18next, { t } from "i18next";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import Colors from "../../constant/Colors";
import useSwr from "swr";
import { Skeleton } from "../../ui/ui/skeleton";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { Error, Loading, fetcher } from "../../screens/UI/CentersViewData";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import getSellerDetails from "../../customFunction/nutrition/getSellerDetails";
import { DateRange } from "react-date-range";
import { updateDuration } from "../../store/actions/supplementAnalaysisAction";
import { useQuery } from "../../screens/Admin/ClassSchedule/ClassSchedulesDetails";

const NUTRITION_VIEW = [
  {
    id: "stock",
    label: "stock",
    href: "",
    notByDate: true,
    columns: [
      { label: "name", value: (a) => a?.name },
      { label: "quantity", value: (a) => a?.quantity },
      { label: "brand", value: (a) => a.brand?.name || "-" },
      {
        label: "price",
        value: (a) => a.prices.singlePrice,
      },
    ],
    url: (centerId) => "/cafeteria-stocks?" + centerId,
    description: "This Table Contain Last 10 Expired Users Of {{centerName}}",
  },
  {
    id: "brands",
    label: "brands",
    href: "",
    hrefId: "_id",
    notByDate: true,
    columns: [
      { label: "#", value: (a) => a._id },
      { label: "brandName", value: (a) => a.name },
      //   { label: "brandName", value: (a) => a.name },
      {
        label: "brandLogo",
        value: (a) => (
          <img src={a.brandLogo} alt='' className='object-cover w-[100px]' />
        ),
      },
      { label: "date", value: (a) => a.date },
    ],
    url: (centerId) => "/cafeteria-brands?" + centerId,
    description: "This Table Contain Last 10 Expired Users Of {{centerName}}",
  },
  {
    id: "purchases",
    label: "purchases",
    href: "",
    hrefId: "_id",
    bySellerId: true,
    notByDate: true,
    columns: [
      { label: "#", value: (a) => a._id },
      { label: "totalPrice", value: (a) => showInIQD(a.totalPrice, "IQD") },
      //   { label: "brandName", value: (a) => a.name },
      {
        label: "numOfIProducts",
        value: (a) => a.items.length + " " + t("products"),
      },
      { label: "date", value: (a) => a.date },
    ],
    url: (centerId) => "/cafeteria-purchases?" + centerId,
    description: "This Table Contain Last 10 Expired Users Of {{centerName}}",
  },
  {
    id: "expired-products",
    label: "expired-products",
    href: "",
    hrefId: "_id",
    bySellerId: true,
    notByDate: false,
    columns: [
      { label: "#", value: (a) => a._id },
      { label: "items", value: (a) => a.items.length },
      // {
      //   label: "price",
      //   value: (a) =>
      //     showInIQD(a.price,'USD'),
      // },
      { label: "date", value: (a) => a.date },
    ],
    url: (centerId) => "/cafeteria-expired-products?" + centerId,
    description: "This Table Contain Last 10 Expired Users Of {{centerName}}",
  },
];

const TYPES_CAN_ADD = [0, 14];

function NutritionViewData({ AddIcon, haveDateIcon }) {
  const viewMode = useQuery().get("view-mode");
  const label = NUTRITION_VIEW.find((a) => a.id === viewMode)?.label || "";
  const Columns = NUTRITION_VIEW.find((a) => a.id === viewMode)?.columns || [];
  const NotByDate =
    NUTRITION_VIEW.find((a) => a.id === viewMode)?.notByDate || null;
  const bySellerId =
    NUTRITION_VIEW.find((a) => a.id === viewMode)?.bySellerId || null;
  const href = NUTRITION_VIEW.find((a) => a.id === viewMode)?.href;
  const hrefId = NUTRITION_VIEW.find((a) => a.id === viewMode)?.hrefId || "_id";
  const duration = useSelector((state) => state.supplementDuration.duration);
  const history = useHistory();
  const url = NUTRITION_VIEW.find((a) => a.id === viewMode)?.url || null;
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const User = useSelector((state) => state.authentication.user);
  const { data, error, isLoading } = useSwr(
    url(
      `&page=${page}&search=${search}&${
        !bySellerId ? null : `sellerId=` + getSellerDetails().sellerId
      }&` +
        (NotByDate
          ? null
          : `startDate=${
              NotByDate ? null : dayjs(duration.startDate).format("YYYY-MM-DD")
            }&endDate=${
              NotByDate ? null : dayjs(duration.endDate).format("YYYY-MM-DD")
            }`)
    ),
    fetcher
  );
  const dispatch = useDispatch();
  const [dateHasChange, setDateHasChange] = useState(false);
  console.log(data);
  const [datePickerHandler, setDatePickerHandler] = useState([
    {
      startDate: dayjs(duration.startDate).toDate(),
      endDate: dayjs(duration.endDate).toDate(),
      key: "selection",
    },
  ]);

  const [showDate, setShowDate] = useState(false);
  const language = i18next.language;

  const onClickSelectDate = () => {
    setShowDate(!showDate);
  };
  const onChangeDateRange = () => {
    //!check if the start date is before 2023-07-01
    if (
      false
      // isSupplement &&
      // user.type === 18 &&
      // dayjs(startDate).isBefore("2023-07-01")
    ) {
      // alert("sorry you can't access to select date before 2023-07-01");
      // return;
    }
    dispatch(
      updateDuration({
        startDate: datePickerHandler[0].startDate,
        endDate: datePickerHandler[0].endDate,
      })
    );
  };

  useEffect(() => {
    if (
      dayjs(datePickerHandler[0].startDate).format("YYYY-MM-DD") !==
        dayjs(duration.startDate).format("YYYY-MM-DD") ||
      dayjs(datePickerHandler[0].endDate).format("YYYY-MM-DD") !==
        dayjs(duration.endDate).format("YYYY-MM-DD")
    ) {
      onChangeDateRange();
    }
  }, [dateHasChange]);

  useEffect(() => {
    if (data) {
      setPageCount(data.pagination?.pageCount);
    }
  }, [data]);

  const handleNext = (a) => {
    const newVal = a.selected + 1;
    if (newVal > page) {
      setPage((p) => {
        return p + 1;
      });
    } else if (newVal < page) {
      setPage((p) => {
        return p - 1;
      });
    }
  };

  if (error) return <Error />;

  return (
    <div
      style={{ direction: language === "en" ? "ltr" : "rtl" }}
      className='flex items-center justify-center flex-1 w-full'>
      <div className='flex flex-col w-[90%] h-[90%] '>
        <div className='pb-3 border-b'>
          <div className='flex justify-between w-full text-3xl font-bold'>
            {t(label)}
            {/* {AddIcon && ( */}
            <div>{<AddIcon />}</div>
            {/* )} */}
          </div>
        </div>
        <div>
          {/* Table Header */}
          <div className='flex justify-between'>
            <div className='flex items-center'>
              {!isLoading && (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel={`${t("next")} >`}
                  onPageChange={handleNext}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(pageCount)}
                  initialPage={page - 1}
                  previousLabel={`< ${t("previous")}`}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  previousLinkClassName='pagination__link'
                  nextLinkClassName='pagination__link'
                  disabledClassName='pagination__link--disabled'
                  activeLinkClassName='pagination__link--active'
                />
              )}
            </div>
            <div className='relative flex items-center'>
              {haveDateIcon && (
                <div className='relative'>
                  <div
                    onClick={onClickSelectDate}
                    style={{ minWidth: 250 }}
                    className='relative flex items-center'>
                    <div className='relative w-full mx-2'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <svg
                          className='w-4 h-4 text-gray-500'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'>
                          <path d='M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z' />
                        </svg>
                      </div>
                      <input
                        value={
                          dayjs(duration.startDate).format("DD-MM-YYYY") +
                          " - " +
                          dayjs(duration.endDate).format("DD-MM-YYYY")
                        }
                        type='text'
                        className='block w-full pl-10 text-sm text-gray-900 border h-[36px] border-gray-300  select-none bg-gray-50 focus:ring-blue-500 focus:border-blue-500 '
                        placeholder={t("selectDate")}
                      />
                    </div>
                  </div>
                  {showDate && (
                    <DateRange
                      className={`absolute z-10 border left-0 top-12`}
                      editableDateInputs={true}
                      onChange={(item) => {
                        setDatePickerHandler([item.selection]);
                      }}
                      onRangeFocusChange={(a) => {
                        if (a[0] === 0 && a[1] === 0) {
                          setShowDate(false);
                          setDateHasChange(!dateHasChange);
                        }
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={datePickerHandler}
                    />
                  )}
                </div>
              )}
              <div className='my-2 h-[36px] relative '>
                <input
                  autoFocus
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className='p-1 h-full border pr-[32px] outline-none rounded w-[250px]'
                  placeholder={t("search")}
                />
                <AiOutlineSearch
                  size={24}
                  color={Colors.mainAqwaBlue}
                  className='absolute -translate-y-1/2 top-1/2 right-1'
                />
              </div>
            </div>
          </div>
          {/* Table Body */}
          <div className='flex flex-col w-full h-full overflow-auto'>
            {/* {isLoading ? <Loading /> : <Table history={history} hrefIdSubKey={hrefIdSubKey} hrefId={hrefId} onClickItem={(a) => href ? history.push(window.location.pathname + href + '/' + (hrefIdSubKey ? a[hrefIdSubKey] : a)) : null} isLoading={isLoading} data={data} columns={Columns} />} */}
            {isLoading ? (
              <Loading />
            ) : (
              <Table
                history={history}
                isLoading={isLoading}
                onClickItem={(a) =>
                  href !== null
                    ? history.push(window.location.pathname + href + "/" + a)
                    : null
                }
                data={data}
                columns={Columns}
                hrefId={hrefId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Table = ({
  columns,
  data,
  isLoading,
  onClickItem,
  hrefIdSubKey,
  hrefId,
}) => {
  return (
    <div className='flex flex-col w-full my-6 max-h-[600px] overflow-auto'>
      <div className='flex items-center justify-between px-1 py-4 rounded bg-slate-100 '>
        {columns?.map((a, i) => (
          <div key={i} className='w-1/4 text-[15px] opacity-90'>
            {t(a.label)}
          </div>
        ))}
      </div>
      <div className='flex flex-col w-full h-full '>
        {isLoading ? (
          <>
            {Array(10)
              .fill(0)
              .map((a, i) => (
                <>
                  <Skeleton
                    key={i}
                    className='w-full my-3 bg-[#f4f3f3] h-[60px]'
                  />
                </>
              ))}
          </>
        ) : (
          <>
            {data &&
              data.length !== 0 &&
              data.items.map((a, i) => (
                <div
                  onClick={onClickItem.bind(this, a[hrefId])}
                  key={i}
                  className={`flex ${
                    i % 2 === 0 ? "bg-[#fff]" : "bg-[#f4f3f3]"
                  } items-center justify-center h-[60px]  p-1 py-4 font-medium border-b rounded cursor-pointer `}>
                  {columns.map(({ value, classnames }, j) => (
                    <div
                      key={j}
                      className={`w-1/4 text-[14px] h-[55px] flex ${
                        classnames ? classnames(a) : ""
                      } items-center whitespace-pre-wrap overflow-hidden text-ellipsis opacity-90`}>
                      {value(a)}
                    </div>
                  ))}
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default NutritionViewData;
