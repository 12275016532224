import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ShowPaginationData from "../../../components/UI/TableView/ShowPaginationData";
import { IoAdd } from "react-icons/io5";
import AdminTypes from "../../../data/Admin/AdminTypes";
import dayjs from "dayjs";

export default function AdminsScreen() {
  const { columns, id, label, url, href } = {
    id: 'admins', label: 'admins',
    href: '/admins',
    columns: [{ label: 'admin', value: a => a.name },
    { label: 'email', classnames: () => 'font-[Montserrat]', value: (a) => a.email },
    { label: 'role', value: (a) => AdminTypes.find(at => at.type === a.type).label },
    { label: 'startDate', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') }],
    url: (centerId) => '/admins?' + centerId,
  }
  // const ref = useRef(false);
  const history = useHistory();

  const onClickItem = (_id) => {
    history.push(`/admin/admins/${_id}`);
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData hrefId='_id' onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
          <IoAdd size={40} onClick={() => history.push('/admin/admins/0?isNew=true')} />
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  );
}
