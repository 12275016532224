import React from 'react'
import { useTranslation } from 'react-i18next'
import ShowPaginationData from '../../components/UI/TableView/ShowPaginationData'

export default function UsersFeedbackScreen() {
  const { t } = useTranslation();
  const { columns, id, label, url, href } = {
    id: 'users-feedback', label: 'users-feedback',
    href: '/users-feedback',
    columns: [{ label: 'date', value: a => a.date },
    { label: 'name', classnames: () => 'font-[Montserrat]', value: (a) => a.name },
    { label: 'starRate', value: (a) => a.starRate + ' ' + t('star') },
    { label: 'feedback', value: (a) => a.feedback },
    ],
    url: (centerId) => '/users-feedback?' + centerId,
  }
  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData onClickItem={(_id) => { }} rightButton={<div className="cursor-pointer" >
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>)
}
