import { StyleSheet, View, ScrollView, Dimensions } from "react-native-web";
import React, { useEffect } from "react";
import HeaderListRender from "../../components/UI/ReactNativeTable/HeaderListRender";
import ItemRender from "../../components/UI/ReactNativeTable/ItemRender";
import { useHistory, useLocation } from "react-router-dom";
import { searchAndPageIndex } from "../../store/actions/searchBarAction";
import { useQuery } from "../Admin/ClassSchedule/ClassSchedulesDetails";

const { width } = Dimensions.get("window");

//!showObjj send from usersFeedbackScreen
const ViewAllDataScreen = ({ showObjj }) => {
  const showObj = useLocation()?.state?.showObj || showObjj;
  const history = useHistory()
  const isV2 = useQuery().get('v2')
  console.log(isV2)

  useEffect(() => {
    return () => {
      if (history.action === "POP" && !history.location.pathname.includes('viewAll')) {
        searchAndPageIndex('', 0)
      }
    };
  }, [history]);


  return (
    <View style={{ flex: 1 }}>
      {isV2 ? null: <ScrollView>
        {width > 800 ? (
          <View style={styles.container}>
            <HeaderListRender
              label={showObj.label}
              headerLabels={showObj.headerLabel}
            />

            <ItemRender
              headerLabels={showObj.headerLabel}
              data={showObj.data}
              type={showObj.type}
              showObj={showObj}
            />
          </View>
        ) : (
          <View style={{ paddingHorizontal: 10 }}>
            <ScrollView horizontal>
              <View>
                <HeaderListRender
                  label={showObj.label}
                  headerLabels={showObj.headerLabel}
                />

                <ItemRender
                  showObj={showObj}
                  headerLabels={showObj.headerLabel}
                  data={showObj.data}
                  type={showObj.type}
                />
              </View>
            </ScrollView>
          </View>
        )}
      </ScrollView>}

    </View>
  );
};


export default ViewAllDataScreen;

const styles = StyleSheet.create({
  container: {
    width: "95%",
    minWidth: 900,
    backgroundColor: "#fff",
    alignSelf: "center",
    marginVertical: 50,
  },
});
