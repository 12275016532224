import React from "react";
import { PropagateLoader } from "react-spinners";

function NutritionInputs({ children, title, printIcon, onSave }) {
  const [loading, setLoading] = React.useState(false);

  const onClickSave = async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  };

  if (loading) return <LoadingForSave />;

  return (
    <div className='flex items-center overflow-auto justify-center w-full border bg-[#f9f9f9]'>
      <div className='items-center justify-center w-[80%] h-fit border  bg-white '>
        <div className='flex items-center justify-between w-full p-6 '>
          <h1 className='text-3xl font-bold'>{title}</h1>
          <div className='flex items-center'>
            {onSave && (
              <div
                onClick={onClickSave}
                className='p-2 px-8 font-bold text-white bg-red-500 cursor-pointer'>
                Save
              </div>
            )}
            {printIcon ? (
              <div className='w-[100px] mx-2'>{printIcon}</div>
            ) : null}
          </div>
        </div>
        {children}
        <div className='py-7' />
      </div>
    </div>
  );
}

export const LoadingForSave = () => (
  <div className='flex items-center justify-center flex-1 w-full'>
    <PropagateLoader size={40} color='#ff0f0f' />
  </div>
);

export default NutritionInputs;
