import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { useHistory } from 'react-router-dom/'
import ViewNutritionInvoice from '../../../components/cafeteria/ViewNutritionInvoice'
import NutritionCreateInvoice from '../../../components/cafeteria/NutritionCreateInvoice'
import getSellerDetails from '../../../customFunction/nutrition/getSellerDetails'
import useQuery from '../../../customFunction/useQuery'

function CafeteriaPurchasesDetails() {
    const { purchaseId } = useParams()
    const isNew = useQuery().get('new')
    const { data, isLoading, error } = useSwr('/cafeteria-purchases/' + purchaseId + '?isNew=' + isNew, fetcher)
    const [currentData, setCurrentData] = useState()
    const [isLoadingg, setIsLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData(data[0])
        }
    }

    const onClickSubmit = async (currentProducts) => {
        setIsLoading(true)
        try {
            await axios.post('/cafeteria-purchases', {
                items: currentProducts,
                sellerDetails: getSellerDetails()
            })
            setIsLoading(false)
            history.goBack()
        } catch (e) {
            alert(e)
        }

    }

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }

    return (
        <div className='flex flex-1 w-full' >
            {isNew ? <NutritionCreateInvoice showPayedPrice id='purchases' onClickSubmit={onClickSubmit} >
                <div className='flex items-center justify-between w-full text-4xl font-bold' >
                    <h1>Create Purchase Invoice </h1>
                </div>
            </NutritionCreateInvoice> : <ViewNutritionInvoice id='purchases' invoiceProducts={currentData?.items} >
                <div className='flex items-center justify-between w-full text-4xl font-bold' >
                    <h1>Purchase Invoice </h1>
                    <h1 className='text-lg opacity-70' >#{currentData?.numberId}</h1>
                </div>
            </ViewNutritionInvoice>}
        </div>
    )
}

export default CafeteriaPurchasesDetails
