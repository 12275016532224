const initialState = { courseTypes: null };

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COURSE_TYPES": {
      return {
        ...state,
        courseTypes: action.data,
      }
    };

    case "CREATE_COURSE_TYPES": {
      let dt = state.courseItem.concat(action.data);
      return {
        ...state,
        courseTypes: dt,
      };
    }
    case "UPDATE_COURSE_TYPES": {
      let dt = state.courseTypes.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        courseTypes: dt,
      };
    }
    case "DELETE_COURSE_TYPES": {
      let dt = state.courseTypes.filter((a) => a._id !== action.data);
      return {
        ...state,
        courseTypes: dt,
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
