import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  TextInput
} from "react-native";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Colors from "../../constant/Colors";
import { useHistory, useLocation,useParams } from "react-router-dom";
import { createSupplementsStorage, } from "../../store/actions/supplementStorageAction.js";
import { handleLoading } from "../../store/actions/loadingAction";
import { RiFileExcel2Fill } from "react-icons/ri";
import { downloadExcel } from "react-export-table-to-excel";

function SellSupplementsScreen() {
  const [currentUser, setCurrentUser] = useState(false);
  const [cart, setCart] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentStorage, setCurrentStorage] = useState();
  const SupplementsStorage = useSelector(
    (state) => state.supplementsStorage.supplementsStorage
  );

  const centers = useSelector((state) => state.centers.centers);
  let supplementItems = useSelector(
    (state) => state.supplementItems.supplementItems
  );
  const state = useLocation().state
  const sellerId=useParams().sellerId
  let id = state?.centerId || sellerId;

  
  const onSubmit = async () => {
    try {
      handleLoading();
      //!new Refrence to cart
      const newCart = JSON.parse(JSON.stringify(cart))

      let newIt = [];

      if (currentStorage && currentStorage.items) {
        newIt = [...currentStorage.items];
        await newCart.forEach((it) => {
          const newItem = currentStorage.items.find(
            (a) => a.itemId === it.itemId
          );
          if (newItem) {
            newIt = newIt.filter((a) => a.itemId !== newItem.itemId);
            newItem.quntity = it.quntity;
          }
          newIt.push(it);
        });
      }

      let newData = {
        ...currentStorage,
        centerId: currentStorage?.centerId || id,
        items: newIt,
        totalPrice
      };

      const res = await createSupplementsStorage(newData);
      setCurrentStorage(res);
      setCart([]);
      setTotalPrice(0);
      handleLoading();
    } catch (e) {
      handleLoading();
      alert(e)
      console.log(e);
    }
  };

  useEffect(() => {
    if (SupplementsStorage) setup();
  }, [SupplementsStorage]);

  const setup = async () => {
    try {
      if (centers.length !== 0) {
        findCurrentUser();
        let data = await SupplementsStorage.find((it) => it.centerId === id);

        setCurrentStorage(data || []);
      } else throw "No Data";
    } catch (e) {
      alert(e);
    }
  };

  const onSelectItem = (item) => {
    let arr = cart || [];

    arr.push({
      itemId: item._id,
      quntity: 1,
      price: Number(item.wholesalePrice),
    });

    setCart(() => [...arr]);
  };

  const calculatePrice = () => {
    let arr = cart || [];

    let tp = 0;

    arr.forEach((cr) => {
      tp += Number(cr?.price) * cr.quntity;
    });

    setTotalPrice(tp);
  };

  useEffect(() => {
    calculatePrice();
  }, [cart]);

  const onChangeQuantity = (id, value) => {
    let arr = cart;
    try {
      arr.forEach((cr, i) => {
        if (cr.itemId === id) {
          arr[i].quntity = value
        }
      });

      setCart(() => [...arr]);
    } catch (e) {
    }
  };
  const onDeleteItem = (id) => {
    let arr = cart;
    let newArr = [];

    arr.forEach((cr, i) => {
      if (cr.itemId !== id) {
        newArr.push(cr);
      }
    });
    setCart(() => [...newArr]);
  }

  const findCurrentUser = async () => {
    try {
      let currUser = centers.find((a) => a._id === id);
      setCurrentUser(currUser);
    } catch (e) { }
  };

  if (!SupplementsStorage) {
    return null;
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#f1f1f1",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >

      <Menu
        supplementItems={supplementItems}
        cart={cart}
        onSelectItem={onSelectItem}
        onChangeQuantity={onChangeQuantity}
        currentUser={currentUser}
        onDeleteItem={onDeleteItem}
        currentItems={(currentStorage && currentStorage.items) || []}
      />
      <CurrentUsers
        supplementItems={supplementItems}
        cart={cart}
        totalPrice={totalPrice}
        currentUser={currentUser}
        onSubmit={onSubmit}
      />
    </View>
  );
}

const CurrentUsers = ({
  currentUser,
  cart,
  totalPrice,
  onSubmit,
  supplementItems,
}) => {
  return (
    <View
      style={{
        width: "25%",
        height: "90%",
        justifyContent: "space-between",
        backgroundColor: "#fff",
      }}
    >
      <View style={{ height: "90%" }}>
        <User currentUser={currentUser} />
        <SelectedItem supplementItems={supplementItems} cart={cart} />
      </View>
      <View style={{ height: "10%" }}>
        <Prices totalPrice={totalPrice} />
        <h5 style={{ color: 'red', }} >This submit replace this quantity item to the current not for add to storage</h5>

        <SubmitButton
          onSubmit={onSubmit}
          cart={cart}
          currentUser={currentUser}
          totalPrice={totalPrice}
        />
      </View>
    </View>
  );
};

const User = ({ }) => {
  return (
    <View
      style={{
        display: "flex",
        width: "100%",
        height: 120,
      }}
    >
      <Image
        source={{ uri: "https://i.ibb.co/FYVmc9z/AKAFitness.png" }}
        style={{ height: "100%", width: "100%" }}
      />
    </View>
  );
};

const SelectedItem = ({ cart, supplementItems }) => {
  return (
    <ScrollView>
      {cart &&
        cart.map((cr, i) => {
          return (
            <SelectedItemIndex
              supplementItems={supplementItems}
              item={cr}
              key={i}
            />
          );
        })}
    </ScrollView>
  );
};

const SelectedItemIndex = ({ item, supplementItems }) => {
  const [currentItem, setCurrentItem] = useState(false);

  const setUpCurrentItem = () => {
    let check = supplementItems.find((a) => a._id === item.itemId);

    setCurrentItem(check);
  };

  useEffect(() => {
    if (item) {
      setUpCurrentItem();
    }
  }, [item]);

  return (
    <View
      style={{
        display: "flex",
        height: 60,
        width: "98%",
        alignSelf: "center",
        marginVertical: 5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10,
        borderBottomWidth: 0.2,
        borderColor: "rgba(29,29,29,0.2)",
      }}
    >
      {currentItem && (
        <View style={{ width: "70%", fontSize: 14 }}>
          <h1>
            ({item.quntity}) - {currentItem.name}
          </h1>
        </View>
      )}
      {currentItem && (
        <View style={{ marginLeft: 15 }}>
          <h1 style={{ fontSize: 22 }}>
            {numeral(Number(item?.price) * item.quntity).format("$0,0.00")}
          </h1>
        </View>
      )}
    </View>
  );
};

const Prices = ({ totalPrice }) => {
  return (
    <View
      style={{
        display: "flex",
        width: "100%",
        height: "50%",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: 10,
        borderColor: "rgba(29,29,29,0.2)",
        borderTopWidth: 0.8,
      }}
    >
      <h1 style={{ fontSize: 20 }}>
        Total Price: {numeral(totalPrice).format("0.00.0$")}
      </h1>
    </View>
  );
};

const Item = ({
  item,
  cart,
  onSelectItem,
  onChangeQuantity,
  currentUser,
  currentItems,
  onDeleteItem
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [inStorage, setInStorage] = useState(false);
  const [deleteItem, setDeleteItem] = useState(-1);
  const onSelectItemHandler = () => {
     onSelectItem(item);
  };

  const checkForSelected = () => {
    let check = false;
    if (cart && cart.length !== 0) {
      check = cart?.find((a) => a.itemId === item._id);
    }

    setIsSelected(check);
  };

  const onChangeQuantityHandler = (value) => {
    onChangeQuantity(isSelected.itemId, value);
  };

  const checkForInStorage = () => {
    let currItem = currentItems.find((a) => a.itemId === item._id);

    setInStorage(currItem);
  };

  useEffect(() => {
    checkForSelected();
    checkForInStorage();
  }, [cart]);

  const onPressKeyDelete = (key) => {
    if (key === 'Control') {
      if (deleteItem === -1) setDeleteItem(0)
    }
    if (key === 'Backspace') {
      if (deleteItem === 0) {
        onDeleteItem(item._id)
        setTimeout(() => {
          setDeleteItem(-1)
        }, 1000);
      }
    }
  }

  return (
    <View
      style={{
        height: 320,
        width: 230,
        margin: 10,
        marginLeft: 10,
        marginRight: 10,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <Image
        style={{
          position: "absolute",
          height: "50%",
          width: "50%",
        }}
        source={{ uri: item.imageUri }}
      />
      <View
        style={{
          height: "85%",
          width: "100%",
          zIndex: 100,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <h1
          style={{
            fontSize: 23,
            color: "#292929",
            opacity: 0.9,
          }}
        >
          {numeral(Number(item.wholesalePrice)).format("$0,0.00")}
        </h1>
        <h1
          style={{
            fontSize: 14,
            marginTop: 7,
            color: "#292929",
            width: "85%",
            textAlign: "center",
            opacity: 0.7,
            textTransform: "capitalize",
          }}
        >
          ({inStorage ? inStorage.quntity : 0}) {item.name}
        </h1>
      </View>
      {isSelected ? (
        <View
          style={{
            height: "15%",
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 100,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <input
            onKeyDown={(e) => {
              onPressKeyDelete(e.key)
            }}
            onKeyUp={() => setDeleteItem(-1)}
            type='text'
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#e0e0e0",
              alignItems: "center",
              justifyContent: "center",
              outline: 'none',
              textAlign: 'center',
              fontSize: 20
            }}
            onChange={(e) => onChangeQuantityHandler(Number(e.target.value))}
            value={isSelected.quntity}
          />
        </View>
      ) : (
        <View
          onClick={onSelectItemHandler}
          style={{
            display: "flex",
            height: "15%",
            width: "100%",
            backgroundColor: currentUser
              ? Colors.mainAqwaBlue
              : "rgba(29,29,29,0.7)",
            zIndex: 100,
            alignItems: "center",
            justifyContent: "center",
            cursor: currentUser ? "pointer" : "no-drop",
          }}
        >
          <h1 style={{ color: "#fff" }}>{"Select"}</h1>
        </View>
      )}
    </View>
  );
};

const Menu = ({
  cart,
  onSelectItem,
  onChangeQuantity,
  currentUser,
  supplementItems,
  currentItems,
  onDeleteItem
}) => {
  const history = useHistory()

  const handleDownloadExcel = async () => {
    const data = []
    await currentItems.map((item) => {
      const suppItem = supplementItems.find((a) => a._id === item.itemId)
      if (suppItem)
        data.push({ name: suppItem.name, quantity: item.quntity })
    })
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: ['name', 'quantity'],
        body: data,
      },
    });
  }
  const navToStorageActivity = () => {
    history.push(window.location.pathname + '/storage-activity')
  }

  return (
    <View
      style={{
        marginLeft: 60,
        width: "65%",
        maxHeight: "95%",
        minHeight: "95%",
        flexDirection: "row",
        flexWrap: "wrap",
        overflow: "auto",
        justifyContent: "space-between",
      }}
    >
      <TouchableOpacity
        onPress={navToStorageActivity}
        style={{
          width: "100%",
          backgroundColor: Colors.mainYellow,
          height: 40,
          justifyContent: "flex-start",
          padding: 5,
          marginBottom: 4,

        }}
      >
        <h2 style={{ color: '#fff' }} >بینینی زانیاریەکانی کۆگای پڕۆتین</h2>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={handleDownloadExcel}
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: 40,
          justifyContent: "flex-start",
          padding: 5,
        }}
      >
        <RiFileExcel2Fill
          size={30}
          color="#216e43"
          style={{ marginLeft: 10 }}
        />
      </TouchableOpacity>
      {(supplementItems||currentItems) &&
        (supplementItems||currentItems).map((d, i) => {
          return (
            <Item
              currentItems={currentItems}
              currentUser={currentUser}
              onChangeQuantity={onChangeQuantity}
              item={d}
              onDeleteItem={onDeleteItem}
              onSelectItem={onSelectItem}
              cart={cart}
              key={i}
            />
          );
        })}
    </View>
  );
};

const SubmitButton = ({ onSubmit, cart, currentUser, totalPrice }) => {
  const [isValid, setIsValid] = useState(false);

  const checkForValidation = () => {
    let check = true;
    if (cart.length === 0) {
      check = false;
    }

    setIsValid(check);
  };

  useEffect(() => {
    checkForValidation();
  }, [cart, currentUser, totalPrice]);

  return (
    <View
      onClick={isValid ? onSubmit : () => { }}
      style={{
        display: "flex",
        width: "100%",
        height: "50%",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: 10,
        borderTopWidth: 1,
        borderColor: "rgba(29,29,29,0.2)",
        backgroundColor: isValid ? Colors.mainAqwaBlue : "rgba(29,29,29,0.7)",
        cursor: isValid ? "pointer" : "no-drop",
      }}
    >
      <h1 style={{ fontSize: 20, color: "#fff" }}>Submit</h1>
    </View>
  );
};

export default SellSupplementsScreen;

const styles = StyleSheet.create({});
