const initialState = { requests: null, length: 0 };

const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_REQUESTS":
      return {
        ...state,
        requests: action.data,
      };
    case "FETCH_REQUESTS_LENGTH":
      return {
        ...state,
        length: action.data,
      };

    case "CREATE_REQUEST": {
      let dt = state.requests.concat(action.data);
      return {
        ...state,
        requests: dt,
      };
    }
    case "UPDATE_REQUEST": {
      let dt = state.requests.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        requests: dt,
      };
    }
    case "DELETE_REQUEST": {
      let dt = state.requests.filter((a) => a._id !== action.data);
      return {
        ...state,
        requests: dt,
      };
    }
    default:
      return state;
  }
};

export default requestsReducer;
