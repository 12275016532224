import axios from "axios";
import { store } from "../../App";

export const uri = {
  customers: "customers",
  repaymentOfDebt: "repayment-of-debt",
  debts: "debts",
  buySupplementActivity: "buy-supplement-activity",
  productReturnActivity: "product-return-activity",
  sellSupplementsActivity: "sell-supplements-activity",
  expenses: "supplements-expense",
  brands: "protein-brands",
  moneyTrans: 'protein-brands-money-transfer',
  customersRatio: "customers-ratio",
};
export const createSpecificSuppProperty = async (data, property,) => {

  const sellerId = window.location.pathname.split('/')[2]
  const isMainSeller = sellerId === '6371150d50d8a55750df0d7d'
  let CREATE_URI = "create";
  if (property === 'productReturnActivity')
    CREATE_URI = "return";
  else if (property === 'sellSupplementsActivity')
    CREATE_URI = "sell";
  else if (property === 'buySupplementActivity') {
    CREATE_URI = "buy";
  }
  // else if (isMainSeller && property === 'repaymentOfDebt') {
  //   CREATE_URI = "repayment-by-invoice";
  // }
  CREATE_URI += `?byProteinSeller=true&isMainSeller=${isMainSeller}`;

  try {
    if (data._id) {
      await axios.put("/" + uri[property] + `/update/${data._id}`, data);
    } else {
      //!
      await axios.post("/" + uri[property] + '/' + CREATE_URI, { ...data, sellerId });
    }
  } catch (e) {
    throw e;
  }
};

export const createSpecificSuppPropertyReducer = (data, property, crudType) => {
  store.dispatch({
    type: crudType === 1
      ? "UPDATE_SPECIFIC_SUPP_PROPERTY"
      : "CREATE_SPECIFIC_SUPP_PROPERTY",
    data,
    property,
  });
}
export const deleteSpecificSuppPropertyReducer = (id, property) => {
  store.dispatch({
    type: "DELETE_SPECIFIC_SUPP_PROPERTY",
    property,
    id,
  });
}

export const cancelSupplementOrder = async (order) => {
  try {
    const sellerId = window.location.pathname.split('/')[2]

    await axios.post(`/sell-supplements-activity/cancel?isMainSeller=${sellerId === '6371150d50d8a55750df0d7d'}`, order)
  } catch (e) {
    throw e;
  }
}

export const fetchSpecificSuppProperty = async (property) => {
  try {
    const user = store.getState().authentication.user;
    let query = '?'
    if (user.type === 8) {
      query += 'sellerId=' + user._id
    }
    const res = await axios.get(`/${uri[property]}${query}`);
    store.dispatch({
      type: "FETCH_SPECIFIC_SUPP_PROPERTY",
      data: res.data,
      property,
    });
  } catch (e) {
    throw e;
  }
};
export const deleteSpecificSuppProperty = async (property, id) => {
  try {
    await axios.delete("/" + uri[property] + `/delete/${id}`);
  } catch (e) {
    throw e;
  }
};

//!calcIsHide to calculate hide items
export const getCurrentCustomerDebt = async (customerId, calcIsHide) => {
  try {
    if (!customerId) return 0;
    let totalDebts = 0;
    let totalReturnDebt = 0;
    let totalReturnProducts = 0;
    let totalRatio = 0;

    await store.getState().supplementsShop.debts.map((d) => {
      if (d.customerId === customerId) {
        totalDebts += false ? calcIsHide ? d.loan : 0 : d.loan;
      }
    });
    await store.getState().supplementsShop.repaymentOfDebt.map((d) => {
      if (d.customerId === customerId) {
        totalReturnDebt += false ? calcIsHide ? d.price : 0 : d.price;
      }
    });

    await store.getState().supplementsShop.productReturnActivity.map((d) => {
      if (d.customerId === customerId) {
        totalReturnProducts += false ? calcIsHide ? d.totalPrice : 0 : d.totalPrice;
      }
    });
    await store.getState().supplementsShop.customersRatio.map((d) => {
      if (d.customerId === customerId && d.type === 0) {
        totalRatio += d.price;
      }
    });

    return totalDebts - totalReturnDebt - totalReturnProducts - totalRatio;
  } catch (e) {
    throw e;
  }
};
export const getCurrentCustomersDebt = async (customers, calcIsHide) => {
  try {
    if (!customers.length) return 0;
    let totalDebts = 0;
    let totalReturnDebt = 0;
    let totalReturnProducts = 0;

    await customers.map(async ({ customerId }) => {
      await store.getState().supplementsShop.debts.map((d) => {
        if (d.customerId === customerId) {
          totalDebts += false ? calcIsHide ? d.loan : 0 : d.loan;
        }
      });
      await store.getState().supplementsShop.repaymentOfDebt.map((d) => {
        if (d.customerId === customerId) {
          totalReturnDebt += false ? calcIsHide ? d.price : 0 : d.price;
        }
      });

      await store.getState().supplementsShop.productReturnActivity.map((d) => {
        if (d.customerId === customerId) {
          totalReturnProducts += false ? calcIsHide ? d.totalPrice : 0 : d.totalPrice;
        }
      });
    })



    return totalDebts - totalReturnDebt - totalReturnProducts;
  } catch (e) {
    throw e;
  }
};

export const updateSupplementSearch = async (value) => {
  try {
    await store.dispatch({ type: "UPDATE_SUPPLEMENT_SEARCH", data: value });
  } catch (e) {
    console.log(e);
  }
};

export const onChangeSellSuppShopDate = async (value) => {
  try {
    await store.dispatch({ type: "CHANGE_SELL_SUPP_SHOP_DATE", data: value });
  } catch (e) {
    console.log(e);
  }
}