const initialState = { cafeteriaGuestUsers: null, activityPurchase: null };

const cafeteriaGuestUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CAFETERIA_GUEST_USERS":
      return {
        ...state,
        cafeteriaGuestUsers: action.data,
      };

    case "FETCH_ACTIVITY_PURCHASE":
      return {
        ...state,
        activityPurchase: action.data,
      };

    case "CREATE_CAFETERIA_GUEST_USERS": {
      let dt = state.cafeteriaGuestUsers.concat(action.data);
      return {
        ...state,
        cafeteriaGuestUsers: dt,
      };
    }
    case "UPDATE_GUEST_USERS": {
      let dt = state.cafeteriaGuestUsers.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        cafeteriaGuestUsers: dt,
      };
    }
    default:
      return state;
  }
};

export default cafeteriaGuestUsersReducer;
