import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ku from "./Locales/ku";
import en from "./Locales/en";
import ar from "./Locales/ar";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ku: {
        translation: {
          ...ku,
        },
      },
      en: {
        translation: {
          ...en,
        },
      },
      ar: {
        translation: {
          ...ar,
        },
      },
    },
    lng: "ku",
    fallbackLng: "ku",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;