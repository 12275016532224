import i18next from "i18next";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

function CustomModal({ onChangeModal, className = {}, title, children }) {
  const Analysis=useSelector(state=>state.analysis.data)
  const language = i18next.language

  return (
    <div
      className={`fixed  top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[100] flex items-center justify-center`}>
      <div
        className={
          `flex flex-col p-4 bg-white  w-1/2 h-1/2 rounded=[8px]  ` + className
        }>
        <div className={`flex ${language === 'en' ? 'flex-row' : 'flex-row-reverse'} items-center  justify-between`}>
          <p className='text-2xl font-semibold'>{title}</p>
          <AiOutlineCloseCircle
            color='red'
            onClick={onChangeModal}
            className='cursor-pointer'
            size={22}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

export default CustomModal;
