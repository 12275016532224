
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrencyUnit,
  updateDuration,
} from "../../store/actions/analyticsAction";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { DateRange, } from 'react-date-range'
import i18n from "../../i18n";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const controlType = [
  { type: 0, label: "currentMonth" },
  { type: 4, label: "lastMonthByThisTime" },
  { type: 1, label: "lastMonth" },
  { type: 3, label: "allOfThem" },
];

const currencyControlType = [
  { type: 0, label: "USD" },
  { type: 1, label: "IQD" },
];

const DurationCallender = ({ duration, isEquipment, isSupplement }) => {
  const dispatch = useDispatch();
  const [currentType, setCurrentType] = useState(false);
  const [currentCurrencyType, setCurrentCurrecyType] = useState(0);
  const [dateHasChange, setDateHasChange] = useState(false)
  const user = useSelector(state => state.authentication.user)
  const centerId = useParams().centerId

  const [datePickerHandler, setDatePickerHandler] = useState([
    {
      startDate: dayjs(duration.startDate).toDate(),
      endDate: dayjs(duration.endDate).toDate(),
      key: 'selection'
    }
  ]);

  const [showDate, setShowDate] = useState(false)
  const { t } = useTranslation();
  const CentersData = useSelector((state) => state.analysis.centersData);
  const language = i18n.language
  const onClickSelectDate = () => {
    setShowDate(!showDate)
  }

  const onChangeDateRange = () => {
    const startDate = dayjs(datePickerHandler[0].startDate).valueOf();
    const endDate = dayjs(datePickerHandler[0].endDate).hour(23).minute(59).second(59).valueOf();
    //!check if the start date is before 2023-07-01
    
    dispatch(updateDuration(0, { startDate: new Date(startDate), endDate: new Date(endDate) }, isEquipment, isSupplement));
    setCurrentType(false);
  }

  const changeToCurrentMonth = async (pickerType) => {
    let currentMonth = dayjs().format("MM");
    let currentYear = dayjs().format("YYYY");
    let currentDay = dayjs().format('DD') == '31' ? dayjs().format('DD') : dayjs().add(1, "day").format("DD");

    let startDate = dayjs(`${currentYear}-${currentMonth}-01`).valueOf()

    let endDate = dayjs(`${currentYear}-${currentMonth}-${currentDay}`).valueOf()
    
    //!replace type 0 replace all, 1 replace only date
    await dispatch(updateDuration(0, { startDate, endDate }, isEquipment, isSupplement, pickerType));
  };

  useEffect(() => {
    if (dayjs(datePickerHandler[0].startDate).format('YYYY-MM-DD') !== dayjs(duration.startDate).format('YYYY-MM-DD') || dayjs(datePickerHandler[0].endDate).format('YYYY-MM-DD') !== dayjs(duration.endDate).format('YYYY-MM-DD'))
      onChangeDateRange()
  }, [dateHasChange])

  const lifeTime = async (pickerType) => {
    let startDate = dayjs("07-01-2022").valueOf();
    let endDate = dayjs().valueOf().hour(23).minute(59);
    
    await dispatch(updateDuration(0, { startDate, endDate }, isEquipment, isSupplement), pickerType);
  };

  const lastMonth = async (pickerType) => {
    let sCurrentMonth = dayjs().subtract(1, "month").format("MM");
    let sCurrentYear = dayjs().format("YYYY");
    if (sCurrentMonth == 12) {
      sCurrentYear = dayjs().subtract(1, "year").format("YYYY");
    }

    let eCurrentDay = dayjs(sCurrentMonth, sCurrentYear).daysInMonth();

    
    let startDate = dayjs(`${sCurrentYear}-${sCurrentMonth}-01`).valueOf()
    let endDate = dayjs(`${sCurrentYear}-${sCurrentMonth}-${eCurrentDay}`).hour(23).minute(59).valueOf()
    
    await dispatch(updateDuration(0, { startDate, endDate }, isEquipment, isSupplement, pickerType));

  };

  const lastMonthUntilThiDate = async (pickerType) => {
    let sCurrentMonth = dayjs().subtract(1, "month").format("MM");
    let sCurrentYear = dayjs().format("YYYY");
    let todayFormat = dayjs().subtract(1, "M").format("DD");
    if (sCurrentMonth == 12) {
      sCurrentYear = dayjs().subtract(1, "year").format("YYYY");
    }

    let startDate = dayjs(`${sCurrentYear}-${sCurrentMonth}-01`).valueOf()
    let endDate = dayjs(`${sCurrentYear}-${sCurrentMonth}-${todayFormat}`).hour(23).minute(59).valueOf()
   
    await dispatch(updateDuration(0, { startDate, endDate }, isEquipment, isSupplement, pickerType));
  };

  const onChangeControll = async (type) => {
    try {
      if (type == 0) {
        await changeToCurrentMonth(type);
      } else if (type == 1) {
        await lastMonth(type);
      } else if (type == 3) {
        await lifeTime(type);
      } else if (type == 4) {
        await lastMonthUntilThiDate(type);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const onChangeCurrencyControll = async (type) => {
    if (isSupplement || isEquipment) type = 0
    await dispatch(updateCurrencyUnit(type));
    setCurrentCurrecyType(type);
  };

  return (
    <div
      className="my-2"
      style={{
        width: "98.5%",
        display: 'flex',
        backgroundColor: "#fff",
        alignSelf: "center",
        borderRadius: 5,
        justifyContent: "space-between",
        padding: 20,
        flexDirection: "row",
        alignItems: "center",
        zIndex: 50,
        flexWrap: "wrap",
      }}
    >
      {!isSupplement && (
        <CurrencyControll
          currentType={currentCurrencyType}
          isSupplement={isSupplement}
          onChangeControll={onChangeCurrencyControll}
        />
      )}
      <Controll
        currentType={currentType}
        onChangeControll={onChangeControll}
      />

      <div className="mt-2" >
        <div onClick={onClickSelectDate} style={{ minWidth: 250 }} className="flex items-center">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <input value={dayjs(duration.startDate).format('DD-MM-YYYY') + ' - ' + dayjs(duration.endDate).format('DD-MM-YYYY')} type="text" className="bg-gray-50 select-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 " placeholder={t("selectDate")} />
          </div>
        </div>
        {showDate && <DateRange
          className={`absolute z-10 border right-3 top-20`}
          editableDateInputs={true}

          onChange={item => {
            setDatePickerHandler([item.selection])
          }}

          onRangeFocusChange={(a) => {
            if (a[0] === 0 && a[1] === 0) {
              setShowDate(false)
              setDateHasChange(!dateHasChange)
            }
          }}
          moveRangeOnFirstSelection={false}
          ranges={datePickerHandler}
        />}
      </div>

    </div>
  );
};

export const Pickers = ({
  duration,
  onChangeOneDay,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: "row",
        width: 400,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: "center",
          width: '50%'
        }}
      >
        <input
          className="border-none outline-none"
          title="test"
          type={"date"}
          onChange={onChangeOneDay}
          value={dayjs(duration.date).format("YYYY-MM-DD")}
        />
      </div>
      <div style={{ width: '50%' }} >
        <div
          style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",

          }}
        >
          <input
            type={"date"}
            className="my-1 border-none outline-none"
            onChange={onChangeStartDate}
            value={dayjs(duration.startDate).format("YYYY-MM-DD")}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type={"date"}
            className="border-none outline-none"
            onChange={onChangeEndDate}
            value={dayjs(duration.endDate).format("YYYY-MM-DD")}
          />
        </div>
      </div>

    </div>
  );
};

const Controll = ({ onChangeControll, currentType }) => {
  const onChangeVal = (val) => {
    onChangeControll(val);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: "center",
        marginTop: 8,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <div style={{ marginHorizontal: 15 }}>
          <select
            style={{
              height: "100%",
              color: "rgba(0,0,0,.7)",
              borderWidth: 0,
              backgroundColor: "#f0f0f0",
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
            className="border-none outline-none"
            value={currentType}
            onChange={(a) => onChangeVal(a.target.value)}
          >
            <option value={""}>{t('select')}</option>
            {controlType.map((bd) => (
              <option key={bd.type} value={bd.type}>{t(bd.label)}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const CurrencyControll = ({ onChangeControll, currentType }) => {
  const currency = useSelector((state) => state.analytics.currency);

  const onChange = (val) => {
    onChangeControll(val);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 8,

      }}
    >
      <div className="flex">
        {currencyControlType && currencyControlType.length !== 0 && (
          <div style={{ marginHorizontal: 15 }}>
            <select
              style={{
                height: "100%",
                color: "rgba(0,0,0,.7)",
                borderWidth: 0,
                backgroundColor: "#f0f0f0",
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
              className="border-none outline-none"
              value={currency === "USD" ? 0 : 1}
              onChange={(a) => onChange(a.target.value)}
              defaultValue={0}
            >
              {currencyControlType.map((bd) => (
                <option value={bd.type}>{bd.label}</option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};
export default DurationCallender;
