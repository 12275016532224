import dayjs from "dayjs";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Colors from "../../../constant/Colors";
import { getCurrentCustomerDebt } from "../../../store/actions/supplementsShopAction";
import { getCurrentCustomerDebt as getCurrentCustomerEqDebt } from "../../../store/actions/equipmentAction";
import AdminTypes from "../../../data/Admin/AdminTypes";
import { t } from "i18next";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";
import i18n from "../../../i18n";

const headerLables = ["userId", "name", "phoneNumber", "registeredAt"];

const ItemIndex = ({ index, onPressItem, data, type }) => {
  const [labels, setLabeles] = useState(false);

  const RepaymentOfDebt = useSelector(
    (state) => state.supplementsShop.repaymentOfDebt
  );
  const RecipientsOfPayments = useSelector((state) => state.receiptsOfPayments.recipientsOfPayments);

  const Debts = useSelector((state) => state.supplementsShop.debts);
  const Customers = useSelector((state) => state.supplementsShop.customers);
  const EqCustomers = useSelector(
    (state) => state.equipments.equipmentCustomers
  );
  const brands = useSelector((state) => state.equipments.equipmentBrands);
    const language=i18n.language
  const centers = useSelector(state => state.centers.centers)
  const setUpLabels = async () => {
    let arr = [];

    if (type === 0) {
      const features = { 0: "Gym", 1: "Swim" };

      arr.push({ label: data?._id.substring(4) + '...' });
      arr.push({ label: data?.labelDetails?.inEn?.title });
      arr.push({ label: numeral(data?.price?.value).format("$0,0.00") });
      arr.push({ label: data?.features?.map((a) => features[a]).join(", ") });
      let centerName = ''
      await Promise.all(centers.map(center => {
        if (data.center.includes(center._id)) {
          centerName += center.label + ', '
        }
      }))
      arr.push({ label: centerName });
    } else if (type === 1) {
      arr.push({ label: data?._id });
      arr.push({ label: data?.name });
      arr.push({ label: data?.email });
      arr.push({
        label: t(AdminTypes.find((a) => a.type === data?.type).label),
      });
    } else if (type === 2) {
      arr.push({ label: data?._id });
      arr.push({ label: data?.label });
      arr.push({ label: dayjs(data?.createdAt).format("DD/MM/YYYY") });
    } else if (type === 3) {
      arr.push({ label: data?.bioStarID });
      arr.push({ label: data?.name });
      arr.push({ label: data?.phone });
      arr.push({ label: dayjs(data?.createdAt).format("DD/MM/YYYY") });

      let end = dayjs(data?.expiry_datetime).utc();
      let start = dayjs().utc();

      //Difference in number of days
      let dayNum = dayjs.duration(start.diff(end)).asDays() * -1;

      arr.push({
        label:
          dayjs(data?.expiry_datetime).utc().fromNow(true) +
          " - " +
          dayjs(data?.expiry_datetime).utc().format("DD-MM-YY"),
        isBold: true,
        color:
          dayNum > 5
            ? Colors.mainGreenColor
            : dayNum <= 0
              ? Colors.soldColor
              : "orange",
      });
    } else if (type === 4) {
      arr.push({ label: data._id });
      arr.push({ label: data.numberId });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: dayjs(data.createdAt).fromNow(true) });
    } else if (type === 5) {
      arr.push({ label: data._id });
      arr.push({ label: data?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: dayjs(data.createdAt).fromNow(true) });
    } else if (type === 6) {
      arr.push({ label: data._id });
      arr.push({ label: data?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: dayjs(data.createdAt).fromNow(true) });
    } else if (type === 7) {
      arr.push({ label: data._id });
      arr.push({ label: data?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: dayjs(data.createdAt).fromNow(true) });
    } else if (type === 8) {
      arr.push({ label: data?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: numeral(data.wholesalePrice).format("$0,0.00") });
      arr.push({ label: numeral(data.minimumsalePrice).format("$0,0.00") });
      arr.push({ label: data.quantity });
    } else if (type === 9) {
      arr.push({ label: data._id });
      arr.push({ label: data.name });
      arr.push({ label: showInIQD(data?.price, "IQD", "IQD") });
    } else if (type === 10) {
      arr.push({ label: data._id });
      arr.push({ label: data?.name });
      arr.push({ label: data?.iqd });
    } else if (type === 11) {
      arr.push({ label: data?.id });
      arr.push({ label: showInIQD(data?.price, "IQD", "IQD") });
      arr.push({ label: data?.numberOfUsers });
      arr.push({ label: dayjs(data?.createdAt).format("YYYY-MM-DD") });
    } else if (type === 12) {
      const debts = await getCurrentCustomerDebt(data?._id);

      arr.push({ label: data?._id });
      arr.push({ label: data?.name });
      arr.push({ label: numeral(debts).format("$0,0.00") });
      // arr.push({ label: dayjs(data?.createdAt).format("YYYY-MM-DD") });
    } else if (type === 13) {
      let currCustomer = Customers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 14) {
      let currCustomer = Customers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.loan).format("$0,0.00") });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 15) {
      let currCustomer = Customers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({ label: numeral(data?.payedPrice).format("$0,0.00") });
      let debts = data?.totalPrice - data?.payedPrice;
      if (debts < 0) {
        debts = 0;
      }
      arr.push({
        label: numeral(debts).format("$0,0.00"),
      });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 16) {
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({ label: data?.items.length });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 17) {
      let currCustomer = Customers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name || "unknown" });
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({ label: data?.items.length });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 18) {
      let currCustomer = Customers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 19) {
      let brand = brands.find((a) => a._id === data?.brandId);
      arr.push({ label: data?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({ label: data.quantity });
      arr.push({ label: brand?.name });
    } else if (type === 20) {
      arr.push({ label: data?.name });
      arr.push({
        label: dayjs(data?.createdAt).format("YYYY-MM-DD"),
      });
    } else if (type === 21) {
      const debts = await getCurrentCustomerEqDebt(data?._id);

      arr.push({ label: data?._id });
      arr.push({ label: data?.name });
      arr.push({ label: numeral(debts).format("$0,0.00") });
      // arr.push({ label: dayjs(data?.createdAt).format("YYYY-MM-DD") });
    } else if (type === 22) {
      let currCustomer = EqCustomers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.loan).format("$0,0.00") });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 23) {
      let currCustomer = EqCustomers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.price).format("$0,0.00") });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 24) {
      let currCustomer = EqCustomers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({ label: numeral(data?.payedPrice).format("$0,0.00") });
      let debts = data?.totalPrice - data?.payedPrice;
      if (debts < 0) {
        debts = 0;
      }
      arr.push({
        label: numeral(debts).format("$0,0.00"),
      });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    } else if (type === 25) {
      let currCustomer = EqCustomers.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: numeral(data?.totalPrice).format("$0,0.00") });
      arr.push({ label: data?.items.length });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    }
    else if (type === 26) {
      arr.push({ label: data?.price });
      arr.push({
        label:
          dayjs(data?.createdAt).format("YYYY-MM-DD") +
          " - " +
          dayjs(data?.createdAt).fromNow(true),
      });
    }
    else if (type === 27) {
      //!Money Transfer Protein
      arr.push({ label: data?.date });
      arr.push({ label: data?.note });
      arr.push({ label: data?.invoiceNo });
      arr.push({ label: data?.payedPrice || data?.invoiceBalance });
      // arr.push({ label: data?.note });

    } else if (type === 28) {
      //!receiptsOfPayments
      let totalPrice = data?.subs.map(a => a.totalPrice).reduce((a, b) => a + b, 0)
      let newC = RecipientsOfPayments.find(a => a._id === data.customer._id)

      arr.push({ label: data?.date });
      arr.push({ label: data?.receiptsNumber });
      arr.push({ label: newC?.name });
      arr.push({ label: totalPrice });
    } else if (type === 29) {
      //!receiptsOfPayments
      arr.push({ label: data?._id });
      arr.push({ label: data?.name });
      arr.push({ label: data?.phoneNumber });
    } else if (type === 30) {
      //!customs
      arr.push({ label: data?.date });
      arr.push({ label: data?.note });
      arr.push({ label: data?.cost });
      arr.push({ label: data?.carNumber });

    } else if (type === 31) {
      //!shippings
      arr.push({ label: data?.driverName });
      arr.push({ label: data?.cost || data?.debtPrice || data?.repaymentOfDebtPrice });
      arr.push({ label: data?.date });
    } else if (type === 32) {
      //!job 
      arr.push({ label: data?._id });
      arr.push({ label: data?.location });
      // arr.push({ label: data?.description?.inKu });

    } else if (type === 33) {
      //!job  cvs
      arr.push({ label: data?.name || data?._id });
      arr.push({ label: data?.date });
      arr.push({ label: (!!data?.isOpen) + '' });
      arr.push({ label: (!!data?.isCheck) + '' });

    } else if (type === 34) {
      //!ratio customers
      let currCustomer = Customers?.find((a) => a._id === data?.customerId);
      arr.push({ label: currCustomer?.name });
      arr.push({ label: data?.price });
      arr.push({ label: data?.date });

    } else if (type === 35) {
      //!equipment price lists
      let currCustomer = EqCustomers?.find((a) => a._id === data?.customerId);

      arr.push({ label: currCustomer?.name });
      arr.push({ label: data?.totalPrice + '$' });
      arr.push({ label: data?.date });
    } else if (type === 36) {
      let name=''
      centers.map(center => {
        if(data.centers.includes(center._id)){
          name+=center.names[language]+', '
        }
      })

      //!course types
      arr.push({ label: data.labels[language] });
      arr.push({ label: name });
      arr.push({ label: data?.price || data.iqd });
    }
    setLabeles(arr);
  }

  useEffect(() => {
    setUpLabels();
  }, [Debts, RepaymentOfDebt, Customers]);

  const onPresItemHanlder = () => {
    onPressItem(data);
  };

  return (
    <div
      onClick={onPresItemHanlder}
      className={`flex w-full justify-between  flex-row border-b p-2 cursor-pointer nav ${index % 2 === 0 ? "bg-white" : "bg-mainLightAqwa"
        } `}
    >
      {labels &&
        labels.map((dt, i) => {
          return (
            <div key={i} style={{ width: 900 / (headerLables?.length * 1.2), }}>
              <h1
                style={{
                  color: dt.color ? dt.color : Colors.mainDark,
                  width: 900 / (headerLables?.length * 1.2),
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                className={`${dt.isBold ? "font-medium" : null}`}
              >
                {dt.label}
              </h1>
            </div>
          );
        })}
    </div>
  );
};

export default ItemIndex;
