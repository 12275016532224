const initialState = { reports: null, fetchedDate: null };

const membershipsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_MONTHLY_REPORT": {
            const { data } = action;

            return {
                ...state,
                reports: data
            }
            // let memberships = state.memberships || []
            // let dates = state.fetchedDate || []
            // data.map((membership) => {
            //     if (!dates.find((date) => date === membership.date)) {
            //         const newMembers = data.filter(a => a.date === membership.date)
            //         memberships = memberships.concat(newMembers)
            //         dates.push(membership.date)
            //     }
            // })

            // if (!dates.find((date) => date === action.endDate)) {
            //     dates.push(action.endDate)
            // }

            // return {
            //     ...state,
            //     memberships: memberships,
            //     fetchedDate: dates
            // }
        };

        case "CREATE_MONTHLY_REPORT": {
            let dt = state.reports.concat(action.data);
            return {
                ...state,
                reports: dt,
            }
        };
        case "UPDATE_MONTHLY_REPORT": {
            let newMessaaging = [...state.reports];
            const currentMembership = action.data;

            newMessaaging = state.reports.map((msg) => {
                if (msg._id === currentMembership._id) {
                    msg = currentMembership;
                }
                return msg;
            });

            return { ...state, reports: newMessaaging };
        }
        case "DELETE_MONTHLY_REPORT": {
            let newMemberships = [...state.reports];

            newMemberships = state.reports.filter((a) => a._id !== action.data);

            return { ...state, reports: newMemberships };
        }
        default:
            return state;
    }
};

export default membershipsReducer;
