import numeral from "numeral";
import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  View,
  TouchableOpacity,
  Dimensions,
} from "react-native-web";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Colors from "../../../constant/Colors";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentCustomerDebt } from "../../../store/actions/supplementsShopAction";
import { getCurrentCustomerDebt as EqGetCurrentCustomerDebt } from "../../../store/actions/equipmentAction";
import ReactPaginate from "react-paginate";

import { downloadExcel } from "react-export-table-to-excel";
import { RiFileExcel2Fill } from "react-icons/ri";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";
import SearchContainer from "../Table/SearchContainer";
import fuzzysort from "fuzzysort";
import { searchAndPageIndex } from "../../../store/actions/searchBarAction";
import i18n from "../../../i18n";
const { width } = Dimensions.get("window");

const ProgramsContainer = ({ data, headerLabels, type, showObj }) => {
  const [allProgramsData, setAllProgramsData] = useState(false);
  const PER_PAGE = 20;
  const havePagination = true;
  const { search, pageIndex } = useSelector(
    (state) => state.searchAndPageIndex
  );
  const Users = useSelector((state) => state.users.users);

  const currentUser = useSelector(state => state.authentication.user)
  const offset = pageIndex * PER_PAGE;
  let dd = [];

  const setUpAllProgramsData = () => {
    try {
      let arr = data.map((dt) => {
        if (dt.bioStarID) dt.bioStarID = dt.bioStarID + "";
        if (type === 1) dt.name = Users.find((u) => u._id === dt.userId)?.name;
        return dt;
      });

      let newArr = [];
      let keywords = search;

      let results = [];

      if (keywords && keywords != null && keywords != "" && keywords != " ") {
        results = fuzzysort.go(keywords, arr, {
          keys: ["name", "phone", "bioStarID", "price", "value"],
          threshold: false,
        });
        results.forEach((rs) => {
          newArr.push(rs.obj);
        });
      } else {
        newArr = arr;
      }
      newArr = newArr.sort((a, b) => b.createdAt - a.createdAt);

      setAllProgramsData(newArr);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    if (data) setUpAllProgramsData();
  }, [data, search]);

  const onChangeDt = async (t) => {
    let obj = {};

    await headerLabels.map((h, i) => {
      obj[h.label] = t[i].label;
    });

    if (dd.length < data.length) dd.push(obj);
  };

  function handleDownloadExcel() {
    let hd = [];

    data.forEach((dd) => {
      hd = [...Object.keys(dd)]
    })

    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: hd,
        body: data,
      },
    });
  }

  const currentPageData =
    havePagination &&
    allProgramsData &&
    allProgramsData.length !== 0 &&
    allProgramsData?.slice(offset, offset + PER_PAGE).map((item, i) => {
      return (
        <ProgramIndex
          item={item}
          key={i}
          currentUser={currentUser}
          headerLabels={headerLabels}
          type={type}
          onChangeBd={onChangeDt}
        />
      );
    });

  const pageCount = Math.ceil(allProgramsData?.length / PER_PAGE);

  const onChangeSearch = async (e) => {
    let value = e.target.value;
    searchAndPageIndex(value, pageIndex);
  };
  const handlePageClick = ({ selected: selectedPage }) => {
    searchAndPageIndex(search, selectedPage);
  };

  if (havePagination && !allProgramsData) {
    return null;
  }

  return (
    <View style={styles.container}>
      <SearchContainer onChangeSearch={onChangeSearch} search={search} />

      <TouchableOpacity
        onPress={handleDownloadExcel}
        style={{
          width: "100%",
          backgroundColor: "#eeeeee",
          height: 40,
          justifyContent: "flex-start",
          padding: 5,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {width > 800 && (
          <RiFileExcel2Fill
            size={30}
            color="#216e43"
            style={{ marginLeft: 10 }}
          />
        )}
      </TouchableOpacity>

      {havePagination ? (
        <>
          {currentPageData}
          <ReactPaginate
            breakLabel="..."
            nextLabel="دواتر >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            initialPage={pageIndex}
            previousLabel="< پێشتر"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeLinkClassName="pagination__link--active"
          />
        </>
      ) : (
        allProgramsData &&
        allProgramsData.map((item, i) => {
          return (
            <ProgramIndex
              item={item}
              key={i}
              currentUser={currentUser}
              headerLabels={headerLabels}
              type={type}
              onChangeBd={onChangeDt}
            />
          );
        })
      )}
    </View>
  );
};

const ProgramIndex = ({ item, headerLabels, currentUser, type, onChangeBd }) => {
  const [label, setLabel] = useState([]);
  const users = useSelector((state) => state.users.users);
  const admins = useSelector((state) => state.admins.admins);
  const customers = useSelector((state) => state.supplementsShop.customers);
  const debts = useSelector((state) => state.supplementsShop.debts);
  const repaymentOfDebt = useSelector(
    (state) => state.supplementsShop.repaymentOfDebt
  );
  const Centers = useSelector(
    (state) => state.centers.centers
  );
  const Eqcustomers = useSelector(
    (state) => state.equipments.equipmentCustomers
  );

  const eqDebts = useSelector((state) => state.equipments.equipmentDebts);
  const eqRepaymentOfDebt = useSelector(
    (state) => state.equipments.equipmentRepaymentOfDebts
  );

  const currency = useSelector((state) => state.analytics.currency);
  const brands = useSelector((state) => state.equipments.equipmentBrands);
  const sellerId = useParams().sellerId
  const language = i18n.language

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setUpLabel();
  }, [item, users, customers, brands]);

  const onPress = async () => {
    if (currentUser.type === 17 || currentUser.type === 19) return
    const isIncludeTotalPath = window.location.pathname.includes(
      "dashboard/total/all/viewAll"
    );
    const currentPath = window.location.pathname;
    if (type === 0) {
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}/viewAll`
          : currentPath
        }/expenses/${item._id}`
      );
      // history.push(`${window.location.pathname}/expenses/${item._id}`);
    } else if (type === 1) {
      let currentUser = users?.find((a) => a._id === item?.userId);
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}/viewAll`
          : currentPath
        }/users/${currentUser._id}`
      );
      // history.push(`${window.location.pathname}/users/${currentUser._id}`);
    } else if (type === 2) {
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}/viewAll`
          : currentPath
        }/supplement-order/${item._id}`
      );
      // history.push(`${window.location.pathname}/supplement-order/${item._id}`);
    } else if (type === 3) {
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}`
          : currentPath
        }/cafeteria/${item._id}`
      );

    } else if (type === 4) {
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}/viewAll`
          : currentPath
        }/course/${item._id}`
      );
      // history.push(`${window.location.pathname}/course/${item._id}`);
    } else if (type === 5) {
      history.push(
        `${isIncludeTotalPath
          ? `/dashboard/${item.centerId}/viewAll`
          : currentPath
        }/users/${item._id}`
      );
      // history.push(`${window.location.pathname}/users/${item._id}`);
    } else if (type === 10) {
      history.push(`/supplements/${sellerId}/sell/viewSelledDetails/${item.sellId}`);
    } else if (type === 11) {
      history.push(`/supplements/${sellerId}/repaymentOfDebt/${item._id}`);
    } else if (type === 12) {
      history.push(`/supplements/${sellerId}/debt/${item._id}`);
    } else if (type === 13) {
      history.push(`/supplements/${sellerId}/customers/${item._id}`);
    } else if (type === 14) {
      history.push(`/equipments/sell/viewSelledDetails/${item.sellId}`);
    } else if (type === 15) {
      history.push(`/equipments/repaymentOfDebt/${item._id}`);
    } else if (type === 16) {
      history.push(`/equipments/debt/${item._id}`);
    } else if (type === 17) {
      history.push(`/equipments/customers/${item._id}`);
    } else if (type === 20) {
      history.push(`/equipments/expenses/${item._id}`);
    } else if (type === 21) {
      history.push(`/supplements/${sellerId}/expenses/${item._id}`);
    }
  };

  const setUpLabel = async () => {
    let arr = [];

    let price = item?.iqd || item?.price;
    //!!!!!!!!!!!
    if (type === 0) {
      arr.push({ label: item.name });
      arr.push({
        label: showInIQD(currency === 'IQD' ? item.iqd : item.price, currency, currency),
      });
      arr.push({
        label: dayjs(item.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }

    //!!!!!!!!!!!
    if (type === 1) {
      let currentAdmin = admins?.find((a) => a._id === item.adminId);
      let currentUser = users?.find((a) => a._id === item?.userId);

      let otherCase = item.tempNumberOrder !== -1;
      arr.push({
        bold: true,
        label: !otherCase
          ? item.package.labelDetails[language === 'en' ? 'inEn' : 'inEn'].title
          : "ئیشتراکی پێشوو",
        color: otherCase
          ? "#C4A484"
          : item.package.price.value === 0
            ? Colors.mainAqwaBlue
            : item.package.offerType === 1
              ? "#9854cc"
              : "#397d54",
      });
      arr.push({
        label:
          currentUser?.gender === undefined
            ? t("undefined")
            : currentUser?.gender === 0
              ? t("male")
              : t("female"),

        color:
          currentUser?.gender === undefined
            ? "#cfa86f"
            : currentUser?.gender === 0
              ? "#0293c2"
              : "#f57ca1",
      });
      arr.push({
        label: numeral(otherCase ? 0 : item?.package?.price?.value).format(
          "0.00$"
        ) + ' - ' + showInIQD(item?.package?.price?.iqd, 'IQD'),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });

      arr.push({
        label: currentUser ? currentUser?.name : item.package.duration === 0 ? 'ڕۆژانە' : 'بەسەرچوو',
      });
      arr.push({
        label: currentUser?.bioStarID || 0,
      });

      arr.push({
        label: otherCase ? item?.tempNumberOrder : "نیەتی",
      });
      arr.push({
        label: currentAdmin?.name,
      });
      arr.push({
        label: item?.type === 0 ? t('newUser') : t("renewSubscription"),
      });
    }

    //!!!!!!!!!!!
    if (type === 2) {
      let currAdmin = item.trainerId
        ? admins.find((a) => a._id === item.trainerId).name
        : "نیەتی";

      arr.push({ label: item?.name });
      arr.push({ label: showInIQD(currency === 'IQD' ? item.iqd : item.price, currency, currency) });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
      arr.push({
        label: currAdmin,
      });
    }

    //!!!!!!!!!!!
    if (type === 3) {
      arr.push({ label: item?.name });
      arr.push({ label: showInIQD(currency === 'IQD' ? item.iqd : item.price, currency, currency) });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }

    if (type === 4) {
      let currCaptain = admins.find((a) => a._id === item?.trainerId);
      arr.push({ label: currCaptain?.name });
      arr.push({ label: item?.userName });
      arr.push({
        label: showInIQD(currency === 'IQD' ? item.iqd : item.price, currency, currency),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }

    //!!!!!!!!!!!
    if (type === 5) {
      arr.push({ label: item?.bioStarID });
      arr.push({
        label: item?.name,
        color:
          dayjs(item?.expiry_datetime).valueOf() > dayjs().valueOf()
            ? "#52B79C"
            : "#BF2A45",
      });
      arr.push({
        label:
          item?.gender === undefined
            ? t("undefined")
            : item?.gender === 0
              ? t("male")
              : t("female"),

        color:
          item?.gender === undefined
            ? "#cfa86f"
            : item?.gender === 0
              ? "#0293c2"
              : "#f57ca1",
      });
      arr.push({ label: item?.phone });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm a"),
      });
      arr.push({
        label: dayjs(item?.start_datetime).format("MMMM D YYYY, h:mm a"),
      });
      arr.push({
        label: dayjs(item?.expiry_datetime).utc().format("MMMM D YYYY, h:mm a"),
      });
    }

    if (type === 6) {
      let currAdminName = admins.find((a) => a._id === item.adminId).name;
      arr.push({ label: item?.door.name });
      arr.push({ label: item?.reasonLabel });
      arr.push({ label: currAdminName });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }

    if (type === 9) {
      arr.push({ label: item?.user_id?.name });
      arr.push({
        label: dayjs(item?.datetime).format("MMMM D YYYY, h:mm:ss a"),
      });
    }

    if (type === 10) {
      let currentCust = customers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(item?.totalPrice).format("$0,00.0"),
      });
      arr.push({
        label: numeral(item?.payedPrice).format("$0,00.0"),
      });
      arr.push({
        label: numeral(item?.totalPrice - item?.payedPrice).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 11) {
      let currentCust = customers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(price).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 12) {
      let currentCust = customers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(item?.loan).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 13) {
      arr.push({ label: item?.name });
      let dBf = 0;
      debts.forEach((a) => {
        if (a.customerId === item._id) dBf += a.loan;
      });

      let dRd = 0;
      repaymentOfDebt.forEach((a) => {
        if (a.customerId === item._id) dRd += a.price;
      });
      let loan = await getCurrentCustomerDebt(item._id);
      arr.push({
        label: numeral(dBf).format("$0,00.0"),
      });
      arr.push({
        label: numeral(dRd).format("$0,00.0"),
      });
      arr.push({
        label: numeral(loan).format("$0,00.0"),
      });
    }
    if (type === 14) {
      let currentCust = Eqcustomers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(item?.totalPrice).format("$0,00.0"),
      });
      arr.push({
        label: numeral(item?.payedPrice).format("$0,00.0"),
      });
      arr.push({
        label: numeral(item?.totalPrice - item?.payedPrice).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 15) {
      let currentCust = Eqcustomers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(price).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 16) {
      let currentCust = Eqcustomers.find((a) => a._id === item.customerId);
      arr.push({ label: currentCust?.name });
      arr.push({
        label: numeral(item?.loan).format("$0,00.0"),
      });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 17) {
      arr.push({ label: item?.name });
      let loan = await EqGetCurrentCustomerDebt(item._id);

      let dBf = 0;
      eqDebts.forEach((a) => {
        if (a.customerId === item._id) dBf += a.loan;
      });

      let dRd = 0;
      eqRepaymentOfDebt.forEach((a) => {
        if (a.customerId === item._id) dRd += a.price;
      });

      let b = "";
      if (dRd > 0)
        b =
          "- " +
          dayjs(
            eqRepaymentOfDebt[eqRepaymentOfDebt.length - 1].createdAt
          ).format("DD/MM/YYYY");

      let e = numeral(loan).format("$0,00.0") + " " + b;

      arr.push({
        label: numeral(dBf).format("$0,00.0"),
      });
      arr.push({
        label: e,
      });

      arr.push({
        label: numeral(loan).format("$0,00.0"),
      });
    }
    if (type === 18) {
      arr.push({ label: item?.name });
      arr.push({
        label: showInIQD(item?.salary, "IQD"),
      });
    }
    if (type === 19) {
      arr.push({ label: item?.name });
      arr.push({
        label: showInIQD(item.salary, "IQD"),
      });
      arr.push({
        label: showInIQD(item.requestCourse, "IQD"),
      });
      arr.push({
        label: showInIQD(item.supplements, "IQD"),
      });
      arr.push({
        label: showInIQD(item.totalSalary, "IQD"),
      });
    }
    if (type === 20) {
      arr.push({ label: item?.name });
      arr.push({ label: numeral(price).format("$0,0.00") });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 21) {
      arr.push({ label: item?.name });
      arr.push({ label: numeral(price).format("$0,0.00") });
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
    }
    if (type === 22) {
      const currBrand = brands?.find((a) => a._id === item.brandId);
      arr.push({ label: item?.name });
      arr.push({ label: item.quantity });
      arr.push({
        label: currBrand.name,
      });
    }
    if (type === 23) {
      arr.push({ label: item?.name });
      arr.push({ label: item?.quantity });
      arr.push({ label: numeral(item?.price).format("$0,0.00") });
      arr.push({ label: numeral(item?.wholesalePrice).format("$0,0.00") });
      arr.push({ label: numeral(item?.buyPrice).format("$0,0.00") });
      arr.push({
        label: numeral(item?.buyPrice * item?.quantity).format("$0,0.00"),
      });
      arr.push({
        label: numeral(item?.quantity * (item.price - item.buyPrice)).format(
          "$0,0.00"
        ),
      });
      arr.push({
        label: numeral(
          item?.quantity * (item.wholesalePrice - item.buyPrice)
        ).format("$0,0.00"),
      });
    }
    if (type === 24) {
      arr.push({ label: item?.name });
      arr.push({ label: item?.quantity });
    }

    if (type === 25) {
      arr.push({
        label: dayjs(item?.createdAt).format("MMMM D YYYY, h:mm:ss a"),
      });
      arr.push({ label: item?.name });
      arr.push({ label: item?.centerId ? Centers.find(a => a._id === item.centerId).label : "Aka Fitness" });
      arr.push({ label: item?.starRate + ' ' + t('star') + ' ' });
      arr.push({ label: item?.feedback });
    }
    onChangeBd(arr);
    setLabel(() => [...arr]);
  };

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        borderBottomWidth: false ? 0 : 0.3,
        borderColor: "rgba(29,29,29,0.05)",
      }}
      disabled={width < 800}
      onPress={onPress}
    >
      {label &&
        label.map((t, i) => {
          return (
            <View
              style={{
                width:
                  width > 800
                    ? 1080 / headerLabels.length
                    : 900 / (headerLabels.length * 1.3),
              }}
              key={i}
            >
              <h1
                style={{
                  fontSize: width > 800 ? 13 : 10,
                  opacity: t.bold ? 1 : 0.9,
                  fontWeight: t.bold ? "600" : "400",
                  textAlign: t.color ? "center" : "left",
                  backgroundColor: t.color || null,
                  padding: t.color ? 5 : 0,
                  color: t.color ? "#fff" : "#292929",
                  borderRadius: 2,
                  width: "80%",
                }}
              >
                {t.label}
              </h1>
            </View>
          );
        })}
    </TouchableOpacity>
  );
};

export default ProgramsContainer;

const styles = StyleSheet.create({
  container: { width: "100%", padding: 0, alignSelf: "center" },
  programIndex: {
    height: 100,
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginVertical: 10,
  },
});
