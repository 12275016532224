import React from 'react'
import { forwardRef } from 'react'
import { CafeItemsReport, CourseTable, CoursesReportData, ExpenseData, ReceptionsReportData, SalaryReportData, SupplementsReportData, Table } from './MonthlyReportScreen'
import i18next from 'i18next'

const PrintMonthlyReportData = forwardRef(({ data, expenses, dollarPrice, }, ref) => {
    const language = i18next.language
    console.log(data[0].akafitness.data[0].centerId)
    return (
        <div style={{ height: '100%' }} ref={ref} className='flex flex-col w-full h-full' >
            <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} className="flex flex-col flex-wrap items-center w-full mt-12 justify-evenly">

                {<Table data={data} isFromPrint dollarPrice={dollarPrice} />}

                <div className='flex flex-col items-center w-full' >
                    <CoursesReportData isFromPrint data={data[0].akafitness.data[0].data.revenue.courses} />
                    <SupplementsReportData isFromPrint data={data[0].akafitness.data[0].data.revenue.supplements} />
                    <ReceptionsReportData isFromPrint data={data[0].akafitness.data[0].data.revenue.memberships.receptions} />
                    <SalaryReportData isFromPrint courses={data[0].akafitness.data[0].data.revenue.courses} receptions={data[0].akafitness.data[0].data.revenue.memberships.receptions} supplements={data[0].akafitness.data[0].data.revenue.supplements} data={data[0].akafitness.data[0].data.salaries} />
                    <CafeItemsReport isFromPrint={true} centerId={data[0].akafitness.data[0].centerId} duration={{startDate:data[0].date.start_date,endDate:data[0].date.end_date}} />

                    {expenses && <ExpenseData isFromPrint expensesData={expenses} />}

                </div>

            </div>
        </div>
    )
})

const courseLabels = ['trainer', 'numOfNormalCourses', 'numOfVIPCourses', 'totalPriceOfRegularCourses', 'totalPriceOfVIPCourses', 'trainerProfitsFromRegularCourses', 'trainerProfitsFromVIPCourses', 'akaFitnessProfitFromRegularCourses', 'akaFitnessProfitsFromVIPCourses', 'coachGrossProfit']
const receptionsLabels = ['reception', 'numOfMemberships', 'numOfThreeMonth', 'numOfSixMonth', 'totalPriceOfThreeMonths', 'totalPriceOfSixMonths', 'receptionRatioOfThreeMonths', 'receptionRatioOfSixMonths', 'receptionDivideBenefitIQD',]
const supplementsLabels = ['trainer', 'numOfSupplements', 'totalPriceOfSupplements', 'trainerProfit15%', 'grossProfitAkaFitness']

const SpecificContentPrint = ({ data }) => {
    return (
        <div className='flex flex-col w-full' >
            {data && <>
                {data.type === 0 ? <TrainerContent data={data} /> : <ReceptionContent data={data} />}
            </>}
        </div>)
}

const TrainerContent = ({ data }) => {
    return <>
        {data.supplement && <CourseTable labels={supplementsLabels} >
            <tr  >
                <td>{data.supplement.trainerName}</td>
                <td>{data.supplement.numOfSellSupplements.toLocaleString()}</td>
                <td>{data.supplement.totalSellSupplements.toLocaleString()}</td>
                <td>{data.supplement.totalSellSupplementsTrainerRatio.toLocaleString()}</td>
                <td>{data.supplement.totalSellSupplementsOurRatio.toLocaleString()}</td>
            </tr>

        </CourseTable>}
        {data.course && <CourseTable labels={courseLabels} >
            <td>{data.course.trainerName}</td>
            <td>{data.course.numOfNormalCourse.toLocaleString()}</td>
            <td>{data.course.numOfVIPCourse.toLocaleString()}</td>
            <td>{data.course.totalNormalCourse.toLocaleString()}</td>
            <td>{data.course.totalVIPCourse.toLocaleString()}</td>
            <td>{data.course.totalNormalCourseWithTrainerRatio.toLocaleString()}</td>
            <td>{data.course.totalVIPCourseWithTrainerRatio.toLocaleString()}</td>
            <td>{data.course.totalNormalCourseWithOurRatio.toLocaleString()}</td>
            <td>{data.course.totalVIPCourseWithOurRatio.toLocaleString()}</td>
            <td>{(data.course.totalNormalCourseWithTrainerRatio + data.course.totalVIPCourseWithTrainerRatio).toLocaleString()}</td>
        </CourseTable>}
    </>
}

const ReceptionContent = ({ data }) => {
    return <>
        {data.reception && <CourseTable labels={receptionsLabels} >
            <tr  >
                <td>{data.reception.name}</td>
                <td>{data.reception.totalMemberships}</td>
                <td>{data.reception.report.numOfThreeMonthMemberships}</td>
                <td>{data.reception.report.numOfSixMonthMemberships}</td>
                <td>{data.reception.report.totalPriceOfThreeMonthMemberships}</td>
                <td>{data.reception.report.totalPriceOfSixMonthMemberships}</td>
                <td>{data.reception.report.receptionRatioOfThreeMonthMemberships}</td>
                <td>{data.reception.report.receptionRatioOfSixMonthMemberships}</td>
                <td>{data.reception.report.receptionDivideBenefitIQD.toLocaleString()}</td>
            </tr>
        </CourseTable>}
    </>
}

export default PrintMonthlyReportData
