import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import HeaderRender from "../../../components/UI/Table/HeaderRender";
import ItemRender from "../../../components/UI/Table/ItemRender";
import { shortIdGenerator } from "../../../customFunction/IDGenerator";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import { handleLoading } from "../../../store/actions/loadingAction";
import { fetchCafeteriaItems } from "../../../store/actions/cafeteriaItemsAction";
import { downloadExcel } from "react-export-table-to-excel";
import { RiFileExcel2Fill } from "react-icons/ri";

const headerLables = ["id", "name", "price"];

function CaffetteriaScreen() {
  const { t } = useTranslation();
  const CafeteriaItems = useSelector(
    (state) => state.cafeteriaItems.cafeteriaItems
  );

  const history = useHistory();

  const onPressItem = (data) => {
    if (data._id) {
      history.push(`${window.location.pathname}/${data._id}`);
    } else {
      let id = shortIdGenerator();
      history.push(`${window.location.pathname}/${id}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (!CafeteriaItems) {
      try {
        handleLoading();
        await fetchCafeteriaItems();
        handleLoading();
      } catch (e) {
        alert(e);
      }
    }
  };
  // console.log(CafeteriaItems)
  const exportExcel = () => {
    const dt = []
    const body = CafeteriaItems.map(item => {
      const it = {}
      const a = item.priceList.map(price => ({ [price.id]: price.iqd }))
      a.map((s, i) => {
        it[Object.keys(s)[0]] = s[Object.keys(s)[0]]
      })
      // console.log(it)
      // return [item.name, ...a]
      dt.push({ name: item.name, ...it })
    })
    // console.log(dt)
    downloadExcel({
      fileName: "cafeteria",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: ['name', '62d3de07e4b5f62ba339b22b', '62d3de0de4b5f62ba339b22d', '62d3de11e4b5f62ba339b22f', '63f38ea7cb9593c58e562b6d', '63f38eb3cb9593c58e562b75', '63f38ec4cb9593c58e562b77', '63f38ed4cb9593c58e562b79', '63f48ae929c35a4c83cdc37c'],
        body: dt,
      },
    });
  }
  // console.log('first')
  return (
    <div className="flex flex-col items-center w-full">
      <HeaderContainer CustomButton={() => <div onClick={exportExcel} className="w-[80px] bg-whiteCardIndexColor h-full flex items-center justify-center mx-2 text-white  cursor-pointer" ><RiFileExcel2Fill size={24} /></div>} label={t("cafeteria")} addBtn onPressBtn={onPressItem} />
      <HeaderRender headerLables={headerLables} />
      {CafeteriaItems && (
        <ItemRender
          onPressItem={onPressItem}
          headerLables={headerLables}
          datas={CafeteriaItems}
          type={10}
        />
      )}
    </div>
  );
}

export default CaffetteriaScreen;
