import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";

const CREATE = "/expenses/create";
const DELETE = "/expenses/delete";
const UPDATE = "/expenses/update";

export const createExpense = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }

    // store.dispatch({
    //   type: data._id ? "UPDATE_EXPENSES" : "CREATE_EXPENSES",
    //   data: res.data.data,
    // });
  } catch (e) {
    throw e;
  }
};
export const deleteExpense = async (id) => {
  try {
    await axios
      .delete(`${DELETE}/${id}`)
      .then((res) => {
        // store.dispatch({ type: "DELETE_EXPENSES", data: id });
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (e) {
    throw e;
  }
};

export const fetchExpenses = async (startDate = dayjs().format('YYYY-MM-DD'), endDate) => {
  try {
    let query = `?startDate=${startDate}`;
    if (endDate) {
      query += `&endDate=${endDate}`;
    }
    const currentUser = store.getState().authentication.user
    if (currentUser.type === 17) {
      //!only fetch specific center or data
      const expenses = []
      await Promise.all(currentUser.center.map(async (id) => {
        const res = await axios.get('/expenses' + query + '&centerId=' + id);
        expenses.push(...res.data)
      }))
      store.dispatch({ type: "FETCH_EXPENSES", data: expenses, });
      return
    }
    const res = await axios.get(`/expenses${query}`);

    store.dispatch({ type: "FETCH_EXPENSES", data: res.data });
  } catch (e) {
    throw e;
  }
};
