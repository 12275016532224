import axios from "axios";
import { store } from "../../App";
import { LoginApi } from "../../db/APIs/AuthAPI";

export const LOGIN = (email, password) => {
  return async (dispatch) => {
    try {
      let res = await LoginApi(email, password);
      res = res.data;
      localStorage.setItem("Auth", JSON.stringify(res));
      axios.defaults.headers.common["Authorization"] = `Bearer ${res.token}`

      dispatch({
        type: "LOGIN",
        data: res.user,
      });
    } catch (e) {
      console.log(e)
      throw e;
    }
  };
};

export const LOGOUT = () => {
  localStorage.removeItem("Auth");
  store.dispatch({
    type: "LOGOUT",
  });
};
