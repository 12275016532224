import React from "react";

const InstanceIndex = ({ label, paddingNum, value }) => {
  return (
    <div className={`flex flex-row justify-between w-full p-${paddingNum || 6}`}>
      <h1>{label}</h1>
      <h1 className="text-xl opacity-80">{value}</h1>
    </div>
  );
};

export default InstanceIndex;
