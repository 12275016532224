import { t } from "i18next";
import React from "react";
import { AiOutlineBarChart } from "react-icons/ai";
import { BiPurchaseTag } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
import { SiOpenaigym } from "react-icons/si";
import { TbBuildingWarehouse } from "react-icons/tb";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function CafeteriaStorage() {
  const history = useHistory();

  const onPress = (route) => {
    history.push(`${window.location.pathname}/${route}`);
  };
  return (
    <div className='flex flex-col items-center justify-center flex-1 w-full'>
      <div className='flex flex-wrap items-center justify-center flex-1 w-full '>
        <Card
          Icons={<TbBuildingWarehouse size={50} />}
          label='stock'
          onPress={onPress.bind(this, "stock?view-mode=stock")}
        />
        <Card
          Icons={<BiPurchaseTag size={50} />}
          label='purchases'
          onPress={onPress.bind(this, "purchases?view-mode=purchases")}
        />
        <Card
          Icons={<SiOpenaigym size={50} />}
          label='suppliers'
          onPress={onPress.bind(this, "brands?view-mode=brands")}
        />
        <Card
          Icons={<BsCalendarDate size={50} />}
          label='expired-products'
          onPress={onPress.bind(
            this,
            "expired-products?view-mode=expired-products"
          )}
        />
        <Card
          Icons={<AiOutlineBarChart size={50} />}
          label='advanceDashboard'
          onPress={onPress.bind(this, "dashboard/advance")}
        />
      </div>
    </div>
  );
}

export const Card = ({ Icons, label, onPress }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={onPress}
      className='flex flex-col items-center justify-center m-4 border cursor-pointer h-60 w-60 '>
      <h1 className='my-3 text-xl font-bold opacity-80'>{t(label)}</h1>
      {Icons}
    </a>
  );
};

export default CafeteriaStorage;
