const initialState = { receiptsOfPayments: null, recipientsOfPayments: null, };

const receiptsOfPaymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_SPECIFIC_RECEIPTS_OF_PAYMENTS_PROPERTY":
            return {
                ...state,
                [action.property]: action.data,

            };

        case "CREATE_SPECIFIC_RECEIPTS_OF_PAYMENTS_PROPERTY": {
            const dt = state[action.property].concat(action.data);
            return {
                ...state,
                [action.property]: dt,
            };
        }
        case "DELETE_SPECIFIC_RECEIPTS_OF_PAYMENTS_PROPERTY": {
            const dt = state[action.property].filter((a) => a._id !== action.id);
            return {
                ...state,
                [action.property]: dt,
            };
        }
        case "UPDATE_SPECIFIC_RECEIPTS_OF_PAYMENTS_PROPERTY": {
            let dt = state[action.property].map((order) => {
                if (order._id === action.data._id) {
                    return action.data;
                }
                return order;
            });
            return {
                ...state,
                [action.property]: dt,
            };
        }

        case "UPDATE_RECEIPTS_OF_PAYMENTS_SEARCH": {
            return {
                ...state,
                search: action.data,
            };
        }
        default:
            return state;
    }
};

export default receiptsOfPaymentsReducer;
