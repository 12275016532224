import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import AdminTypes from "../../../data/Admin/AdminTypes";
import { DateInput, HandleButton } from "../../Users/CreateUserScreen";
import useSwr from "swr";
import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../src/components/shadui/ui/select";
import axios from "axios";
import { useQuery } from "../ClassSchedule/ClassSchedulesDetails";
import dayjs from "dayjs";

function AdminDetailsScreen() {
  const { adminId } = useParams()
  const isNew = useQuery().get('isNew')
  const { data, isLoading, error } = useSwr('/admins/' + adminId + '?isNew=' + isNew, fetcher)
  const [currentData, setCurrentData] = useState()
  const history = useHistory()

  useEffect(() => {
    setup()
  }, [data])

  const setup = () => {
    if (data && data[0]) {
      setCurrentData(data[0])
    }
  }

  const onChangeValue = (key, value, child) => {
    if (child) {
      setCurrentData({ ...currentData, [child]: { ...(currentData && currentData[child] ? currentData[child] : {}), [key]: value } })
      return
    }
    setCurrentData({ ...currentData, [key]: value })
  }

  const onSave = async () => {
    try {
      if (!currentData || !currentData.name || (currentData.email ? currentData.password && currentData.password.length < 5 : false) || !currentData.employment || currentData.type === undefined || !currentData.employment.startEmploymentDate) {
        alert('Please fill all the fields')
        return
      }

      if (isNew) {
        const newData = {
          ...currentData,
          employment: {
            ...currentData.employment,
            status: 'active'
          },
        }
        await axios.post('/admins/', newData)
      }
      else
        await axios.put('/admins/', currentData)
      history.goBack()
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }
  console.log(currentData)
  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer onSave={onSave} title={t('admin')} >
        <InstanceIndex label={t("id")} value={`#${currentData && currentData._id ? currentData._id : ''}`} />
        <TextInput
          placeHolder={t('name')}
          onChangeValue={(type, text) => onChangeValue('name', text)}
          label={t('name')}
          value={currentData?.name}
        />
        <TextInput
          placeHolder={t('email') + ' (' + t('optional') + ')'}
          onChangeValue={(type, text) => onChangeValue('email', text)}
          label={t('email') + ' (' + t('optional') + ')'}
          value={currentData?.email}
        />
        {currentData?.email && <TextInput
          placeHolder={t('password')}
          onChangeValue={(type, text) => onChangeValue('password', text)}
          label={t('password')}

          value={currentData._id ? null : currentData?.password}
        />}
        <TextInput
          isNumber
          placeHolder={t('phoneNumber')}
          onChangeValue={(type, text) => onChangeValue('phoneNumber', text)}
          label={t('phoneNumber') + ' (' + t('optional') + ')'}
          value={currentData?.phoneNumber}
        />
        <div className="flex items-center justify-between p-6" >
          <p>{t('role')}</p>
          <Select value={currentData?.type} onValueChange={a => onChangeValue('type', Number(a))} >
            <SelectTrigger className="w-1/2 rounded-none outline-none">
              <SelectValue placeholder="role" />
            </SelectTrigger>
            <SelectContent className='bg-white' >
              {AdminTypes.map((role, index) => <SelectItem key={index} value={role.type}>{t(role.label)}</SelectItem>)}
            </SelectContent>
          </Select>
        </div>
        {currentData?.type === 3 && <>
          <TextInput
            placeHolder={t('normalCourseRatio')}
            onChangeValue={(type, text) => onChangeValue('normalCourseRatio', Number(text), 'employment')}
            label={t('normalCourseRatio')}
            value={currentData?.employment?.normalCourseRatio}
            isNumber
          />
          <TextInput
            placeHolder={t('vipCourseRatio')}
            onChangeValue={(type, text) => onChangeValue('vipCourseRatio', Number(text), 'employment')}
            label={t('vipCourseRatio')}
            value={currentData?.employment?.vipCourseRatio}
            isNumber
          />
        </>}
        <DateInput value={currentData?.employment?.startEmploymentDate ? dayjs(currentData?.employment?.startEmploymentDate).format('YYYY-MM-DD') : null} onChangeValue={(type, value) => onChangeValue('startEmploymentDate', value, 'employment')} label={t('startEmploymentDate')} />
        <HandleButton label={t("status")} onPress={() => onChangeValue('status', (currentData?.employment?.status === 'active') ? 'inactive' : 'active', 'employment')} value={currentData?.employment?.status === 'active' || false} />

        {/* {!isNew && <DateInput value={currentData?.employment?.endEmploymentDate ? dayjs(currentData?.employment?.endEmploymentDate).format('YYYY-MM-DD') : null} onChangeValue={(type, value) => {
          //  if(value!==dayjs().format('YYYY-MM-DD')){
          //   alert('End Date Should be Today')
          //   return
          // }

          // onChangeValue('endEmploymentDate', value, 'employment')
        }}
          label={t('endEmploymentDate')} />} */}
      </CommonInputsContainer>
    </div >
  );
}

export default AdminDetailsScreen;