import { t } from 'i18next';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { TbBuildingWarehouse } from 'react-icons/tb'
import { BiPurchaseTag } from 'react-icons/bi';
import { CgExtensionRemove } from 'react-icons/cg';
import { SiOpenaigym } from 'react-icons/si';

function NutritionScreen() {
    const history = useHistory();
    const onPress = (route) => {
        history.push(`${window.location.pathname}/${route}`);
    };

    return (
        <div className='flex flex-1 w-full' >
            <div className="flex flex-wrap items-center justify-center flex-1 w-full " >
                <Card
                    Icons={<SiOpenaigym size={50} />}
                    label="brands"
                    onPress={onPress.bind(this, "brands")}
                />
                <Card
                    Icons={<TbBuildingWarehouse size={50} />}
                    label="stock"
                    onPress={onPress.bind(this, "stock")}
                />
                <Card
                    label={t("storage")}
                    Icons={<BiPurchaseTag color={"black"} size={50} />}
                    onPress={onPress.bind(this, "storage")}
                />
                <Card
                    label={t("return-items")}
                    Icons={<CgExtensionRemove color={"black"} size={50} />}
                    onPress={onPress.bind(this, "return-items")}
                />
                {/* 
                <Card
                    Icons={<BiPurchaseTag size={50} />}
                    label="purchases"
                    onPress={onPress.bind(this, "purchases?view-mode=purchases")}
                /> */}
            </div>
        </div>
    )
}

export const Card = ({ Icons, label, onPress }) => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
            onClick={onPress}
            className="flex flex-col items-center justify-center m-4 border cursor-pointer h-60 w-60 "
        >
            <h1 className="my-3 text-xl font-bold opacity-80">{t(label)}</h1>
            {Icons}
        </a>
    );
};


export default NutritionScreen
