import React, { useEffect, useState } from 'react'
import { t } from 'i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ArrayIndex from '../../../components/UI/AddingData/ArrayIndex';
import { useTranslation } from 'react-i18next';
import { MultiLangTextInput } from '../Center/CenterDetailsScreen';
import { TextInput } from '../../Users/CreateUserScreen';
import { useQuery } from '../ClassSchedule/ClassSchedulesDetails';
import { Error, fetcher, Loading } from '../../UI/CentersViewData';
import useSWR from 'swr';
import axios from 'axios';
import CommonInputsContainer from '../../../components/UI/TableView/CommonInputsContainer';

function CourseTypesDetailsScreen() {
    const isNew = useQuery().get('isNew');
    const id = useParams().id;

    const { data, isLoading, error } = useSWR('/course-types/' + id, fetcher)
    const language = useTranslation().i18n.language
    const [currentData, setCurrentData] = useState();

    const history = useHistory();

    const onChangeValue = (type, value) => {
        let obj = currentData;

        if (type === 0) {
            obj.labels = value;
        } else if (type === 1) {
            obj.price = value;
        } else if (type === 2) {
            obj.iqd = value;
        } else if (type === 3) {
            obj.type = value;
        }
        else if (type === 4) {
            let check = false;
            check = obj.centers?.find((a) => a === value);
            if (check !== undefined) {
                obj.centers = obj.centers.filter((a) => a !== value);
            } else {
                obj.centers.push(value);
            }
        }
        setCurrentData({ ...obj });
    };
    const checkValidation = async () => {

        if (currentData.type === undefined) {
            throw "Invlaid Type";
        }
    };

    useEffect(() => {
        if (data) {
            if (data.length !== 0)
                setCurrentData(data[0])
            else {
                setCurrentData({
                    labels: "",
                    centers: ['62d3de07e4b5f62ba339b22b'],
                    price: 0,
                    iqd: 0
                })
            }
        }
    }, [data])

    const onSave = async () => {
        try {
            await checkValidation();

            if (isNew) {
                const newData = {
                    ...currentData,
                }
                await axios.post('/course-types/', newData)
            }
            else
                await axios.put('/course-types/', currentData)
            history.goBack()
        } catch (e) {
            console.log(e)
            alert(e.response.data.message)
        }
    };

    if (error) return <Error />

    if (isLoading) return <Loading />


    return (
        <div className="flex flex-1 w-full">
            <CommonInputsContainer onSave={onSave} title={t('course')} >
                <div style={{
                    textAlign: language === 'en' ? "left" : "right"
                }} className="p-6 text-sm" >
                    <p>{t("notes")}</p>
                    <p>{t("changeCourseTypesNote")}</p>
                </div>
                <MultiLangTextInput label='labels' value={currentData?.labels} onChangeValue={onChangeValue.bind(this, 0)} />
                <ArrayIndex
                    label={t("type")}
                    data={[{ id: 0, label: t('normal') }, { id: 1, label: t('vip') }]}
                    type={3}
                    valType={1}
                    value={currentData?.type}
                    index='id'
                    onChangeValue={onChangeValue}
                />
                <TextInput
                    label={'price'}
                    placeHolder={t("price")}
                    value={currentData?.price}
                    onChangeValue={onChangeValue}
                    type={1}
                    isNumber
                />
                <TextInput
                    label={'iqd'}
                    placeHolder={t("iqd")}
                    value={currentData?.iqd}
                    onChangeValue={onChangeValue}
                    type={2}
                    isNumber
                />
            </CommonInputsContainer>
        </div>

    )
}

export default CourseTypesDetailsScreen
