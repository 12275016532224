import axios from "axios";
import { store } from "../../App";
const CREATE = "/cafeteria-items/create";
const UPDATE = "/cafeteria-items/update";

export const createCafeteriaItem = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }

    store.dispatch({
      type: data._id ? "UPDATE_CAFETERIA_ITEM" : "CREATE_CAFETERIA_ITEM",
      data: res.data.data,
    });
  } catch (e) {
    throw e;
  }
};

export const fetchCafeteriaItems = async (data) => {
  try {
    const res = await axios.get(`/cafeteria-items`);
    store.dispatch({ type: "FETCH_CAFETERIA_ITEMS", data: res.data });
  } catch (e) {
    console.log(e)
    throw e;
  }
};
