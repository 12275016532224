import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HeaderContainer from '../../components/UI/‌Header/HeaderContainer';
import { fetchPurchases } from '../../store/actions/purchasesAction';
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../UI/CentersViewData';

function PurchasesScreen() {
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState(false);
    const id = useParams().id;
    const { t } = useTranslation();
    const { data, isLoading, error } = useSwr('/users/' + id, fetcher)
 
    const [currentPurchases, setCurrentPurchases] = useState();
    const setUpCurrentUser = async () => {
        setCurrentUser(data[0]);
        await getPurchases(data[0]);
    };

    const getPurchases = async (currUser) => {
        let data = await fetchPurchases(currUser.bioStarID);
        data = data.sort((a, b) => b.createdAt - a.createdAt)
        setCurrentPurchases(data);
    }

    useEffect(() => {
        if (data && data[0]) setUpCurrentUser();
    }, [data]);

    if (error) return <Error />

    if (isLoading) return <Loading />

    if (!currentUser || !currentPurchases) {
        return null;
    }
    return (
        <div className='flex flex-col items-center flex-1 w-full'>
            <HeaderContainer label={t("purchasesHistory")} />
            <div style={{ marginTop: 60, width: "90%" }}>
                {currentPurchases.map((a, index) => {
                    return (
                        <Item
                            key={index}
                            history={history}
                            item={a}
                        />
                    );
                })}
            </div>
        </div>
    )
}

const Item = ({ item, history }) => {
    const onPressItem = () => {
        history.push({
            pathname: `${window.location.pathname + "/" + item._id}`,
            state: {
                item,
            },
        });
    };
    return (
        <div
            onClick={onPressItem}
            className='flex flex-col items-center flex-1 w-full my-2 cursor-pointer'>
            <div className='flex items-center justify-between w-full p-1 bg-gray-100 '>
                <div className='ml-2 '>{item._id}</div>
                <div className='ml-2 '>{item.date}</div>
                <div className='ml-2 '>{item.price}</div>
                <div className='ml-2 '>moneyBeforeCalc:{item.moneyBeforeCalc}</div>
            </div>
        </div>
    );
};
export default PurchasesScreen
