import { t } from "i18next";
import React from "react";

export default ({ onChangeSearch , search }) => {
  return (
    <div className="w-5/6 h-10 border">
      <input
        onChange={onChangeSearch}
        className="w-full h-full p-3 outline-none opacity-60"
        placeholder={t("search")}
        value={search}
      />
    </div>
  );
};
