const initialState = { admins: null };

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ADMINS":
            return {
                ...state,
                admins: action.data,
            };
        case "ADD_EDIT_ADMIN": {
            let newMessaaging = state.admins;

            if (
              !state.admins.find((a) => a._id === action.data._id) ||
              state.admins.length === 0
            ) {
              newMessaaging.push(action.data);
            } else {
              newMessaaging = state.admins.map((msg) => {
                if (msg._id === action.data._id) {
                  msg = action.data;
                }
                return msg;
              });
            }
            return { ...state, admins: newMessaaging };
      
        }
        default:
            return state;
    }
};

export default authenticationReducer;
