import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosAdd } from "react-icons/io";

const HeaderRender = ({ headerLables }) => {
  const { t } = useTranslation();
  return (
    <div className="w-5/6 border mt-9">
      <div className="flex items-center justify-between w-full p-3 bg-mainAqwaBlue">
        {headerLables.map((hd, i) => {
          return (
            <div key={i} style={{ width: 900 / headerLables.length }}>
              <h1 className="text-white">{t(hd)}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default HeaderRender;
