const initialState = { supplementsStorage: null };

const supplementItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SUPPLEMENTS_STORAGE_ITEMS":
      return {
        ...state,
        supplementsStorage: action.data,
      };

    case "CREATE_SUPPLEMENTS_STORAGE_ITEM": {
      let dt = state.supplementsStorage.concat(action.data);
      return {
        ...state,
        supplementsStorage: dt,
      };
    }
    case "UPDATE_SUPPLEMENTS_STORAGE_ITEM": {
      let dt = state.supplementsStorage.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        supplementsStorage: dt,
      };
    }
    default:
      return state;
  }
};

export default supplementItemsReducer;
