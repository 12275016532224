import axios from "axios";

const loginPath = "/login";

export const LoginApi = async (email, password) => {
  try {
    return axios
      .post(`${loginPath}`, {
        email,
        password,
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        throw "1";
      });
  } catch (e) {
    console.log(e)
    throw e;
  }
};
