import { StyleSheet } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native-web";

const PickerInput = ({ label, isPackage, value, onPress }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-row justify-between w-full p-6'>
      <h1>{label}</h1>
      <TouchableOpacity
        onPress={onPress}
        style={{ borderRadius: 400, opacity: value ? 1 : 0.5 }}>
        <h1>
          {value
            ? isPackage
              ? value.labelDetails.inEn.title
              : value?.name || value
            : t("select")}
        </h1>
      </TouchableOpacity>
    </div>
  );
};

export default PickerInput;

const styles = StyleSheet.create({});
