import React from "react";
import ShowPaginationData from "../../../components/UI/TableView/ShowPaginationData";
import { IoAdd } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "i18next";
import dayjs from "dayjs";

function NotificationsScreen() {
  const { columns, id, label, url, href } = {
    id: "notifications",
    label: "notifications",
    href: "/",
    columns: [
      {
        label: "type",
        value: (a) => (a.type === 0 ? t("direct") : t("static")),
      },
      { label: "enTitle", value: (a) => a.en.title },
      { label: "enBody", value: (a) => a.en.body },
      {
        label: "date",
        value: (a) => dayjs(a.createdAt).format("YYYY-MM-DD HH:mm"),
      },
    ],
    url: (centerId) => "/notifications?" + centerId,
  };
  const history = useHistory();

  const onClickItem = (_id) => {
    history.push(`/admin/notifications/${_id}`);
  };

  return (
    <div className='flex flex-1 w-full h-full mt-10'>
      <div className='flex flex-1 w-full h-full mt-10'>
        <ShowPaginationData
          hrefId='_id'
          onClickItem={(_id) => onClickItem(_id)}
          rightButton={
            <div className='cursor-pointer'>
              <IoAdd
                size={40}
                onClick={() =>
                  history.push("/admin/notifications/0?isNew=true")
                }
              />
            </div>
          }
          columns={columns}
          href={href}
          id={id}
          label={label}
          url={url}
        />
      </div>
    </div>
  );
}

export default NotificationsScreen;
