import dayjs from 'dayjs';
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData';
import { IoAdd } from 'react-icons/io5';
import { showInIQD } from '../../../customFunction/Numeral/CurrencyFormat';
import { t } from 'i18next';


function DollarPricesScreen() {
    const { columns, id, label, url, href } = {
        id: 'admins', label: 'admins',
        href: '/admins',
        columns: [{ label: '#', value: a => a._id.slice(10) },
        { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.price, 'IQD') },
        { label: 'type', classnames: () => 'font-[Montserrat]', value: (a) => a.type === 0 ? t('dollarRate') : t('monthly') },
        { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') }],
        url: (centerId) => '/dollar-prices?' + centerId,
    }

    const history = useHistory();

    const onClickItem = (_id) => {
        history.push(`${window.location.pathname}/${_id}`);
    }

    return (
        <div className="flex flex-col items-center flex-1 w-full bg-white">
            <div className='flex flex-1 w-full h-full mt-10' >
                <ShowPaginationData hrefId='_id' onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
                    <IoAdd size={40} onClick={() => history.push(`${window.location.pathname}/1?isNew=true`)} />
                </div>} columns={columns} href={href} id={id} label={label} url={url} />
            </div>
        </div>
    );
}

export default DollarPricesScreen
