import React from 'react'
import NutritionViewData from '../../../components/cafeteria/NutritionViewData'
import { shortIdGenerator } from '../../../customFunction/IDGenerator';
import { useHistory } from 'react-router-dom';
import { AddIcon } from '../stock/CafeteriaStock';

function CafeteriaPurchases() {
  const history = useHistory()
  const clickAddIcon = () => {
    let id = shortIdGenerator();

    history.push('/cafeteria-storage/purchases/' + id + '?new=true')
  }
  return (
    <div className='flex flex-1 w-full ' >
      <NutritionViewData AddIcon={()=><AddIcon onClick={clickAddIcon} />} />
    </div>
  )
}

export default CafeteriaPurchases
