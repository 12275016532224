import React from "react";

const ViewTotalDataContainer = ({ data, onPressViewAllOrder, label }) => {
  return (
    <div
      className="my-2"
      onClick={onPressViewAllOrder}
      style={{
        width: "98.5%",
        height: 50,
        backgroundColor: "#fff",
        alignSelf: "center",
        borderRadius: 5,
        justifyContent: "space-between",
        padding: 20,
        flexDirection: "row",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <h1
        style={{
          fontSize: 13,
          opacity: 0.5,
        }}
      >
        {label}
      </h1>
    </div>
  );
};

export default ViewTotalDataContainer;
