import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ViewStorageInvoice from '../../../components/Storage/ViewStorageInvoice'
import { showInIQD } from '../../../customFunction/Numeral/CurrencyFormat'
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuTrigger } from '../../../src/components/shadui/ui/dropdown-menu'
import { IoMdMore } from 'react-icons/io'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../../src/components/shadui/ui/alert-dialog'
import { buttonVariants } from '../../../src/components/shadui/ui/button'
import axios from 'axios'
import { t } from 'i18next'

function NutritionViewInvoice() {
    const data = useLocation().state.data
    const [alertOpen, setAlertOpen] = useState(false)
    const history = useHistory()

    const columns = [
        {
            label: "image",
            value: (a) => (
                <img
                    alt=''
                    className='object-contain w-20 h-20'
                    src={a.productDetails.imageUri}
                />
            ),
        },
        { label: "name", value: (a) => a.productDetails.name },
        { label: "quantity", value: (a) => a.quantity },

        {
            label: "price",
            value: (a) => showInIQD(a.price, "USD"),
        },
        {
            label: "totalPrice",
            value: (a) => showInIQD(a.price * a.quantity, "USD"),
        },
    ]

    const onDeleteItem = async () => {
        // console.log('delete')
        try {
            await axios.delete('/nutrition-storage-activities/' + data._id)
            history.goBack()

        } catch (e) {
            console.log(e)
            alert('error')
        }
    }

    // console.log({data})
    return (
        <div className='flex flex-col items-center justify-center flex-1 bg-white' >
            
            <ViewStorageInvoice columns={columns} id='swimming' invoiceProducts={data?.items} >
                <div className='flex flex-col w-full ' >
                <div className='flex items-center justify-between' >
                <h1 className='text-4xl font-bold' >Products </h1>
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <IoMdMore size={30} />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuLabel className='text-red-500 cursor-pointer' onClick={() => setAlertOpen(true)} >{t('delete')}</DropdownMenuLabel>
                        <AlertDialog open={alertOpen} >
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        {t('deleteItemDesc')}
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
                                    <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>                    <h1 className='mt-5 font-medium' >By: {data.admin.name} </h1>
                </div>

            </ViewStorageInvoice>
        </div>
    )
}

export default NutritionViewInvoice
