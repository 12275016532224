import axios from "axios";
import dayjs from "dayjs";
import numeral from "numeral";
import { store } from "../../App";

const CREATE = "/dollar-prices/create";
const DELETE = "/dollar-prices/delete";
const UPDATE = "/dollar-prices/update";

export const createDollarPrice = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }
  } catch (e) {
    throw e;
  }
};
export const deleteDollarPrice = async (id) => {
  try {
    await axios
      .delete(`${DELETE}/${id}`)
  } catch (e) {
    throw e;
  }
};

export const convertCurrency = (amount, currency, forShow, withFormat, haveRounding) => {
  const currentDollarPrice = store.getState().dollarPrices.currentPrice.price;
  const fourNumDollar = (currentDollarPrice + '').substring(0, 4);

  if (currency === "USD") {
    if (forShow) return numeral(amount).format("0.00$");
    if (withFormat) return numeral(amount / fourNumDollar).format("0.00$");
    return (amount / fourNumDollar).toFixed(2);
    // return numeral(amount).format("0.0$");
  }
  if (currency === "IQD") {
    if (forShow) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'IQD', roundingIncrement: haveRounding ? 1000 : 1 }).format((amount))
    if (withFormat) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'IQD', roundingIncrement: haveRounding ? 1000 : 1 }).format((Math.round((amount * fourNumDollar) / 1000) * 1000))
    return Math.round((amount * fourNumDollar) / 1000) * 1000;//! 1000 is the rounding increment
  }
}


export const fetchDollarPrices = async (startDate = dayjs().format('YYYY-MM-DD'), endDate) => {
  try {
    const res = await axios.get(`/dollar-prices`);

    store.dispatch({ type: "FETCH_DOLLAR_PRICES", data: res.data });
  } catch (e) {
    throw e;
  }
};
