import React from "react";
import { useSelector } from "react-redux";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";
import Colors from "../../../constant/Colors";
import { t } from "i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LeftPart = ({ data }) => {
  const currency = useSelector((state) => state.analytics.currency);

  const physicalTherapyProfit = data.revenue.cafeteria.other.physicalTherapy[currency.toLowerCase()]

  return (
    <div
      className="flex w-[49%] flex-col max-md:w-full bg-white rounded-md"
    >
      <div
        className="flex flex-wrap items-center justify-between" >
        <Index label={t('activeUser')} viewId='active-users' value={data.totalActiveSubscribers} />
        <Index label={t('expiredUser')} viewId='expire-users' color={Colors.soldColor} value={data.numOfExpiredUsers} />
        <Index label={t('male')} viewId='male-users' color={'#0293c2'} value={data.totalActiveSubscribers - data.totalFemaleSubscriber} />
        <Index label={t('female')} viewId='female-users' color={'#f57ca1'} value={data.totalFemaleSubscriber} />
        <Index label={t('users')} viewId='users' value={(data.totalActiveSubscribers + data.numOfExpiredUsers)} />
        <Index label={t('memberships')} viewId='memberships' value={showInIQD(data.revenue.memberships.prices.revenue[currency.toLowerCase()], currency)} />
        <Index label={t('newSubscribers')} viewId='new-subscription' value={data.revenue.memberships.users.numOfNewUser} />
        <Index label={t('renewalOfSubscriptions')} viewId='renewal-subscription' value={data.revenue.memberships.users.numOfRenewUser} />
        <Index label={t('openingTheDoors')} viewId='doors' value={data.numOfOpeningDoors} />
        <Index label={t('freeSubscriptions')} viewId='free-subscription' color={'#0293c2'} value={data.numOfFreeUsers} />
        <Index label={t('expiryTodayMemberships')} viewId='expiry-today-memberships' color={Colors.soldColor} value={data.revenue.memberships.users.numOfExpiredUser} />
        <Index label={t('requestCourse')} viewId='courses' value={showInIQD(data.revenue.courses.prices.revenue[currency.toLowerCase()], currency)} />
        <Index label={t('supplements')} viewId='supplements' value={showInIQD(data.revenue.supplements.prices.revenue[currency.toLowerCase()], currency)} />
        <Index label={t('swimmingItems')} viewId='swimming-items' value={showInIQD(data.revenue.swimmingItems.prices.revenue[currency.toLowerCase()], currency)} />
        <Index label={t('physicalTherapy')} viewId='physical-therapy' value={showInIQD(physicalTherapyProfit, currency)} />
        <Index label={t('cafeteria')} viewId='cafeteria' value={showInIQD(data.revenue.cafeteria.prices.revenue[currency.toLowerCase()] - physicalTherapyProfit, currency)} />
        <Index label={t('expenses')} viewId='expenses' color={Colors.soldColor} value={showInIQD((['gymExpenses', 'cafeteriaExpenses', 'bigExpenses'].reduce((acc, index) => acc + data.expenses[index][currency.toLowerCase()], 0)), currency)} />
        <Index label={t('gymExpenses')} viewId='gym-expenses' color={Colors.soldColor} value={showInIQD(data.expenses.gymExpenses[currency.toLowerCase()], currency)} />
        <Index label={t('cafeteriaExpenses')} viewId='cafeteria-expenses' color={Colors.soldColor} value={showInIQD(data.expenses.cafeteriaExpenses[currency.toLowerCase()], currency)} />
        <Index label={t('bigExpenses')} viewId='big-expenses' color={Colors.soldColor} value={showInIQD(data.expenses.bigExpenses[currency.toLowerCase()], currency)} />

      </div>
    </div>
  );
};

const Index = ({ label, value, viewId, color }) => {
  const currency = useSelector((state) => state.analytics.currency);
  const history = useHistory()

  const onClick = () => {
    history.push(window.location.pathname + `/view-data/${viewId}?type=0`)
  }
  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        padding: 20,
        width: "45%",
      }}
    >
      <h1
        style={{
          color: color || "#292929",
          fontSize: 15,
        }}
      >
        {label}
      </h1>
      <h1
        style={{
          color: color || "#292929",
          opacity: 0.6,
          fontSize: 28,
          fontFamily: "Montserrat",
        }}
      >
        {value || 0}
      </h1>
    </div>
  );
};

export default LeftPart;
