import React from "react";
import ItemIndex from "./ItemIndex";
import ReactPaginate from "react-paginate";
import { useState } from "react";

const ItemRender = ({ datas, onPressItem, type }) => {
  const PER_PAGE = type === 8 ? 100 : 20
  const [currentPage, setCurrentPage] = useState(0);
  const havePagination = type >= 12 && type <= 17 || type === 5 || type === 26
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage)
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = havePagination && datas && datas.length !== 0 && datas?.slice(offset, offset + PER_PAGE).map((dt, i) => {
    return <ItemIndex type={type} data={dt} key={dt._id} index={i} onPressItem={onPressItem} />;
  })

  const pageCount = Math.ceil(datas?.length / PER_PAGE);

  if (havePagination && !currentPageData) {
    return null
  }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col w-5/6 border">
        {havePagination ? <>
          {currentPageData}
          <ReactPaginate
            breakLabel="..."
            nextLabel="دواتر >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< پێشتر"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            previousLinkClassName='pagination__link'
            nextLinkClassName='pagination__link'
            disabledClassName="pagination__link--disabled"
            activeLinkClassName="pagination__link--active"
          />
        </> : datas &&
        datas.map((dt, i) => {
          return (
            <ItemIndex
              type={type}
              data={dt}
              key={dt._id}
              index={i}
              onPressItem={onPressItem}
            />
          );
        })}

      </div>
      <div className="h-28" />
    </div>
  );
};

export default ItemRender;
