import React from 'react'
import { useHistory } from 'react-router-dom'
import { showInIQD } from '../../../customFunction/Numeral/CurrencyFormat'
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData'
import { IoAdd } from 'react-icons/io5'
import dayjs from 'dayjs'


function CourseTypesScreen() {
    const { columns, id, label, url, href } = {
        id: 'course', label: 'courses',
        href: '/course',
        columns: [{ label: '#', value: a => a._id.slice(10) },
        { label: 'name', value: (a) => a.labels.en },
        { label: 'price', value: (a) => a.iqd ? showInIQD(a.iqd, 'IQD') : showInIQD(a.price,) },
        { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD') },],
        url: (centerId) => '/course-types?' + centerId,
    }

    const history = useHistory();

    const onClickItem = (_id) => {
        history.push(`/admin/course-types/${_id}`);
    }

    return (
        <div className="flex flex-col items-center flex-1 w-full bg-white">
            <div className='flex flex-1 w-full h-full mt-10' >
                <ShowPaginationData hrefId='_id' onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
                    <IoAdd size={40} onClick={() => history.push('/admin/course-types/0?isNew=true')} />
                </div>} columns={columns} href={href} id={id} label={label} url={url} />
            </div>
        </div>
    )
}

export default CourseTypesScreen
