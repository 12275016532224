import axios from "axios";
import { store } from "../../App";
const CREATE = "/supplements-storage/buy";
const UPDATE = "/supplements-storage/update";

export const createSupplementsStorage = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }
    return res.data.data
  } catch (e) {
    throw e;
  }
};

export const fetchSupplementStorage = async () => {
  try {
    const res = await axios.get(`/supplements-storage`);

    store.dispatch({ type: "FETCH_SUPPLEMENTS_STORAGE_ITEMS", data: res.data });
  } catch (e) {
    throw e;
  }
};

//!only use protein seller
export const fetchSpecificSupplementStorage = async (id) => {
  try {
    const supplementItems = store.getState().supplementItems.supplementItems;

    const res = await axios.get(`/supplements-storage/${id}`);
    store.dispatch({ type: "FETCH_SUPPLEMENTS_STORAGE_ITEMS", data: res.data });
    console.log(res.data)
    //! replace quantity in supplementItems by quantity in supplementStorage
    // const supplementStorageItems = res.data[0].items;

    // const newItems = supplementItems.map((item) => {
    //   const storageItem = supplementStorageItems.find((storageItem) => storageItem.itemId === item._id);
    //   if (storageItem) {
    //     item.quantity = storageItem.quntity;
    //   }else {
    //     item.quantity = 0;
    //   }
    //   return item;
    // });
    
    // store.dispatch({ type: "FETCH_SUPPLEMENT_ITEMS", data: newItems });
  } catch (e) {
    throw e;
  }
};
export const fetchSupplementStorageActivity = async (centerId) => {
  try {
    const res = await axios.get(`/supplements-storage-activities?centerId=${centerId}`);
    return (res.data)
  } catch (e) {
    throw e;
  }
};