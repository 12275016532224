import axios from "axios"
import dayjs from "dayjs"
import { store } from "../../App"
import { fetchCafeteriaItems } from "./cafeteriaItemsAction"

export const fetchPurchases = async (bioStarID) => {
  try {
    let startDate = dayjs().startOf('month').format('YYYY-MM-DD')
    let endDate = dayjs().endOf('month').format('YYYY-MM-DD')

    const response = await axios.get('/purchases-activity?bioStarID=' + bioStarID + '&startDate=' + startDate + '&endDate=' + endDate)
    return response.data
  } catch (e) {
    alert(e)
  }
}
export const fetchAllPurchases = async (startDate = dayjs().format('YYYY-MM-DD'), endDate, centerId) => {
  try {
    const cafeItems = store.getState().cafeteriaItems.cafeteriaItems

    let query = `?startDate=${startDate}&centerId=${centerId}`;
    if (endDate) {
      query += `&endDate=${endDate}`;
    }
    const currentUser = store.getState().authentication.user
    if (!cafeItems) {
      await fetchCafeteriaItems()
    }
    if (currentUser.type === 17) {
      //!only fetch specific center or data
      const purchases = []
      await Promise.all(
        currentUser.center.map(async (id) => {
          const res = await axios.get('/purchases-activity' + query + '&centerId=' + id);
          purchases.push(...res.data)
        }))
      // store.dispatch({ type: "FETCH_PURCHASES_HISTORY", isMulti: true, data: purchases });
      return purchases
    }
    const res = await axios.get(`/purchases-activity${query}`);
    
    return res.data
    // store.dispatch({ type: "FETCH_PURCHASES_HISTORY", data: res.data });
  } catch (e) {
    throw e;
  }
}

export const generatedCafeItemsData = async (data) => {
  try {
    const cafeItems = store.getState().cafeteriaItems.cafeteriaItems
    const report = {
      items: [],
      totalQuantity: 0,
      totalPrice: 0,
    }
    data.map((item) => {
      //!check if item 
      report.totalPrice += Number(item.price)
      item.items.map((it) => {
        const check = report.items.find(a => a.itemId === it.itemId)
        let newItem = check || {}
        if (check) {
          newItem.totalQuantity += Number(it.quntity)
          newItem.totalPrice += (it.price * it.quntity)

          report.items = report.items.map(a => {
            if (a.itemId === newItem.itemId) {
              a = newItem
            }
            return a
          })
        } else {
          const currentItem = cafeItems.find(a => a._id === it.itemId)
          newItem = { itemId: it.itemId, name: currentItem.name, totalQuantity: it.quntity, totalPrice: (currentItem.iqd * it.quntity) }

          report.items.push(newItem)
        }
      })
    })

    report.totalQuantity = report.items.reduce((accumulator, currentElement) => accumulator + currentElement.totalQuantity, 0)

    return report
  } catch (e) {
    throw e
  }
}

export const updatePurchase = async (data) => {
  try {
    await axios.put(`/purchases-activity/update/${data._id}`, data);
  } catch (e) {
    throw e;
  }
};