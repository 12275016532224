import numeral from "numeral";
import { store } from "../../App";

export const showInIQD = (value, showCurrr, realCurr, returnValue, v) => {
  if (v === 2) {
    const showCurr = showCurrr || store.getState().analytics.currency
    if (realCurr === "USD") {
      if (showCurr === "USD") {
        if (returnValue) {
          return value;
        } else {
          return numeral(value.usd).format("$0,00.00");
        }
      }
    }

    if (showCurr === "USD" && !returnValue) {
      return numeral(value.usd).format("$0,00.00");
    }

    if (returnValue) return value;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: showCurr || "IQD",
      minimumFractionDigits: 0,

    }).format(value.iqd);
  }
  const showCurr = showCurrr || store.getState().analytics.currency
  if (realCurr === "USD") {
    if (showCurr === "USD") {
      if (returnValue) {
        return value;
      } else {
        return numeral(value).format("$0,00.00");
      }
    }
  }

  if (showCurr === "USD" && !returnValue) {
    return numeral(value).format("$0,00.00");
  }

  if (returnValue) return value;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: showCurr || "IQD",
    minimumFractionDigits: 0,
  }).format(value);
};
