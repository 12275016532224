import React from "react";
import TotalBenfitCard from "./TotalBenfitCard";

const Bottom = ({ detailsData,isCenter,data }) => {
  return (
    <div className="w-full p-3" >
      <TotalBenfitCard isCenter={isCenter} data={data} />
    </div>
  );
};

export default Bottom;
