import { StyleSheet, View, Dimensions } from "react-native-web";
import React from "react";
import Colors from "../../../constant/Colors";
import { useTranslation } from "react-i18next";

const { height, width } = Dimensions.get("window");

const HeaderListRender = ({ headerLabels, label, onSortByHeader }) => {
  const { t } = useTranslation();

  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          width: "100%",
          backgroundColor: "#fff",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 10,
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: "#292929",
          }}
        >
          {t(label)}
        </h1>
      </View>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: width > 800 ? 10 : 5,
          backgroundColor: Colors.mainAqwaBlue,
        }}
      >
        {headerLabels.map((hd, i) => {
          return (
            <View
              key={i}
              style={{

                width:
                  width > 800
                    ? 900 / headerLabels.length
                    : 900 / (headerLabels.length * 1.3),
                alignItems: "flex-start",
                justifyContent: "center",
                cursor: 'pointer'
              }}
            >
              <h1
                onClick={() => onSortByHeader(hd.objKey)}

              style={{
                color: "#fff",
                fontSize: width > 800 ? 15 : 12,
              }}
              >
              {t(hd.label)}
            </h1>
            </View>
      );
        })}
    </View>
    </View >
  );
};

export default HeaderListRender;

const styles = StyleSheet.create({});
