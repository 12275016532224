import dayjs from "dayjs";
import { store } from "../../App";
import { showInIQD } from "../Numeral/CurrencyFormat";

export const calculateMembershipTotalPrice = (data, users, id, currency) => {
  let val = 0;
  let newUser = 0;
  let oldUser = 0;
  let receptionBenefit = 0;

  //type
  //1 === newUser
  //2 === oldUser
  //3 === exist

  if (id) data = data.filter((a) => a.centerId === id);

  if (users && data && data.length !== 0) {
    data.forEach((dt) => {
      let curr = "USD";

      let pck = dt.package;

      let price = (currency === 'IQD' ? pck?.price?.iqd : pck?.price?.value) || 0;

      let discount = pck?.price?.discount;
      let fPrice = currency === 'IQD' ? price : (price / 100) * (100 - discount);

      if (dt.type === 0 && price !== 0) {
        newUser++;
      } else {
        oldUser++;
      }
      if (dt.tempNumberOrder === -1) {
        val += showInIQD(fPrice, currency, curr, true) || 0;
      }

    });
  }

  return { finalPrice: val, newUser, oldUser, receptionBenefit };
};


export const calculateReceptionRatioData = async (data) => {
  const packages=store.getState().packages.packages
  const generatedData={
    numOfThreeMonthMemberships:0,
    numOfSixMonthMemberships:0,
    totalPriceOfThreeMonthMemberships:0,
    totalPriceOfSixMonthMemberships:0,

    receptionRatioOfThreeMonthMemberships:0,
    receptionRatioOfSixMonthMemberships:0,

    receptionDivideBenefitIQD:0,
    akaFitnessDivideBenefitIQD:0,
  }
  

  await data.forEach(dt => {
    const pck = dt.package;
    let receptionDivideBenefitIQD

    if(pck.receptionDivideBenefitIQD){
      receptionDivideBenefitIQD=pck.receptionDivideBenefitIQD
    }else {
      //!because in old data we dont have receptionDivideBenefitIQD 🦦
      const currentPackage=packages.find(a=>a._id===pck._id)
      receptionDivideBenefitIQD=currentPackage.receptionDivideBenefitIQD
    }

    if(receptionDivideBenefitIQD&&dayjs(dt.date).isAfter(dayjs().month(3).year(2023), 'month')){
      if(pck.duration===90){
        //!3 month
        generatedData.receptionRatioOfThreeMonthMemberships+=receptionDivideBenefitIQD
        generatedData.receptionDivideBenefitIQD+=receptionDivideBenefitIQD

        generatedData.akaFitnessDivideBenefitIQD+=pck.price.iqd-receptionDivideBenefitIQD
        generatedData.numOfThreeMonthMemberships++
        generatedData.totalPriceOfThreeMonthMemberships+=pck.price.iqd
      }else if(pck.duration===180){
        //!6 month
        generatedData.receptionRatioOfSixMonthMemberships+=receptionDivideBenefitIQD
        generatedData.receptionDivideBenefitIQD+=receptionDivideBenefitIQD
        generatedData.akaFitnessDivideBenefitIQD+=pck.price.iqd-receptionDivideBenefitIQD
        generatedData.numOfSixMonthMemberships++
        generatedData.totalPriceOfSixMonthMemberships+=pck.price.iqd
      }
    }
  })
  return generatedData
};