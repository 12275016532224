import i18next, { t } from "i18next";
import { cn } from "../../../lib/utils";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { LOGOUT } from "../../../store/actions/authenticationAction";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Colors from "../../../constant/Colors";

function SettingModal({ onChangeLanguage }) {
  const [currentTab, setCurrentTab] = useState("profile");
  const language = i18next.language;
  return (
    <div
      style={{ direction: language === "en" ? "ltr" : "rtl" }}
      className={`h-[80%] `}>
      <Header isActive={currentTab} onClick={(a) => setCurrentTab(a)} />
      <div className='h-full p-1'>
        {currentTab === "profile" && <ProfileTab />}
        {currentTab === "appearance" && (
          <Appearance onChangeLanguage={onChangeLanguage} />
        )}
        {currentTab === "other" && <Other />}
      </div>
    </div>
  );
}

const data = ["profile", "appearance", "other"];
const Header = ({ onClick, isActive }) => {
  return (
    <div className='flex flex-col w-full border-b'>
      <div className='flex items-center justify-between h-16 px-4'>
        <div className={cn("flex items-center ")}>
          {data.map((lb, i) => (
            <CustomNavLink
              label={lb}
              key={i}
              isActive={lb === isActive}
              onClick={onClick.bind(null, lb)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomNavLink = ({ onClick, label, isActive }) => {
  return (
    <div
      onClick={onClick}
      className={`text-sm mx-2 cursor-pointer ${
        isActive ? "font-extrabold" : "opacity-60"
      } transition-colors text-muted-foreground hover:text-primary hover:opacity-100`}>
      {t(label)}
    </div>
  );
};

const Other = () => {
  const currentPrice = useSelector((state) => state.dollarPrices?.currentPrice);
  const currentUser = useSelector((state) => state.authentication.user);

  return (
    <div>
      <Index
        title={"dollarPrice"}
        description='directDollarExchangeRateLinkedToTheKurdistanStockExchange'>
        <div className='flex flex-col'>
          {currentPrice && currentUser.type !== 18 && (
            <>
              <div
                className='p-2 pt-4 font-mono text-base font-bold text-center text-green-500 capitalize border-b opacity-80 '
                style={{ borderBottomWidth: 1 }}>
                {t("buy")} $ ={" "}
                {numeral(currentPrice?.price * 1 - 1000).format("0,0")}
              </div>
              <div
                className='p-2 pt-4 font-mono text-base font-bold text-center text-blue-500 capitalize opacity-80 border-b-whiteCardIndexColor'
                style={{ borderBottomWidth: 1 }}>
                {t("sell")} $ ={" "}
                {numeral(currentPrice?.price * 1 + 1000).format("0,0")}
              </div>
            </>
          )}
        </div>
      </Index>
    </div>
  );
};
const ProfileTab = () => {
  const currentUser = useSelector((state) => state.authentication.user);
  return (
    <div className='relative h-full'>
      <Index title='name'>
        <p className='text-md'>{currentUser.name}</p>
      </Index>
      <Index title='email'>
        <p className='text-md'>{currentUser.email}</p>
      </Index>
      <Index title='logout' className='absolute bottom-0 '>
        <LogoutButton />
      </Index>
    </div>
  );
};
const Appearance = ({ onChangeLanguage }) => {
  const language = i18next.language;

  return (
    <div>
      <Index
        title={t("language")}
        description={t("selectTheLanguageOfThePlatform")}>
        <select
          onChange={onChangeLanguage}
          value={language}
          className='p-2 px-5 border'>
          <option value='en'>English</option>
          <option value='ku'>کوردی</option>
          <option value='ar'>العربیە</option>
        </select>
      </Index>
    </div>
  );
};

const Index = ({ title, description, className, children }) => {
  return (
    <div
      className={
        "flex items-center justify-between w-full py-3 border-b " + className
      }>
      <div className='flex flex-col '>
        <p className='text-base font-medium'>{t(title)}</p>
        <p className='w-2/3 text-sm break-words opacity-75'>{t(description)}</p>
      </div>
      {children}
    </div>
  );
};

const LogoutButton = () => {
  const history = useHistory();
  const onLogout = async () => {
    LOGOUT();
    history.replace("/login");
  };

  return (
    <button
      onClick={onLogout}
      className={`  flex items-center cursor-pointer hover:opacity-80`}>
      <FiLogOut color={Colors.mainAqwaBlue} size={22} className='mx-2' />
      <p className='text-black opacity-80'>{t("logout")}</p>
    </button>
  );
};

export default SettingModal;
