const initialState = { requestedCourses: null, fetchedDate: null };

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COURSES": {
      const { data } = action;

      let memberships = state.requestedCourses || []
      let dates = state.fetchedDate || []
      data.map((membership) => {
        if (!dates.find((date) => date === membership.date)) {
          const newMembers = data.filter(a => a.date === membership.date)
          memberships = memberships.concat(newMembers)
          dates.push(membership.date)
        }
      })

      return {
        ...state,
        requestedCourses: memberships,
        fetchedDate: dates
      }
    };

    case "CREATE_COURSE": {
      let dt = state.requestedCourses.concat(action.data);
      return {
        ...state,
        requestedCourses: dt,
      };
    }
    case "UPDATE_COURSE": {
      let dt = state.requestedCourses.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        requestedCourses: dt,
      };
    }
    case "DELETE_COURSE": {
      let dt = state.requestedCourses.filter((a) => a._id !== action.data);
      return {
        ...state,
        requestedCourses: dt,
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
