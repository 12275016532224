const initialState = { users: null, currentUser: null, isExpiredFetch: false };

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS":
      return {
        ...state,
        users: state.users ? state.users.concat(action.data) : action.data,
        isExpiredFetch: action.isExpiredFetch,
      };
    case "ADD_EDIT_USER": {
      let newMessaaging = [...state.users];
      const currentUser = action.data;
      if (
        !state.users.find((a) => a._id === currentUser._id) ||
        state.users.length === 0
      ) {
        newMessaaging.push(currentUser);
      } else {
        newMessaaging = state.users.map((msg) => {
          if (msg._id === currentUser._id) {
            msg = currentUser;
          }
          return msg;
        });
      }

      return { ...state, users: newMessaaging, currentUser: null };
    }
    case "DELETE_USER": {
      let newUsers = [...state.users];

      newUsers = state.users.filter((a) => a._id !== action.data);

      return { ...state, users: newUsers };
    }
    default:
      return state;
  }
};

export default authenticationReducer;
