const initialState = {
    equipmentDebts: null,
    equipmentCustomers: null,
    equipmentRepaymentOfDebts: null,
    equipmentReturnActivities: null,
    equipmentBuyActivities: null,
    equipmentBrands: null,
    equipmentItems: null,
    equipmentTransactions: null,
    equipmentSellActivities: null,
    equipmentExpenses: null,
    equipmentShippings: null,
    equipmentPriceLists: null,
    equipmentRODAkaPower: null,
    equipmentDebtsAkaPower: null,
    search: "",
    fetchedDate: null
};

const equipmnetsReducer = (state = initialState, action) => {
    switch (action.type) {
        //!customers
        case "FETCH_SPECIFIC_EQUIP_PROPERTY": {
            return {
                ...state,
                [action.property]: action.data,
            };
        }
        case "CREATE_SPECIFIC_EQUIP_PROPERTY": {
            const dt = state[action.property].concat(action.data);
            return {
                ...state,
                [action.property]: dt,
            };
        }
        case "UPDATE_SPECIFIC_EQUIP_PROPERTY": {
            let dt = state[action.property].map((order) => {
                if (order._id === action.data._id) {
                    return action.data;
                }
                return order;
            });
            return {
                ...state,
                [action.property]: dt,
            };
        }
        case "DELETE_SPECIFIC_EQUIP_PROPERTY": {
            const dt = state[action.property].filter((a) => a._id !== action.id);
            return {
                ...state,
                [action.property]: dt,
            };
        }
        case "FETCH_EQUIP_DATES": {
            // const 
            let dates = state.fetchedDate || []
            
            return {
                ...state,
                fetchedDate: null,
            };
        }
        default:
            return state;
    }
};

export default equipmnetsReducer;
