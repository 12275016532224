import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import { deleteDollarPrice } from "../../../store/actions/dollarPricesAction";
import { handleLoading } from "../../../store/actions/loadingAction";
import { DateInput } from "../../Users/CreateUserScreen";
import dayjs from "dayjs";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import useSWR from "swr";
import { Error, fetcher, Loading } from "../../UI/CentersViewData";
import useQuery from "../../../customFunction/useQuery";
import axios from "axios";
import { IoTrashOutline } from "react-icons/io5";

function DollarPriceDetailsScreen() {
    const { id } = useParams()
    const isNew = useQuery().get('isNew')
    const { data, isLoading, error } = useSWR('/dollar-prices/' + id + '?isNew=' + isNew, fetcher)
    const [currentData, setCurrentData] = useState()
    const history = useHistory()
    const user = useSelector((state) => state.authentication.user)

    const onChangeValue = (type, value) => {
        let obj = currentData;

        if (type === 0) {
            obj.price = value;
        }
        else if (type === 1) {
            obj.type = 1;
            let check = false;
            check = obj.centers?.find((a) => a === value);
            if (check !== undefined) {
                obj.centers = obj.centers.filter((a) => a !== value);
            } else {
                obj.centers.push(value);
            }
        }
        else if (type === 2) {
            obj.date = value;
            obj.createdAt = dayjs(value).valueOf();

        }
        setCurrentData({ ...obj });
    };

    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData(data[0])
        }
    }

    const onSubmit = async () => {
        try {
            if (!currentData || !currentData.price) {
                alert('Please fill all the fields')
                return
            }

            if (isNew) {
                const newData = {
                    ...currentData,
                    adminId: user._id
                }
                await axios.post('/dollar-prices/', newData)
            }
            else
                await axios.put('/dollar-prices/', currentData)
            history.goBack()
        } catch (e) {
            console.log(e)
            alert(e.response.data.message)
        }
    };

    const onDelete = async () => {
        try {
            const check = window.confirm(t('areYouSureAboutDeleting'));
            if (check) {
                handleLoading();
                await deleteDollarPrice(currentData._id);
                history.goBack();
                history.goBack();
                handleLoading();
            }
        } catch (e) {
            console.log(e);
            handleLoading();
            alert(e);
        }
    };

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }

    return (
        <div className='flex flex-col items-center flex-1 w-full '>
            <CommonInputsContainer customIcon={currentData&&currentData.type===1 && <IoTrashOutline color="red" onClick={onDelete} size={30} />} onSave={onSubmit} title={t('dollar')} >
                {currentData?._id && (
                    <InstanceIndex label={t("#")} value={`#${currentData._id}`} />
                )}
                <TextInput
                    label={t("price")}
                    placeHolder={t("price")}
                    value={currentData?.price}
                    isNumber
                    onChangeValue={onChangeValue}
                    type={0}
                />
                <DateInput
                    label={`${t("date")}`}
                    placeHolder={t("date")}
                    value={currentData?.date}
                    onChangeValue={onChangeValue}
                    type={2}
                />
            </CommonInputsContainer>
        </div>
    );
}

export default DollarPriceDetailsScreen
