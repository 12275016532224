import React from 'react'
import { useHistory } from 'react-router-dom'
import StorageCreateInvoice from '../../../components/Storage/StorageCreateInvoice'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../../src/components/shadui/ui/alert-dialog"

function NutritionStorageDetails() {
    const currentCenterId = '62d3de07e4b5f62ba339b22b'
    const currentUser = useSelector((state) => state.authentication.user);
    const history = useHistory()
    const [alertOpen, setAlertOpen] = React.useState(false)
    const [expensesChecked, setExpensesChecked] = React.useState(null)
    const [items, setItems] = React.useState([])

    const onClickSubmit = async (selectedItems) => {
        try {
            // if (expensesChecked === null) {
            //     alert('Please select if it is add expense or not')
            //     return
            // }
            const newItems = selectedItems || items
            const res = await axios.put('/nutrition-storage/' + currentCenterId, ({ items: newItems, adminId: currentUser._id, expensesChecked }))

            history.goBack()
            // setAlertOpen(false)
        } catch (e) {
            console.log({ e })
            alert('error')
            throw e
        }
    }

    const onHandleExpense = (e) => {
        setExpensesChecked(e.target.value === 'true')
    }

    return (
        <div className='flex flex-1 w-full h-full bg-white' >
            {/* <AlertDialog open={alertOpen} >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Please make sure the items are correct</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently add the items to the storage,
                            so please make sure the items and price are correct.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <div className='flex flex-col' >
                        Do you want it to be expense?
                        <div className='flex gap-x-1' >
                            <div className='mr-2' ><input onChange={onHandleExpense} type='radio' checked={expensesChecked === true} name='expense' value='true' /> Yes</div>
                            <> <input type='radio' onChange={onHandleExpense} name='expense' value='false' checked={expensesChecked === false} /> No</>
                        </div>
                    </div>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={onClickSubmit}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog> */}
            {true ? <div className='flex flex-col w-full h-full' >
                <StorageCreateInvoice currencyType='usd' stockQuery={'/nutrition-stocks'} onClickSubmit={onClickSubmit} />
            </div> : null}
        </div>
    )
}

export default NutritionStorageDetails
