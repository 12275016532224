import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ArrayIndex from "../../../components/UI/AddingData/ArrayIndex";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import Features from "../../../data/Features/Features";
import PackageDivideBenfitType from "../../../data/Features/PackageDivideBenfitType";
import PackageOfferType from "../../../data/Features/PackageOfferType";
import PackageShowTypes from "../../../data/Features/PackageShowTypes";
import { handleLoading } from "../../../store/actions/loadingAction";
import { createOrUpdatePackage } from "../../../store/actions/packagesAction";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";

import useSwr from 'swr'
import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import { useQuery } from "../ClassSchedule/ClassSchedulesDetails";
import { HandleButton } from "../../Users/CreateUserScreen";
import axios from "axios";
function PackageDetailsScreen() {
  const isNew = useQuery().get('isNew');
  const id = useParams().id;

  const { data, isLoading, error } = useSwr('/packages/' + id, fetcher)

  const [currentPackage, setCurrentPackage] = useState();

  useEffect(() => {
    if (data) {
      if (data.length !== 0)
        setCurrentPackage(data[0])
      else {
        setCurrentPackage({
          duration: 30,
          price: { value: 30, discount: 0 },
          receptionDevideBenefit: 0,
          offerType: 0,
          center: ["62d3de07e4b5f62ba339b22b"],
          features: [0],
          showType: 0,
          labelDetails: {
            inEn: { title: "" },
            inKu: { title: "" },
            inAr: { title: "" },
          },
        })
      }
    }
  }, [data])

  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentPackage;

    if (type === 0) {
      obj.duration = value;
    }
    if (type === 1) {
      obj.price.value = Number(value);
    }
    if (type === 2) {
      obj.price.discount = Number(value);
    }
    if (type === 3) {
      obj.labelDetails.inEn.title = value;
    }
    if (type === 4) {
      obj.labelDetails.inKu.title = value;
    }
    if (type === 5) {
      let check = false;
      check = obj.features.find((a) => a === value);
      if (check !== undefined) {
        obj.features = obj.features.filter((a) => a !== value);
      } else {
        obj.features.push(value);
      }
    }

    if (type === 6) {
      let check = false;
      check = obj.center.find((a) => a === value);

      if (check !== undefined) {
        obj.center = obj.center.filter((a) => a !== value);
      } else {
        obj.center.push(value);
      }
    }
    if (type === 7) {
      obj.showType = value;
    }
    if (type === 8) {
      obj.devideBenefit = value;
    }
    if (type === 9) {
      obj.offerType = value;
    }
    if (type === 10) {
      obj.receptionDivideBenefit = Number(value);
    }
    else if (type === 11) {
      obj.receptionDivideBenefitIQD = Number(value);
    }
    else if (type === 12) {
      obj.isHide = !(obj?.isHide || false);
    }
    else if (type === 13) {
      obj.startTime = value;
      obj.tempValid = true;
    }
    else if (type === 14) {
      obj.endTime = value;
      obj.tempValid = true;
    }
    else if (type === 15) {
      obj.startTime = null;
      obj.endTime = null;
      obj.tempValid = false;
    }
    setCurrentPackage({ ...obj });
  };

  const onSave = async () => {
    try {
      await checkValidation();

      if (isNew) {
        const newData = {
          ...currentPackage,
        }
        await axios.post('/packages/', newData)
      }
      else
        await axios.put('/packages/', currentPackage)
      history.goBack()
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
    }
  };

  const checkValidation = async () => {
    if (
      !currentPackage.labelDetails.inEn.title) {
      throw "Invlaid Name";
    }

    if (currentPackage.features.length === 0) {
      throw "Invlaid Features";
    }
    if (currentPackage.showType === undefined) {
      throw "Invlaid Show Type";
    }
  };

  if (error) return <Error />

  if (isLoading) return <Loading />

  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer onSave={onSave} title={t('package')} >
        {currentPackage?._id && <InstanceIndex label={t("idNumber")} value={`#${currentPackage?._id}`} />}
        <TextInput
          label={t("duration")}
          placeHolder={t("name")}
          value={currentPackage?.duration}
          onChangeValue={onChangeValue}
          type={0}
        />
        <TextInput
          label={`${t("price")} $`}
          placeHolder={t("email")}
          value={currentPackage?.price.value}
          onChangeValue={onChangeValue}
          type={1}
        />
        <TextInput
          label={`${t("discount")} %`}
          placeHolder={t("discount")}
          value={currentPackage?.price.discount}
          onChangeValue={onChangeValue}
          type={2}
        />
        <TextInput
          label={t("englishName")}
          placeHolder={t("englishName")}
          value={currentPackage?.labelDetails.inEn.title}
          onChangeValue={onChangeValue}
          type={3}
        />
        <TextInput
          label={t("receptionDivideBenefitIQD")}
          placeHolder={t("receptionDivideBenefitIQD")}
          value={currentPackage?.receptionDivideBenefitIQD}
          onChangeValue={onChangeValue}
          type={11}
        />
        <ArrayIndex
          label={t("features")}
          data={Features}
          type={5}
          index="id"
          value={currentPackage?.features}
          onChangeValue={onChangeValue}
        />
        <ArrayIndex
          label={t("offerType")}
          data={PackageOfferType}
          type={9}
          valType={1}
          value={currentPackage?.offerType}
          index="id"
          onChangeValue={onChangeValue}
        />
        <div className="flex flex-row justify-between w-full p-6">
          <h1 className="w-1/4">{t('limitationByTime')}</h1>
          <div className="flex justify-between w-3/5 gap-x-2">
            <input value={currentPackage?.startTime || ''} type='time' onChange={e => onChangeValue(13, e.target.value)} />
            <input value={currentPackage?.endTime || ''} type='time' onChange={e => onChangeValue(14, e.target.value)} />
            <button className="p-2 border cursor-pointer" onClick={() => onChangeValue(15, null)}>Clear</button>
          </div>
        </div>
        {currentPackage?._id && <HandleButton label={t("deletePackage")} onPress={onChangeValue.bind(this, 12)} value={currentPackage?.isHide || false} />}

      </CommonInputsContainer>
    </div >
  );
}

export default PackageDetailsScreen;
