import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";

const CREATE = "/requests/create";
const UPDATE = "/requests/update";


export const createRequest = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }
  } catch (e) {
    throw e;
  }
};

export const fetchRequests = async (status) => {
  try {
    const res = await axios.get(`/requests?onlyLength=true&status=0`);
    store.dispatch({ type: "FETCH_REQUESTS_LENGTH", data: res.data });
  } catch (e) {
    throw e;
  }
};
