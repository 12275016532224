import { store } from "../../App";
import { showInIQD } from "../Numeral/CurrencyFormat";

export const calculateCoursesTotalPrice = (data, id, currency) => {
  let val = 0;

  if (id) data = data.filter((a) => a.centerId === id);

  if (data)
    data.forEach((dt) => {
      let curr = "USD";
      let value = currency === 'IQD' ? dt.iqd : Number(dt.price);

      val += showInIQD(value, currency, curr, true) || 0;
    });
  return val;
};
export const calculateVIPCoursesTotalPrice = (data, id, currency) => {
  let val = 0;

  if (id) data = data.filter((a) => a.centerId === id);

  if (data)
    data.forEach((dt) => {
      //!if type !== null or undefined
      let curr = "USD";
      if (dt.type === 1 || dt.iqd > 20000) {
        let value = currency === 'IQD' ? dt.iqd : Number(dt.price);
        val += showInIQD(value, currency, curr, true) || 0;
      }

    });
  return val;
};
export const calculateCoursesWithAllDetail = (data, id, currency) => {

  let curr = "USD";

  const admins = store.getState().admins.admins;
  let alldata = []
  if (id && id !== 'total') data = data.filter((a) => a.centerId === id);

  if (data) {
    const trainers = admins.filter(a => (id === 'total' ? true : a.center && a.center.includes(id)) && a.type === 3 && !a.isHide);
    trainers.map(trainer => {
      const TRAINER_RATIO = trainer.trainerRatio;
      const VIP_TRAINER_RATIO = trainer.vipTrainerRatio;

      let numOfNormalCourse = 0;
      let numOfVIPCourse = 0;

      let totalNormalCourse = 0;
      let totalVIPCourse = 0;

      let totalNormalCourseWithTrainerRatio = 0;
      let totalVIPCourseWithTrainerRatio = 0;

      let totalNormalCourseWithOurRatio = 0
      let totalVIPCourseWithOurRatio = 0

      let trainerCourses = data.filter(a => a.trainerId === trainer._id);

      trainerCourses.map(course => {
        //!Normal Course
        if (course.type === 0 || course.iqd <= 35000) {
          numOfNormalCourse++;
          totalNormalCourse += currency === 'IQD' ? course.iqd : Number(course.price);

          totalNormalCourseWithTrainerRatio += Math.round(((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * TRAINER_RATIO);

          totalNormalCourseWithOurRatio += Math.round(((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * (100 - TRAINER_RATIO));
        } else if (course.type === 1 || course.iqd > 20000) {
          //!VIP Course
          numOfVIPCourse++;
          totalVIPCourse += currency === 'IQD' ? course.iqd : Number(course.price);

          totalVIPCourseWithTrainerRatio += Math.round(((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * VIP_TRAINER_RATIO);

          totalVIPCourseWithOurRatio += Math.round(((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * (100 - VIP_TRAINER_RATIO));
        }
      })

      alldata.push({
        trainerName: trainer.name,
        trainerId: trainer._id,
        numOfNormalCourse,
        numOfVIPCourse,
        totalNormalCourse: showInIQD(totalNormalCourse, currency, curr, true),
        totalVIPCourse: showInIQD(totalVIPCourse, currency, curr, true),
        totalNormalCourseWithTrainerRatio: showInIQD(totalNormalCourseWithTrainerRatio, currency, curr, true),
        totalVIPCourseWithTrainerRatio: showInIQD(totalVIPCourseWithTrainerRatio, currency, curr, true),
        totalNormalCourseWithOurRatio: showInIQD(totalNormalCourseWithOurRatio, currency, curr, true),
        totalVIPCourseWithOurRatio: showInIQD(totalVIPCourseWithOurRatio, currency, curr, true),
      })
    })

    return alldata;
  }
};
export const calculateCourseWithTrainerRatio = (data, id, currency) => {
  let val = 0;//!our ratio
  let totalTrainerRatio = 0;


  if (id) data = data.filter((a) => a.centerId === id);

  if (data)
    data.forEach((dt) => {
      //!if type !== null or undefined
      let curr = "USD";
      let trainerRatio = store.getState().admins.admins.find(a => a._id === dt.trainerId);

      if (trainerRatio) trainerRatio = Number(trainerRatio.trainerRatio)
      // if(!trainerRatio) alert('trainerRatio is undefined')
      if (dt.type === 0 || dt.iqd <= 20000) {

        let value = ((currency === 'IQD' ? dt.iqd : Number(dt.price)) / 100) * trainerRatio;
        totalTrainerRatio += showInIQD(value, currency, curr, true) || 0;
        val += showInIQD((currency === 'IQD' ? dt.iqd : Number(dt.price)) - value, currency, curr, true) || 0;
      }

    });
  return { totalOurRatio: val, totalTrainerRatio };
};
