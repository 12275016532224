import dayjs from "dayjs";
import { store } from "../../App";
import { fetchRequestedCourse } from "./coursesAction";
import { fetchDoors } from "./doorsAction";
import { fetchExpenses } from "./expensesAction";
import { handleLoading } from "./loadingAction";
import { fetchExpiryToday, fetchMemberships } from "./membershipsAction";
import { fetchOrders } from "./ordersAction";
import { fetchRecords } from "./recordAction";
import { fetchSupplements } from "./supplementsAction";
import axios from "axios";

export const updateDuration = (type, { startDate, endDate }, isEquipment, isSupplement, pickerType) => {
  return async (dispatch, getState) => {
    // handleLoading()
    let obj = getState().analytics[isSupplement ? 'supplementsDuration' : isEquipment ? 'equipmentsDuration' : 'duration'];
    let fetchedDate = getState().memberships.fetchedDate || [];

    obj.startDate = startDate || obj.startDate;
    obj.endDate = endDate || obj.endDate || new Date();


    if (!isSupplement && !isEquipment) {
      // if (!fetchedDate.includes(dayjs(startDate).format('YYYY-MM-DD')) || (endDate ? !fetchedDate.includes(dayjs(endDate).format('YYYY-MM-DD')) : false))
      await fetchAllData(dayjs(obj.startDate).format('YYYY-MM-DD'), (obj.endDate && dayjs(obj.endDate).format('YYYY-MM-DD')))
      // fetchEveryTime(dayjs(obj.startDate).format('YYYY-MM-DD'), (obj.endDate && dayjs(obj.endDate).format('YYYY-MM-DD')))//!fetch every time when change date
    } else if (isSupplement) {
      let fetchedDate = getState().supplementsShop.fetchedDate || [];

      // if (!fetchedDate.includes(dayjs(startDate).format('YYYY-MM-DD')) || (endDate ? !fetchedDate.includes(dayjs(endDate).format('YYYY-MM-DD')) : false))
      //   await fetchSupplements(dayjs(obj.date || obj.startDate).format('YYYY-MM-DD'), (obj.endDate && dayjs(obj.endDate).format('YYYY-MM-DD')))
    } else if (isEquipment) {

    }

    dispatch({
      type: "UPDATE_DURATION",
      data: { ...obj },
      isEquipment,
      isSupplement
    });
    // handleLoading()
  };
};

const fetchAllData = async (startDate, endDate) => {
  try {
    store.dispatch({ type: 'FETCH_CENTERS_DATA', data: null })

    const User = store.getState().authentication.user
    if (User.type === 17) {
      const centers = []
      let d
      await Promise.all(User.center.map(async (centerId) => {
        await axios.get(`/analysis/centers?start_date=${dayjs().format(startDate)}&end_date=${dayjs().format(endDate)}&centerId=${centerId} `).then(data => {
          data.data[0].akafitness.data = data.data[0].akafitness.data.sort((a, b) => a.centerName.localeCompare(b.centerName))
          d = data.data[0]?.date
          centers.push(data.data[0].akafitness.data[0])
        });
      }))
      store.dispatch({ type: 'FETCH_CENTERS_DATA', data: [{ akafitness: { data: centers }, date: d }] })
    } else {
      axios.get(`/analysis/centers?start_date=${dayjs().format(startDate)}&end_date=${dayjs().format(endDate)}  `).then(data => {
        data.data[0].akafitness.data = data.data[0].akafitness.data.sort((a, b) => a.centerName.localeCompare(b.centerName))
        store.dispatch({ type: 'FETCH_CENTERS_DATA', data: data.data })
      });
    }

  } catch (e) {
    console.log(e);
  }
};

const fetchEveryTime = async (startDate, endDate) => {
  fetchExpiryToday(startDate, endDate)

}

export const updateCurrencyUnit = (type) => {
  return (dispatch, getState) => {
    let label = "USD";

    if (type == 1) {
      label = "IQD";
    }


    dispatch({
      type: "UPDATE_CURRENCY",
      data: label,
    });
  };
};
