import { t } from 'i18next'
import React, { useState } from 'react'
import i18n from '../../i18n'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { createRequest } from '../../store/actions/requestsAction'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../UI/CentersViewData'
import { Skeleton } from '../../ui/ui/skeleton'

function RequestsScreen() {
    const language = i18n.language
    const { data: requests, isLoading, error } = useSwr('/requests', fetcher)

    const pendingRequests = requests && requests.filter(request => request.status === 0)
    const answeredRequests = requests && requests.filter(request => request.status !== 0)
    const [requestModal, setRequestModal] = useState(false)
    const centers = useSelector(state => state.centers.centers)
    const user = useSelector(state => state.authentication.user)

    pendingRequests &&
        pendingRequests.length !== 0 &&
        pendingRequests.sort((a, b) => a.status - b.status);
    answeredRequests &&
        answeredRequests.length !== 0 &&
        answeredRequests.sort((a, b) => a.status - b.status);

    const onChangeModal = (data, isClose) => {
        if (user.type === 0)
            setRequestModal(requestModal => requestModal ? false : data)
    }

    if (error) return <Error />

    if (isLoading) return <Loading />

    return (
        // <View style={{flex:1}} >
        <div
            style={{ direction: language === "en" ? "ltr" : "rtl" }}
            className="flex flex-col flex-1 w-full h-full m-8"
        >
            <h1 style={{ fontSize: 24, fontFamily: "Montserrat-Bold" }}>
                {" "}
                {t("requests")}{" "}
            </h1>
            {isLoading ? <>
                <Skeleton className='w-[100%] my-10 bg-slate-200 h-[400px]' />
                <Skeleton className='w-[100%] my-4 bg-slate-200 h-[400px]' />
            </> : <>
                <PendingRequests onChangeModal={onChangeModal} requests={pendingRequests} />
                <AnsweredRequests onChangeModal={() => { }} requests={answeredRequests} />
                {requestModal && <RequestModal centers={centers} language={language} requestModal={requestModal} setRequestModal={onChangeModal} />}</>}

        </div>
        // </View>
    )
}

const AnsweredRequests = ({ requests, onChangeModal }) => {
    return <div className="my-7">

        <h4 style={{ fontSize: 18 }} >
            {t("answeredRequests")}{" "}
            <span style={{ opacity: 0.7, fontSize: 14 }}>
                ({requests?.length || 0})
            </span>
        </h4>
        <div
            className="flex flex-col p-2 my-2 border-gray-300"
            style={{ borderRadius: 8, borderWidth: 2 }}
        >
            <TableHeader />
            <TableBody onChangeModal={onChangeModal} requests={requests} />
        </div>
    </div>
}

const PendingRequests = ({ requests, onChangeModal }) => {
    return (
        <div className="my-9">
            <h4 style={{ fontSize: 18 }}>
                {t("pendingRequests")}{" "}
                <span style={{ opacity: 0.7, fontSize: 14 }}>
                    ({requests?.length || 0})
                </span>
            </h4>
            <div
                className="flex flex-col p-2 my-2 border-gray-300"
                style={{ borderRadius: 8, borderWidth: 2 }}
            >
                <TableHeader />
                <TableBody onChangeModal={onChangeModal} requests={requests} />
            </div>
        </div>
    );
};

const HEADER = [
    { label: "reception", width: "20%" },
    { label: "request", width: "30%" },
    { label: "moreDetails", width: "30%" },
    { label: "status", width: "20%" },
];
const TableHeader = () => {
    return (
        <div style={{ width: "100%" }} className="flex px-2 py-1 border-b-1">
            {HEADER.map((item, index) => (
                <div key={index} className="flex " style={{ width: item.width }}>
                    <h4
                        className="opacity-75"
                        style={{ fontFamily: "Montserrat-Bold", fontSize: 15 }}
                    >
                        {t(item.label)}
                    </h4>
                </div>
            ))}
        </div>
    );
};

const STATUS = [
    { label: "pending", color: "text-yellow-700", id: 0 },
    { label: "accepted", color: "text-green-600", id: 1 },
    { label: "rejected", color: "text-red-600", id: 2 },
];
const TableBody = ({ requests, onChangeModal }) => {
    const centers = useSelector(state => state.centers.centers)
    const language = i18n.language
    return (
        <div>
            {requests &&
                requests.length !== 0 &&
                requests.map((item, index) => {
                    const adminName = item.admin[0].name;
                    const status = STATUS.find((a) => a.id === item.status);

                    return (
                        <div
                            key={index}
                            onClick={onChangeModal.bind(this, item)}
                            className={`flex items-center justify-between px-2 py-3 ${index !== requests.length - 1 ? "border-b-2" : ""
                                }`}
                        >
                            <PendingTableIndex
                                width="20%"
                                label={adminName}
                                label2={dayjs(item.createdAt).format("lll")}
                                label3={item.centerId}
                                centers={centers}
                                language={language}
                            />
                            <PendingTableIndex width="30%" label={item.label} />
                            <PendingTableIndex width="28%" label={item.moreDetails} />
                            <PendingTableIndex
                                width="20%"
                                color={status.color}
                                label={t(status.label)}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

const PendingTableIndex = ({ label, color, width, label2, label3, centers, language }) => {
    return (
        <div style={{ width, cursor: "pointer" }} className={`${color}`}>
            <h4 style={{ fontSize: 15 }} className="truncate-2line">
                {label}
            </h4>
            {label3 && (
                <h4 className="mb-1 text-sm text-blue-600 opacity-75 truncate-2line">{centers.find(a => a._id === label3).names[language]}</h4>
            )}
            {label2 && (
                <h4 className="text-xs opacity-75 truncate-2line">{label2}</h4>
            )}

        </div>
    );
};

const RequestModal = ({ requestModal, language, centers, setRequestModal }) => {
    const title = requestModal?.label
    const moreDetails = requestModal?.moreDetails
    const status = STATUS.find((a) => a.id === requestModal?.status).label;
    const history = useHistory()

    const onClickData = () => {
        const type = requestModal?.requestType
        const data = requestModal?.data
        if (type === 0)
            history.push(`/centers/${data.centerId}/view-data/users/users/${data._id}`)
        else if (type === 1)
            history.push(`/centers/${data.centerId}/view-data/courses/courses/${data._id}`)
        else if (type === 2)
            history.push(`/centers/${data.centerId}/view-data/expenses/expenses/${data._id}`)
        else if (type === 4)
            history.push(`/centers/${data.centerId}/view-data/cafeteria/cafeteria/${data._id}`)
    }

    const onPressBtn = async (type) => {
        // type 1 => accept , type 2 => reject
        await createRequest({ ...requestModal, status: type })
        setRequestModal()
    }
    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center`}
        >

            <div
                className="flex flex-col p-4 bg-white "
                style={{ borderRadius: 8, width: "50%", maxWidth: "500px", maxHeight: "500px" }}
            >
                <div className="flex justify-end">
                    <AiOutlineCloseCircle color='red' onClick={setRequestModal} className="cursor-pointer" size={22} />
                </div>
                <div>
                    <div className="flex justify-between my-3 ">
                        <h2>{title}</h2>
                        <span class={`inline-block px-2 py-1 text-xs font-semibold tracking-wide text-gray-800 uppercase ${requestModal?.status === 0 ? 'bg-yellow-300' : requestModal?.status === 1 ? 'bg-green-300' : 'bg-red-300'} rounded-full`}>{t(status)}</span>
                    </div>
                </div>

                <div className='my-3' >
                    <h4 className="text-sm text-gray-600">{(moreDetails)}</h4>
                </div>
                <div className='flex flex-col justify-between my-7' >
                    <div className='flex justify-between' >
                        <h2 className="">{t('data')}</h2>
                        <span onClick={onClickData} class={`inline-block cursor-pointer px-2 py-1 text-xs font-semibold tracking-wide text-gray-800 uppercase bg-blue-300 rounded-full`}>{t('viewData')}</span>
                    </div>
                    <ShowData data={requestModal?.data} requestType={requestModal?.requestType} />
                </div>

                <div className="flex items-center justify-center">
                    <div onClick={onPressBtn.bind(this, 1)} className="inline-block px-4 py-2 mx-3 font-semibold text-gray-800 bg-white border border-green-300 rounded shadow-sm cursor-pointer hover:bg-green-100">Accept</div>

                    <div onClick={onPressBtn.bind(this, 2)} className="inline-block px-4 py-2 font-semibold text-gray-800 bg-white border border-red-300 rounded shadow-sm cursor-pointer hover:bg-red-100">Reject</div>

                </div>
            </div>
        </div>
    );

}

const ShowData = ({ data, requestType }) => {
    return <div>
        {requestType === 0 && <div className='flex flex-col my-4 text-sm '>
            <div className='flex my-3' >
                <h5 className='' >{t('name')}: </h5>
                <h5>{data?.name}</h5>
            </div>
            <div className='flex'>
                <h5 className='' >{t('id')}: </h5>
                <h5>{data?.bioStarID}</h5>
            </div>

        </div>}
    </div>
}
export default RequestsScreen
