import React from "react";

import dayjs from "dayjs";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-2 bg-white border ">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const MainChart = ({ duration, data }) => {
  const packages = data.revenue.memberships.packages

  const chartData = packages.map((p) => ({
    name: p.labelDetails.inEn.title+p.features.map(f=>f===0?' gym ':' swim ').join(' '),
    pv: p.num,
    fill: "#" + Math.floor(Math.random() * 16777215).toString(16)
  }))
  return (
    <div className="flex bg-white h-full w-[49%] flex-col max-md:w-full max-md:h-[300px] max-md:my-4 rounded-md ">
      <div style={{ width: "100%", padding: 20 }}>
        <h1
          style={{
            color: "#292929",
            fontSize: 25,
          }}
        >
          <h1
            style={{
              opacity: 0.8,
            }}
          >
            {dayjs(duration.startDate).format("DD-MM-YYYY")}
            {" - "}
            {dayjs(duration.endDate).format("DD-MM-YYYY")}.
          </h1>
        </h1>
      </div>

      <div className="flex items-center justify-center w-full h-full" >
        <ResponsiveContainer width="100%" height="80%">
          <BarChart
            // width={100}
            // height={300}
            data={chartData}
            style={{ fontFamily: 'Montserrat' }}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={false} dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="pv" barSize={20} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>


    </div>
  );
};

export default MainChart;