import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import { createOrUpdateCenter } from "../../../store/actions/centersAction";
import { handleLoading } from "../../../store/actions/loadingAction";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";

const FEATURES = [
  { id: 0, label: "gym", iconName: 'ios-fitness', },
  { id: 1, label: "pool", icon: 'pool', },
  { id: 2, label: "sauna", iconName: 'sunny-outline', },
  { id: 3, label: "steam", iconName: 'water-outline', },
  { id: 4, label: "Locker Rooms", icon: 'locker-multiple' },
  { id: 5, label: "Boxing and mma", icon: 'boxing-glove', },
  { id: 6, label: "Crossfit", icon: 'dumbbell' },
  { id: 7, label: "jacuzzi", icon: 'shower', },
  { id: 8, label: "turkishBath", icon: 'shower-head', },
  { id: 9, label: "parking", iconName: 'car-outline', },
]
const SOCIALS = [{ id: 0, label: "instagram", iconName: 'logo-instagram' },
{ id: 1, label: "facebook", iconName: 'logo-facebook' },
{ id: 2, label: "snapchat", icon: 'snapchat' },
]

function CenterDetailsScreen() {
  const [currentCenter, setCurrentCenter] = useState(false);
  const Centers = useSelector((state) => state.centers.centers);

  const id = useParams().id;

  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentCenter;

    if (type === 0) {
      obj.label = value;
    } else if (type === 1) {
      obj.locations = value;
    } else if (type === 2) {
      obj.gender = value;

    } else if (type === 3) {
      obj.descriptions = value;
    } else if (type === 4) {
      obj.features = value;
    } else if (type === 5) {
      obj.socials = value;
    } else if (type === 6) {
      obj.names = value;
    }
    else if (type === 7) {
      obj.image = value;
    }
    else if (type === 8) {
      obj.phone = value;
    }
    else if (type === 9) {
      obj.isOpen = !(obj?.isOpen || false);
    } else if (type === 10) {
      //! value is array but in string and seperated by comma
      obj.imagesCollection = value;
    }
    else if (type === 12) {
      //! value is array but in string and seperated by comma
      obj.coverUri = value;

    } else if (type === 11) {
      //! value is array but in string and seperated by comma
      obj.coverType = value;
    }
    else if (type === 13) {
      obj.trainers = value;
    }

    setCurrentCenter({ ...obj });
  };

  const onSubmit = async () => {
    handleLoading();
    let isUpdate = Centers?.find((a) => a._id === id);
    await createOrUpdateCenter(currentCenter, isUpdate);
    history.goBack();
    handleLoading();
  };

  const setUp = () => {
    let objData = {};
    let check = Centers?.find((a) => a._id === id);

    if (check) {
      objData = check;
    } else {
      let obj = {
        label: "",
      };

      objData = obj;
    }

    setCurrentCenter(objData);
  };

  useEffect(() => {
    setUp();
  }, []);

  console.log(currentCenter)

  return (
    <div className="flex flex-col items-center w-full ">
      <CommonInputsContainer onSave={onSubmit} title={t('center')} >
        <div className="items-center justify-center bg-white">
          <InstanceIndex label={t("id")} value={`#${currentCenter._id}`} />
          <TextInput
            label={t("name")}
            placeHolder={t("name")}
            value={currentCenter.label}
            onChangeValue={onChangeValue}
            type={0}
          />

          {/* <HandleButton label={t("isOpen")} onPress={onChangeValue.bind(this, 9)} value={currentCenter?.isOpen || false} /> */}
          <MultiLangTextInput label='names' value={currentCenter.names} onChangeValue={onChangeValue.bind(this, 6)} />
          <TextInput
            label={t("image")}
            placeHolder={t("image")}
            value={currentCenter.image}
            onChangeValue={onChangeValue}
            type={7}
          />
          <TextInput
            label={t("coverUri")}
            placeHolder={t("coverUri")}
            value={currentCenter.coverUri}
            onChangeValue={onChangeValue}
            type={12}
          />
          <MultiLangTextInput label='locations' value={currentCenter.locations} onChangeValue={onChangeValue.bind(this, 1)} />
          <MultiLangTextInput label='descriptions' value={currentCenter.descriptions} onChangeValue={onChangeValue.bind(this, 3)} />
          <TextInput
            label={t("phone")}
            placeHolder={t("phone")}
            value={currentCenter.phone}
            onChangeValue={onChangeValue}
            type={8}
          />
          <FeaturesContainer onChangeValue={onChangeValue.bind(this, 4)} type={0} value={currentCenter.features} />
          <FeaturesContainer onChangeValue={onChangeValue.bind(this, 5)} type={1} value={currentCenter.socials} />
          {/* trainers */}
          <div className="flex flex-row justify-between w-full p-6" >
            <h1>Trainers</h1>
            <TrainersContainer trainersData={currentCenter.trainers} onChangeTrainers={(d) => onChangeValue(13, d)} />
          </div>
        </div>
      </CommonInputsContainer>
    </div>
  );
}

const Container = ({ onChangeValue, onSubmit, currentCenter }) => {
  return (
    <div className="w-4/5 mt-10 border">
      <InputContainer
        onChangeValue={onChangeValue}
        currentCenter={currentCenter}
      />
    </div>
  );
};

const InputContainer = ({ onChangeValue, currentCenter }) => {
  const { t } = useTranslation();

  return (
    <div className="items-center justify-center bg-white">
      <InstanceIndex label={t("id")} value={`#${currentCenter._id}`} />
      <TextInput
        label={t("name")}
        placeHolder={t("name")}
        value={currentCenter.label}
        onChangeValue={onChangeValue}
        type={0}
      />

      {/* <HandleButton label={t("isOpen")} onPress={onChangeValue.bind(this, 9)} value={currentCenter?.isOpen || false} /> */}
      <MultiLangTextInput label='names' value={currentCenter.names} onChangeValue={onChangeValue.bind(this, 6)} />
      <TextInput
        label={t("image")}
        placeHolder={t("image")}
        value={currentCenter.image}
        onChangeValue={onChangeValue}
        type={7}
      />
      <TextInput
        label={t("coverUri")}
        placeHolder={t("coverUri")}
        value={currentCenter.coverUri}
        onChangeValue={onChangeValue}
        type={12}
      />
      <MultiLangTextInput label='locations' value={currentCenter.locations} onChangeValue={onChangeValue.bind(this, 1)} />
      <MultiLangTextInput label='descriptions' value={currentCenter.descriptions} onChangeValue={onChangeValue.bind(this, 3)} />
      <TextInput
        label={t("phone")}
        placeHolder={t("phone")}
        value={currentCenter.phone}
        onChangeValue={onChangeValue}
        type={8}
      />
      <FeaturesContainer onChangeValue={onChangeValue.bind(this, 4)} type={0} value={currentCenter.features} />
      <FeaturesContainer onChangeValue={onChangeValue.bind(this, 5)} type={1} value={currentCenter.socials} />
      {/* trainers */}
      <div className="flex flex-row justify-between w-full p-6" >
        <h1>Trainers</h1>
        <TrainersContainer trainersData={currentCenter.trainers} onChangeTrainers={(d) => onChangeValue(13, d)} />
      </div>
    </div>
  );
};

//! contain the features of the center can select multiple and input image uri
const FeaturesContainer = ({ type, value, onChangeValue }) => {
  const [selectedFeatures, setSelectedFeatures] = useState(value || []);
  const DATA = type === 0 ? FEATURES : SOCIALS
  const onChangeValuee = (id, e) => {
    const valuee = e.target.value

    //! add image uri value to object in selected features

    const newS = selectedFeatures.map(a => {
      if (a.id === id) {
        a[type === 0 ? 'imageUri' : 'uri'] = valuee
      }
      return a
    })

    setSelectedFeatures(newS)
    onChangeValue(newS)
  };

  const onSelectFeature = (valuee) => {
    const newFeatures = [...selectedFeatures];
    const index = newFeatures.findIndex((a) => a.id === valuee.id);
    if (index === -1) {
      newFeatures.push(valuee);
    } else {
      newFeatures.splice(index, 1);
    }
    setSelectedFeatures(newFeatures)
    onChangeValue(newFeatures)

  }

  return (
    <div className="flex flex-row justify-between w-full p-6">
      <h1>{type === 0 ? 'features' : 'socials'}</h1>
      <div style={{ width: '60%' }} className="flex flex-col" >

        {DATA.map((ft, i) => {
          const isCheck = selectedFeatures.find(a => a.id === ft.id)
          return <div key={ft.id} className="flex items-center m-2" style={{ width: '100%' }} >
            <div onClick={onSelectFeature.bind(this, ft)} style={{ width: '25%', backgroundColor: isCheck ? 'green' : null, color: isCheck ? '#fff' : null }} className="p-1 border cursor-pointer" >{ft.label.toUpperCase()}</div>
            <input disabled={!isCheck} value={isCheck?.imageUri || isCheck?.uri} onChange={onChangeValuee.bind(this, ft.id)} style={{ width: '75%', height: '100%' }} type='text' placeholder="image uri" className="px-2 border-none outline-none h-9 bg-whiteCardColor" />
          </div>
        })}
      </div>
    </div>
  );
};
export const MultiLangTextInput = ({ value, label, onChangeValue }) => {
  const [selectedFeatures, setSelectedFeatures] = useState(value || { en: '', ku: '', ar: '' });
  const onChangeValuee = (type, e) => {
    const valuee = e.target.value
    const newS = selectedFeatures
    //! add image uri value to object in selected features

    newS[type] = valuee


    setSelectedFeatures(newS)
    onChangeValue(newS)
  };
  return (
    <div className="flex flex-row justify-between w-full p-6">
      <h1>{label}</h1>
      <div style={{ width: '60%' }} className="flex flex-col" >

        <input
          onChange={onChangeValuee.bind(this, 'en')}
          className='w-full px-2 m-1 border-none outline-none h-9 bg-whiteCardColor'
          placeholder={'en'}
          value={value?.en}
        />
        <input
          onChange={onChangeValuee.bind(this, 'ku')}
          className='w-full px-2 m-1 border-none outline-none h-9 bg-whiteCardColor'
          placeholder={'ku'}
          value={value?.ku}
        />
        <input
          onChange={onChangeValuee.bind(this, 'ar')}
          className='w-full px-2 m-1 border-none outline-none h-9 bg-whiteCardColor'
          placeholder={'ar'}
          value={value?.ar}
        />
        {/* {DATA.map((ft, i) => {
          const isCheck = selectedFeatures.find(a => a.id === ft.id) ? 'green' : null
          return <div key={ft.id} className="flex items-center m-2" style={{ width: '100%' }} >
            <div onClick={onSelectFeature.bind(this, ft)} style={{ width: '25%', backgroundColor: isCheck ? 'green' : null, color: isCheck ? '#fff' : null }} className="p-1 border cursor-pointer" >{ft.label.toUpperCase()}</div>
            <input disabled={!isCheck} onChange={onChangeValuee.bind(this, ft.id)} style={{ width: '75%', height: '100%' }} type='text' placeholder="image uri" className="px-2 border-none outline-none h-9 bg-whiteCardColor" />
          </div>
        })} */}
      </div>
    </div>
  );
};

const TrainersContainer = ({
  onChangeTrainers,
  trainersData
}) => {
  const [trainers, setTrainers] = useState(trainersData || [{ name: '', about: "", profileImg: '' }])


  const onChangeValue = (type, value, index) => {
    const newTrainers = [...trainers]
    newTrainers[index][type] = value
    setTrainers(newTrainers)
    onChangeTrainers(newTrainers)
  }

  const addTrainer = () => {
    const newTrainers = [...trainers]
    newTrainers.push({ name: '', about: "", profileImg: '' })
    setTrainers(newTrainers)
    onChangeTrainers(newTrainers)
  }

  const removeTrainer = (index) => {
    const newTrainers = [...trainers]
    if (newTrainers.length === 1) return
    newTrainers.splice(index, 1)
    setTrainers(newTrainers)
    onChangeTrainers(newTrainers)
  }
  // console.log(trainers)
  return (
    <div className="flex flex-col w-3/5 gap-y-[3rem]" >
      {trainers.map((trainer, index) => <div key={index} className="flex flex-col w-full" >
        <div className="flex items-center w-full" >
          <div className="flex items-center w-1/2 gap-x-2 " >
            <p  >Name</p>
            <input className="w-[80%] p-1 text-sm border border-none outline-none h-7 bg-whiteCardColor" placeholder="Trainer name" value={trainer.name} onChange={(e) => onChangeValue('name', e.target.value, index)} />
          </div>
          <div className="flex items-center w-1/2 gap-x-2" >
            <p>About</p>
            <input className="w-[80%] p-1 text-sm border border-none outline-none h-7 bg-whiteCardColor" placeholder="Trainer about" value={trainer.about} onChange={(e) => onChangeValue('about', e.target.value, index)} />
          </div>
        </div>
        <div className="flex items-center w-full mt-6" >
          <div className="flex items-center w-full gap-x-1" >
            <p>Profile Image</p>
            <div className="flex items-center w-full gap-x-2" >
              <input className="w-[80%] h-8 p-1 text-sm border border-none outline-none bg-whiteCardColor" placeholder="Trainer profile image" value={trainer.profileImg} onChange={(e) => onChangeValue('profileImg', e.target.value, index)} />
              {/* <button onClick={removeTrainer.bind(this,index)} className="h-full p-1 text-white bg-red-500 cursor-pointer" > */}
              <IoCloseCircleOutline onClick={removeTrainer.bind(this, index)} className="cursor-pointer" color="red" size={30} />
              {index === trainers.length - 1 && <IoAddCircleOutline onClick={addTrainer} className="cursor-pointer" color="green" size={30} />}
            </div>
          </div>
        </div>
      </div>)}
      {/* <HandleButton label="add" onPress={addTrainer} /> */}
    </div>
  )
}





export default CenterDetailsScreen;
