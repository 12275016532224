const ku = {
  somethingWentWrong: "ببورە هەڵەیەک ڕوویدا تکایە دواتر هەوڵبەرەوە بکەرەوە",

  login: "چوونە ژوورەوە",
  loginId: "ئایدی چوونە ژوورەوە",
  password: "وشەی تێپەڕ",

  users: "بەژداربوان",
  name: "ناو",
  phoneNumber: "ژمارەی مۆبایل",
  userId: "ئایدی هەژمار",
  email: "ئیمەیڵ",
  idNumber: "ئایدی ژمارە",
  submit: "جيبەجيکردن",
  createUser: "دروستکردنی هەژمار",
  registeredAt: "کاتی تۆمارکردن",
  days: "رۆژەکان",
  gym: "جیم",
  swim: "مەلەوانگە",

  dailyReport: "ڕاپۆرتی ڕۆژانە",
  advancedDailyReport: "ڕاپۆرتی ڕۆژانەی پێشکەوتوو",
  orders: "کافتریا",
  admin: "ئادمین",
  logout: "چوونە دەرەوە",

  admins: "ئادمینەکان",
  center: "هۆڵ",
  packages: "پاکێجەکان",

  id: "ئایدی",
  createdAt: "کاتی دروستکردن",
  type: "جۆر",
  features: "خزمەتگوزاریەکان",

  duration: "ماوە",
  price: "نرخ",
  discount: "داشکاندن",
  englishName: "ناوی ئینگلیزی",
  kurdishName: "ناوی کوردی",
  arabicName: "ناوی ‌عەرەبی",
  showType: "جۆری پشاندان",
  firstTime: "جاری یەکەم",
  afterFirstTime: "لە پاش جاری یەکەم",
  allTime: "هەموو کات",

  owner: "خاوەن",
  manager: "بەڕيوبەر",
  trainer: "ڕاهينەر",
  reception: "ڕیسيپشن",

  total: 'کۆی گشتی',
  distributionWithTrainer: "دابەشکردن لەگەڵ ڕاهێنەر",
  notDistributionWithTrainer: "دابەش نەکردن لەگەڵ ڕاهێنەر",

  divideBenefit: "دابەشکردنی قازانجە دارایەکەی",
  requestCourse: "کۆرس",
  cafeteria: "کافتریا",
  cafeteriaBenefit: "قازانجی کافتریا",
  expenses: "خەرجیەکان",
  cafeteriaExpenses: "خەرجی کافتریا",
  gymExpenses: "خەرجی جیم",
  date: "بەروار",

  endDate: "بەرواوری تەواوبوون",

  supplements: "تەواوکەری خۆراکی",

  newUser: "ئەندامی نوێ",
  renewSubscription: "نوێکردنەوەی ئەندامێتی",
  membershipBenefit: "داهاتی ئەندامێتی",
  reportSummary: "پوختەی ڕاپۆرت",
  advancedReportSummary: "پوختەی ڕاپۆرتی پێشکەوتوو",
  revenue: "داهات",
  profit: "قازانج",
  membershipSummary: "پوختەی ئەندامێتی",
  financeSummary: "پوختەی دارای",
  main: "سەرەکی",

  numberOrder: "ژمارەی وەسڵ",
  dashboard: "داشبۆڕد",

  memberships: "بەژداریکردن",
  activeUser: "بەژداربووی چالاک",
  expiredUser: "بەژداربوە بەسەرچوەکان",
  startDate: "بەرواری دەستپێکردن",
  start: "دەستپێک",
  end: "کۆتا",
  day: "ڕۆژ",
  username: "ناوی بەژداربوو",
  ticketNumber: "ژمارەی وەسڵ",

  offerType: "جۆری ئۆفەر",
  receptionDivideBenefit: "بڕی دابەشکردن لەگەڵ ڕیسێپشن",
  receptionDivideBenefitIQD: 'بڕی دابەشکردن لەگەڵ ڕیسێپشن دینار',
  normal: "ئاسای",
  offer: "ئۆفەری تایبەت",

  delete: "دیلیت",

  undefined: "نەزانراو",
  male: "نێر",
  female: "مێ",
  gender: "ڕەگەز",

  openingTheDoors: "کردنەوەی دەرگاکان",
  door: "دەرگای",
  reason: "هۆکار",
  employeeComing: "هاتنە ژوورەوەی کارمەندان",

  wholesalePrice: "نرخی کۆ",
  minimumsalePrice: "کەمترین نرخی فرۆشتن",
  buyPrice: "نرخی کڕین",

  balance: "باڵانس",

  numberOfGuestUsers: "ژمارەی بەژداربووی میوان",
  sellPrice: "نرخی فرۆشتن",
  realPrice: "نرخی ڕاستەقینە",

  numberOfProductInStore: "ژمارەی بەرهەم لە کۆگا",

  buy: "کڕین",
  returnOfPurchase: "گەڕاندنەوەی کڕین",
  image: "وێنە",
  select: "هەڵیبژێرە",
  sellId: "ژمارەی وەسڵ",
  note: "تێبینی",
  customers: "کڕیارەکان",
  debt: "قەرز",
  repaymentOfDebt: "گەڕاندەوەی قەرز",
  sell: "فرۆشتن",
  freeWallet: "جزدانی خۆڕای",
  money: "پارە",
  payedPrice: "پارەی دراوە",
  totalPrice: "کۆی پارە",
  productNumber: "ژمارەی بەرهەم",
  totalPreviousDebts: "کۆی قەرزەکانی پێشووی",
  totalDebts: "کۆی قەرزەکان",
  quantity: "بڕ",
  qty: 'دانە',
  print: "پرنت",
  equipments: "ئامێرەکان",
  shippingCars: "گوازەرەوەکان",
  brands: "براندەکان",
  totalPayedPrice: "کۆی دەستکەوتوو",
  remainingDebt: "کۆی قەرزی ماوە",
  brand: "براند",
  code: "کۆد",
  salary: "مووچە",
  stock: "کۆگا",
  cleaner: "پاکەرەوە",
  accountant: "ژمێریار",
  proteinSeller: "فرۆشەری پرۆتیین",
  socialMedia: "سۆشیال میدیا",
  technical: "تەکنیکاڵ",
  expense: "خەرجی",
  caffetteriaGuestUser: "میوانانی کافێتریا",
  totalSalary: "کۆی موچە",
  garage: "گەراج",
  supplementExpenses: "پڕۆتینی شکێنراو",
  bigExpenses: "خەرجیە گەورەکان",
  package: "پاکێج",
  insertLogoToImage: "دانانی لۆگۆ بۆ وێنەکان",
  proteinManager: "بەڕێوەبەری پرۆتین",
  equipmentManager: "بەڕێوەبەری ئامێرەکان",
  stockBenfitPrice: "قازانجی مەغزەن (نرخی تاک)",
  stockBenfitWholePrice: "قازانجی مەغزەن (نرخی کۆ)",
  stockBenfitBuyPrice: "کۆی مەغزەن (نرخی کڕین)",

  benfitPrice: "قازانج (نرخی تاک)",
  BenfitWholePrice: "قازانج (نرخی کۆ)",

  live: "ڕاستەوخۆ",
  purchasesHistory: "مێژووی کڕینەکان",
  balancesHistory: "مێژووی باڵانسەکان",
  usersFeedback: "فیدباکەکان",
  starRate: "ئەستێرەکان",
  feedback: "فیدباک",
  newSubscribers: "بەژداریکردنی نوێ",
  renewalOfSubscriptions: "نوێکردنەوەی بەژداریکردن",
  dollarPrice: "نرخی دۆلار",
  proteinStock: 'کۆگای پرۆتین',
  receiptsOfPayment: "پسوڵەی پێدانی پارە",
  retailPrice: 'نرخی تاک',
  subs: 'بابەتەکان',
  subject: 'بابەت',
  part: 'بەش',
  recipients: 'وەرگرەکان',
  receipts: 'پسوڵەکان',
  customs: 'گومرگ',
  carNumber: 'ژمارەی ئۆتۆمبێل',
  cost: 'تێچوو',
  expense_1: 'خەرجی لابەلا',
  creatingTransition: 'دروستکردنی گواستنەوە',
  centers: 'هۆڵەکان',
  administration: 'بەڕێوەبەری',
  brandImage: 'وێنەی براند',
  categories: 'کاتێگۆریەکان',
  jobOpportunities: 'هەلی کارەکان',
  jobOpportunitie: 'هەلی کار',
  cvs: 'سیڤیەکان',
  locaiton: 'ناونیشان',
  position: 'پێگە',
  revenueByBenefitPrice: 'داهات بەپێی (نرخی كڕین - نرخی فرۆشتن)',
  revenueByItems: 'داهات بەپێی بەرهەمی فرۆشراو',
  revenueByBuyPrice: 'داهات بەپێی (نرخی كڕین)',
  revenueWithoutExpenses: 'قازانج بەبێ خەرجی',
  publicDebt: 'قەرزی گشتی',
  ratio: 'نسبە',
  profitWithRetail: 'قازانج بەنرخی تاک',
  profitWithWholeSale: 'قازانج بەنرخی کۆ',
  gymReception: 'ڕیسیپشنی هۆڵ',
  cafeReception: 'ڕیسیپشنی کافتریا',
  gymCoach: 'ڕاهێنەری هۆڵ',
  monthlyReport: 'ڕاپۆرتی مانگانە',
  normalCourseProfit: 'قازانجی کۆرسە ئاساییەکان',
  VIPCourseProfit: 'قازانجی کۆرسە تایبەتەکان',
  cafeteriaProfit: 'قازانجی کافتریا',
  publicProfitAfterMamAzad: 'قازانجی گشتی دوای مام ئازاد',
  publicProfit: 'قازانجی گشتی',
  coursesReport: 'ڕاپۆرتی کۆرسەکان',
  save: 'پاشەکەوتکردن',
  loading: 'چاوەڕوانبە',
  equipmentStorage: 'کۆگای ئامێرەکان',
  licenses: 'مۆڵەتەکان',
  'attendance-time': 'کاتی ئامادەبوون',
  responsibleOfChilds: 'دایانی منداڵان',
  accountantOfAnotherCompany: 'ژمێریاری کۆمپانیاکانی دیکە',
  asudaCity: 'ئاسودە ستی',
  'asudaCity$': 'ئاسودە ستی $',
  paroshTowers: 'پەرۆش تاوەرز',
  'paroshTowers$': 'پەرۆش تاوەرز $',
  slavaCity: 'سلاڤا ستی',
  'slavaCity$': 'سلاڤا ستی $',
  mamAzad: 'مام ئازاد',
  'mamAzad$': 'مام ئازاد $',
  miniSlavaMen: 'مینی سلاڤا پیاوان',
  'miniSlavaMen$': 'مینی سلاڤا پیاوان $',
  miniSlavaWomen: 'مینی سلاڤا خانمان',
  'miniSlavaWomen$': 'مینی سلاڤا خانمان $',
  lifeTowers: 'لایف تاوەرز',
  'lifeTowers$': 'لایف تاوەرز $',
  akaFitnessProfit: 'قازانجی ئاکە فیتنس',
  akaFitnessProfit$: 'قازانجی ئاکە فتنس $',
  finalProfit: 'قازانجی گشتی',
  'price-lists': 'لیستی نرخەکان',
  'select-item': 'دیاریکردن',
  cancel: 'لابردن',
  repaymentOfDebtAkaPower: 'گەڕانەوەی قەرزی ئاکا پاوەڕ',
  debtsAkaPower: 'قەرزی ئاکا پاوەڕ',
  swimmingItems: 'بەرهەمی مەلەوانگە',
  akaFitnessSupplementsProfit: 'قازانجی ئاکە فتنس تەواوکەری خۆراکی',
  asudaSupplementsProfit: 'قازانجی ئاسودە تەواوکەری خۆراکی',
  finalPrice: 'نرخی کۆتا',
  totalRepaymentOfDebt: 'کۆی گەڕاندەوەی قەرز',
  totalPayedPriceFromSells: 'کۆی پارەی دەستکەوتو لە فرۆشتن',
  'revenue(repaymentOfDebt,payedPrice)': 'داهات (گەڕاندەوەی قەرز,پارەی دەستکەوتو لە فرۆشتنەوە)',
  ratioForDebt: 'نسبە بەپێی قەرز',
  ratioForSupplement: 'نسبە بەپێی تەواوکەری خۆراکی',
  ratioForMoney: 'نسبە بەپێی پارە',
  ratioType: 'جۆری نسبە',
  gyms: 'هۆڵەکانی لەشجوانی',

  currentMonth: 'ئەم مانگە',
  lastMonthByThisTime: 'مانگی ڕابردوو تا ئەمکاتە',
  lastMonth: 'مانگی ڕابردوو',
  allOfThem: 'هەمووی 💥',
  trainers: 'ڕاهێنەرەکان',
  thereIsNoDataToDisplay: 'هیچ داتایەک نیە بۆ پشاندان',
  sellSupplements: 'فرۆشتنی تەواوکەرە خۆراکیەکان',
  deleteMembership: 'سڕینەوەی بەژداریکردن',

  'areYouSureYouWantToDeleteThisExpense?': 'دڵنیای لە سڕینەوەی ئەم خەرجیە؟',
  deletionOfExpenses: 'سڕینەوەی خەرجی',
  'areYouSureAboutDeleting?': 'دڵنیای لە سڕینەوە؟',
  numOfNormalCourses: 'ژمارەی کۆرسە ئاساییەکان',
  numOfVIPCourses: 'ژمارەی کۆرسە VIP ەکان',

  totalPriceOfRegularCourses: 'کۆی گشتی کۆرسە ئاسایەکان',
  'totalPriceOfVIPCourses': 'کۆی گشتی کۆرسە تایبەتەکان',

  'trainerProfitsFromRegularCourses': 'قازانجی گشتی ڕاهێنەر لە کۆرسە ئاساییەکان',
  'trainerProfitsFromVIPCourses': 'قازانجی گشتی ڕاهێنەر لە کۆرسە تایبەتەکان',
  akaFitnessProfitFromRegularCourses: 'قازانجی ئاکە فتنس لە کۆرسە ئاساییەکان',
  'akaFitnessProfitsFromVIPCourses': 'قازانجی ئاکە فتنس لە کۆرسە تایبەتەکان',
  coachGrossProfit: 'قازانجی گشتی ڕاهێنەر',
  grossProfitAkaFitness: 'قازانجی گشتی ئاکە فتنس',

  num: 'ژمارە',
  nameOfTheEmployee: 'ناوی کارمەند',
  fixedSalary: 'مووچەی جێگیر',
  courseRatio: 'نسبەی کۆرس',
  supplementRatio: 'نسبەی تەواوکەری خۆراکی',
  numOfSupplements: 'ژمارەی تەواوکەری خۆراکی',
  totalPriceOfSupplements: 'نرخی گشتیی تەواوکەری خۆراکی',
  'trainerProfit15%': 'قازانجی ڕاهێنەر 15%',
  theDollarIsGenerallyCalculatedAt: 'نرخی دۆلار بە گشتی هەژمارکراوە بە',
  courseReportSummary: 'پوختەی ڕاپۆرتی کۆرسەکان',
  salariesOfEmployees: 'مووچەی کارمەندەکان {{price}}',
  summaryOfTheDietarySupplementReport: 'پوختەی ڕاپۆرتی تەواوکەرە خۆراکیەکان',
  cafeteriaSalesReportSummary: 'پوختەی ڕاپۆرتی بەرهەمەکانی کافتریا',
  totalOfProductsWereSoldAtPriceOfDinars: 'بە کۆی گشتی {{qty}} بەرهەم فۆرشراوە بە نرخی {{price}} دینار',
  salaryNotSpecified: 'مووچە دیاری نەکراوە',
  dateNotSpecified: 'بەروار دیاری نەکراوە',
  salaryByDate: 'مووچە بەپێی بەرواری',

  increasedExpenses: 'زیادکردنی خەرجی',
  updateOfExpenses: 'تازەکردنی خەرجی',
  byPrice: 'بە نرخی',

  courseWriting: 'نوسینی کۆرس',
  courseUpdate: 'تازەکردنی کۆرس',
  changeOfParticipation: 'گۆڕینی بەژداریکردن',

  sellingOfSupplements: 'فرۆشتنی تەواوکەری خۆراکی',
  updateOfSellingSupplements: 'تازەکردنی فرۆشتنی تەواوکەری خۆراکی',
  purchaseBalance: 'زیادکردنی باڵانس',
  changeOfBalance: 'گۆڕینی باڵانس',
  openingTheDoor: 'کردنەوەی دەرگا',
  becauseOf: 'لەبەر ئەوەی',
  saleOfCafeteriaProducts: 'فرۆشتنی بەرهەمی کافتریا',

  star: 'ئەستێرە',
  in: 'لە',
  for: 'بە',

  search: 'گەڕان',

  numOfMemberships: 'ژمارەی بەژداریکردن',
  numOfThreeMonth: 'ژمارەی بەژداریکردنی سێ مانگی',
  numOfSixMonth: 'ژمارەی بەژداریکردنی شەش مانگی',
  totalPriceOfThreeMonths: 'نرخی گشتی بەژداریکردنی سێ مانگی',
  totalPriceOfSixMonths: 'نرخی گشتی بەژداریکردنی شەش مانگی',
  receptionRatioOfThreeMonths: 'نسبەی بەژداریکردنی سێ مانگی',
  receptionRatioOfSixMonths: 'نسبەی بەژداریکردنی شەش مانگی',
  akaFitnessDivideBenefitIQD: 'قازانجی ٧٨ جیم',

  receptionReportSummary: 'پوختەی ڕاپۆرتی ڕیسێپشنەکان',
  receptionsRatio: 'نسبەی ڕیسێپشنەکان',
  'thereWere{{update}}UpdatesAnd{{new}}NewSubscribersInTheReportGenerated': 'لەو ڕاپۆرتەی کە تۆمار کراوە {{new}} بەژداربووی نوێیە و {{update}} نوێکردنەوە بوە',
  membershipsRatio: 'نسبەی بەژداریکردن',

  cafeExpense: 'خەرجی کافتریا',
  gymExpense: 'خەرجی جیم',
  bigExpense: 'خەرجی گەورە',
  by: 'لەلایەن',
  cafeExpenses: 'خەرجی کافتریا',
  totalExpenses: 'کۆی خەرجیەکان',
  requests: 'داواکاریەکان',
  inProgress: '⛏️ لە کارکردندایە',
  pendingRequests: 'داواکاریە چاوەڕوانکراوەکان',
  status: 'دۆخ',
  pending: 'چاوەڕوانکراوە',
  accepted: 'پەسەندکراوە',
  rejected: 'ڕەتکراوە',
  moreDetails: 'زانیاری زیاتر',
  request: 'داواکاری',
  answeredRequests: 'داواکاریە وەڵامدراوەکان',
  data: 'داتا',
  viewData: 'بینینی داتا',
  captain: 'کاپتن',
  normalCustomer: 'کڕیاری ئاسایی',
  hallOrAgentCustomer: 'کڕیاری هۆڵ یان وەکیل',
  partnerAmount: 'بڕی هاوبەش',
  pleaseSelectCustomer: 'تکایە کڕیارێک دیاری بکە',
  supplementsPartner: 'هاوبەشی تەواوکەری خۆراکی',
  selectInvoice: 'دیاریکردنی پسوڵە',
  invoices: 'پسوڵەکان',
  notPaid: 'پارە نەدراوەکان',
  'paid(uncompleted)': 'پارە دراوەکان (ماوە)',
  'paid(completed)': 'پارە دراوەکان (تەواو)',
  'nutritionalSupplementReportSummary': 'پوختەی ڕاپۆرتی تەواوکەری خۆراکی',
  'revenue(repaymentOfDebt,payedPrice)OfPartnerItems': 'داهات (گەڕاندەوەی قەرز,پارەی دەستکەوتو لە فرۆشتنەوە) بەرهەمی هاوبەش',
  'profit(repaymentOfDebt,payedPrice)OfPartnerItems': 'قازانج (گەڕاندەوەی قەرز,پارەی دەستکەوتو لە فرۆشتنەوە) بەرهەمی هاوبەش',
  partnerBalance: 'باڵانسی هاوبەش',
  'partnerSellDetails': 'لە بەرواری دیاریکراودا ئەو بەرهەمە هاوبەشانەی کە فرۆشراون نرخی گشتی دەکاتە {{totalPrice}}، لەو بەرهەمانە بڕی{{payedPrice}} بە پارەی دراو بووە، بڕی {{debt}} قەرز بووە، قازانجی بەرهەمی فرۆشراو (بەرهەمی فرۆشراو بە پارەی دراو - نرخی کڕین) دەکاتە{{profitByBuyPrice}}, قازانجی کۆتا (قازانج - خەرجی گشتی) دەکاتە {{balance}} ',
  supplementName: 'ناوی تەواوکەری خۆراکی',
  buyReportSummary: 'پوختەی ڕاپۆرتی کڕینەکان',
  buySupplementDetails: 'کڕین ئەنجامدراوە بە نرخی گشتی {{totalPrice}} و کڕینی {{quantity}} تەواوکەری خۆراکی جیاواز ، کۆی گشتی تەواوکەرە خۆراکیەکان {{totalQuantity}} دانە کڕدراوە',
  expenseName: 'ناوی خەرجی',
  expenseReportSummary: 'ڕاپۆرتی خەرجیەکان',
  expensesReportDetails: `بە کۆی گشتی {{numOfExpense}} خەرجی کراوە کە نرخی گشتی خەرجیەکان دەکاتە {{totalPrice}} دۆلار`,
  customerName: 'ناوی کڕیار',
  totalRatio: 'کۆی نسبە',
  ratioReportSummary: 'پوختەی ڕاپۆرتی نسبەکان',
  ratioReportDetails: 'نسبەی دراو بە نرخی گشتی پێکهاتوە لە {{totalRatio}} بە {{totalCustomers}} کڕیاری جیاواز دراوە  ',
  amountOfRepaymentDebt: 'بڕی گەڕاندنەوەی قەرز',
  repaymentOfDebtReportSummary: 'پوختەی ڕاپۆرتی گەڕاندنەوەی قەرز',
  'thisReportWillBePreparedInTheNext24Hours': 'ئەم ڕاپۆرتە لە 24 کاتژمێری داهاتودا ئامادە دەبێت',
  'thisReportWillBePreparedSoonAsSoon': 'لەبەر ئەوەی لە ڕابردوودا پێکهاتەی گەڕاندنەوەی پارەمان جیاواز بوو، پێویستە بیگۆڕین و هەموو گەڕاندنەوەکان حیساب بکەین، بۆیە بە زووترین کات ئەم ڕاپۆرتە ئامادە دەکرێت',
  'summaryOfTheSoldSupplementsReport': 'پوختەی ڕاپۆرتی فرۆشتنی تەواوکەرە خۆراکیەکان',
  totalSoldQty: 'کۆی دانەی فرۆشراوەکان',
  totalSoldPrice: 'کۆی نرخی فرۆشراوەکان',
  totalProfitPrice: 'کۆی قازانجی نرخی گشتی',
  totalPriceOfCash: 'کۆی نرخی پارەی دراو',
  totalPriceOfDebt: 'کۆی نرخی قەرز',
  totalPriceOfFree: 'کۆی نرخی خۆڕای',
  totalPriceOfRatio: 'کۆی نرخی نسبە',
  'totalSoldPrice(cash+debt)': 'کۆی نرخی فرۆشراوەکان (پارەی دراو + قەرز)',
  'totalProfitPrice(cash)': 'کۆی قازانجی نرخی فرۆشراوەکان (پارەی دراو)',
  'totalProfitPrice(OnlyCash)': 'کۆی قازانجی نرخی فرۆشراوەکان (پارەی دراو)',
  selectDate: 'دیاریکردنی بەروار',
  expiryTodayMemberships: 'بەژداریکردنە بەسەرچوەکانی ئەمڕۆ',
  freeSubscriptions: 'بەژداریکردنە خۆڕاییەکان',
  'partnerNutritionalSupplementReportSummary': 'پووختەی ڕاپۆرتی تەواوکەری خۆراکی هاوبەش',
  debtsReportSummary: 'پووختەی ڕاپۆرتی قەرزەکان',
  debtReportDetails: 'قەرزی دراو بە نرخی گشتی پێکهاتوە لە {{totalDebt}} بە {{totalCustomers}} کڕیاری جیاواز دراوە  ',
  productReturnReportSummary: 'پووختەی ڕاپۆرتی گەڕانەوەی تەواوکەری خۆراکی',
  productReturnReportDetails: `بە کۆی گشتی {{totalQty}} دانە تەواوکەری خۆراکی گەڕاوەتەوە بە نرخی گشتی {{totalPrice}} دۆلار`,
  sellSupplementsReportSummary: 'پووختەی ڕاپۆرتی فرۆشتن',
  sellSupplementsReportDetails: `کۆی گشتی {{totalQty}} دانە تەواوکەری خۆراکی فرۆشراوە بە بڕی {{totalPrice}}$ کە بڕی {{totalDebt}} $ قەرز بوەو بڕی {{totalPayedPrice}} $ پارەی دراو بوە, کۆی نرخی مەوادە خۆڕاییەکان {{totalFree}} $ بووە`,
  freeSupplementsBy: ' ( تەواوکەری خۆراکی بە بڕی {{totalPrice}} $)',
  totalDebt: 'کۆی قەرز',
  'summaryOfTheSoldSupplementsReportDetails': 'داهاتی گشتی بە نرخی کڕین (بە مەوادی خۆڕاییەوە) {{totalBenefitPriceWithoutMinus}} $ ، قازانجی گشتی {{totalBenefitPrice}} دۆلار',
  totalBuyPrice: 'کۆی گشتی نرخی کڕین',
  totalStockSupplements: 'کۆی گشتی تەواوکەرە خۆراکیەکانی کۆگا',
  totalStockDetails: 'کۆی گشتی بەرهەمی ماوە {{totalQty}} دانەیە کە بە نرخی کڕین دەکاتە {{totalBuyPrice}} $ ، بڕی {{totalIronmaxQty}} دانە لە بڕاندی ئایرۆن ماکسە و بە نرخی {{totalIronmaxPrice}} $ ، بڕی {{totalHelltechQty}} دانە لە بڕاندی هێڵتەچە بە بڕی {{totalHelltechPrice}} $ ، و بڕی {{totalOtherBrand}} دانە لە بڕاندەکانی دیکەیە بە بڕی {{totalOtherBrandPrice}} دۆلار  ',
  totalDebtors: 'کۆی گشتی قەرزدارەکان',
  totalDebtorsDetails: 'بەی گشتی{{totalDebt}} $ ئێمە قەرزارن، لە نرخە {{totalHallAgent}} $ وەکیل و هۆڵەکانی ٧٨ جیمە و{{otherCustomers}} $ ئێمەی دەرەکیە',
  'revenue(payedPrice)': 'داهات (پارەی دەستکەوتو لە فرۆشتنەوە)',
  'revenue(repaymentOfDebt)': 'داهات (گەڕاندەوەی قەرز)',
  totalRevenue: 'کۆی داهاتەکان',
  'profit(repaymentOfDebt,payedPrice)': 'قازانج (گەڕاندەوەی قەرز,پارەی دەستکەوتو لە فرۆشتنەوە)',
  'buyPriceXtotalSoldQty': 'نرخی کڕین * کۆی دانەی فرۆشراوەکان',
  'profit(totalSoldPrice-buyPrice*totalSoldQty': 'قازانج (کۆی نرخی فرۆشراوەکان - نرخی کڕین * کۆی دانەی فرۆشراوەکان)',

  totalCafeteriaExpenses: 'کۆی خەرجی کافتریا',
  totalMembershipsRevenue: 'کۆی داهاتی بەژداریکردنەکان',
  totalNormalCoursesRevenue: 'کۆی داهاتی کۆرسە ئاساییەکان',
  totalPTCoursesRevenue: 'کۆی داهاتی کۆرسە تایبەتەکان',
  totalCafeteriaRevenue: 'کۆی داهاتی کافتریا',
  PTCourseRatio: 'نسبەی کۆرسە تایبەتەکان',
  normalCourseRatio: 'نسبەی کۆرسە ئاساییەکان',
  'totalOtherRevenue(salon,garage,vipLocker)': 'کۆی داهاتی تر (ساڵۆن، گەراج، ڵۆکەری VIP)',
  totalGymExpenses: 'کۆی خەرجی هۆڵ',
  totalBigExpenses: 'کۆی خەرجی گەورە',
  totalReceptionRatio: 'کۆی نسبەی ڕیسێپشن',
  totalFixedSalary: 'کۆی گشتی مووچەی جێگیر',
  totalMembershipsProfit: 'کۆی قازانجی بەژداریکردنەکان',
  totalCoursesProfit: 'کۆی قازانجی کۆرسەکان',
  totalCafeteriaProfit: 'کۆی قازانجی کافتریا',
  totalProfit: 'کۆی قازانجی گشتی',
  totalPartnerProfit: 'کۆی قازانجی هاوبەش',
  totalAkaFitnessProfit: 'کۆی قازانجی ئاکە فتنس',
  settings: 'ڕێکخستنەکان',
  beta: 'تاقیکاری',
  courses: 'کۆرسەکان',

  'fromSelectedRangeLastMonth': 'لە بەرواری هەڵبژێردراوەی مانگی ڕابردوەوە',
  overview: 'گشتی',
  totalRevenueAkaPower: 'داهاتی گشتی ( ئاکا پاوەر)',
  totalExpensesAkaPower: 'کۆی خەرجی ( ئاکا پاوەر)',
  totalProfitAkaPower: 'کۆی قازانجی گشتی ( ئاکا پاوەر)',
  totalProfitAkaNutrition: 'کۆی قازانجی گشتی ( ئاکا نووتریشن)',
  totalProfitAkaFitness: 'کۆی قازانجی گشتی ( ٧٨ جیم)',
  totalEmployees: 'کۆی کارمەندەکان',
  totalActiveSubscribersAkaFitness: 'کۆی بەژداربوە چالاکەکانی ٧٨ جیم',
  expire: 'بەسەرچوو',
  new: 'نوێ',
  renew: 'نوێکردنەوە',

  akaFitness: '٧٨ جیم',
  akaNutrition: 'ئاکا نووتریشن',
  akaPower: 'ئاکا پاوەر',
  akaEquipment: 'ئاکا ئیکویپمێنت',

  packageName: 'ناوی پاکێج',
  receptionName: 'ناوی کارمەند',
  expiryDate: 'بەرواری بەسەرچون',

  cash: 'کاش',
  vipLocker: 'لۆکەری VIP',
  salon: 'ساڵۆن',
  cafeCard: 'کارتی کافتریا',

  totalCourseProfit: 'کۆی قازانجی کۆرسەکان',
  totalSupplementProfit: 'کۆی قازانجی تەواوکەری خۆراکی',
  activeUsers: 'بەژداربوە چالاکەکان',

  'new,renew,expireSubscription': 'بەژداریکردنی نوێ، نوێکردنەوە، بەسەرچوو',
  'male&female': 'نێر و مێ',
  cafeteriaAnalysis: "ڕاپۆرتی کافتریا",
  totalPriceOfCard: "نرخی گشتی کارت",
  totalPriceOfBalance: "نرخی گشتی باڵانس",

  calculateSalaries: "هەژمارکردنی مووچەی کارمەندان",
  calculateSalariesDesc: "بەمزوانە هەژمارکردنی مووچە بەردەست دەبێت",

  totalSalaries: "کۆی مووچەی کارمەندان",

  directDollarExchangeRateLinkedToTheKurdistanStockExchange: "نرخی دۆلار ڕاستەوخو نوێ دەبێتەوە لەگەڵ گۆڕانی لە بازاڕی کوردستان",
  language: 'زمان',
  selectTheLanguageOfThePlatform: 'زمانی سیستەم دیاری بکە',
  profile: 'پرۆفایل',
  appearance: 'ڕووکار',
  other: 'ئەوانیتر',
  next: 'دواتر',
  previous: 'پێشتر',
  salaryListOf: 'لیستی مووچەی {{name}}',
  totalOtherRevenueProfit: 'کۆی قازانجی بەشی داهاتی دیکە',
  nutrition: 'نووتریشن',
  columns: 'ستونەکان',
  deleteUserWarning: 'تکایە لە کاتی سڕینەوەی بەکارهێنەر ئاگاداربە چونکە هەموو داتاکانی پەیوەست بە بەکارهێنەرەکە دەسڕێتەوە و ناتوانرێت وەربگیرێتەوە',
  "physicalTherapy": "چارەسەری فیزیای",
  "physicalTherapyRevenue": "داهاتی چارەسەری فیزیای",
  "physicalTherapyProfit": "قازانجی چارەسەری فیزیای",
  aFullHourPhysicalTherapySessionForPartBody: "دانیشتنێکی چارەسەری فیزیایی کاتژمێری تەواو بۆ بەشێک لە جەستە",
  fullBodyMessageOneHour: "مەساجی تەواوی جەستە بۆ ماوەی یەک کاتژمێر",
  fourPhysicalTherapy: "چوار جار چارەسەری فیزیایی یان بەشێک لە جەستە",
  amountOfPaymentByCustomer: 'بڕی پارەی کڕیار',
  payedType: 'جۆری پارە',
  changeType: 'جۆری وردە',
  changePrice: 'بڕی وردە',
  amountByCustomer: 'بڕی پارەدان لای مشتەری',
  notes: "تێبینی:",
  changeMembershipNotes_1: '١- لەکاتی گۆڕینی پاکێجێک بۆ پاکێجێکی دیکە ڕاستەوخۆ بەرواری بەکارهێنەر دەگۆڕێت.  ',
  changeMembershipNotes_2: '٢-لەکاتی گۆڕینی پاکێجەکان ئەگەر نرخی جیاواز بوو تکایە دڵنیا بەرەوە لە داخڵکردنی نرخی درووست کە لالەیەن کڕیارەوە دراوە بە دینار یاخود دۆلار.  ',
  "areYouSure": "دڵنیایت؟",
  "deleteMembershipWarning": `ئەگەر ئەندامێتیەکە بسڕیتەوە ئەوا داتاکانی بەکارهێنەر دەسڕدرێتەوە و بەکارهێنەر دەگەڕێتەوە بۆ دۆخی پێشووی ئەندامێتی \nئەگەر بەکارهێنەر هیچ ئەندامێتییەکی تری نەبێت ئەوا دەسڕدرێتەوە`,
  membership: "بەژداریکردن",
  "limitationByTime": "سنووردارکردن بە کات",
  deletePackage: "سڕینەوەی پاکێج",
  exportViaExcel: "داگرتن",
  selectColumns: "ستونەکان دیاری بکە",
  filter: "فلتەر",
  changeCourseTypesNote: "تێبینی: تەنها پێویستت بە داخڵکردنی نرخی دۆلارە لە فیڵدی دۆلار. یاخوود فیڵدی iqd بە دینار، پێویست ناکات هەردووکی پڕبکەیتەوە",
  addExpenseNote_1: "کاتێک خەرجی زیاد دەکەیت لێرەوە زیاد دەکرێت بۆ خەرجییەکانی ڕۆژە هەڵبژێردراوەکە، بۆ سڕینەوە یان دەستکاریکردنی خەرجییەکە دەتوانیت بچیتە سەر خەرجییەکانی ڕۆژەکە و دەستکاری بکەیت یان بیسڕیتەوە",
  amountOfExpense: "بڕی خەرجی",
  employee: "کارمەند",
  service: "خزمەتگوزاری",
  storage: "ستۆرەیج",
  "return-items": "گەڕانەوەی ئایتمەکان",
  productName: "ناوی بەرهەم",
  imageUri: "لینکی وێنە",
  swimmingStorage: "ستۆرەیجی مەلەوانگە",
  items: "ئایتمەکان",
  receipt: "پسوڵە",
  usd: "دۆلار",
  dinar: "دینار",
  "pleaseMakeSureThisAction": "تکایە دڵنیابەرەوە لەم کردارە",
  "thisActionCannotBeUndone_swimming": "ئەم کارە ناتوانرێت ڕەتبکرێتەوە بکرێتەوە. ئەم ئایتمە بۆ هەمیشە دەسڕێتەوە. و بڕی ئایتمی فرۆشراو دەگەڕێتەوە بۆ سەر کۆگا.",
  enName: "ناوی ئینگلیزی",
  kuName: "ناوی کوردی",
  arName: "ناوی عەرەبی",
  vipCourseRatio: "نسبەی کۆرسە تایبەتەکان",
  supplementsRevenue: "داهاتی تەواوکەری خۆراکی",
  swimmingItemsRevenue: "داهاتی ئایتمەکانی مەلەوانگە",
  totalTrainerRatioOfSupplements: "کۆی نسبەی راهێنەرەکان لە تەواوکەری خۆراکی",
  supplementsProfit: "قازانجی تەواوکەری خۆراکی",
  totalSwimmingItemsRevenue: "داهاتی ئایتمەکانی مەلەوانگە",
  totalSupplementsProfit: "کۆی قازانجی تەواوکەری خۆراکی",
  totalPhysicalTherapyProfit: "کۆی قازانجی چارەسەری فیزیایی",
  captainName: "ناوی کاپتن",
  brandName: "ناوی براند",
  brandLogo: "لۆگۆی براند",
  nutritionStorage: "ستۆرەیجی نووتریشن",
  "nutrition-items": "ئایتمەکانی نووتریشن",
  deleteItemDesc: "ئەگەر ئەم بەرهەمە بسڕیتەوە چیتر لە سیستەم دەرناکەوێت و ناتوانرێت بگەڕێندرێتەوە",
  thisActionCannotBeUndone_storage: "ئەم کارە ناتوانرێت ڕەتبکرێتەوە ، کۆی عەدەدی ماوە کە نەفرۆشراوە دەگەڕێتەوە سەر ستۆرەیج.",
  swimmingItemsProfit: "قازانجی بابەتی مەلەکردن",
  expensesByCategory: "خەرجیەکان بەپێی جۆر",
  category: "جۆر",
  totalIqd: "نرخی گشتی دینار",
  totalUsd: "نرخی گشتی دۆلار",
  expenseCategory: "جۆری خەرجی",
  salaryUsd: "مووچەی دۆلار",
  salaryUSD: "مووچەی دۆلار"
};


export default ku