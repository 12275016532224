import { customAlphabet } from "nanoid/non-secure";

export const shortIdGenerator = (numOfAlphabet) => {
  let number = numOfAlphabet || 8;
  let alhabet = "-_abcdefghijklmnopqurstuvwxyz";
  const nanoid = customAlphabet(alhabet, number);

  let id = nanoid();

  return id;
};

export const BullHeadUserIDGenerator = () => {
  let number = 6;
  let alhabet = "abcdefghijklmnopqurstuvwxyz".toUpperCase();

  const nanoid = customAlphabet(alhabet, number);

  let id = nanoid();

  return id;
};

export const IDNumberGenerator = (numOfAlphabet) => {
  let number = numOfAlphabet || 8;
  let alhabet = "1234567890";
  const nanoid = customAlphabet(alhabet, number);

  let id = nanoid();

  return Number(id);
};