import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import InstanceIndex from '../../../components/UI/AddingData/InstanceIndex'
import { t } from 'i18next'
import { TextInput } from '../../Users/CreateUserScreen'
import axios from 'axios'
import { useHistory } from 'react-router-dom/'
import useQuery from '../../../customFunction/useQuery'
import CommonInputsContainer from '../../../components/UI/TableView/CommonInputsContainer'

function NutritionBrandDetails() {
  const { brandId } = useParams()
  const isNew = useQuery().get('new')
  const { data, isLoading, error } = useSwr('/nutrition-brands/' + brandId + '?isNew=' + isNew, fetcher)
  const [currentData, setCurrentData] = useState()
  const history = useHistory()

  useEffect(() => {
    setup()
  }, [data])

  const setup = () => {
    if (data && data[0]) {
      setCurrentData(data[0])
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const onChangeValue = (key, b, value) => {
    setCurrentData({ ...currentData, [key]: value })
  }

  const onSave = async () => {
    try {
      if (!currentData.name || !currentData.brandLogo) {
        alert('Please fill all the fields')
        return
      }
      if (isNew)
        await axios.post('/nutrition-brands', currentData)
      else
        await axios.put('/nutrition-brands/' + brandId, currentData)
      history.goBack()
    } catch (e) {
      alert(e.message)
    }
  }

  return (
    <div className='flex flex-1 w-full' >
      <CommonInputsContainer onSave={onSave} title={t('brands')} >
        <InstanceIndex label={t("id")} value={`#${currentData && currentData._id ? currentData._id : ''}`} />
        <TextInput
          label={t("brandName")}
          placeHolder={t("brandName")}
          value={currentData ? currentData.name : ''}
          onChangeValue={onChangeValue.bind(this, 'name',)}
        />
        <TextInput
          label={t("brandLogo")}
          placeHolder={t("brandLogo")}
          value={currentData ? currentData.brandLogo : ''}
          onChangeValue={onChangeValue.bind(this, 'brandLogo',)}
        />
      </CommonInputsContainer>
    </div>
  )
}
{/*  */ }
export default NutritionBrandDetails
