import dayjs from 'dayjs';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { IoAdd } from 'react-icons/io5';
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData';

function ReturnNutritionItems() {
    const currentCenterId = '62d3de07e4b5f62ba339b22b'

    const { columns, id, label, url, href } = {
        id: 'nutrition-items', label: 'nutrition-items',
        href: '/nutrition-items',
        columns: [{ label: '#', value: a => a._id.slice(0, 10) },
        { label: 'by', classnames: () => 'font-[Montserrat]', value: (a) => a.admin?.name || 'unknown' },
        { label: 'items', value: (a) => a.items.length },
        { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') },
        ],
        url: (centerId) => `/nutrition-return-items/${currentCenterId}?` + centerId,
    }
    const history = useHistory()

    const onAddStorage = () => {
        history.push(`/nutrition-storage/return-items/1`)
    }
    
    return (
        <div className="flex flex-col items-center justify-center flex-1 bg-white">
            <ShowPaginationData hrefId={null} onClickItem={(data) => {
                history.push(`/nutrition-storage/return-items/view/${data._id}`, {
                    data
                })
            }} rightButton={<div className="cursor-pointer" >
                <IoAdd onClick={onAddStorage} size={30} />
            </div>} columns={columns} href={href} id={id} label={label} url={url} />
        </div>
    )
}

export default ReturnNutritionItems
