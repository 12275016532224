// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { id: 0, type: 0, label: "owner" },
  { id: 2, type: 2, label: "reception" },
  { id: 3, type: 3, label: "trainer" },
  { id: 4, type: 4, label: "cafeteria" },
  { id: 7, type: 7, label: "accountant" },
  { id: 8, type: 8, label: "cleaner" },
  { id: 9, type: 9, label: "employee" },
  { id: 10, type: 10, label: "service" },
];