import { t } from 'i18next'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { CgTrashEmpty } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PickerInput from '../../components/UI/AddingData/PickerInput'
import HeaderContainer from '../../components/UI/‌Header/HeaderContainer'
import { fetchCafeteriaItems } from '../../store/actions/cafeteriaItemsAction'
import { handleLoading } from '../../store/actions/loadingAction'
import { updatePurchase } from '../../store/actions/purchasesAction'
import useSWR from 'swr'
import { fetcher } from '../UI/CentersViewData'

const smalltalk = require("smalltalk");

function PurchaseDetailsScreen() {
  const { item } = useLocation().state
  const [currentItem, setCurrentItem] = useState({ ...item })
  const history = useHistory()
  const { data, isLoading, error } = useSWR('/cafeteria-stocks?fromReception', fetcher)
  
  const onSubmit = async () => {
    try {
      handleLoading();
      let newObj = {
        ...currentItem,
      };
      await updatePurchase(newObj);
      history.goBack()
    } catch (error) {
      smalltalk.alert(t("error"), error.message);
    } finally {
      handleLoading();
    }
  }

  const onChangeValue = (type, value) => {
    switch (type) {
      case 0: {
        currentItem.items = currentItem.items.filter(it => it.itemId !== value.itemId)
        let sum = 0
        currentItem.items.forEach(it => {
          sum += (it.price * it.quntity)
        })
        currentItem.price = sum
        break;
      }
      default:
        break;
    }

    setCurrentItem({ ...currentItem });
  };

  if (isLoading) {
    return <div>Loading</div>
  }
  return (
    <div className='flex flex-col items-center flex-1 w-full '>
      <HeaderContainer label={t("purchases")}  onPressBtn={onSubmit} />
      <Container
        onChangeValue={onChangeValue}
        currentMembership={currentItem}
        CafeteriaItems={data}
      />
      {/* 
      
      <Button onClick={onDelete} bgColor='bg-red-500' label={t("delete")} /> */}
    </div>
  )
}


const Container = ({
  onChangeValue,
  toggleUserModalHandler,
  currentMembership,
  toggleUserModal,
  packages,
  CafeteriaItems
}) => {
  return (
    <div className='w-4/5 mt-8 border'>
      <InputContainer
        currentMembership={currentMembership}
        onChangeValue={onChangeValue}
        toggleUserModalHandler={toggleUserModalHandler}
        toggleUserModal={toggleUserModal}
        packages={packages}
        CafeteriaItems={CafeteriaItems}
      />
    </div>
  );
};

const InputContainer = ({
  CafeteriaItems,
  currentMembership,
}) => {
  
// console.log({data,error})
  return (
    <div className='items-center justify-center bg-white'>
      {currentMembership?.items.map((item) => {
        const currentCafeItem = CafeteriaItems.find(it => it._id === item.itemId)
        // const onDeleteItem = () => {
        //   onChangeValue(0, item)
        // }
        return (
          <div className='flex flex-row items-center justify-between w-full border-b h-14'>
            <div className='flex flex-row items-center justify-between w-3/5'>
              <div className='flex items-center justify-center w-1/5 h-full'>
                <img
                  src={currentCafeItem.imageUri}
                  alt='img'
                  className='w-10 h-10 rounded-full'
                />
              </div>
              <div className='flex flex-col items-start justify-center w-4/5 h-full'>
                <div className='text-sm font-bold'>{currentCafeItem.name}</div>
              </div>
            </div>
            <div className='flex flex-row items-center justify-between w-2/5'>
              <div className='flex flex-col items-center justify-center w-1/2 h-full'>
                <div className='text-xs font-bold'>{t("price")}</div>
                <div className='text-xs'>{item.price}</div>
              </div>
              <div className='flex flex-col items-center justify-center w-1/2 h-full'>
                <div className='text-xs font-bold'>{t("quantity")}</div>
                <div className='text-xs'>{item.quntity}</div>
              </div>
              {/* <div className='flex flex-col items-center justify-center w-1/2 h-full cursor-pointer'>
                <CgTrashEmpty color='red' onClick={onDeleteItem} />
              </div> */}
            </div>
          </div>
        )
      })}

    </div>
  );
};

export default PurchaseDetailsScreen
