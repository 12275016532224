const initialState = { cafeteriaItems: null };

const cafeteriaItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CAFETERIA_ITEMS":

      return {
        ...state,
        cafeteriaItems: action.data,
      };

    case "CREATE_CAFETERIA_ITEM": {
      let dt = state.cafeteriaItems.concat(action.data);
      return {
        ...state,
        cafeteriaItems: dt,
      };
    }
    case "UPDATE_CAFETERIA_ITEM": {
      let dt = state.cafeteriaItems.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        cafeteriaItems: dt,
      }
    };
    default:
      return state;
  }
};

export default cafeteriaItemsReducer;
