const initialState = { centers: null, currentCenterId: null };

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CENTERS":
      return {
        ...state,
        centers: action.isMulti ? state.centers ? state.centers.concat(action.data) : action.data : action.data,
      };
    case "SET_CENTER_ID":
      return {
        ...state,
        currentCenterId: action.data,
      };
    case "ADD_EDIT_CENTER": {
      let newMessaaging = state.centers;

      if (
        !state.centers.find((a) => a._id === action.data._id) ||
        state.centers.length === 0
      ) {
        newMessaaging.push(action.data);
      } else {
        newMessaaging = state.centers.map((msg) => {
          if (msg._id === action.data._id) {
            msg = action.data;
          }
          return msg;
        });
      }
      return { ...state, centers: newMessaaging };
    }
    default:
      return state;
  }
};

export default authenticationReducer;
