const initialState = { search: '', pageIndex: 0 };

const supplementItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SEARCH_AND_PAGE_INDEX":
            return {
                ...state,
                search: action.data.search,
                pageIndex: action.data.pageIndex,
            };
        default:
            return state;
    }
};

export default supplementItemsReducer;
