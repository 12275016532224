import dayjs from "dayjs";
import numeral from "numeral";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Dimensions, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Bottom from "../../components/Dashboard/CenterAnalyticsScreen/Bottom";
import Upper from "../../components/Dashboard/CenterAnalyticsScreen/Upper";
import ViewTotalDataContainer from "../../components/Dashboard/CenterAnalyticsScreen/ViewTotalDataContainer";
import DurationCallender from "../../components/Dashboard/DurationCalender";
import Colors from "../../constant/Colors";
import PackagesColor from "../../constant/PackagesColor";
import { calculateCaffetteriaTotalPrice } from "../../customFunction/PricesCalculation/caffetteria";
import { calculateCoursesTotalPrice } from "../../customFunction/PricesCalculation/courses";
import { calculateExpensesTotalPrice } from "../../customFunction/PricesCalculation/expenses";
import { calculateMembershipTotalPrice } from "../../customFunction/PricesCalculation/memberships";
import { calculateSupplementsTotalPrice } from "../../customFunction/PricesCalculation/supplements";
import Features from "../../data/Features/Features";

const { height, width } = Dimensions.get("window");

const TotalAnalyticsScreen = (props) => {
  const [data, setData] = useState(false);
  const [detailsData, setDetailsData] = useState(false);
  const [durationObj, setDurationObj] = useState(false);
  const currency = useSelector((state) => state.analytics.currency);

  const supplements = (useSelector((state) => state.supplements.supplements));
  const memberships = (useSelector((state) => state.memberships.memberships));
  const expenses = (useSelector((state) => state.expenses.expenses));
  const duration = useSelector((state) => state.analytics.duration);
  const requestCourse = (useSelector((state) => state.courses.requestedCourses));
  const orders = (useSelector((state) => state.orders.orders));
  const users = useSelector((state) => state.users.users);
  const packages = useSelector((state) => state.packages.packages);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const setUpData = async () => {
    let obj = {};
    let date = duration.date;

    obj.supplements = supplements;
    obj.expenses = expenses;
    obj.requestCourse = requestCourse;
    obj.orders = orders;
    obj.memberships = memberships;
    obj.users = users;

    if (date) {
      date = dayjs(duration.date).format("YYYY-MM-DD");

      obj.expenses = obj.expenses?.filter((a) => {
        let currForm = dayjs(a.createdAt).format("YYYY-MM-DD");
        return currForm === date;
      });
      obj.supplements = obj.supplements?.filter((a) => {
        let currForm = dayjs(a.createdAt).format("YYYY-MM-DD");
        return currForm === date;
      });
      obj.requestCourse = obj.requestCourse?.filter((a) => {
        let currForm = dayjs(a.createdAt).format("YYYY-MM-DD");
        return currForm === date;
      });
      obj.orders = obj.orders?.filter((a) => {
        let currForm = dayjs(a.createdAt).format("YYYY-MM-DD");
        return currForm === date;
      });
      obj.memberships = obj.memberships?.filter((a) => {
        let currForm = dayjs(a.date).format("YYYY-MM-DD");
        return currForm === date;
      });
      obj.users = obj.users?.filter((a) => {
        return true;
      });
    } else {
      obj.expenses = obj.expenses?.filter(
        (a) =>
          a.createdAt <= duration.endDate && a.createdAt >= duration.startDate
      );

      obj.supplements = obj.supplements?.filter(
        (a) =>
          a.createdAt <= duration.endDate && a.createdAt >= duration.startDate
      );

      obj.requestCourse = obj.requestCourse?.filter(
        (a) =>
          a.createdAt <= duration.endDate && a.createdAt >= duration.startDate
      );

      obj.orders = obj.orders?.filter(
        (a) =>
          a.createdAt <= duration.endDate && a.createdAt >= duration.startDate
      );

      obj.memberships = obj.memberships?.filter(
        (a) =>
          dayjs(a.date) <= duration.endDate &&
          dayjs(a.date) >= duration.startDate
      );

      obj.users = obj.users?.filter((a) => {
        return true;
      });
    }

    let durationObj = await createDurationObject();

    setDurationObj(durationObj);
    setData(obj);
  };

  const createDurationObject = () => {
    let startYear = Number(dayjs(duration.startDate).format("YYYY"));
    let startMonth = Number(dayjs(duration.startDate).format("MM"));

    let endYear = Number(dayjs(duration.endDate).format("YYYY"));
    let endMonth = Number(dayjs(duration.endDate).format("MM"));

    if (duration.date) {
      startYear = Number(dayjs(duration.date).format("YYYY"));
      startMonth = Number(dayjs(duration.date).format("MM"));

      endYear = Number(dayjs(duration.date).format("YYYY"));
      endMonth = Number(dayjs(duration.date).format("MM"));
    }

    let date = undefined;
    let arr = [];

    while (true) {
      date = dayjs(`${startMonth}/1/${startYear}`).valueOf();
      arr.push({ date });

      if (startMonth === 13) {
        startMonth = 0;
        startYear++;
      }

      if (startMonth === endMonth && startYear === endYear) {
        break;
      }
      startMonth++;
    }

    return arr;
  };

  const setUpMianChartData = async () => {
    let datasets = [];
    let labels = [];
    let expensesTotalPrice = 0;
    let supplementsTotalPrice = 0;
    let ordersTotalPrice = 0;
    let membershipTotalPrice = 0;
    let requestCourseTotalPrice = 0;
    let newMemberships = 0;
    let renewMemberships = 0;

    let pckArr = [];

    let cafeBenfit = [];
    let cafeExp = [];
    let cafeProfit = [];

    packages.forEach((pck, i) => {
      let obj = {};

      obj.label =
        "(" +
        pck.labelDetails.inEn.title +
        " " +
        numeral(pck?.price.value).format("0.0$");

      let featuresLabel = "";

      pck.features.forEach((tp, i) => {
        featuresLabel +=
          Features.find((a) => a.id === tp).label +
          `${i === pck.features.length - 1 ? "" : ", "}`;
      });

      if (pck.offerType == 1) {
        if (pck.showType == 1) {
          obj.label += " " + "renew";
        } else {
          obj.label += " " + "new";
        }
      }

      obj.label += " - " + featuresLabel + ")";

      obj.backgroundColor = PackagesColor[i];
      obj.data = [];
      pckArr.push(obj);
    });

    durationObj.forEach((dt, i) => {
      let date = dt.date;
      labels.push(dayjs(date).format("MM-YYYY"));

      let objData = {};

      objData.expenses = data.expenses?.filter(
        (a) =>
          dayjs(a.createdAt).format("MM-YYYY") === dayjs(date).format("MM-YYYY")
      );
      objData.supplements = data.supplements?.filter(
        (a) =>
          dayjs(a.createdAt).format("MM-YYYY") === dayjs(date).format("MM-YYYY")
      );
      objData.memberships = data.memberships?.filter(
        (a) => dayjs(a.date).format("MM-YYYY") === dayjs(date).format("MM-YYYY")
      );
      objData.orders = data.orders?.filter(
        (a) =>
          dayjs(a.createdAt).format("MM-YYYY") === dayjs(date).format("MM-YYYY")
      );
      objData.requestCourse = data.requestCourse?.filter(
        (a) =>
          dayjs(a.createdAt).format("MM-YYYY") === dayjs(date).format("MM-YYYY")
      );

      if (objData.expenses) {
        cafeExp.push(0);
        objData.expenses.forEach((xp) => {
          if (xp.type === 2) cafeExp[i] += xp.price || 0;
        });
      }
      if (objData.orders) {
        cafeBenfit.push(0);
        objData.orders.forEach((caf) => {
          cafeBenfit[i] += caf.price || 0;
        });
      }

      if (cafeBenfit[i] && cafeExp[i]) {
        cafeProfit.push(0);
        cafeProfit[i] = cafeBenfit[i] - cafeExp[i];
      }

      packages.forEach((pck, i) => {
        let check = objData.memberships.filter(
          (a) => a.package?._id === pck?._id && a.tempNumberOrder === -1
        );

        pckArr[i].data.push(check.length || 0);
      });

      expensesTotalPrice += calculateExpensesTotalPrice(
        objData.expenses,
        false,
        currency
      );
      const calc = calculateMembershipTotalPrice(
        objData.memberships,
        users,
        false,
        currency
      )
      membershipTotalPrice += calc.finalPrice;
      newMemberships = calc.newUser
      renewMemberships = calc.oldUser
      ordersTotalPrice += calculateCaffetteriaTotalPrice(
        objData.orders,
        false,
        currency
      );

      requestCourseTotalPrice = +calculateCoursesTotalPrice(
        objData.requestCourse,
        false,
        currency
      );
      supplementsTotalPrice += calculateSupplementsTotalPrice(
        objData.supplements,
        false,
        currency
      );
    });

    let cafeDatasets = [
      {
        label: t("cafeteriaExpenses"),
        data: cafeExp,
        borderColor: Colors.mainRedColor,
        backgroundColor: Colors.mainRedColor,
      },
      {
        label: t("cafeteria"),
        data: cafeBenfit,
        borderColor: Colors.mainAqwaBlue,
        backgroundColor: Colors.mainAqwaBlue,
      },
      {
        label: t("caffetteriaBenfit"),
        data: cafeProfit,
        borderColor: Colors.mainGreenColor,
        backgroundColor: Colors.mainGreenColor,
      },
    ];

    let cafeDat = {
      datasets: cafeDatasets,
      labels,
    };

    datasets = [];
    pckArr.forEach((pck) => {
      let sum = 0;

      pck.data.forEach((a) => {
        sum += a;
      });

      if (sum > 0) {
        datasets.push(pck);
      }
    });

    let mainChartData = {
      labels,
      datasets,
    };

    let totalData = [];

    let allUsers = data?.users?.filter((a) => {
      let check = false;
      let startTime = dayjs(a.start_datetime);
      let endDate = dayjs(a.expiry_datetime);

      var duration = dayjs.duration(endDate.diff(startTime));
      var hours = duration.asHours();

      if (hours >= 26) check = true;

      return check;
    });

    let activeUser = allUsers?.filter((a) => {
      let check = false;

      if (dayjs(a.expiry_datetime).valueOf() > dayjs().valueOf()) {
        check = true;
      }

      return check;
    });

    let expiredUser = allUsers?.filter((a) => {
      let check = false;

      if (dayjs(a.expiry_datetime).valueOf() < dayjs().valueOf()) {
        check = true;
      }

      return check;
    });

    let maleUser = activeUser?.filter(
      (a) =>
        a.gender === 0 && dayjs(a.expiry_datetime).valueOf() > dayjs().valueOf()
    );
    let femaleUser = activeUser?.filter(
      (a) =>
        a.gender === 1 && dayjs(a.expiry_datetime).valueOf() > dayjs().valueOf()
    );

    let cafeeExpenses = data.expenses.filter((a) => a.type === 2);

    let gymExpenses = data.expenses.filter((a) => a.type === 1);
    let bigExpenses = data.expenses.filter((a) => a.type === 3);

    let totalCafeExpenses = calculateCaffetteriaTotalPrice(
      cafeeExpenses,
      false,
      currency
    );
    let totalGymExpenses = calculateCaffetteriaTotalPrice(
      gymExpenses,
      false,
      currency
    );
    let totalBigExpenses = calculateCaffetteriaTotalPrice(
      bigExpenses,
      false,
      currency
    );

    expiredUser.sort(
      (a, b) =>
        dayjs(a.expiry_datetime).valueOf() - dayjs(b.expiry_datetime).valueOf()
    );

    totalData.push({
      label: t("activeUser"),
      value: activeUser.length,
      pure: true,
      color: "#52B79C",
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 5,
              data: activeUser,
              headerLabel: [
                { label: "id" },
                { label: "name" },
                { label: "gender" },
                { label: "phoneNumber" },
                { label: "createdAt" },
                { label: "startDate" },
                { label: "endDate" },
              ],
              label: "activeUser",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("expiredUser"),
      value: expiredUser.length,
      pure: true,
      color: "#BF2A45",
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 5,
              data: expiredUser,
              headerLabel: [
                { label: "id" },
                { label: "name" },
                { label: "gender" },
                { label: "phoneNumber" },
                { label: "createdAt" },
                { label: "startDate" },
                { label: "endDate" },
              ],
              label: "expiredUser",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("male"),
      value: maleUser.length,
      pure: true,
      color: "#0293c2",
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 5,
              data: maleUser,
              headerLabel: [
                { label: "id" },
                { label: "name" },
                { label: "gender" },
                { label: "phoneNumber" },
                { label: "createdAt" },
                { label: "startDate" },
                { label: "endDate" },
              ],
              label: "activeUser",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("female"),
      value: femaleUser.length,
      pure: true,
      color: "#f57ca1",
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 5,
              data: femaleUser,
              headerLabel: [
                { label: "id" },
                { label: "name" },
                { label: "gender" },
                { label: "phoneNumber" },
                { label: "createdAt" },
                { label: "startDate" },
                { label: "endDate" },
              ],
              label: "activeUser",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("users"),
      value: allUsers.length,
      pure: true,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 5,
              data: allUsers,
              headerLabel: [
                { label: "id" },
                { label: "name" },
                { label: "gender" },
                { label: "phoneNumber" },
                { label: "createdAt" },
                { label: "startDate" },
                { label: "endDate" },
              ],
              label: "users",
            },
          },
        });
      },
    });

    totalData.push({
      label: t("memberships"),
      value: membershipTotalPrice,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 1,
              data: data.memberships,
              headerLabel: [
                { label: "name" },
                { label: "gender" },
                { label: "price" },
                { label: "date" },
                { label: "username" },
                { label: "id" },
                { label: "ticketNumber" },
                { label: "reception" },
                { label: "type" },
              ],
              label: "memberships",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("newSubscribers"),
      value: newMemberships,
      pure: true,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 1,
              data: data.memberships.filter(a => a.type == 0),
              headerLabel: [
                { label: "name" },
                { label: "gender" },
                { label: "price" },
                { label: "date" },
                { label: "username" },
                { label: "id" },
                { label: "ticketNumber" },
                { label: "reception" },
                { label: "type" },
              ],
              label: "memberships",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("renewalOfSubscriptions"),
      value: renewMemberships,
      pure: true,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 1,
              data: data.memberships.filter(a => a.type == 1),
              headerLabel: [
                { label: "name" },
                { label: "gender" },
                { label: "price" },
                { label: "date" },
                { label: "username" },
                { label: "id" },
                { label: "ticketNumber" },
                { label: "reception" },
                { label: "type" },
              ],
              label: "memberships",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("requestCourse"),
      value: requestCourseTotalPrice,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 4,
              data: data.requestCourse,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
              ],
              label: "requestCourse",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("supplements"),
      value: supplementsTotalPrice,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 2,
              data: data.supplements,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
                { label: "date" },
              ],
              label: "supplements",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("orders"),
      value: ordersTotalPrice,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 3,
              data: data.orders,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
              ],
              label: "orders",
            },
          },
        });
      },
    });

    totalData.push({
      label: t("expenses"),
      value: expensesTotalPrice,
      color: Colors.mainRedColor,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 0,
              data: data.expenses,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
                { label: "type" },
              ],
              label: "expenses",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("gymExpenses"),
      value: totalGymExpenses,
      color: Colors.mainRedColor,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 0,
              data: gymExpenses,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
                { label: "type" },
              ],
              label: "gymExpenses",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("cafeteriaExpenses"),
      value: totalCafeExpenses,
      color: Colors.mainRedColor,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 0,
              data: cafeeExpenses,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
                { label: "type" },
              ],
              label: "cafeteriaExpenses",
            },
          },
        });
      },
    });
    totalData.push({
      label: t("bigExpenses"),
      value: totalBigExpenses,
      color: Colors.mainRedColor,
      onPress: () => {
        history.push({
          pathname: `${window.location.pathname}/viewAll`,
          state: {
            showObj: {
              type: 0,
              data: bigExpenses,
              headerLabel: [
                { label: "name" },
                { label: "price" },
                { label: "date" },
                { label: "type" },
              ],
              label: "bigExpenses",
            },
          },
        });
      },
    });

    let finalBenefit = calculateFinalBenefit(
      [
        membershipTotalPrice,
        requestCourseTotalPrice,
        supplementsTotalPrice,
        ordersTotalPrice,
      ],
      [expensesTotalPrice]
    );

    let obj = {
      ...detailsData,
      mainChartData,
      totalData,
      finalBenefit,
      cafeDat,
    };

    setDetailsData({ ...obj });
  };

  const calculateFinalBenefit = (arr1, arr2) => {
    let sum1 = 0;
    let sum2 = 0;

    arr1.forEach((dt) => {
      sum1 += dt;
    });

    arr2.forEach((dt) => {
      sum2 += dt;
    });

    let obj = {
      benefit: sum1,
      expenses: sum2,
      revenue: sum1 - sum2,
    };

    return obj;
  };

  useEffect(() => {
    if (data && durationObj) {
      setUpMianChartData();
    }
  }, [data, durationObj, currency]);

  const onPressMonthlyReport = () => {
    history.push({
      pathname: `${window.location.pathname}/monthly-report`,
      state: {
        centerId: 'total',
      },
    });
  };

  useEffect(() => {
    if (duration) setUpData();
  }, [duration, supplements, orders, requestCourse, memberships, users]);

  return (
    <ScrollView
      style={{ flex: 1, width: "100%", backgroundColor: "#f1f1f1" }}
      showsVerticalScrollIndicator={false}
    >
      {duration && <DurationCallender duration={duration} />}

      {detailsData && duration && (
        <Upper detailsData={detailsData} duration={duration} />
      )}
      {detailsData && duration && (
        <Bottom isCenter detailsData={detailsData} duration={duration} />
      )}
      {width > 800 && (
        <ViewTotalDataContainer
          label={t("monthlyReport")}
          onPressViewAllOrder={onPressMonthlyReport}
        />
      )}
      <View style={{ height: 30, width: "100%" }} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({});

export default TotalAnalyticsScreen;
