import React from 'react'
import { useHistory } from 'react-router-dom';
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData';
import { IoAdd } from 'react-icons/io5';
import { showInIQD } from '../../../customFunction/Numeral/CurrencyFormat';

function NutritionStock() {
  const { columns, id, label, url, href } = {
    id: 'stock', label: 'stock',
    href: '/stock',
    columns: [
      { label: "#", value: (a) => a._id.slice(10) },
      { label: "image", value: (a) => <img alt='' src={a.imageUri} className='object-contain w-12 h-12' /> },
      { label: "name", value: (a) => a.name },
      { label: "price", value: (a) => showInIQD(a.prices.singlePrice,'USD') },
    ],
    url: (centerId) => '/nutrition-stocks?' + centerId,
  }

  const history = useHistory();

  const onClickItem = (_id) => {
    history.push('/nutrition-storage/stock/' + _id)
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData hrefId={'_id'} onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
          <IoAdd size={40} onClick={() => history.push('/nutrition-storage/stock/0?new=true')} />
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  );
}

export default NutritionStock
