import React from "react";
import { CourseTable } from "../../../../screens/Centers/MonthlyReportScreen";
import { t } from "i18next";
import useSwr from "swr";
import { fetcher } from "../../../../screens/UI/CentersViewData";
import { showInIQD } from "../../../../customFunction/Numeral/CurrencyFormat";

const Label = [
  "#",
  "Supplier",
  "Total Order Price",
  "Total Qty. Order",
  "Gross Profit",
];

const data = [
  {
    _id: 1,
    name: "Slemani Water",
    totalOrderPrice: 1500,
    totalQtyOrder: 10,
    grossProfit: 100,
  },
];

function SupplierTable({ query }) {
  //   const { data, isLoading, error } = useSwr(
  //     "/cafeteria-dashboard/advance-dashboard" + query + "supplierReport",
  //     fetcher
  //   );
  //   console.log({ data });
  return (
    <div className='flex flex-col w-full p-2 my-5 bg-white'>
      <h1 className='mb-4 text-lg'>{t("Supplier Table")}</h1>
      {/* {error && <div className='text-red-500'>{t("errorFetchingData")}</div>} */}
      <CourseTable labels={Label}>
        {data &&
          data &&
          data.map((product, i) => {
            return (
              <tr key={product._id} className='cursor-pointer'>
                <td className=''>{i + 1}</td>
                <td className=''>{product.name}</td>
                <td className=''>{product.totalOrderPrice}</td>
                <td className=''>{product.totalQtyOrder}</td>
                <td className=''>{showInIQD(product.grossProfit, "IQD")}</td>
              </tr>
            );
          })}
      </CourseTable>
    </div>
  );
}

export default SupplierTable;
