import axios from "axios";
import { store } from "../../App";
const UPDATE = "/users/update";

export const fetchUsers = async () => {
  try {
    const currentUser = store.getState().authentication.user
    if (currentUser.type === 17) {
      //!only fetch specific center or data
      const Users = []
      await Promise.all(currentUser.center.map(async (id) => {
        const res = await axios.get('/users?isExpire=false&centerId=' + id);
        Users.push(...res.data)
      }))
      store.dispatch({ type: "FETCH_USERS", isMulti: true, data: Users, });
      return
    }
    //!fetch all active users
    const res = await axios.get(`/users?isExpire=false`);
    store.dispatch({ type: "FETCH_USERS", data: res.data });
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (currentPackage) => {
  try {
    await axios.put(`${UPDATE}/${currentPackage._id}`, currentPackage);
  } catch (e) {
    throw e;
  }
};

export const deleteUser = async (id, whatToDelete) => {
  try {
    await axios.delete(`/users/delete-user/${id}?whatToDelete=` + (whatToDelete), { whatToDelete });
  } catch (e) {
    alert(e);
    throw e;
  }
};
