import React, { useEffect } from "react";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { compose, combineReducers, createStore, applyMiddleware } from "redux";
import Routes, { LANGUAGES } from "./routes/Routes";
import axios from "axios";
import io from "socket.io-client";

import authenticationReducer from "./store/reducers/authenticationReducer";
import packagesReducer from "./store/reducers/packagesReducer";
import loadingReducer from "./store/reducers/loadingReducer";
import centersReducer from "./store/reducers/centersReducer";
import adminsReducer from "./store/reducers/adminsReducer";
import userReducers from "./store/reducers/userReducers";
import memberhipsReducer from "./store/reducers/memberhipsReducer";
import supplementsReducer from "./store/reducers/supplementsReducer";
import courseReducer from "./store/reducers/courseReducer";
import ordersReducer from "./store/reducers/ordersReducer";
import expensesReducer from "./store/reducers/expensesReducer";
import supplementItemsReducer from "./store/reducers/supplementItemsReducer";
import analyticsReducer from "./store/reducers/analyticsReducer";
import doorReducer from "./store/reducers/doorsReducer";
import recordReducer from "./store/reducers/recordReducer";
import cafeteriaItemsReducer from "./store/reducers/cafeteriaItemsReducer";
import balancesReducer from "./store/reducers/balancesReducer";
import cafeteriaGuestUsersReducers from "./store/reducers/cafeteriaGuestUsersReducers";
import supplementsStorageReducer from "./store/reducers/supplementsStorageReducer";
import supplementsShopReducer from "./store/reducers/supplementsShopReducer";
import credentialsReducer from "./store/reducers/credentialsReducer";
// import socketReducer from "./store/reducers/onSocketReducer";
import searchAndPageIndexReducer from "./store/reducers/searchBarReducer";
import supplementStorageActivityReducer from "./store/reducers/supplementStorageActivityReducer";
import receiptsOfPaymentsReducer from "./store/reducers/receiptsOfPaymentsReducer";
import usersFeedbackReducer from "./store/reducers/usersFeedbackReducer";
import dollarPricesReducer from "./store/reducers/dollarPricesReducer";
import customsReducer from "./store/reducers/customsReducer";
import administrationReducer from "./store/reducers/administrationReducer";
import monthlyReportReducer from "./store/reducers/monthlyReportReducer";
import courseTypes from "./store/reducers/courseTypesReducer";
import supplementAnalyticsReducer from "./store/reducers/supplementAnalyticsReducer";

import dayjs from "dayjs";
import equipmnetsReducer from "./store/reducers/equipmentReducer";
import { onSocketAction } from "./store/actions/onSocketAction";
import requestsReducer from "./store/reducers/requestsReducer";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import analysisReducer from "./store/reducers/analysisReducer";
import i18next from "i18next";

const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
const LocalizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

//!Server
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? "https://akapower.xyz:4500/aka/api" : 'http://localhost:4000/aka/api'
// axios.defaults.baseURL = "http://localhost:4000/aka/api";
axios.defaults.headers.options = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "Access-Control-Allow-Headers":
    "Content-Type, Authorization, Content-Length, X-Requested-With",
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
const appReducer = combineReducers({
  authentication: authenticationReducer,
  packages: packagesReducer,
  loading: loadingReducer,
  centers: centersReducer,
  admins: adminsReducer,
  users: userReducers,
  memberships: memberhipsReducer,
  courses: courseReducer,
  orders: ordersReducer,
  expenses: expensesReducer,
  supplementItems: supplementItemsReducer,
  supplements: supplementsReducer,
  analytics: analyticsReducer,
  doors: doorReducer,
  records: recordReducer,
  cafeteriaItems: cafeteriaItemsReducer,
  balances: balancesReducer,
  cafeteriaGuestUsers: cafeteriaGuestUsersReducers,
  supplementsStorage: supplementsStorageReducer,
  supplementsShop: supplementsShopReducer,
  equipments: equipmnetsReducer,
  credentials: credentialsReducer,
  // sockets: socketReducer,
  searchAndPageIndex: searchAndPageIndexReducer,
  usersFeedback: usersFeedbackReducer,
  dollarPrices: dollarPricesReducer,
  supplementStorageActivity: supplementStorageActivityReducer,
  receiptsOfPayments: receiptsOfPaymentsReducer,
  customs: customsReducer,
  administration: administrationReducer,
  monthlyReports: monthlyReportReducer,
  courseTypes,
  requests: requestsReducer,
  analysis: analysisReducer,
  supplementDuration: supplementAnalyticsReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export const socket = io.connect(process.env.NODE_ENV === 'production' ? "https://akapower.xyz:4500/" : 'http://localhost:4000/');

socket.on("onSocketAction", (data) => {
  onSocketAction(data);
});

function App() {

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language') || 'en')
  }, [])

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
