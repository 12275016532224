import React from "react";
import { CourseTable } from "../../../../screens/Centers/MonthlyReportScreen";
import { t } from "i18next";
import useSwr from "swr";
import { fetcher } from "../../../../screens/UI/CentersViewData";
import { showInIQD } from "../../../../customFunction/Numeral/CurrencyFormat";

const Label = [
  "product",
  "inStock",
  "totalPrice",
  "lastOrderedQty",
  "lastOrderedDate",
];

function StockTable({ query }) {
  const { data, isLoading, error } = useSwr(
    "/cafeteria-dashboard/advance-dashboard" + query + "stockTable",
    fetcher
  );
  console.log({ data });
  return (
    <div className='flex flex-col w-full p-2 my-5 bg-white'>
      <h1 className='mb-4 text-lg'>{t("stockTable")}</h1>
      {error && <div className='text-red-500'>{t("errorFetchingData")}</div>}
      <CourseTable isLoading={isLoading} labels={Label}>
        {data &&
          data.products &&
          data.products.length !== 0 &&
          data.products.map((product) => {
            const lastPurchase = product.purchaseData[0];
            const currentItemInPurchase = lastPurchase
              ? lastPurchase.items.find((item) => item._id === product._id)
              : null;

            return (
              <tr key={product._id} className='cursor-pointer'>
                <td className=''>{product.name}</td>
                <td className=''>{product.quantity}</td>
                <td className=''>
                  {showInIQD(product.quantity * product.prices.buyPrice)}
                </td>

                <td className={`${currentItemInPurchase ? "" : " opacity-40"}`}>
                  {currentItemInPurchase
                    ? currentItemInPurchase.quantity
                    : t("notSelected")}
                </td>
                <td className={`${lastPurchase ? "" : " opacity-40"}`}>
                  {lastPurchase ? lastPurchase.date : t("notSelected")}
                </td>
              </tr>
            );
          })}
        {data?.products && (
          <tr>
            <td className='text-blue-500'>{t("total")}</td>
            <td className='text-blue-500'>
              {data.products.reduce((a, b) => a + b.quantity, 0)}
            </td>
            {/* <td className='text-blue-500'>{showInIQD(data?.totalPrice)}</td> */}
            <td className='text-blue-500'>
              {showInIQD(
                data.products.reduce(
                  (a, b) => a + b.quantity * b.prices.buyPrice,
                  0
                )
              )}
            </td>
            <td className='text-blue-500'>20</td>
            <td className='text-blue-500'>#</td>
          </tr>
        )}
      </CourseTable>
    </div>
  );
}

export default StockTable;
