import React from "react";
import { useHistory } from "react-router-dom";
import ShowPaginationData from "../../../components/UI/TableView/ShowPaginationData";
import { IoAdd } from "react-icons/io5";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";
import dayjs from "dayjs";

function PhysicalTherapyScreen() {
  const { columns, id, label, url, href } = {
    id: 'physicalTherapy', label: 'physicalTherapy',
    href: '/physicalTherapy',
    columns: [{ label: '#', value: a => a._id.slice(10) },
    { label: 'name', classnames: () => 'font-[Montserrat]', value: (a) => a.name.en },
    { label: 'amount', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.price) },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('DD-MM-YYYY') },
   ],
    url: (centerId) => '/physical-therapy?' + centerId,
  }

  const history = useHistory();

  const onClickItem = (_id) => {
    history.push(`/admin/physical-therapy/${_id}`);
  }
  
  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
    <div className='flex flex-1 w-full h-full mt-10' >
      <ShowPaginationData hrefId='_id' onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
        <IoAdd size={40} onClick={() => history.push(`/admin/physical-therapy/0?isNew=true`)} />
      </div>} columns={columns} href={href} id={id} label={label} url={url} />
    </div>
  </div>
  );
}

export default PhysicalTherapyScreen;
