import React from "react";
import { CourseTable } from "../../../../screens/Centers/MonthlyReportScreen";
import { t } from "i18next";
import useSwr from "swr";
import { fetcher } from "../../../../screens/UI/CentersViewData";
import { showInIQD } from "../../../../customFunction/Numeral/CurrencyFormat";

const Label = ["#", "Supplier", "Total Debt"];

const data = [
  {
    _id: 1,
    name: "Water",
    qty: 2,
    totalPrice: 300,
    reason: "Expire",
  },
];

function SupplierDebt({ query }) {
  return (
    <div className='flex flex-col w-full p-2 my-5 bg-white'>
      <h1 className='mb-4 text-lg'>{t("Supplier Debt")}</h1>
      <CourseTable labels={Label}>
        {data &&
          [{ name: "Slemani Water", _id: "sdc", totalPrice: 10000 }].map(
            (product, i) => {
              return (
                <tr key={product._id} className='cursor-pointer'>
                  <td className=''>{i + 1}</td>
                  <td className=''>{product.name}</td>
                  <td>{showInIQD(product.totalPrice, "IQD")}</td>
                </tr>
              );
            }
          )}
      </CourseTable>
    </div>
  );
}

export default SupplierDebt;
