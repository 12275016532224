const initialState = { customs: null, };

const customsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CUSTOMS":
      return {
        ...state,
        customs: action.data,
      }


    case "CREATE_CUSTOMS": {
      let dt = state.customs.concat(action.data);
      return {
        ...state,
        customs: dt,
      };
    }
    case "UPDATE_CUSTOMS": {
      let dt = state.customs.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        customs: dt,
      }
    };
    case "DELETE_CUSTOMS": {
      let dt = state.customs.filter((a) => a._id !== action.data);
      return {
        ...state,
        customs: dt,
      };
    }
    default:
      return state;
  }
};

export default customsReducer;
