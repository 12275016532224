const initialState = { doors: null, fetchedDate: null };

const doorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DOORS": {
      const { data } = action;

      let memberships = state.doors || []
      let dates = state.fetchedDate || []
      data.map((membership) => {
        if (!dates.find((date) => date === membership.date)) {
          const newMembers = data.filter(a => a.date === membership.date)
          memberships = memberships.concat(newMembers)
          dates.push(membership.date)
        }
      })

      return {
        ...state,
        doors: memberships,
        fetchedDate: dates
      }
    };
    case "CREATE_DOOR": {
      const newDoors = state.doors.concat(action.data)

      return {
        ...state,
        doors: newDoors,
      }
    };
    default:
      return state;
  }
};

export default doorReducer;
