import dayjs from "dayjs";
const initialState = {
  duration: {
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    pickerType: 0,
  },
  supplementsDuration: {
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  equipmentsDuration: {
    startDate: dayjs().hour(0).minute(0).second(0).toDate(),
    endDate: dayjs().hour(23).minute(59).second(59).toDate(),
  },
  currency: "USD",
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DURATION": {
      return {
        ...state,
        [action.isSupplement ? 'supplementsDuration' : action.isEquipment ? 'equipmentsDuration' : 'duration']: action.data,
      };
    }
    case "UPDATE_CURRENCY": {
      return {
        ...state,
        currency: action.data,
      };
    }
    default:
      return state;
  }
};

export default analyticsReducer;
