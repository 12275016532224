const initialState = { packages: null };

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PACKAGES":
            return {
                ...state,
                packages: action.data,
            };
        case "ADD_EDIT_PACKAGE": {
            let newMessaaging = state.packages;
            if (
              !state.packages.find((a) => a._id === action.data._id) ||
              state.packages.length === 0
            ) {
              newMessaaging.push(action.data);
            } else {
              newMessaaging = state.packages.map((msg) => {
                if (msg._id === action.data._id) {
                  msg = action.data;
                }
                
                return msg;
              });
            }
            return { ...state, packages: newMessaaging };
      
        }
        default:
            return state;
    }
};

export default authenticationReducer;
