import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import HeaderContainer from "../../components/UI/‌Header/HeaderContainer";
import { fetchMemberships } from "../../store/actions/membershipsAction";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import ShowPaginationData from "../../components/UI/TableView/ShowPaginationData";

function MembershipScreen() {
  const userId = useParams().id;
  const { columns, id, label, url, href } = {
    id: 'memberships', label: 'memberships',
    href: '/memberships',
    columns: [{ label: 'name', value: a => a.userName },
    { label: 'duration', classnames: () => 'font-[Montserrat]', value: (a) => a.originalDocument.package.duration||1 },
    { label: 'price', value: (a) => showInIQD(a.originalDocument.package.price.value, 'USD') },
    { label: 'date', value: (a) => a.originalDocument.date },
    ],

    url: (centerId) => `/memberships?userId=${userId}&` + centerId,
  }
  const history = useHistory()
  const onClickItem = (id) => {
    history.push({
      pathname: `${window.location.pathname + "/" + id}`,
    });
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData hrefId='_id' onClickItem={onClickItem} rightButton={<div className="cursor-pointer" >
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  );
}

export default MembershipScreen;
