import axios from "axios";
import { store } from "../../App";
const GET = "/centers";
const CREATE = "/centers/create";
const UPDATE = "/centers/update";

export const fetchCenters = async () => {
  try {
    const currentUser = store.getState().authentication.user
    if (currentUser.type === 17) {
      //!only fetch specific center or data
      currentUser.center.map(async (id) => {
        const res = await axios.get(GET + '?centerId=' + id);
        store.dispatch({ type: "FETCH_CENTERS", isMulti: true, data: res.data });
      })
      return
    }
    const res = await axios.get(GET);
    store.dispatch({ type: "FETCH_CENTERS", data: res.data });
  } catch (e) {
    throw e;
  }
};
export const createOrUpdateCenter = async (currentPackage, isUpdate) => {
  try {
    let res;
    if (isUpdate) {
      res = await axios.put(`${UPDATE}/${currentPackage._id}`, currentPackage);
    } else {
      res = await axios.post(`${CREATE}`, currentPackage);
    }
    store.dispatch({ type: "ADD_EDIT_CENTER", data: res.data.data });
  } catch (e) {
    throw e;
  }
};

export const setCenterId = async (id) => {
  try {
    await store.dispatch({ type: "SET_CENTER_ID", data: id });
  } catch (e) {
    console.log(e);
  }
};
