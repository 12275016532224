import { t } from "i18next";
import React from "react";

function ViewStorageInvoice({ invoiceProducts, columns, children, id }) {
  return (
    <div className='flex items-center justify-center flex-1 w-full '>
      <div className='w-[90%] h-[90%]  '>
        {children}
        <Table data={invoiceProducts} columns={columns} />
      </div>
    </div>
  );
}

const Table = ({ columns, data }) => {
  return (
    <div className='flex flex-col w-full my-6 max-h-[600px] overflow-auto'>
      <div className='flex items-center justify-between px-1 py-4 rounded bg-slate-100 '>
        {columns?.map((a, i) => (
          <div key={i} className='w-1/4 text-[15px] opacity-90'>
            {t(a.label)}
          </div>
        ))}
      </div>
      <div className='flex flex-col w-full h-full '>
        <>
          {data &&
            data.length !== 0 &&
            data.map((a, i) => (
              <div
                // onClick={onClickItem.bind(this, a[hrefId])}
                key={i}
                className={`flex ${
                  i % 2 === 0 ? "bg-[#fff]" : "bg-[#f4f3f3]"
                } items-center justify-center min-h-[60px]  p-1 py-4 font-medium border-b rounded cursor-pointer `}>
                {columns.map(({ value, classnames }, j) => (
                  <div
                    key={j}
                    className={`w-1/4 text-[14px] min-h-[55px] flex ${
                      classnames ? classnames(a) : ""
                    } items-center whitespace-pre-wrap overflow-hidden text-ellipsis opacity-90`}>
                    {value(a)}
                  </div>
                ))}
              </div>
            ))}
        </>
      </div>
    </div>
  );
};

export default ViewStorageInvoice;
