const initialState = { records: null, fetchedDate: null };

const recordReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_RECORDS": {
      const { data } = action;

      let memberships = state.records || []
      let dates = state.fetchedDate || []
      data.map((membership) => {
        if (!dates.find((date) => date === membership.date)) {
          const newMembers = data.filter(a => a.date === membership.date)
          memberships = memberships.concat(newMembers)
          dates.push(membership.date)
        }
      })

      return {
        ...state,
        records: memberships,
        fetchedDate: dates
      }
    };
    default:
      return state;
  }
};

export default recordReducer;
