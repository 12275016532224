import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import { useSelector } from "react-redux";
import { handleLoading } from "../../../store/actions/loadingAction";
import { createCafeteriaItem } from "../../../store/actions/cafeteriaItemsAction";
import ArrayIndex from "../../../components/UI/AddingData/ArrayIndex";

const CAFE_ITEMS = [{ id: 0, label: 'akaFitness' }, { id: 1, label: 'forrestFood' }]
function CaffetteriaDetailsScreen() {
  const [currentBalance, setCurrentBalance] = useState(false);
  const CafeteriaItems = useSelector(
    (state) => state.cafeteriaItems.cafeteriaItems
  );
  const id = useParams().id;

  const history = useHistory();
  const onChangeValue = (type, value) => {
    let obj = currentBalance;

    if (type === 0) {
      obj.name = value;
    }
    if (type === 1) {
      obj.price = Number(value);
    }
    if (type === 2) {
      obj.imageUri = value;
    }
    if (type === 3) {
      obj.iqd = Number(value);
    }
    if (type === 4) {
      obj.priceList = (value);
    }
    if (type === 5) {
      obj.type = Number(value);
    }

    setCurrentBalance({ ...obj });
  };

  const onSubmit = async () => {
    try {
      handleLoading();
      let isUpdate = CafeteriaItems.find((a) => a._id === id);
      await createCafeteriaItem(currentBalance, isUpdate);
      history.goBack();
      handleLoading();
    } catch (e) {
      handleLoading();
      console.log(e);
      alert(e);
    }
  };

  const setUp = () => {
    let objData = {};
    let check = CafeteriaItems.find((a) => a._id == id);

    if (check) {
      objData = check;
    } else {
      let obj = {
        price: '',
        name: "",
        imageUri: "",
      };
      objData = obj;
    }

    setCurrentBalance(objData);
  };

  const checkValidation = async () => {
    if (!currentBalance.name) {
      throw "Invlaid Name";
    }

    if (!currentBalance.imageUri) {
      throw "Invlaid Image";
    }
  };
  console.log({ currentBalance })
  useEffect(() => {
    setUp();
  }, []);

  return (
    <div className='flex flex-col items-center w-full'>
      <HeaderContainer label={t("createUser")} saveBtn onPressBtn={onSubmit} />
      {currentBalance && (
        <Container
          currentBalance={currentBalance}
          onChangeValue={onChangeValue}
        />
      )}
    </div>
  );
}

const Container = ({ onChangeValue, currentBalance }) => {
  return (
    <div className='w-4/5 mt-10 border max-h-max'>
      <InputContainer
        onChangeValue={onChangeValue}
        currentBalance={currentBalance}
      />
    </div>
  );
};

const InputContainer = ({ onChangeValue, currentBalance }) => {
  const { t } = useTranslation();

  return (
    <div className='items-center justify-center bg-white'>
      <InstanceIndex label={t("idNumber")} value={`#${currentBalance._id}`} />
      <TextInput
        label={t("name")}
        placeHolder={t("name")}
        value={currentBalance.name}
        onChangeValue={onChangeValue}
        type={0}
      />
      <TextInput
        label={`${t("price")} IQD`}
        placeHolder={t("price")}
        value={currentBalance?.iqd}
        onChangeValue={onChangeValue}
        isNumber={true}
        type={3}
      />
      <TextInput
        label={`${t("image")} $`}
        placeHolder={t("image")}
        value={currentBalance.imageUri}
        onChangeValue={onChangeValue}
        type={2}
      />
      <ArrayIndex
        label={t("itemFrom")}
        data={CAFE_ITEMS}
        type={5}
        valType={1}
        value={currentBalance.type}
        index='id'
        onChangeValue={onChangeValue}
      />      
      <FeaturesContainer type={4} value={currentBalance.priceList} onChangeValue={onChangeValue.bind(this, 4)} />
    </div>
  );
};

const FeaturesContainer = ({ value, onChangeValue }) => {
  const [selectedFeatures, setSelectedFeatures] = useState(value || []);
  const centers = useSelector(state => state.centers.centers)
  const DATA = centers.map(a => ({ id: a._id, label: a.label }))

  const onChangeValuee = (id, e) => {
    const valuee = e.target.value

    //! add image uri value to object in selected features

    const newS = selectedFeatures.map(a => {
      if (a.id === id) {
        a['iqd'] = Number(valuee)
      }
      return a
    })

    setSelectedFeatures(newS)
    onChangeValue(newS)
  };

  useEffect(() => {
    setSelectedFeatures((value && value.length > 0 && value) || DATA)

    onChangeValue(value || DATA)
  }, [])

  const onSelectFeature = (valuee) => {
    const newFeatures = [...selectedFeatures];
    const index = newFeatures.findIndex((a) => a.id === valuee.id);
    if (index === -1) {
      newFeatures.push(valuee);
    } else {
      newFeatures.splice(index, 1);
    }
    setSelectedFeatures(newFeatures)
    onChangeValue(newFeatures)

  }

  return (
    <div className="flex flex-row justify-between w-full p-6">
      <h1>لیستی نرخەکان</h1>
      <div style={{ width: '60%' }} className="flex flex-col" >

        {DATA.map((ft, i) => {
          const isCheck = selectedFeatures.find(a => a.id === ft.id)

          return <div key={ft.id} className="flex items-center m-2" style={{ width: '100%' }} >
            <div onClick={onSelectFeature.bind(this, ft)} style={{ width: '25%', backgroundColor: isCheck ? 'green' : null, color: isCheck ? '#fff' : null }} className="p-1 border cursor-pointer" >{ft.label.toUpperCase()}</div>
            <input value={isCheck?.iqd} disabled={!isCheck} onChange={onChangeValuee.bind(this, ft.id)} style={{ width: '75%', height: '100%' }} type='number' placeholder="iqd" className="px-2 border-none outline-none h-9 bg-whiteCardColor" />
          </div>
        })}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({});

export default CaffetteriaDetailsScreen;
