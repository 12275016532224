import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";
const CREATE = "/packages/create";
const UPDATE = "/packages/update";

export const fetchPackages = async () => {
  try {
    const res = await axios.get(`/packages`);
    store.dispatch({ type: "FETCH_PACKAGES", data: res.data });
  } catch (e) {
    throw e;
  }
};
export const createOrUpdatePackage = async (currentPackage, isUpdate) => {
  try {
    let res;
    if (isUpdate) {
      res = await axios.put(`${UPDATE}/${currentPackage._id}`, currentPackage);
    } else {
      res = await axios.post(`${CREATE}`, currentPackage);
    }
    store.dispatch({ type: "ADD_EDIT_PACKAGE", data: res.data.data });
  } catch (e) {
    throw e;
  }
};
