import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from 'react-date-range';
import dayjs from 'dayjs';
import { updateDuration } from '../../../store/actions/supplementAnalaysisAction';
import { t } from 'i18next';
import DetailsProfitTable from '../../../components/cafeteria/dashboard/advanceDashboard/DetailsProfitTable';
import StockTable from '../../../components/cafeteria/dashboard/advanceDashboard/StockTable';
import ProductSalesTable from '../../../components/cafeteria/dashboard/advanceDashboard/ProductSalesTable';
import SupplierTable from '../../../components/cafeteria/dashboard/advanceDashboard/SupplierTable';
import DepreciationTable from '../../../components/cafeteria/dashboard/advanceDashboard/DepreciationTable';
import SupplierDebt from '../../../components/cafeteria/dashboard/advanceDashboard/SupplierDebt';

function CafeteriaAdvanceDashboard() {
  const duration = useSelector((state) => state.supplementDuration.duration);
  const [isCheckCalcPartnerItems, setIsCheckCalcPartnerItems] = useState(false)

  return (
    <div className='flex flex-1 flex-col p-2 w-full bg-[#f1f1f1]' >
      <Header isCheckCalcPartnerItems={isCheckCalcPartnerItems} setIsCheckCalcPartnerItems={() => setIsCheckCalcPartnerItems(prev => !prev)} duration={duration} />
      <AnalysisBody query={`?isCheckCalcPartnerItems=${isCheckCalcPartnerItems}&startDate=${duration.startDate}&endDate=${duration.endDate}&type=`} />
    </div>
  )
}

const Header = ({ duration, isCheckCalcPartnerItems, setIsCheckCalcPartnerItems }) => {
  const [datePickerHandler, setDatePickerHandler] = useState([
    {
      startDate: duration.startDate,
      endDate: duration.endDate,
      key: 'selection'
    }
  ]);
  const [showDate, setShowDate] = useState(false)
  const [dateHasChange, setDateHasChange] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.authentication.user)
  const onClickSelectDate = () => {
    setShowDate(!showDate)
  }

  const onChangeDateRange = () => {
    //! if range more than 7 days show error
    if (user.type === 14 && dayjs(datePickerHandler[0].endDate).diff(dayjs(datePickerHandler[0].startDate), 'day') > 7) {
      alert('Please select range less than 7 days')
      return
    }
    dispatch(
      updateDuration({
        startDate: datePickerHandler[0].startDate,
        endDate: datePickerHandler[0].endDate,
      })
    );
  }

  useEffect(() => {
    if (
      dayjs(datePickerHandler[0].startDate).format("YYYY-MM-DD") !==
      dayjs(duration.startDate).format("YYYY-MM-DD") ||
      dayjs(datePickerHandler[0].endDate).format("YYYY-MM-DD") !==
      dayjs(duration.endDate).format("YYYY-MM-DD")
    )
      onChangeDateRange()
  }, [dateHasChange])

  return <div className='flex bg-[#fff] rounded-md p-3 items-center justify-between w-full' >
    <div>
      <h1 className='text-2xl font-bold' >Cafeteria Dashboard</h1>
    </div>
    <div>
      <div className="mt-2" >
        <div onClick={onClickSelectDate} style={{ minWidth: 250 }} className="flex items-center">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
            <input value={dayjs(duration.startDate).format('DD-MM-YYYY') + ' - ' + dayjs(duration.endDate).format('DD-MM-YYYY')} type="text" className="bg-gray-50 select-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 " placeholder={t("selectDate")} />
          </div>
        </div>
        {showDate && <DateRange
          className={`absolute z-10 border right-3 top-20`}
          editableDateInputs={true}

          onChange={item => {
            setDatePickerHandler([item.selection])
          }}

          onRangeFocusChange={(a) => {
            if (a[0] === 0 && a[1] === 0) {
              setShowDate(false)
              setDateHasChange(!dateHasChange)
            }
          }}
          moveRangeOnFirstSelection={false}
          ranges={datePickerHandler}
        />}
      </div>
    </div>
  </div>
}

const AnalysisBody = ({ query }) => {
  return <div>
    <DetailsProfitTable query={query} />
    <ProductSalesTable query={query} />
    <StockTable query={query} />
    <SupplierTable query={query} />
    <DepreciationTable query={query} />
    <SupplierDebt query={query} />
  </div>
}

export default CafeteriaAdvanceDashboard
