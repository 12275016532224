import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { handleLoading } from "../../store/actions/loadingAction";
import {
  deleteUser,
  updateUser,
} from "../../store/actions/usersAction";
import dayjs from "dayjs";
import { fetchCredential } from "../../store/actions/credentialsAction";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import CommonInputsContainer from "../../components/UI/TableView/CommonInputsContainer";
import InstanceIndex from "../../components/UI/AddingData/InstanceIndex";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import { IoCloseOutline } from "react-icons/io5";
import { t } from "i18next";
import { useSelector } from "react-redux";

function CreateUser() {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(false);
  const id = useParams().id;
  const { t } = useTranslation();
  const user = useSelector(state => state.authentication.user)

  const [base64Image, setBase64Image] = useState(null);
  const { data, isLoading, error } = useSwr('/users/' + id, fetcher)
  const [deleteModal, setDeleteModal] = useState(false)
  const onChangeValue = (type, value) => {
    switch (type) {
      case 0:
        currentUser.name = value;
        break;
      case 1:
        currentUser.email = value;
        break;
      case 2:
        currentUser.phone = value;
        break;
      case 3:
        currentUser.expiry_datetime = dayjs(value)
          .hour(23)
          .minute(59)
          .format();
        break;
      case 4: {
        currentUser.wallet.isFree = !(currentUser?.wallet?.isFree || false);
        break;
      }
      case 5: {
        currentUser.start_datetime = dayjs(value)
          .hour(23)
          .minute(59)
          .format();
        break;
      }
      case 9: {
        currentUser.centerId = value
        break;
      }
      default:
        break;
    }

    setCurrentUser({ ...currentUser });
  };

  useEffect(() => {
    if (currentUser) {
      getCredential()
    }
  }, [currentUser]);

  const getCredential = async () => {
    const credential = await fetchCredential(currentUser._id)

    if (credential && credential[0].length !== 0) {
      setBase64Image(credential[0].credential)
    } else {
      setBase64Image(false)
    }
  }

  const onSubmit = async () => {
    try {
      handleLoading();
      await updateUser({ ...currentUser });

      handleLoading();
      history.goBack();
      history.goBack();
    } catch (e) {
      handleLoading();
      alert("Error", JSON.stringify(e));
    }
  };

  const onHandleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }


  useEffect(() => {
    if (data && data[0]) setCurrentUser(data[0])
  }, [data])

  const onPressPathBtn = (path) => {
    history.push({
      pathname: `${window.location.pathname}/${path}`,
      state: {
        user: {
          ...currentUser,
        },
      },
    });
  };

  const menu = [{
    title: 'memberships',
    onClick: () => onPressPathBtn('memberships'),
  }, {
    title: 'purchasesHistory',
    onClick: () => onPressPathBtn('purchases'),
  }, {
    title: 'balancesHistory',
    onClick: () => onPressPathBtn('balances'),
    showType: 1,
  }, {
    title: 'deleteUser',
    onClick: () => onHandleDeleteModal(),
    showType: 1,
    color: "red"
  },]

  if (!currentUser) {
    return null;
  }

  if (error) return <Error />

  if (isLoading) return <Loading />

  return (
    <div className='flex flex-col items-center justify-center flex-1 z-1 w-full bg-[#f9f9f9] h-full  '>
      <DeleteUserModal currentUser={currentUser} onHandleDeleteModal={onHandleDeleteModal} deleteModal={deleteModal} />
      <CommonInputsContainer
        title={'user'}
        onSave={user.type === 0 ? onSubmit : null}
        menu={user.type === 0 ? menu : null}//!only for owner
        className='my-10' >
        <TextInput
          label={t("id")}
          placeHolder={t("id")}
          value={currentUser._id}
          onChangeValue={onChangeValue}
          type={7}
          dir='ltr'
        />
        <TextInput
          label={t("bioStarID")}
          placeHolder={t("bioStarID")}
          value={currentUser.bioStarID}
          onChangeValue={onChangeValue}
          type={8}
        />
        <TextInput
          label={t("name")}
          placeHolder={t("name")}
          value={currentUser.name}
          onChangeValue={onChangeValue}
          type={0}
        />
        <ImageContainer label={t("image")} value={base64Image} />
        <TextInput
          label={t("email")}
          placeHolder={t("email")}
          value={currentUser.email}
          onChangeValue={onChangeValue}
          type={1}
        />
        <TextInput
          label={t("phoneNumber")}
          placeHolder={t("phoneNumber")}
          value={currentUser.phone}
          dir='ltr'

          onChangeValue={onChangeValue}
          type={2}
        />
        <InstanceIndex label={t("wallet")} value={showInIQD(currentUser.wallet?.money || 0, "IQD")} />
        <div>
          <DateInput
            label={t("startDate")}
            placeHolder={t("startDate")}
            value={dayjs(currentUser.start_datetime).utc().format("YYYY-MM-DD")}
            onChangeValue={onChangeValue}
            type={5}
          />
          <DateInput
            label={t("endDate")}
            placeHolder={t("endDate")}
            value={dayjs(currentUser.expiry_datetime).utc().format("YYYY-MM-DD")}
            onChangeValue={onChangeValue}
            type={3}
          />
        </div>

      </CommonInputsContainer>
    </div >
  );
}

const ImageContainer = ({ label, value }) => {
  return (
    <div className='flex flex-row justify-between w-full p-6'>
      <h1>{label}</h1>
      {value !== null ? value === false ? <h1>وێنە نیە</h1> : <img alt="" src={`${value}`} width={100} height={100} /> : <div role="status">
        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
      </div>}
    </div>
  );
};

export const TextInput = ({
  label,
  value,
  placeHolder,
  onChangeValue,
  type,
  isNumber,
  dir,
  onlyReturnValue

}) => {
  const onChangeValueHandler = (e) => {
    let tx = e.target.value;
    if (onlyReturnValue) return onChangeValue(isNumber ? tx * 1 : tx)
    onChangeValue(type, isNumber ? tx * 1 : tx);
  };

  return (
    <div className='flex flex-row justify-between w-full p-6'>
      <h1>{label}</h1>
      <input
        onChange={onChangeValueHandler}
        style={{ direction: 'ltr' }}
        className='w-3/5 px-2 border-none outline-none h-9 bg-whiteCardColor'
        type={isNumber ? "number" : "text"}
        placeholder={placeHolder}
        value={value}
      />
    </div>
  );
};

export const DateInput = ({
  label,
  value,
  placeHolder,
  onChangeValue,
  onlyReturnValue,
  type,
}) => {
  const onChangeValueHandler = (e) => {
    let tx = e.target.value;
    if (onlyReturnValue) return onChangeValue(tx)
    onChangeValue(type, tx);
  };

  return (
    <div className='flex flex-row justify-between w-full p-6'>
      <h1>{label}</h1>
      <input
        onChange={onChangeValueHandler}
        className='w-3/5 px-2 border-none outline-none h-9 bg-whiteCardColor'
        type={"date"}
        placeholder={placeHolder}
        value={value}
      />
    </div>
  );
};

export const Button = ({ onClick, label, bgColor }) => {
  return (
    <div
      onClick={onClick}
      className={`w-4/5 p-4 ${bgColor} border cursor-pointer select-none hover:opacity-80`}>
      <h1 className='text-white'>{label}</h1>
    </div>
  );
};


export const DeleteButton = ({ onDelete, type }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onDelete}
      className='w-4/5 p-4 bg-red-500 border cursor-pointer select-none hover:opacity-80'>
      <h1 className='text-white'>
        {type === 0 ? t("delete") : "سڕینەوەی بەکارهێنەر بەتەنها"}
      </h1>
    </div>
  );
};

export const HandleButton = ({ onPress, value, label }) => {
  return (
    <div className='flex flex-row justify-between w-full p-6'>
      <h1>{label}</h1>
      <div
        onClick={onPress}
        className={`w-10 h-10 rounded-full cursor-pointer ${value ? 'bg-green-500' : 'bg-gray-500'}`}>

      </div>
    </div>
  );
};

const DeleteUserModal = ({
  deleteModal,
  onHandleDeleteModal,
  currentUser
}) => {
  const history = useHistory()
  const [whatToDelete, setWhatToDelete] = useState([])

  const onChangeValue = (type, value) => {
    if (whatToDelete.includes(type)) {
      setWhatToDelete(whatToDelete.filter(a => a !== type))
    }
    else {
      setWhatToDelete([...whatToDelete, type])
    }
  }
  const onDelete = async () => {
    try {
      if (whatToDelete.length === 0) return alert('Select what to delete')
      handleLoading()
      await deleteUser(currentUser._id, whatToDelete)
      handleLoading()
      history.goBack()

    } catch (e) {
      console.log(e)
      handleLoading()
      alert(e.message)
    }
  }

  return <div className={`absolute flex items-center  ${deleteModal ? 'opacity-100 z-[1000]' : 'opacity-0 z-[-1]'} duration-300 transition-all justify-center bg-black/50 w-full h-full  -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`} >
    <div className='flex flex-col p-2 w-[50%] h-auto bg-white rounded-lg' >
      <div className='flex items-center justify-between' >
        <span className='text-lg font-bold' >{t('deleteUser')}</span>
        <IoCloseOutline className='cursor-pointer ' onClick={onHandleDeleteModal} size={30} />
      </div>
      <span className="text-sm text-red-600" >
        {t('deleteUserWarning')}
      </span>
      <div className='my-6' >
        <div className='flex flex-wrap gap-5' >
          <div className="flex items-center gap-x-1" >
            <input type="checkbox" onChange={() => onChangeValue('memberships')} />
            <label>{t('deleteMemberships')}</label>
          </div>
          <div className="flex items-center gap-x-1" >
            <input type="checkbox" onChange={() => onChangeValue('purchases')} />
            <label>{t('deletePurchases')}</label>
          </div>
          {/* <div className="flex items-center gap-x-1" >
            <input type="checkbox" onChange={() => onChangeValue('balances')} />
            <label>{t('deleteBalances')}</label>
          </div>
          <div className="flex items-center gap-x-1" >
            <input type="checkbox" onChange={() => onChangeValue('courses')} />
            <label>{t('deleteCourses')}</label>
          </div> */}
        </div>

      </div>
      <div className='flex items-center justify-center w-full' >
        <div onClick={onDelete} className='p-2 mb-2 text-xl text-white bg-red-600 border border-none cursor-pointer' >{t('deleteUser')}</div>
      </div>
    </div>
  </div>
}

export default CreateUser;
