import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { handleLoading } from "../../store/actions/loadingAction";
import { useEffect } from "react";
import HeaderContainer from "../../components/UI/‌Header/HeaderContainer";
import { t } from "i18next";
import { Button, DateInput, TextInput } from "../Users/CreateUserScreen";
import { useTranslation } from "react-i18next";
import InstanceIndex from "../../components/UI/AddingData/InstanceIndex";
import ArrayIndex from "../../components/UI/AddingData/ArrayIndex";
import { createCourse, deleteCourse } from "../../store/actions/coursesAction";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";

const CourseDetailsScreen = () => {
  const [currentPackage, setCurrentPackage] = useState(false);
  const centers = useSelector((state) => state.centers.centers);
  const id = useParams().id;
  const { data, isLoading, error } = useSwr('/courses/' + id, fetcher)
  const centerId = useParams().centerId;
  const { data: admins, isLoading: adminsLoading, error: adminsError } = useSwr('/admins?type=3&activeEmployee=true&centerId=' + centerId, fetcher)
  const user = useSelector(state => state.authentication.user)

  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentPackage;
    if (type === 0) {
      obj.trainerId = value;
    } else if (type === 1) {
      obj.iqd = Number(value);
    } else if (type === 2) {
      obj.centerId = value;
    } else if (type === 3) {
      obj.type = value;
    } else if (type === 4) {
      obj.date = value;
      obj.createdAt = dayjs(value).valueOf();
    } else if (type === 5) {
      obj.price = Number(value)
    }
    else if (type === 7) {
      obj.paymentDetails.payedType = Number(value);
    }
    else if (type === 8) {
      obj.paymentDetails.amountByCustomer = Number(value);
    }
    else if (type === 9) {
      obj.paymentDetails.dollarAmount = Number(value);
    }
    else if (type === 10) {
      obj.paymentDetails.iqdAmount = Number(value);
    }
    else if (type === 11) {
      obj.paymentDetails.changeType = Number(value);
    }
    else if (type === 12) {
      obj.paymentDetails.changePrice = Number(value);
    }

    setCurrentPackage({ ...obj });
  };

  const onSubmit = async () => {
    try {
      handleLoading();
      await createCourse(currentPackage);
      // history.goBack();
      history.goBack();
      handleLoading();
    } catch (e) {
      handleLoading();
      alert(e);
    }
  };

  const setUp = () => {
    setCurrentPackage(data[0]);
  };

  useEffect(() => {
    if (data && data[0]) setUp();
  }, [data]);

  const onDeleteExpense = async () => {
    try {
      const check = window.confirm(t('areYouSureAboutDeleting'));
      if (check) {
        handleLoading();
        await deleteCourse(currentPackage._id);
        history.goBack();
        handleLoading();
      }
    } catch (e) {
      console.log(e);
      handleLoading();
      alert(e);
    }
  };

  if (error || adminsError) return <Error />

  if (isLoading || adminsLoading) return <Loading />

  return (
    <div className='flex flex-col items-center w-full '>
      <HeaderContainer label={t("courses")} saveBtn onPressBtn={onSubmit} />
      {currentPackage && (
        <Container
          currentPackage={currentPackage}
          onChangeValue={onChangeValue}
          centers={centers}
          admins={admins}
        />
      )}
      {currentPackage._id && user.type === 0 && (
        <Button
          onClick={onDeleteExpense}
          bgColor='bg-soldColor'
          label={t('delete')}
        />
      )}
    </div>
  );
};

const Container = ({ onChangeValue, admins, currentPackage, centers }) => {
  return (
    <div className='w-4/5 mt-10 border max-h-max'>
      <InputContainer
        onChangeValue={onChangeValue}
        currentPackage={currentPackage}
        centers={centers}
        admins={admins}
      />
    </div>
  );
};

const InputContainer = ({ onChangeValue, admins, currentPackage, centers }) => {
  const { t } = useTranslation();

  return (
    <div className='items-center justify-center bg-white'>
      {currentPackage._id && (
        <InstanceIndex label={t("idNumber")} value={`#${currentPackage._id}`} />
      )}
      {/* <TextInput
        label={t("name")}
        placeHolder={t("name")}
        value={currentPackage.name}
        onChangeValue={onChangeValue}
        type={0}
      /> */}
      <TextInput
        label={`${t("price")} IQD`}
        placeHolder={t("price")}
        value={currentPackage?.iqd}
        onChangeValue={onChangeValue}
        type={1}
        isNumber
      />
      <TextInput
        label={`${t("price")} $`}
        placeHolder={t("price")}
        value={currentPackage?.price}
        onChangeValue={onChangeValue}
        type={5}
        isNumber
      />
      {currentPackage.paymentDetails && (
        <>
          <ArrayIndex
            label={t("payedType")}
            data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
            type={7}
            valType={1}
            value={currentPackage.paymentDetails.payedType}
            index='_id'
            onChangeValue={onChangeValue}
          />
          {currentPackage.paymentDetails.payedType === 2 ? <>
            <TextInput
              label={t("dollarAmount")}
              placeHolder={t("dollarAmount")}
              value={currentPackage.paymentDetails.dollarAmount}
              onChangeValue={onChangeValue}
              type={9}
            />
            <TextInput
              label={t("iqdAmount")}
              placeHolder={t("iqdAmount")}
              value={currentPackage.paymentDetails.iqdAmount}
              onChangeValue={onChangeValue}
              type={10}
            />
          </> : <TextInput
            label={t("amountByCustomer")}
            placeHolder={t("amountByCustomer")}
            value={currentPackage.paymentDetails.amountByCustomer}
            onChangeValue={onChangeValue}
            type={8}
          />}
          <ArrayIndex
            label={t("changeType")}
            data={[{ _id: 0, label: 'dollar' }, { _id: 1, label: 'iqd' }, { _id: 2, label: 'dollar + iqd' }]}
            type={11}
            valType={1}
            value={currentPackage.paymentDetails.changeType}
            index='_id'
            onChangeValue={onChangeValue}
          />
          {currentPackage.paymentDetails.payedType === 2 ? <></> : <TextInput
            label={t("changePrice")}
            placeHolder={t("changePrice")}
            value={currentPackage.paymentDetails.changePrice}
            onChangeValue={onChangeValue}
            type={12}
          />}
        </>
      )}
      {currentPackage.date && (
        <DateInput
          label={`${t("date")}`}
          placeHolder={t("date")}
          value={currentPackage?.date}
          onChangeValue={onChangeValue}
          type={4}
        />
      )}
      {admins && (
        <ArrayIndex
          label={t("coachName")}
          data={admins.filter((a) => a.type === 3 && a.center?.includes(currentPackage.centerId))}
          type={0}
          valType={2}
          value={currentPackage.trainerId}
          index='_id'
          onChangeValue={onChangeValue}
        />
      )}
      {/* {centers && (
        <ArrayIndex
          label={t("center")}
          data={centers}
          type={2}
          valType={1}
          value={currentPackage.centerId}
          index='_id'
          onChangeValue={onChangeValue}
        />
      )} */}
    </div>
  );
};

export default CourseDetailsScreen;

const styles = StyleSheet.create({});
