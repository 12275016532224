import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";

import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import useSWR from "swr";
import { useQuery } from "../ClassSchedule/ClassSchedulesDetails";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import axios from "axios";

function BalanceDetailsScreen() {
  const { id } = useParams()
  const isNew = useQuery().get('isNew')
  const { data, isLoading, error } = useSWR('/balances/g/' + id + '?isNew=' + isNew, fetcher)
  const [currentData, setCurrentData] = useState()
  const history = useHistory()

  useEffect(() => {
    setup()
  }, [data])

  const setup = () => {
    if (data && data[0]) {
      setCurrentData(data[0])
    }
  }

  const onChangeValue = (type, value) => {
    let obj = currentData||{};

    if (type === 0) {
      obj['name'] = value;
    }
    if (type === 2) {
      obj['value'] = Number(value);
    }

    setCurrentData({ ...obj });
  };

  const onSave = async () => {
    try {
      if (!currentData || !currentData.name || !currentData.value) {
        alert('Please fill all the fields')
        return
      }

      if (isNew) {
        await axios.post('/balances/', currentData)
      }
      else
        await axios.put('/balances/', currentData)
      history.goBack()
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer onSave={onSave} title={t('balance')} >
        {currentData?._id && <InstanceIndex label={t("#")} value={`#${currentData._id}`} />}
        <TextInput
          label={t("name")}
          placeHolder={t("name")}
          value={currentData?.name}
          onChangeValue={onChangeValue}
          type={0}
        />
        <TextInput
          label={`${t("amount")}`}
          placeHolder={t("amount")}
          value={currentData?.value}
          onChangeValue={onChangeValue}
          type={2}
          isNumber
        />
      </CommonInputsContainer>
    </div >
  );
}
export default BalanceDetailsScreen;

