import React from "react";
import { FiPackage } from "react-icons/fi";
import { MdCoffee, MdOutlineAdminPanelSettings } from "react-icons/md";
import { CgGym } from "react-icons/cg";
import { GiExpense, GiJumpingRope, GiPayMoney, GiPillDrop } from "react-icons/gi";
import Colors from "../../constant/Colors";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { AiFillDollarCircle } from "react-icons/ai";
import { IoIosFitness, IoIosNotificationsOutline } from "react-icons/io";
import { TbMassage } from "react-icons/tb";

function AdminScreen() {
  const history = useHistory();
  const user = useSelector(state => state.authentication.user)

  const onPress = (route) => {
    history.push(`${window.location.pathname}/${route}`);
  };

  return (
    <div className="flex flex-wrap items-center flex-1 w-full mt-12 justify-evenly">

      {user.type === 0 && <>
        <Card
          Icons={<FiPackage size={50} color={Colors.mainAqwaBlue} />}
          label="packages"
          onPress={onPress.bind(this, "packages")}
        />
        <Card
          Icons={
            <MdOutlineAdminPanelSettings size={50} color={Colors.mainAqwaBlue} />
          }
          label="admins"
          onPress={onPress.bind(this, "admins")}
        />
        <Card
          Icons={<CgGym size={50} color={Colors.mainAqwaBlue} />}
          label="center"
          onPress={onPress.bind(this, "center/62d3de07e4b5f62ba339b22b")}
        />
        <Card
          Icons={<GiPayMoney size={50} color={Colors.mainAqwaBlue} />}
          label="balance"
          onPress={onPress.bind(this, "balance")}
        />
        <Card
          Icons={<TbMassage size={50} color={Colors.mainAqwaBlue} />}
          label="physicalTherapy"
          onPress={onPress.bind(this, "physical-therapy")}
        />
        <Card
          Icons={<IoIosFitness size={50} color={Colors.mainAqwaBlue} />}
          label="course"
          onPress={onPress.bind(this, "course-types")}
        />
        <Card
          Icons={<IoIosNotificationsOutline size={50} color={Colors.mainAqwaBlue} />}
          label="notification"
          onPress={onPress.bind(this, "notifications")}
        />
        <Card
          Icons={<GiJumpingRope size={50} color={Colors.mainAqwaBlue} />}
          label="class-schedule"
          onPress={onPress.bind(this, "class-schedule/66437b007d76b7496a27cb9e")}
        />
      </>}
      <Card
        Icons={<GiExpense size={50} color={Colors.mainAqwaBlue} />}
        label="expenses"
        onPress={onPress.bind(this, "bigExpenses/0")}
      />

      <Card
        Icons={<AiFillDollarCircle size={50} color={Colors.mainAqwaBlue} />}
        label="dollarPrice"
        onPress={onPress.bind(this, "dollar-price")}
      />


    </div>
  );
}

export const Card = ({ Icons, label, onPress }) => {
  return (
    <a
      onClick={onPress}
      className="flex flex-col items-center justify-center m-4 border cursor-pointer h-60 w-60 "
    >
      <h1 className="my-3 text-xl font-bold opacity-80">{t(label)}</h1>
      {Icons}
    </a>
  );
};

export default AdminScreen;
