import React from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Dimensions } from "react-native";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Bottom from "../../components/Dashboard/CenterAnalyticsScreen/Bottom";
import Upper from "../../components/Dashboard/CenterAnalyticsScreen/Upper";
import ViewTotalDataContainer from "../../components/Dashboard/CenterAnalyticsScreen/ViewTotalDataContainer";
import DurationCallender from "../../components/Dashboard/DurationCalender";
import { Loading } from "../UI/CentersViewData";
import useSWR from "swr";
import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";

const { width } = Dimensions.get("window");

const fetcher = async ({ startDate, endDate, centerId }) => {
  return await axios.get(`/analysis/centers?start_date=${dayjs().format(startDate)}&end_date=${dayjs().format(endDate)}&centerId=${centerId}`).then(data => {
    data.data[0].akafitness.data = data.data[0].akafitness.data.sort((a, b) => a.centerName.localeCompare(b.centerName))
    store.dispatch({ type: 'FETCH_CENTERS_DATA', data: data.data })
  })
}

const CenterAnalyticsScreen = (props) => {
  const duration = useSelector((state) => state.analytics.duration);
  const CentersData = useSelector((state) => state.analysis.centersData);
  const { t } = useTranslation();
  const User = useSelector(state => state.authentication.user)
  const id = useParams().centerId;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  searchParams.set('v2', 'true');
  const { data: d } = useSWR({ startDate: duration.startDate, endDate: duration.endDate, centerId: id }, fetcher)
  const history = useHistory();

  const data = CentersData && CentersData[0]?.akafitness?.data?.find(a => a.centerId === id)?.data


  const onPressSalary = () => {
    history.push({
      pathname: `${window.location.pathname}/salary`,
      state: {
        centerId: id,
      },
    });
  };

  const onPressMonthlyReport = () => {
    history.push({
      pathname: `${window.location.pathname}/monthly-report`,
      state: {
        centerId: id,
      },
    });
  };

  if (data === null) {
    return <Loading />
  }

  return (
    <div
      className="flex relative flex-col flex-1 bg-[#f1f1f1]"

    >
      {duration && <DurationCallender duration={duration} />}

      {data && duration && (
        <Upper data={data} duration={duration} />
      )}
      {data && duration && (
        <Bottom isCenter={true} data={data} duration={duration} />
      )}
      <>
        {(
          <ViewTotalDataContainer
            label={t("monthlyReport")}
            onPressViewAllOrder={onPressMonthlyReport}
          />
        )}

        {User.type !== 17 && <>
          {width > 800 && (
            <ViewTotalDataContainer
              label={t("salary")}
              onPressViewAllOrder={onPressSalary}
            />
          )}
        </>}
      </>
      <View style={{ height: 30, width: "100%" }} />
    </div>
  );
};

const styles = StyleSheet.create({});

export default CenterAnalyticsScreen;
