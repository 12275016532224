import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeaderRender from "../../../components/UI/Table/HeaderRender";
import ItemRender from "../../../components/UI/Table/ItemRender";
import { shortIdGenerator } from "../../../customFunction/IDGenerator";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import { updateSupplementSearch } from "../../../store/actions/supplementsShopAction";
import fuzzysort from "fuzzysort";
import SearchContainer from "../../../components/UI/Table/SearchContainer";

const headerLables = [
  'id',
  "name",
  "price",
  "date",
];

function BigExpensesScreen() {
  const [allData, setAllData] = useState(false);
  const [data, setData] = useState(false);

  const { t } = useTranslation();
  const expeneses = useSelector((state) => state.expenses.expenses);
  const search = useSelector((state) => state.supplementsShop.search);

  const history = useHistory();
  const dispatch = useDispatch();

  const onPressItem = (data) => {
    if (data._id) {
      history.push(`${window.location.pathname}/${data._id}`);
    } else {
      let id = shortIdGenerator();
      history.push(`${window.location.pathname}/${id}`);
    }
  };

  const onChangeSearch = async (e) => {
    let value = e.target.value;
    await dispatch(updateSupplementSearch(value));
  };

  const setUpAllData = () => {
    try {
      let arr = expeneses.filter(a => a.type == 3);

      let newArr = [];
      let keywords = search;

      let results = [];

      if (keywords && keywords != null && keywords != "" && keywords != " ") {
        results = fuzzysort.go(keywords, arr, {
          keys: ["name"],
          threshold: false,
        });
        results.forEach((rs) => {
          newArr.push(rs.obj);
        });
      } else {
        newArr = arr;
      }
      newArr = newArr.sort((a, b) => b.createdAt - a.createdAt);
      setAllData(newArr);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    if (data) {
      setUpAllData();
    }
  }, [search, data]);

  const setUpData = () => {
    try {
      let arr = expeneses;

      setData(arr);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (expeneses) {
      setUpData();
    }
  }, [expeneses]);

  return (
    <div className="flex flex-col items-center w-full">
      <HeaderContainer
        label={t("bigExpenses")}
        addBtn
        onPressBtn={onPressItem}
      />
      <HeaderRender headerLables={headerLables} />
      <SearchContainer onChangeSearch={onChangeSearch} search={search} />

      {allData && (
        <ItemRender
          onPressItem={onPressItem}
          headerLables={headerLables}
          datas={allData}
          type={6}
        />
      )}
    </div>
  );
}

export default BigExpensesScreen;
