const initialState = { data: null, centersData: null, date: { startDate: new Date(), endDate: new Date() } };

const analysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ANALYSIS": {
            return {
                ...state,
                data: action.data,
            };
        }
        case "FETCH_CENTERS_DATA":{
            return {
                ...state,
                centersData: action.data,
            }
        }
        case "CHANGE_ANALYSIS_DATE": {
            return {
                ...state,
                date: action.data,
            };
        }
        default:
            return state;
    }
};

export default analysisReducer;
