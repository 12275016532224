import React from 'react'
import { useEffect } from 'react'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { View, TouchableOpacity } from 'react-native-web'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSupplementStorageActivity } from '../../store/actions/supplementStorageAction'
import { t } from 'i18next'

function StorageActivityScreen() {
    const [data, setData] = React.useState()
    const { centerId } = useParams()

    const setup = async () => {
        const res = await fetchSupplementStorageActivity(centerId)
        setData(res)
    }

    useEffect(() => {
        setup()
    }, [])

    if (!data) return <div>Loading...</div>
    if (data.length === 0) return <div>No data</div>
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: 1000, alignSelf: 'center' }} >
                {data.map((item, index) => {
                    return (
                        <div className='w-full m-4 bg-slate-600' key={index}>
                            <Item items={item} />
                        </div>
                    )
                })}
            </View>
        </View>
    )
}

const Item = ({ items }) => {
    const [show, setShow] = React.useState(false)

    const onPressItem = () => {
        setShow(!show)
    }
    return <View>
        <TouchableOpacity onPress={onPressItem} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', padding: 4 }} >
            <View>
                <h3 style={{ color: '#fff' }} >{items.date} - {items.type !== undefined ? items.type === 1 ? t('cancel') : t('buy') : ''}</h3>
            </View>
            <View>
                {!show ? <AiOutlineDown color='#fff' /> : <AiOutlineUp color='#fff' />}
            </View>
        </TouchableOpacity>
        {show && <View style={{}} >
            {items.items && items.items.length !== 0 && items.items.map((item, index) => {
                return <ItemRender item={item} isLast={(index === items.items.length - 1)} key={index} />
            })}
        </View>}

    </View>
}

const ItemRender = ({ item, isLast }) => {
    const supplementItem = useSelector(state => state.supplementItems.supplementItems)

    return <View style={{ flexDirection: 'row', borderBottomWidth: isLast ? 0 : 1, borderColor: '#fff', marginVertical: 4, alignItems: 'center', justifyContent: 'space-between', padding: 4 }} >
        <View>
            <h3 style={{ color: '#fff' }} >{supplementItem.find(a => a._id === item.itemId)?.name}</h3>
        </View>
        <View>
            <h3 style={{ color: '#fff' }} >{item.quntity || item.quantity}</h3>
        </View>
    </View>
}


export default StorageActivityScreen
