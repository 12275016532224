import React, { useState } from "react";
import useSwr from "swr";
import { Suspense } from "react";
import { t } from "i18next";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import { Error, fetcher, Loading } from "../../screens/UI/CentersViewData";
import numeral from "numeral";
import { IoTrashOutline } from "react-icons/io5";

function StorageCreateInvoice({
  onClickSubmit,
  showCustomerModal,
  id,
  stockQuery,
  currencyType = "iqd",
}) {
  const { data, isLoading, error } = useSwr(stockQuery, fetcher);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modal, setModal] = useState(false);

  const onClickModal = () => {
    setModal(!modal);
  };

  const onChangeSelectedProduct = (product, priceType) => {
    //! check if product is already in selectedProduct remove it else add it
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (isProductExist) {
      setSelectedProducts((prev) => prev.filter((a) => a._id !== product._id));
    } else {
      const newProduct = {
        _id: product._id,
        quantity: 1,
        price: 0,
        name: product.name,
        productId: product._id,
      };
      setSelectedProducts((prev) => [...prev, newProduct]);
    }
  };

  const onChangeQuantity = (product, quantity) => {
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (quantity <= 0) {
      setSelectedProducts((prev) => prev.filter((a) => a._id !== product._id));
      return;
    }
    if (isProductExist) {
      //!remove product when quantity is 0
      if (quantity === 0) {
        setSelectedProducts((prev) =>
          prev.filter((a) => a._id !== product._id)
        );
        return;
      }
      setSelectedProducts((prev) =>
        prev.map((a) => {
          if (a._id === product._id) {
            return { ...a, quantity };
          }
          return a;
        })
      );
    } else {
      setSelectedProducts((prev) => [...prev, { ...product, quantity }]);
    }
  };

  const onChangeExpiryOrCode = (product, key, value) => {
    setSelectedProducts((prev) =>
      prev.map((a) => {
        if (a._id === product._id) {
          return { ...a, [key]: value };
        }
        return a;
      })
    );
  };

  const onSubmit = async (e) => {
    try {
      if (
        id === "spoiled-items" &&
        (selectedProducts.length === 0 ||
          selectedProducts.find((a) => !a.quantity))
      ) {
        alert("please check quantity");
        return;
      } else if (
        id !== "spoiled-items" &&
        (selectedProducts.length === 0 ||
          selectedProducts.find((a) => !a.price))
      ) {
        alert("please check quantity and price");
        return;
      }
      e.target.disabled = true;

      await onClickSubmit(selectedProducts);
      e.target.disabled = false;
    } catch (err) {
      console.log(err);
      e.target.disabled = false;
    }
  };

  const onRemoveItem = (id) => {
    setSelectedProducts((prev) => prev.filter((a) => a._id !== id));
  };

  const onChangePrice = (product, price) => {
    if (price < 0) return;
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (isProductExist) {
      setSelectedProducts((prev) =>
        prev.map((a) => {
          if (a._id === product._id) {
            return { ...a, price };
          }
          return a;
        })
      );
    } else {
      setSelectedProducts((prev) => [...prev, { ...product, price }]);
    }
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <div className='flex w-full flex-1 overflow-x-hidden bg-[#f1f1f1] '>
      <Suspense fallback={<Loading />}>
        <div className={`flex w-full overflow-hidden p-7`}>
          <ProductItems
            id={id}
            selectedProducts={selectedProducts}
            onChangeSelectedProduct={onChangeSelectedProduct}
            products={data}
            onChangePrice={onChangePrice}
            onChangeQuantity={onChangeQuantity}
          />
          <Menu
            onClickModal={onClickModal}
            showCustomerModal={showCustomerModal}
            onSubmit={onSubmit}
            id={id}
            onRemoveItem={onRemoveItem}
            currencyType={currencyType}
            onChangeExpiryOrCode={onChangeExpiryOrCode}
            selectedProducts={selectedProducts}
          />
        </div>
      </Suspense>
    </div>
  );
}

const ProductItems = ({
  products,
  selectedProducts,
  onChangeSelectedProduct,
  onChangeQuantity,
  onChangePrice,
  id,
}) => {
  const [prds, setPrds] = useState(products);
  const [search, setSearch] = useState("");

  const onSearch = (val) => {
    const newPrds = products.filter((a) =>
      a.name.toLowerCase().includes(val.toLowerCase())
    );
    setPrds(newPrds);
    setSearch(val);
  };

  return (
    <div className='w-[70%] h-[96vh] overflow-hidden'>
      <div className='w-full items-center px-3 justify-between mb-7 h-[50px] flex'>
        <input
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t("search")}
          className={`w-full outline-none p-2 h-full bg-white border`}
        />
      </div>
      <div className='flex flex-wrap pb-[150px] justify-center w-full overflow-auto h-[100vh] gap-7'>
        {prds.map((product) => (
          <div key={product._id} className='w-[250px] bg-white h-fit'>
            <img
              alt={product.name}
              src={product.imageUri}
              className='h-[200px] w-full object-contain'
            />
            <div className=''>
              <h1 className='p-1 font-medium truncate'>{product.name}</h1>
              {/* <h4 className='p-1 text-sm font-medium opacity-70'>
                ({product.quantity} available)
              </h4> */}
              <div className='flex cursor-pointer  h-[44px] '>
                {selectedProducts &&
                selectedProducts.length !== 0 &&
                selectedProducts.find((a) => a._id === product._id) ? (
                  <>
                    <div
                      className={`w-[${
                        id === "spoiled-items" ? "100%" : "50%"
                      }] `}>
                      <input
                        type='number'
                        onChange={(e) =>
                          onChangeQuantity(product, e.target.value * 1)
                        }
                        onFocus={(e) => e.target.select()}
                        className='w-full text-xl outline-none text-center h-full border  bg-[#f9f9f9]'
                        placeholder={t("qty")}
                        value={
                          selectedProducts.find((a) => a._id === product._id)
                            .quantity
                        }
                      />
                    </div>
                    {id !== "spoiled-items" && (
                      <div className='w-[50%] relative'>
                        <p className='absolute text-sm -translate-y-1/2 opacity-50 top-1/2'>
                          {t("price")}
                        </p>
                        <input
                          onFocus={(e) => e.target.select()}
                          value={
                            selectedProducts.find((a) => a._id === product._id)
                              .price
                          }
                          onChange={(e) =>
                            onChangePrice(product, e.target.value * 1)
                          }
                          type='number'
                          className='w-full text-xl outline-none text-center h-full border  bg-[#f9f9f9]'
                          placeholder={t("price")}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    onClick={onChangeSelectedProduct.bind(this, product)}
                    className='flex items-center justify-center w-full text-lg font-bold text-white bg-blue-500'>
                    {t("select")}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Menu = ({
  selectedProducts,
  onSubmit,
  currencyType,
  id,
  onChangeExpiryOrCode,
  onRemoveItem,
}) => {
  return (
    <div
      key={id}
      className='w-[30%] relative h-[96vh] overflow-hidden p-2 bg-white'>
      <div className='flex flex-col w-full pb-1 border-b'>
        <h1 className='text-2xl font-bold'>{t("receipt")}</h1>
        {/* <p className='font-medium opacity-70'>
          {t("note: The final price is written approximately")}
        </p> */}
      </div>
      <div className=' max-h-[80%] overflow-auto w-full'>
        {selectedProducts &&
          selectedProducts.length !== 0 &&
          selectedProducts.map((prds) => {
            return (
              <div
                key={prds._id}
                className='flex flex-col w-full p-2 my-4 border-b'>
                <div className='flex items-center justify-between w-full'>
                  <p className='text-base font-bold'>{prds.name}</p>
                  <IoTrashOutline
                    onClick={onRemoveItem.bind(this, prds._id)}
                    className='text-red-500 cursor-pointer'
                    size={20}
                  />
                </div>{" "}
                <div className='text-base text-right opacity-70'>
                  <p>
                    {prds.quantity} {t("qty")}
                  </p>
                  <p>
                    {id === "spoiled-items"
                      ? null
                      : prds.price +
                        " " +
                        (currencyType === "usd" ? t("usd") : t("dinar"))}
                  </p>
                </div>
                {id === "purchases" && (
                  <div>
                    <div>
                      <input
                        type='date'
                        className='w-full p-1 my-3 border'
                        placeholder='expiryDate'
                        onFocus={(e) => e.target.select()}
                        value={prds.expiryDate}
                        onChange={(e) =>
                          onChangeExpiryOrCode(
                            prds,
                            "expiryDate",
                            e.target.value
                          )
                        }
                      />
                      <input
                        type='text'
                        className='w-full p-1 mb-3 border'
                        placeholder='productCode'
                        value={prds.code}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          onChangeExpiryOrCode(prds, "code", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className='absolute bottom-0 w-full'>
        {id !== "spoiled-items" && (
          <div className='flex flex-col items-center justify-center w-full '>
            <div className='text-2xl font-medium'>
              {t("total")}:
              {numeral(
                selectedProducts.reduce((a, b) => a + b.price * b.quantity, 0)
              ).format("0,0")}{" "}
              {currencyType === "usd" ? t("usd") : t("dinar")}
            </div>
          </div>
        )}
        <button
          onClick={(e) => onSubmit(e)}
          className='flex items-center justify-center w-full p-3 text-2xl font-bold text-white bg-blue-500 cursor-pointer'>
          {t("submit")}
        </button>
      </div>
    </div>
  );
};

export default StorageCreateInvoice;
