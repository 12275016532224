import React from 'react'
import { useHistory } from 'react-router-dom/'
import { IoAdd } from 'react-icons/io5'
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData'
import dayjs from 'dayjs'

function ClassSchedules() {
  const { columns,  id, label, url, href } = {
    id: 'class-schedule', label: 'classSchedule',
    href: '/',
    columns: [{ label: 'id', value: a => a._id },
    { label: 'gym', classnames: () => 'font-[Montserrat]', value: (a) => '78 Gym' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') }],
    url: (centerId) => '/class-schedule?'+centerId,
  }
  const history = useHistory()

  const onClickItem = (_id) => {
    history.push(`/admin/class-schedule/${_id}`)
  }

  return (
    <div className='flex flex-1 w-full h-full mt-10' >
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
          <IoAdd size={40} onClick={() => history.push('/admin/class-schedule/0?isNew=true')} />
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  )
}

export default ClassSchedules
