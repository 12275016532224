import i18next, { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { AiOutlineSearch } from 'react-icons/ai'
import Colors from '../../constant/Colors'
import useSwr from 'swr'
import axios from 'axios'
import { Skeleton } from '../../ui/ui/skeleton'
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie';
import animationData from './animation_llnmaab2.json';
import animationLoading from './animation-loading.json';
import { SiMicrosoftexcel } from 'react-icons/si'
import { Checkbox } from '../../ui/ui/checkbox'
import { IoCloseOutline } from 'react-icons/io5'
import FilterDropdown from './FilterDropdown'
import { CENTERS_VIEW_DATA } from './CENTERS_VIEW_DATA'
import { BiSortAlt2 } from 'react-icons/bi'
import { downloadExcel } from 'react-export-table-to-excel'

export const fetcher = url => axios.get(url).then(res => res.data)

function CentersViewData() {
    const { centerId, viewId } = useParams()
    const label = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.label || ''
    const Columns = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.columns || []
    const NotByDate = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.notByDate || null
    const href = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.href || null
    const hrefId = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.hrefId || '_id'
    const hrefIdSubKey = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.hrefIdSubKey || null
    const filterOfCenter = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.filter || null
    // console.log({ href, hrefId, hrefIdSubKey })
    const duration = useSelector(state => state.analytics.duration)
    const history = useHistory()
    const url = CENTERS_VIEW_DATA.find(a => a.id === viewId)?.url || null
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [sort, setSort] = useState([])
    const [filter, setFilter] = useState([])

    const { data, error, isLoading } = useSwr(url(`&centerId=${centerId}&sort=${JSON.stringify(sort)}&filter=${JSON.stringify(filter)}&page=${page}&search=${search}&` + (NotByDate ? null : `startDate=${NotByDate ? null : dayjs(duration.startDate).format('YYYY-MM-DD')}&endDate=${NotByDate ? null : dayjs(duration.endDate).format('YYYY-MM-DD')}`)), fetcher)
    const language = i18next.language
    const [unSelectedColumns, setUnSelectedColumns] = useState([])
    const [exportModal, setExportModal] = useState(false)

    useEffect(() => {
        if (data) {
            setPageCount(data.pagination?.pageCount)
        }
    }, [data])

    const handleNext = (a) => {
        const newVal = a.selected + 1
        if (newVal > page) {
            setPage(p => {
                return p + 1
            })
        } else if (newVal < page) {
            setPage(p => {
                return p - 1
            })
        }
    }

    const OnClickExportExcel = async () => {
        // const {data,error}=await useSwr(url(`&centerId=${centerId}&` + (NotByDate ? null : `startDate=${NotByDate ? null : dayjs(duration.startDate).format('YYYY-MM-DD')}&endDate=${NotByDate ? null : dayjs(duration.endDate).format('YYYY-MM-DD')}`)), fetcher)
        // console.log(error,data)
        setExportModal(!exportModal)
    }
    if (error) return <Error />

    return (
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} className='flex items-center justify-center flex-1 w-full overflow-x-hidden ' >
            {/* //!create modal with tailwind */}
            {/* <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50' onClick={() => history.goBack()} ></div> */}
            <ExportModal duration={duration} centerId={centerId} url={url} items={data?.items} columns={Columns} exportModal={exportModal} onClickExportExcel={OnClickExportExcel} />
            <div className='flex flex-col w-[90%] h-[90%] ' >
                <div className='pb-3 border-b' >
                    <div className='flex items-center justify-between' >
                        <div className='text-3xl font-bold' >
                            {t(label)}
                        </div>
                        <div className='z-50 cursor-pointer' onClick={OnClickExportExcel} >
                            <SiMicrosoftexcel size={28} color='green' />
                        </div>
                    </div>
                </div>
                <div>
                    {/* Table Header */}
                    <div className='flex justify-between max-md:flex-col' >
                        <div className='flex items-center' >
                            {!isLoading && <ReactPaginate
                                breakLabel="..."
                                nextLabel={`${t('next')} >`}
                                onPageChange={handleNext}
                                pageRangeDisplayed={5}
                                pageCount={Math.ceil(pageCount)}
                                initialPage={page - 1}
                                previousLabel={`< ${t('previous')}`}
                                renderOnZeroPageCount={null}
                                containerClassName="pagination"
                                previousLinkClassName="pagination__link"
                                nextLinkClassName="pagination__link"
                                disabledClassName="pagination__link--disabled"
                                activeLinkClassName="pagination__link--active"
                            />}

                        </div>
                        <div className='flex flex-wrap items-center' >
                            <div className='my-2 h-[36px] relative ' >
                                <input autoFocus value={search} onChange={e => setSearch(e.target.value)} className='p-1 h-full border pr-[32px] outline-none rounded w-[250px]' placeholder={t('search')} />
                                <AiOutlineSearch size={24} color={Colors.mainAqwaBlue} className='absolute -translate-y-1/2 top-1/2 right-1' />

                            </div>
                        </div>
                    </div>
                    <div className='flex items-center' >

                        <ColumnsDropdown language={language} columns={Columns} unSelectedColumns={unSelectedColumns} onChangeUnselectedColumns={(a) => setUnSelectedColumns(a)} />
                        {filterOfCenter && <FilterDropdown data={data} filterOfCenter={filterOfCenter} language={language} columns={Columns} filter={filter} setFilter={(a) => setFilter(a)} />}
                    </div>

                    {/* Table Body */}
                    <div className='flex flex-col w-full h-full overflow-auto' >
                        {isLoading ? <Loading /> : <Table sort={sort} setSort={(lb) => setSort(lb)} unSelectedColumns={unSelectedColumns} history={history} hrefIdSubKey={hrefIdSubKey} hrefId={hrefId} onClickItem={(a) => href ? history.push(window.location.pathname + href + '/' + (hrefIdSubKey ? a[hrefIdSubKey] : a)) : null} isLoading={isLoading} data={data} columns={Columns} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Table = ({ columns, sort, unSelectedColumns, setSort, data, isLoading, onClickItem, hrefId }) => {
    const onClickHeader = (item) => {
        if (!item.sort) return
        const isSelected = sort.find((a) => a.query === item.query);
        if (isSelected) {
            if (isSelected.sortBy === 1)
                setSort([{ sort: item.sort, sortBy: -1 }]);
            else
                setSort([{ sort: item.sort, sortBy: 1 }]);
        } else {
            setSort([{ sort: item.sort, sortBy: 1 }]);
        }
    }

    columns = columns.filter(a => !unSelectedColumns.includes(t(a.label)))

    return <div className='flex flex-col w-full my-6 max-h-[600px] ' >
        <div className='flex min-w-[600px] items-center justify-between px-1 py-4 rounded bg-slate-100 ' >
            {columns.map((a, i) => <div key={i} onClick={onClickHeader.bind(this, a)} className='w-1/4 flex cursor-pointer items-center text-[15px] opacity-90' >
                <p className='mx-2' >{t(a.label)}</p>
                {a.sort && <BiSortAlt2 color='#ccc' className='' size={22} />}
            </div>)}
        </div>
        <div className='flex flex-col w-full h-full ' >
            {isLoading ? <>
                {Array(10).fill(0).map((a, i) => <>
                    <Skeleton key={i} className='w-full my-3 bg-[#f4f3f3] h-[60px]' />
                </>)}
            </> : <>
                {data && data.items && data.items.map((a, i) => <div onClick={onClickItem.bind(this, hrefId ? a[hrefId] : a)} key={i} className={`flex ${i % 2 === 0 ? 'bg-[#fff]' : 'bg-[#f4f3f3]'} min-w-[600px] items-center justify-center h-[60px]  p-1 py-4 font-medium border-b rounded cursor-pointer `} >
                    {columns.map(({ value, classnames }, j) => <div key={j} className={`w-1/4 text-[14px] h-[55px] flex ${classnames ? classnames(a) : ''} items-center whitespace-pre-wrap overflow-hidden text-ellipsis opacity-90`} >{value(a)}</div>)}
                </div>)}</>}
        </div>
    </div>
}

export const Loading = () => {
    return <div className='flex items-center justify-center flex-1 w-full' style={{ height: 'auto' }} >
        <Lottie height={500} width={500} isClickToPauseDisabled={false} options={{ animationData: animationLoading, loop: true, autoplay: true }} />
    </div>
}
export const Error = () => {
    return <div className='flex items-center justify-center flex-1 w-full' style={{ height: 'auto' }} >
        <Lottie height={600} width={600} isClickToPauseDisabled={false} options={{ animationData, loop: true, autoplay: true }} />
    </div>
}

const ExportModal = ({ exportModal, centerId, duration, url, columns, onClickExportExcel }) => {
    const [unSelectedColumns, setUnSelectedColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const handleCheckboxChange = (item) => {
        const isSelected = unSelectedColumns.includes(item);

        if (isSelected) {
            setUnSelectedColumns(unSelectedColumns.filter((selectedItem) => selectedItem !== item));
        } else {
            setUnSelectedColumns([...unSelectedColumns, item]);
        }
    };

    const onExport = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(url('fromExcel=true&centerId=' + centerId + '&startDate=' + dayjs(duration.startDate).format('YYYY-MM-DD') + '&endDate=' + dayjs(duration.endDate).format('YYYY-MM-DD')), fetcher)
            const data = res.data.map((a, i) => {
                const obj = {}
                columns.filter(a => !unSelectedColumns.includes(a.label)).forEach(b => {
                    obj[t(b.label)] = b.value(a)
                })
                return obj
            })

            downloadExcel({
                fileName: "78gym",
                sheet: "78gym",
                tablePayload: {
                    header: columns.filter(a => !unSelectedColumns.includes(a.label)).map(a => t(a.label)),
                    body: data,
                },
            });
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            setIsLoading(false)
            alert('Sorry something went wrong')
        }

    }
    return <div className={`absolute flex items-center  ${exportModal ? 'opacity-100 z-[1000]' : 'opacity-0 z-0'} duration-300 transition-all justify-center bg-black/50 w-full h-full  -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`} >
        <div className='flex flex-col p-2 w-[50%] h-auto bg-white rounded-lg' >
            <div className='flex items-center justify-between' >
                <span className='text-lg font-bold' >{t('exportViaExcel')}</span>
                <IoCloseOutline className='cursor-pointer ' onClick={onClickExportExcel} size={30} />
            </div>

            <div className='my-6' >
                <span className='font-normal' >
                    {t('selectColumns')}
                </span>
                <div className='flex flex-wrap' >
                    {columns.map((a, i) => <div key={i} onClick={handleCheckboxChange.bind(this, a.label)} className={`flex items-center justify-center p-2 m-2 text-sm border rounded-lg cursor-pointer ${unSelectedColumns.includes(a.label) ? 'border-gray-500 ' : 'border-green-600'} text-black border-[2px]`} >{t(a.label)}</div>)}
                </div>

            </div>
            <div className='flex items-center justify-center w-full' >
                <div onClick={isLoading ? null : onExport} className='p-2 mb-2 text-xl text-white bg-green-600 border border-none cursor-pointer' >{isLoading ? <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg> : t('exportViaExcel')}</div>
            </div>
        </div>
    </div>
}

export const ColumnsDropdown = ({ columns, language, unSelectedColumns, onChangeUnselectedColumns }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [unSelectedItems, setSelectedItems] = useState([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (item) => {
        const isSelected = unSelectedItems.includes(item);

        if (isSelected) {
            setSelectedItems(unSelectedItems.filter((selectedItem) => selectedItem !== item));
            onChangeUnselectedColumns(unSelectedItems.filter((selectedItem) => selectedItem !== item))
        } else {
            setSelectedItems([...unSelectedItems, item]);
            onChangeUnselectedColumns([...unSelectedItems, item])
        }
    };

    const dropdownItems = columns.map((item) => t(item.label));
    return (
        <div className="relative inline-block text-left">
            <div
                type="button"
                onClick={toggleDropdown}
                className="flex justify-between w-full px-2 py-2 text-sm font-medium text-black bg-white border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
                <span className='mx-2' >{t('columns')}</span>
                <svg
                    className="w-5 h-5 ml-2 -mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="black"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>

            {isOpen && (
                <div className={`absolute ${language === 'en' ? 'left-0' : 'right-0'}  z-50 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5`}>
                    <div className="py-1">
                        {dropdownItems.map((item) => (
                            <label
                                key={item}
                                className="flex items-center p-2 hover:bg-gray-100"
                            >
                                <Checkbox checked={!unSelectedItems.includes(item)} onCheckedChange={() => handleCheckboxChange(item)} id="terms1" />

                                <span className="mx-2 ml-2 text-gray-700">{item}</span>
                            </label>
                        ))}
                    </div>
                </div>
            )}
        </div>)
}

export default CentersViewData
