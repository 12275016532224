import React from 'react'
import NutritionViewData from '../../../components/cafeteria/NutritionViewData'
// import { AddIcon } from '../brands/NutritionBrands'
import { shortIdGenerator } from '../../../customFunction/IDGenerator';
import { useHistory } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';

function CafeteriaStock() {
  const history = useHistory()
  const clickAddIcon = () => {
    let id = shortIdGenerator();

    history.push('/cafeteria-storage/stock/' + id + '?new=true')
  }
  return (
    <div className='flex flex-1 w-full ' >
      <NutritionViewData AddIcon={() => <AddIcon onClick={clickAddIcon} />} />
    </div>
  )
}
export const AddIcon = ({ size, onClick }) => {
  return <div className='cursor-pointer' onClick={onClick} >
    <AiOutlinePlus size={size} />
  </div>
}
export default CafeteriaStock
