import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosAdd, IoIosSave } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Colors from "../../../constant/Colors";
import i18n from "../../../i18n";

function HeaderContainer({
  removeBackBtn,
  addBtn,
  label,
  onPressBtn,
  saveBtn,
  onBackCheck,
  onBackFunction,
  CustomButton,
  onPressCustomButton,
}) {
  const history = useHistory();
  const language = i18n.language
  const onBack = () => {
    if (onBackCheck) {
      const a = window.confirm('please make sure when you back the current user will be deleted')
      if (a) {
        onBackFunction()
      }
      return
    }
    history.goBack()
  };
  return (
    <div className="z-50 flex justify-center mb-9">

      <div className="fixed flex items-center justify-between w-4/5 h-12 bg-mainAqwaBlue border-x">
        <div className="flex flex-row items-center h-full">
          {!removeBackBtn && (
            <button
              onClick={onBack}
              className="flex items-center justify-center h-full px-8 bg-whiteCardIndexColor"
            >
              <AiOutlineArrowLeft color={Colors.mainWhite} size={30} />
            </button>
          )}
          {!!label && (
            <div className="">
              <h1 className="p-2 ml-8 font-bold text-white">{label}</h1>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center h-full" >
          {CustomButton && (<CustomButton onPress={onPressCustomButton} />)}

          {addBtn && (
            <button
              onClick={onPressBtn}
              className="flex items-center justify-center h-full px-8 bg-whiteCardIndexColor"
            >
              <IoIosAdd color="#fff" size={26} />
            </button>
          )}

        </div>
        {saveBtn && (
          <button
            onClick={onPressBtn}
            className="flex items-center justify-center h-full px-8 bg-whiteCardIndexColor"
          >
            <IoIosSave color="#fff" size={26} />
          </button>
        )}
      </div>
    </div>
  );
}

export default HeaderContainer;
