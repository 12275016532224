const ar = {
  somethingWentWrong: "عذرا، هنالك خطأ ما، الرجاء إعادة المحاولة لاحقا",

  login: "تسجيل الدخول",
  loginId: "معرف الدخول",
  password: "كلمة المرور",

  users: "المستخدمون",
  name: "اسم",
  phoneNumber: "رقم التليفون",
  userId: "معرف المستخدم",
  email: "بريد إلكتروني",
  idNumber: "رقم معرف",
  submit: "يُقدِّم",
  createUser: "إنشاء مستخدم",
  registeredAt: "مسجل فى",
  days: "أيام",
  gym: "جیم",
  swim: "السباحة",

  dailyReport: "تقرير يومي",
  advancedDailyReport: "التقرير اليومي المسبق",
  orders: "طلبات",
  admin: "مسؤل",
  logout: "تسجيل خروج",

  admins: "المشرفون",
  center: "القاعە",
  packages: "الحزم",

  id: "ئایدی",
  createdAt: "أنشئت في",
  type: "يكتب",
  features: "سمات",

  duration: "مدة",
  price: "سعر",
  discount: "تخفيض",
  englishName: "الاسم الانجليزي",
  kurdishName: "الاسم الكردي",
  arabicName: "الاسم العربي",
  showType: "نوع العرض",
  firstTime: "اول مرة",
  afterFirstTime: "بعد أول مرة",
  allTime: "كل الوقت",

  owner: "صاحب",
  manager: "مدير",
  trainer: "مدرب",
  reception: "ريسبشن",

  distributionWithTrainer: "التوزيع مع المدرب",
  notDistributionWithTrainer: "عدم التوزيع مع المدرب",

  divideBenefit: "فائدة القسمة",
  requestCourse: "الکورسات",
  cafeteria: "كافيتريا",
  cafeteriaBenefit: "فوائد الكافتيريا",
  expenses: "مصاريف",
  cafeteriaExpenses: "مصاريف الكافتيريا",
  totalCafeteriaExpenses: 'إجمالي مصاريف الكافتيريا',
  gymExpenses: "مصاريف الجيم",
  date: "تاريخ",

  endDate: "تاريخ الانتهاء",

  supplements: "المكملات",

  newUser: "مستخدم جديد",
  renewSubscription: "تجديد الاشتراك",
  membershipBenefit: "مزايا العضوية",
  reportSummary: "ملخص التقرير",
  advancedReportSummary: "ملخص التقرير المتقدم",
  revenue: "إيرادات",
  profit: "ربح",
  membershipSummary: "ملخص العضوية",
  financeSummary: "ملخص التمويل",
  main: "رئيسي",

  numberOrder: "رقم الطلب.",
  dashboard: "داشبورد",

  memberships: "العضويات",
  activeUser: "مستخدم نشط",
  expiredUser: "مستخدم منتهي الصلاحية",
  startDate: "تاريخ البدء",
  start: "يبدأ",
  end: "نهاية",
  day: "يوم",
  username: "اسم المستخدم",
  ticketNumber: "رقم التذكرة",

  offerType: "نوع العرض",
  receptionDivideBenefit: "مبلغ التوزيع مع الإيصال",
  receptionDivideBenefitIQD: "مبلغ التوزيع مع الإيصال بالدينار",
  normal: "طبيعي",
  offer: "يعرض",

  delete: "يمسح",

  undefined: "غير معرف",
  male: "ذكر",
  female: "أنثى",
  gender: "جنس",

  openingTheDoors: "فتح الأبواب",
  door: "باب",
  reason: "سبب",
  employeeComing: "موظف قادم",

  wholesalePrice: "سعر بالجملة",
  minimumSalePrice: "الحد الأدنى لسعر البيع",
  buyPrice: "سعر شراء",

  balance: "الرصيد",

  numberOfGuestUsers: "عدد المستخدمين الضيوف",
  sellPrice: "سعر البيع",
  realPrice: "السعر الحقيقي",

  numberOfProductInStore: "عدد المنتجات في المخزون",

  buy: "يشتري",
  returnOfPurchase: "عودة الشراء",
  image: "صورة",
  select: "يختار",
  sellId: "ئایدی البيع",
  note: "ملحوظة",
  customers: "عملاء",
  debt: "قرض",
  repaymentOfDebt: "سداد الديون",
  sell: "يبيع",
  freeWallet: "محفظة مجانية",
  money: "الفلوس",
  payedPrice: "السعر المدفوع",
  totalPrice: "السعر الكلي",
  productNumber: "رقم المنتج",
  totalPreviousDebts: "إجمالي الديون السابقة",
  totalDebts: "إجمالي الديون",
  quantity: "العدد",
  qty: 'عدد',
  print: "مطبعة",
  equipments: "تجهيزات",
  shippingCars: "شحن سيارات",
  brands: "الماركات",
  totalPayedPrice: "إجمالي السعر المدفوع",
  remainingDebts: "الديون المتبقية",
  brand: "ماركة",
  code: "کود",
  salary: "راتب",
  stock: "مخزون",
  cleaner: "منظف",
  accountant: "محاسب",
  proteinSeller: "بائع البروتين",
  socialMedia: "سوشیال میدیا",
  technical: "تکنیکال",
  expense: "مصروف",
  cafeteriaGuestUser: "مستخدم ضيف الكافتيريا",
  totalSalary: "كامل الراتب",
  garage: "کراج",
  supplementExpenses: "مصاريف المكملات",
  bigExpenses: "مصاريف كبيرة",
  package: "پاکیج",
  insertLogoToImage: "أدخل الشعار إلى الصورة",
  proteinManager: "مدير البروتين",
  equipmentManager: "مدير الجهازات",
  stockBenefitPrice: "قازانجی کۆگا (نرخی تاک)",
  stockBenefitWholePrice: "قازانجی کۆگا (نرخی کۆ)",
  stockBenefitBuyPrice: "کۆی کۆگا (نرخی کڕین)",

  benefitPrice: "قازانج (نرخی تاک)",
  BenefitWholePrice: "قازانج (نرخی کۆ)",

  live: "مباشر",
  purchasesHistory: "تاريخ المشتريات",
  balancesHistory: "تاريخ الرصید",
  usersFeedback: "التقيمات",
  starRate: "النجوم",
  feedback: "تعليق",
  newSubscribers: "المشتركون الجدد",
  renewalOfSubscriptions: "تجديد الاشتراكات",
  dollarPrice: "سعر الدولار",
  proteinStock: 'مخزون البروتين',
  receiptsOfPayment: "إيصالات الدفع",
  retailPrice: 'سعر التجزئة',
  subs: 'الغواصات',
  subject: 'موضوع',
  part: 'جزء',
  recipients: 'المستلمون',
  receipts: 'الإيصالات',
  customs: 'جمارك',
  carNumber: 'رقم السياره',
  cost: 'يكلف',
  expense_1: 'مصروف',
  creatingTransition: 'إنشاء انتقال',
  centers: 'مراكز',
  administration: 'إدارة',
  brandImage: 'صورة الماركة',
  categories: 'فئات',
  jobOpportunities: 'فرص عمل',
  cvs: 'السير الذاتية',
  location: 'موقع',
  position: 'موقع',
  revenueByBenefitPrice: 'الإيرادات حسب (سعر البيع - سعر الشراء)',
  revenueByItems: 'الإيرادات حسب المنتج المباع',
  revenueByBuyPrice: 'الإيرادات حسب المنتج المباع',
  profitWithoutExpenses: 'الإيرادات حسب المنتج المباع',
  publicDebt: 'قرض عام',
  ratio: 'نسبە',
  profitAtRetailPrice: 'الربح بسعر التجزئة',
  profitAtWholeSalePrice: 'الربح بسعر الجملة',
  gymReception: 'جیم ریسیپشن',
  cafeteriaReception: 'کافتیریا ریسیپشن',
  gymCoach: 'مدرب جیم',
  monthlyReport: 'تقرير شهري',
  normalCourseProfit: 'ربح کورس العادية',
  VIPCourseProfit: 'ربح کورس شخصیە',
  cafeteriaProfit: 'ربح كافتيريا',
  publicProfitAfterMamAzad: 'الربح العام بعد مام ازاد',
  publicProfit: 'الربح العام',
  coursesReport: 'تقرير الکورسات',
  save: 'يحفظ',
  loading: 'تحميل',
  equipmentStock: 'مخزون الاجهزه',
  licenses: 'التراخيص',
  'attendance-time': 'وقت الحضور',
  childrenNannies: 'مربيات الأطفال',
  accountantOfAnotherCompany: 'محاسب شركة أخرى',
  asudaCity: 'اسودا ستی',
  'asudaCity$': 'اسودا ستی $',
  paroshTowers: 'پروش تاورز',
  'paroshTowers$': 'پروش تاورز $',
  slavaCity: 'سلافا ستی',
  'slavaCity$': 'سلافا ستی $',
  mamAzad: 'مام ازاد',
  'mamAzad$': 'مام ازاد $',
  miniSlavaMen: 'مینیسلافا رجال',
  'miniSlavaMen$': 'مینیسلافا رجال $',
  miniSlavaWomen: 'مینیسلافا نساء',
  'miniSlavaWomen$': 'مینیسلافا نساء $',
  lifeTowers: 'لایف تاورز',
  'lifeTowers$': 'لایف تاورز $',
  akaFitnessProfit: '٧٨ جیم ربح',
  akaFitnessProfit$: '٧٨ جیم ربح $',
  finalProfit: 'الربح النهائي',
  'price-lists': 'قائمة الأسعار',
  'select-item': 'حدد البند',
  cancel: 'ألغ',
  repaymentOfDebtAkaPower: 'سداد القرض (AkaPower)',
  debtsAkaPower: 'قروض (AkaPower)',
  swimmingItems: 'عناصر السباحة',
  akaFitnessSupplementsProfit: '٧٨ جیم ربح المكملات',
  asudaSupplementsProfit: 'اسودا ربح المكملات',
  finalPrice: 'السعر النهائي',
  totalRepaymentOfDebt: 'إجمالي سداد القرض',
  totalPayedPriceFromSells: 'إجمالي السعر المدفوع من عمليات البيع',
  'revenue(repaymentOfDebt,payedPrice)': 'الإيرادات (سداد القرض ، السعر المدفوع)',
  ratioByDebt: 'النسبة حسب القرض',
  ratioBySupplement: 'النسبة حسب المكملات',
  ratioByMoney: 'النسبة حسب الرصيد',
  ratioType: 'نوع النسبة',

  gyms: 'الجيمات',

  currentMonth: 'الشهر الحالي',
  lastMonthByThisTime: 'الشهر الماضي في هذا الوقت',
  lastMonth: 'الشهر الماضي',
  allOfThem: 'كل منهم 💥',
  trainers: 'المدربون',
  thereIsNoDataToDisplay: 'لا توجد بيانات لعرضها',
  sellSupplements: 'بيع المكملات',
  deleteMembership: 'حذف العضوية',

  'areYouSureYouWantToDeleteThisExpense?': 'هل أنت متأكد أنك تريد حذف هذه النفقات؟',
  deletionOfExpenses: 'شطب المصاريف',
  'areYouSureAboutDeleting?': 'هل أنت متأكد من الحذف؟',
  totalPriceOfRegularCourses: 'السعر الإجمالي للکورسات العادية',
  'totalPriceOfVIPCourses': 'السعر الإجمالي لکورسات شخصیە',
  numOfNormalCourses: 'عدد کورسات العادية',
  numOfVIPCourses: 'عدد کورسات شخصیە',
  'trainerProfitsFromRegularCourses': 'أرباح المدرب من الکورسات العادية',
  'trainerProfitsFromVIPCourses': 'أرباح المدرب من کورسات شخصیە',
  akaFitnessProfitFromRegularCourses: '٧٨ جیم ربح من الکورسات العادية',
  'akaFitnessProfitsFromVIPCourses': '٧٨ جیم ربح من کورسات شخصیە',
  coachGrossProfit: 'الربح الإجمالي للمدرب',
  grossProfitAkaFitness: 'الربح الإجمالي ل٧٨ جیم',
  num: 'رقم.',
  nameOfTheEmployee: 'اسم الموظف',
  fixedSalary: 'راتب ثابت',
  courseRatio: 'نسبة الکورس',
  supplementRatio: 'نسبة المكملات',
  numOfSupplements: 'عدد المكملات',
  totalPriceOfSupplements: 'السعر الإجمالي للمكملات',
  'trainerProfit15%': 'أرباح المدرب 15%',
  theDollarIsGenerallyCalculatedAt: 'يتم حساب الدولار بشكل عام في',
  courseReportSummary: 'ملخص تقرير الکورسات',
  salariesOfEmployees: 'رواتب الموظفين {{price}}',
  summaryOfTheDietarySupplementReport: 'ملخص تقرير المكملات الغذائية',
  cafeteriaSalesReportSummary: 'ملخص تقرير مبيعات الكافتيريا',
  totalOfProductsWereSoldAtPriceOfDinars: 'تم بيع إجمالي {{qty}} منتجًا بالسعر {{price}} دینار',
  salaryNotSpecified: 'الراتب غير محدد',
  dateNotSpecified: 'التاريخ غير محدد',
  salaryByDate: 'الراتب حسب التاريخ',

  increasedExpenses: 'زيادة المصاريف',
  updateOfExpenses: 'تحديث المصاريف',
  byPrice: 'بالسعر',

  courseWriting: 'كتابة الکورس',
  courseUpdate: 'تحديث الکورس',
  changeOfParticipation: 'تغيير المشاركة',

  sellingOfSupplements: 'بيع المكملات',
  updateOfSellingSupplements: 'تحديث بيع المكملات',
  purchaseBalance: 'رصيد الشراء',
  changeOfBalance: 'تغيير الرصيد',
  openingTheDoor: 'فتح الأبواب',
  becauseOf: 'بسبب',
  saleOfCafeteriaProducts: 'بيع منتجات الكافتيريا',

  star: 'نجمة',
  in: 'في',
  for: 'ل',
  total: 'مجموع',
  search: 'بحث',

  numOfMemberships: 'عدد العضويات',
  numOfThreeMonth: 'عدد العضويات لمدة ثلاثة اشهر',
  numOfSixMonth: 'عدد العضويات ستة اشهر',
  totalPriceOfThreeMonths: 'السعر الإجمالي لثلاثة أشهر',
  totalPriceOfSixMonths: 'السعر الإجمالي لستة أشهر',
  receptionRatioOfThreeMonths: 'نسبة الاستقبال لمدة ثلاثة أشهر',
  receptionRatioOfSixMonths: 'نسبة الاستقبال لمدة ستة أشهر',
  akaFitnessDivideBenefitIQD: '٧٨ جیم تقسيم الفائدة IQD',

  receptionReportSummary: 'ملخص تقرير الاستقبال',
  receptionsRatio: 'نسبة الاستقبال',
  'thereWere{{update}}UpdatesAnd{{new}}NewSubscribersInTheReportGenerated': 'كان هناك {{new}} وتحديثًا وتم إنشاء {{update}} مشتركًا جديدًا في التقرير',
  membershipsRatio: 'نسبة العضوية',

  cafeExpense: 'مصاريف الكافتيريا',
  gymExpense: 'مصاريف الجيم',
  bigExpense: 'مصاريف كبيرة',
  by: 'بقرب',
  totalExpenses: 'المصروفات الكلية',
  requests: 'طلبات',
  inProgress: '⛏️ في تقدم',
  pendingRequests: 'طلبات معلقة',
  status: 'حالة',
  pending: 'معلق',
  accepted: 'مقبول',
  rejected: 'مرفوض',
  moreDetails: 'مزيد من التفاصيل',
  request: 'طلب',
  answeredRequests: 'الرد على الطلبات',
  data: 'بيانات',
  viewData: 'عرض البيانات',
  captain: 'کابتن',
  normalCustomer: 'زبون عادي',
  hallOrAgentCustomer: 'زبون قاعة أو وكيل',
  partnerAmount: 'مبلغ الشريك',
  pleaseSelectCustomer: 'الرجاء تحديد العميل',
  supplementsPartner: 'شريك المكملات',
  selectInvoice: 'حدد الفاتورة',
  invoice: 'فاتورة',
  notPaid: 'لم تدفع',
  'paid(uncompleted)': 'مدفوعة (غير مكتملة)',
  'paid(completed)': 'مدفوعة (مكتملة)',
  'nutritionalSupplementReportSummary': 'ملخص تقرير المكملات الغذائية',
  'revenue(repaymentOfDebt,payedPrice)OfPartnerItems': 'الإيرادات (سداد القرض ، السعر المدفوع) من عناصر الشريك',
  'profit(repaymentOfDebt,payedPrice)OfPartnerItems': 'الربح (سداد القرض ، السعر المدفوع) من عناصر الشريك',
  partnerBalance: 'رصيد الشريك',
  'partnerSellDetails': 'في التاريخ المحدد ، كان السعر الإجمالي للمنتجات المشتركة المباعة هو {{totalPrice}} ، كان مبلغ {{paidPrice}} نقدًا ، ومبلغ {{debt}} كان دينًا ، وربح المنتج المُباع (المنتج المُباع نقدًا - سعر الشراء) هو {{profitByBuyPrice}} ، الربح النهائي (الربح - إجمالي المصروفات) هو {{balance}}',
  supplementName: 'اسم المكملات',
  buyReportSummary: 'ملخص تقرير الشراء',
  buySupplementDetails: 'تم إجراء عمليات الشراء بالسعر الإجمالي {{totalPrice}} وشراء {{الكمية}} من المكملات الغذائية المختلفة ، إجمالي عدد المكملات الغذائية المشتراة هو {{totalQuantity}}',
  expenseName: 'اسم المصروفات',
  expenseReportSummary: 'ملخص تقرير المصروفات',
  expensesReportDetails: `لقد تم إنفاقها في إجمالي {{numOfExpense}} ، وهو السعر الإجمالي للمصروفات بعملة {{totalPrice}} دولار`,
  customerName: 'اسم الزبون',
  totalRatio: 'نسبة الإجمالي',
  ratioReportSummary: 'ملخص تقرير النسبة',
  ratioReportDetails: 'تتكون نسبة العملة إلى السعر الإجمالي من {{totalRatio}} المقدمة إلى {{totalCustomers}} عملاء مختلفين',
  ratioForDebt: 'نسبة القرض',
  ratioForSupplement: 'نسبة المكملات',
  ratioForMoney: 'نسبة النقود',
  amountOfRepaymentDebt: 'مبلغ سداد القرض',
  repaymentOfDebtReportSummary: 'ملخص تقرير سداد القرض',
  'thisReportWillBePreparedInTheNext24Hours': 'سيتم إعداد هذا التقرير في الـ 24 ساعة القادمة',
  'thisReportWillBePreparedSoonAsSoon': 'نظرًا لأن هيكل السداد الخاص بنا كان مختلفًا في الماضي ، فنحن بحاجة إلى تغييره وحساب جميع عمليات السداد ، لذلك سيتم إعداد هذا التقرير في أقرب وقت ⚠️',

  'summaryOfTheSoldSupplementsReport': 'ملخص تقرير المكملات المباعة',
  totalSoldQty: 'إجمالي الكمية المباعة',
  totalSoldPrice: 'إجمالي السعر المباع',
  totalProfitPrice: 'إجمالي سعر الربح',
  totalPriceOfCash: 'إجمالي سعر النقد',
  totalPriceOfDebt: 'إجمالي سعر القرض',
  totalPriceOfFree: 'إجمالي سعر الحر',
  totalPriceOfRatio: 'إجمالي سعر النسبة',
  'totalSoldPrice(cash+debt)': 'إجمالي السعر المباع (نقد + دين)',
  'totalProfitPrice(cash)': 'إجمالي سعر الربح (نقد)',
  'totalProfitPrice(OnlyCash)': 'إجمالي سعر الربح (نقد فقط)',
  selectDate: 'حدد التاريخ',
  expiryTodayMemberships: 'عضويات انتهاء الصلاحية اليوم',
  freeSubscriptions: 'الاشتراكات المجانية',
  'partnerNutritionalSupplementReportSummary': 'ملخص تقرير المكملات الغذائية',
  debtsReportSummary: 'ملخص تقرير الائتمان',
  debtReportDetails: 'تتكون يُقرض العملة إلى السعر الإجمالي من {{totalDebt}} المقدمة إلى {{totalCustomers}} عملاء مختلفين',
  productReturnReportSummary: 'ملخص تقرير إرجاع المنتج',
  productReturnReportDetails: `بە کۆی تم إرجاع إجمالي {{totalQty}} من المكملات بسعر إجمالي قدره {{totalPrice}} دولار`,
  sellSupplementsReportSummary: 'ملخص تقرير المبيعات',
  sellSupplementsReportDetails: `تم بيع إجمالي {{totalQty}} من المكملات الغذائية مقابل {{totalPrice}} دولار ، بما في ذلك $ {{totalDebt}} كقروض و $ {{totalPayedPrice}} نقدًا ، السعر الإجمالي للمكملات المجانية كان {{totalFree}} $`,
  freeSupplementsBy: ' ( ملاحق مجانية بواسطة {{totalPrice}} $)',
  totalDebt: 'الدين الكلي',
  'summaryOfTheSoldSupplementsReportDetails': 'إجمالي العائد بسعر الشراء (بما في ذلك العناصر المجانية) {{totalBenefitPriceWithoutMinus}} دولار ، إجمالي الربح {{totalBenefitPrice}} دولار',
  totalBuyPrice: 'السعر الكلي للشراء',
  totalStockSupplements: 'إجمالي مكملات المخزون',
  totalStockPrice: 'إجمالي المخزون المتبقي هو {{totalQty}} بسعر شراء يبلغ {{totalBuyPrice}} دولار ، ومبلغ {{totalIronmaxQty}} من ماركة Iron Max ومبلغ {{totalIronmaxPrice}} هو دولار ، والمبلغ هو {{totalHelltechQty}} من ماركة Helltech بسعر {{totalHelltechPrice}} دولار ، ومبلغ {{totalOtherBrand}} هي إحدى العلامات التجارية الأخرى بسعر {{totalOtherBrandPrice}} دولار',
  'revenue(payedPrice)': 'الإيرادات (السعر المدفوع)',
  'revenue(repaymentOfDebt)': 'الإيرادات (سداد القرض)',
  totalRevenue: 'إجمالي الإيرادات',
  'profit(repaymentOfDebt,payedPrice)': 'الربح (سداد القرض ، السعر المدفوع)',
  'buyPriceXtotalSoldQty': 'سعر الشراء * إجمالي الكمية المباعة',
  'profit(totalSoldPrice-buyPrice*totalSoldQty': 'الربح (إجمالي السعر المباع - سعر الشراء * إجمالي الكمية المباعة)',

  totalMembershipsRevenue: 'إجمالي إيرادات العضويات',
  totalNormalCoursesRevenue: 'إجمالي إيرادات الکورسات العادية',
  totalPTCoursesRevenue: 'إجمالي إيرادات الکورسات شخصیە',
  PTCourseRatio: 'نسبة الکورس الشخصي',
  normalCourseRatio: 'نسبة الکورس العادي',
  totalCafeteriaRevenue: 'إجمالي إيرادات الکافتیریا',
  'totalOtherRevenue(salon,garage,vipLocker)': 'إجمالي الإيرادات الأخرى (صالون ، كراج ، خزانة VIP)',
  totalGymExpenses: 'إجمالي مصاريف الجيم',
  totalBigExpenses: 'إجمالي مصاريف كبيرة',
  totalReceptionRatio: 'إجمالي نسبة الاستقبال',
  totalFixedSalary: 'إجمالي الراتب الثابت',
  totalMembershipsProfit: 'إجمالي ربح العضويات',
  totalCoursesProfit: 'إجمالي ربح الکورسات',
  totalCafeteriaProfit: 'إجمالي ربح الکافتیریا',
  totalProfit: 'إجمالي الربح',
  totalPartnerProfit: 'إجمالي ربح الشريك',
  totalAkaFitnessProfit: 'إجمالي ربح ٧٨ جیم',
  settings: 'إعدادات',
  courses: 'الکورسات',

  'fromSelectedRangeLastMonth': 'من النطاق المحدد في الشهر الماضي',

  overview: 'نظرة عامة',
  totalRevenueAkaPower: 'إجمالي الإيرادات (AkaPower)',
  totalExpensesAkaPower: 'إجمالي المصروفات (AkaPower)',
  totalProfitAkaPower: 'إجمالي الربح (AkaPower)',
  totalProfitAkaNutrition: 'إجمالي الربح (AkaNutrition)',
  totalProfitAkaFitness: 'إجمالي الربح (AkaFitness)',
  totalEmployees: 'إجمالي الموظفين',
  totalActiveSubscribersAkaFitness: 'إجمالي المشتركين النشطين (AkaFitness)',
  expire: 'ينتهي',
  new: 'جديد',
  renew: 'يجدد',

  akaFitness: '٧٨ جیم',
  akaNutrition: 'ئاکا نیوتریشن',
  akaPower: 'ئاکا باور',
  akaEquipment: 'ئاکا ئیکویپمێنت',

  packageName: 'اسم الباقة',
  receptionName: 'اسم الاستقبال',
  expiryDate: 'تاريخ الانتهاء',

  cash: 'نقد',
  vipLocker: 'خزانة VIP',
  salon: 'صالون',
  cafeCard: 'بطاقة الكافتيريا',

  totalCourseProfit: 'إجمالي ربح الکورسات',
  totalSupplementProfit: 'إجمالي ربح المكملات',
  activeUsers: 'المستخدمون النشطون',

  'new,renew,expireSubscription': 'الاشتراكات الجديدة والمجددة والمنتهية',
  'male&female': 'ذكر وأنثى',
  cafeteriaAnalysis: "تحليل الكافتيريا",
  totalPriceOfCard: "سعر البطاقة",
  totalPriceOfBalance: "سعر الرصيد",

  calculateSalaries: "حساب الرواتب",
  calculateSalariesDesc: "سيتم توفير حسابات الرواتب قريبا",

  totalSalaries: "إجمالي الرواتب",
  directDollarExchangeRateLinkedToTheKurdistanStockExchange: "معدل صرف الدولار المباشر المرتبط ببورصة كردستان",
  language: 'لغة',
  selectTheLanguageOfThePlatform: 'حدد لغة المنصة',
  profile: 'الملف الشخصي',
  appearance: 'مظهر',
  other: 'آخر',
  beta: 'بيتا',
  next: 'التالى',
  previous: 'سابق',
  salaryListOf: 'قائمة رواتب {{name}}',
  totalOtherRevenueProfit: 'إجمالي أرباح الإيرادات الأخرى',
  nutrition: 'تغذية',

  columns: 'أعمدة',
  deleteUserWarning: "يرجى توخي الحذر عند حذف مستخدم لأنه سيؤدي إلى حذف جميع البيانات المتعلقة بالمستخدم ولن تكون قابلة للاسترداد˝",
  "physicalTherapy": "العلاج الطبيعي",
  "physicalTherapyRevenue": "إيراد العلاج الطبيعي",
  "physicalTherapyProfit": "ربح العلاج الطبيعي",
  aFullHourPhysicalTherapySessionForPartBody: "جلسة علاج طبيعي لمدة ساعة كاملة لجزء من الجسم",
  fullBodyMessageOneHour: "جلسة كاملة للجسم لمدة ساعة",
  fourPhysicalTherapy: "أربع جلسات علاج طبيعي",
  amountOfPaymentByCustomer: 'مبلغ الدفع من العميل',
  payedType: 'نوع الدفع',
  changeType: 'تغيير النوع',
  changePrice: 'تغيير السعر',
  notes: "ملحوظة:",
  changeMembershipNotes_1: '1. عند التبديل من باقة إلى أخرى، يتم تغيير تاريخ المستخدم مباشرة.',
  changeMembershipNotes_2: '2. عند تغيير الباقات إذا كان السعر مختلفا يرجى التأكد من إدخال السعر الصحيح الذي قدمه المشتري بالدينار أو الدولار.',
  amountByCustomer: 'المبلغ من العميل',
  "areYouSure": "هل أنت متأكد؟",
  "deleteMembershipWarning": "في حالة حذف العضوية سيتم حذف بيانات المستخدم وسيعود المستخدم إلى حالة العضوية السابقة إذا لم يكن لدى المستخدم أي عضوية أخرى سيتم حذفه",
  membership: "عضوية",
  limitationByTime: "الحد الزمني",
  deletePackage: "حذف الباقة",
  exportViaExcel: "تصدير عبر Excel",
  selectColumns: "حدد الأعمدة",
  filter: "فلتر",
  changeCourseTypesNote:`ملاحظة: "تحتاج فقط إلى إدخال السعر بالدولار أو الإدخال بالدينار ولا تحتاج إلى إدخالهما معًا`,
  addExpenseNote_1: "عند إضافة المصاريف من هنا سيتم إضافتها إلى مصاريف اليوم المحدد، لحذف أو تعديل المصاريف يمكنك الذهاب إلى مصاريف اليوم وتعديلها أو حذفها",
  amountOfExpense: "مبلغ المصروف",
  employee: "موظف",
  service: "خدمة",
  storage: "تخزين",
  "return-items": "إرجاع العناصر",
  productName: "اسم المنتج",
  imageUri: "رابط الصورة",
  swimmingStorage: "تخزين السباحة",
  items: "العناصر",
  receipt: "إيصال",
  usd: "دولار",
  dinar: "دينار",
  "pleaseMakeSureThisAction": "يرجى التأكد من هذا الإجراء",
  "thisActionCannotBeUndone_swimming": "لا يمكن التراجع عن هذا الإجراء. سيؤدي هذا إلى حذف العنصر الذي يسبح بشكل دائم، وستعود الكمية الكاملة لهذا العنصر إلى التخزين.",
  enName: "الاسم الإنجليزي",
  kuName: "الاسم الكوردي",
  arName: "الاسم العربي",
  vipCourseRatio: "نسبە الکورس شخصیە",
  supplementsRevenue: "إيراد المكملات",
  swimmingItemsRevenue: "إيراد السباحة",
  totalTrainerRatioOfSupplements: "إجمالي نسبة المدرب من المكملات",
  supplementsProfit: "ربح المكملات",
  totalSwimmingItemsRevenue: "إجمالي إيرادات السباحة",
  totalSupplementsProfit: "إجمالي ربح المكملات",
  totalPhysicalTherapyProfit: "إجمالي ربح العلاج الطبيعي",
  captainName: "اسم الكابتن",
  brandName: "اسم الماركة",
  brandLogo: "لوکو الماركة",
  nutritionStorage: "تخزين التغذية",
  "nutrition-items": "عناصر التغذية",
  deleteItemDesc: "إذا قمت بحذف عنصر المخزون، فسيتم حذف العنصر ولن يظهر على النظام ولن يكون قابلاً للاسترداد",
  thisActionCannotBeUndone_storage: "لا يمكن رفض هذا الإجراء، وسيتم إرجاع إجمالي الكمية غير المباعة المتبقية إلى المخزن.",
  swimmingItemsProfit: "ربح من أدوات السباحة",
  expensesByCategory: "النفقات حسب الفئة",
  category: "الفئة",
  totalIqd: "النفقات بالدينار",
  totalUsd: "النفقات بالدولار",
  expenseCategory: "فئة النفقات",
  salaryUsd: "رواتب بالدولار",
  salaryUSD: "رواتب بالدولار"


};

export default ar