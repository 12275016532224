import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import LoginScreen from "../screens/Auth/LoginScreen";
import AdminScreen from "../screens/Admin/AdminScreen";
import PackagesScreen from "../screens/Admin/Packages/PackagesScreen";
import PackageDetailsScreen from "../screens/Admin/Packages/PackageDetailsScreen";
import AdminsScreen from "../screens/Admin/Admin/AdminsScreen";
import AdminDetailsScreen from "../screens/Admin/Admin/AdminDetailsScreen";
import CenterScreen from "../screens/Admin/Center/CenterScreen";
import CenterDetailsScreen from "../screens/Admin/Center/CenterDetailsScreen";
import Spinner from "../components/utils/Spinner";
import { fetchCenters } from "../store/actions/centersAction";
import { fetchPackages } from "../store/actions/packagesAction";
import SideBar from "../screens/SideBar/SideBar";
import CenterAnalyticsScreen from "../screens/Centers/CenterAnalyticsScreen";
import ViewAllDataScreen from "../screens/Centers/ViewAllDataScreen";
import TotalAnalyticsScreen from "../screens/Centers/TotalAnalyticsScreen";
import CreateUser from "../screens/Users/CreateUserScreen";
import MembershipScreen from "../screens/Users/MembershipsScreen";
import BalancesScreen from "../screens/Admin/Balance/BalancesScreen";
import BalanceDetailsScreen from "../screens/Admin/Balance/BalanceDetailsScreen";
import MembershipDetailScreen from "../screens/Users/MembershipDetailScreen";
import CaffetteriaScreen from "../screens/Admin/Caffetteria/CaffetteriaScreen";
import CaffetteriaDetailsScreen from "../screens/Admin/Caffetteria/CaffetteriaDetailsScreen";
import SellSupplementsScreen from "../screens/Centers/SellSupplementsScreen";
import { LOGOUT } from "../store/actions/authenticationAction";

import SalaryScreen from "../screens/Centers/SalaryScreen";
import BigExpensesScreen from "../screens/Admin/BigExpenses/BigExpensesScreen";
import BigExpensesDetailsScreen from "../screens/Admin/BigExpenses/BigExpensesDetailsScreen";
import SupplementOrderDetails from "../screens/Centers/SupplementOrderDetails";
import CafeOrderScreen from "../screens/Centers/CafeOrderScreen";
import CourseDetailsScreen from "../screens/Centers/CourseDetailsScreen";
import PurchasesScreen from "../screens/Users/PurchasesScreen";
import PurchaseDetailsScreen from "../screens/Users/PurchaseDetailsScreen";
import UserBalancesHistory from "../screens/Users/UserBalancesHistory";
import UsersFeedbackScreen from "../screens/UsersFeedback/UsersFeedbackScreen";
import StorageActivityScreen from "../screens/Centers/StorageActivityScreen";
import DollarPricesScreen from "../screens/Admin/DollarPrice/DollarPricesScreen";
import DollarPriceDetailsScreen from "../screens/Admin/DollarPrice/DollarPriceDetailsScreen";
import { fetchDollarPrices } from "../store/actions/dollarPricesAction";
import MonthlyReportScreen from "../screens/Centers/MonthlyReportScreen";
import { useState } from "react";
import Colors from "../constant/Colors";
import i18next from "i18next";
import CourseTypesScreen from "../screens/Admin/CourseTypes/CourseTypesScreen";
import CourseTypesDetailsScreen from "../screens/Admin/CourseTypes/CourseTypesDetailsScreen";
import RequestsScreen from "../screens/Requests/RequestsScreen";
import { fetchRequests } from "../store/actions/requestsAction";
import i18n from "../i18n";
import CentersViewData from "../screens/UI/CentersViewData";
import ClassSchedules from "../screens/Admin/ClassSchedule/ClassSchedules";
import ClassSchedulesDetails from "../screens/Admin/ClassSchedule/ClassSchedulesDetails";
import NotificationsScreen from "../screens/Admin/Notifications/NotificationsScreen";
import NotificationDetailsScreen from "../screens/Admin/Notifications/NotificationDetailsScreen";
import CafeteriaStorage from "../screens/CafeteriaStorage/CafeteriaStorage";
import CafeteriaStock from "../screens/CafeteriaStorage/stock/CafeteriaStock";
import CafeteriaStockDetails from "../screens/CafeteriaStorage/stock/CafeteriaStockDetails";
import CafeteriaPurchases from "../screens/CafeteriaStorage/purchases/CafeteriaPurchases";
import CafeteriaPurchasesDetails from "../screens/CafeteriaStorage/purchases/CafeteriaPurchasesDetails";
import CafeteriaAdvanceDashboard from "../screens/CafeteriaStorage/dashboard/CafeteriaAdvanceDashboard";
import NutritionScreen from "../screens/Nutrition/NutritionScreen";
import NutritionStock from "../screens/Nutrition/stock/NutritionStock";
import NutritionStockDetails from "../screens/Nutrition/stock/NutritionStockDetails";
import NutritionBrands from "../screens/Nutrition/brands/NutritionBrands";
import NutritionBrandDetails from "../screens/Nutrition/brands/NutritionBrandDetails";


import CafeteriaBrands from "../screens/CafeteriaStorage/brands/CafeteriaBrands";
import CafeteriaBrandDetails from "../screens/CafeteriaStorage/brands/CafeteriaBrandDetails";
import CafeteriaExpiredProducts from "../screens/CafeteriaStorage/expiredProducts/CafeteriaExpiredProducts";
import CafeteriaExpiredProductsDetails from "../screens/CafeteriaStorage/expiredProducts/CafeteriaExpiredProductsDetails";
import SwimmingStorageScreen from "../screens/SwimmingStorage/SwimmingStorageScreen";
import SwimmingStock from "../screens/SwimmingStorage/stock/SwimmingStock";
import SwimmingStockDetails from "../screens/SwimmingStorage/stock/SwimmingStockDetails";

import Home from "../screens/Home/Home";
import PhysicalTherapyScreen from "../screens/Admin/PhysicalTherapy/PhysicalTherapyScreen";
import PhysicalTherapyDetailsScreen from "../screens/Admin/PhysicalTherapy/PhysicalTherapyDetailsScreen";
import PrivateRoute from "./PrivateRoute";
import SwimmingStorage from "../screens/SwimmingStorage/storage/SwimmingStorage";
import SwimmingStorageDetails from "../screens/SwimmingStorage/storage/SwimmingStorageDetails";
import SwimmingViewInvoice from "../screens/SwimmingStorage/storage/SwimmingViewInvoice";
import ReturnSwimmingItems from "../screens/SwimmingStorage/return-items/ReturnSwimmingItems";
import ReturnItemsDetails from "../screens/SwimmingStorage/return-items/ReturnItemsDetails";
import SwimmingReturnsViewInvoice from "../screens/SwimmingStorage/return-items/SwimmingReturnsViewInvoice";
import SwimmingItemDetails from "../screens/Centers/SwimmingItemDetails";
import NutritionStorage from "../screens/Nutrition/storage/NutritionStorage";
import NutritionStorageDetails from "../screens/Nutrition/storage/NutritionStorageDetails";
import NutritionViewInvoice from "../screens/Nutrition/storage/NutritionViewInvoice";
import ReturnNutritionItems from "../screens/Nutrition/return-items/ReturnNutritionItems";
import NutritionItemDetails from "../screens/Centers/NutritionItemDetails";
import ReturnNutritionItemsDetails from "../screens/Nutrition/return-items/ReturnItemsDetails";
import NutritionReturnsViewInvoice from "../screens/Nutrition/return-items/NutritionReturnsViewInvoice";

export const LANGUAGES = [{ id: 0, label: 'English', lang: 'en' }, { id: 1, label: 'کوردی', lang: 'ku' }, { id: 2, label: 'عربی', lang: 'ar' }]

const Routess = (props) => {
  let User = useSelector((state) => state.authentication.user);
  const lodaing = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [isSideBarHide, setIsSideBarHide] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState((localStorage.getItem('language') || 'en'))//! *1 change to number 🦦
  const language = i18n.language
  // console.log(localStorage.getItem('language'))
  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    checkSession();
  };

  const checkSession = () => {
    let token = localStorage.getItem("Auth");
    if (!token) return;
    token = JSON.parse(token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    dispatch({
      type: "LOGIN",
      data: token.user,
    });
  };

  useEffect(() => {
    if (User) {
      try {
        fetchDataByUserType();
      } catch (e) {
        console.log(e);
      }
    }
  }, [User]);

  const fetchDataByUserType = async () => {
    try {
      // handleLoading();
      fetchDollarPrices()
      if (
        User.type === 0 ||
        User.type === 7 ||
        User.type === 12 ||
        User.type === 13 ||
        User.type === 17 ||
        User.type === 19
      ) {
        await fetchAdminData();
      }
    } catch (e) {
      console.log(e);
      if (e.response.status === 401) {
        alert("Session Expired");
      } else alert("something went wrong please try again later");

      LOGOUT();
    }
  };

  const onChangeLanguage = (e) => {
    setCurrentLanguage(e.target.value)
    localStorage.setItem('language', e.target.value)
    i18next.changeLanguage(e.target.value)
  }

  useEffect(() => {
    localStorage.setItem('isSideBarHide', "false")

    const handleStorageChange = (event) => {
      if (localStorage.getItem("isSideBarHide") === 'true') {
        setIsSideBarHide(true)
      } else {
        setIsSideBarHide(false)
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchAdminData = async () => {
    fetchRequests();
    fetchCenters();
    fetchPackages();
  };

  return (
    <Router >

      {lodaing && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
            position: "fixed",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(22,29,29,0.8)",
          }}
        >
          <Spinner color={Colors.mainGreenColor} />
        </div>
      )}
      {/* {!lodaing && !User && <Redirect to="/login" />} */}
      {!lodaing && !User && <Route exact path="/">
        <Home />
      </Route>}
      {!lodaing && !User && <Route exact path="/login">
        <LoginScreen />
      </Route>}
      {!lodaing && User && (
        <div style={{ fontFamily: language === 'en' ? 'Montserrat' : 'Rudaw-Regular' }} className="flex max-sm:flex-col ">
          {<SideBar onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />}
          <Switch  >
            <Route exact path="/admin">
              <AdminScreen />
            </Route>
            <Route exact path="/centers/:centerId">
              <CenterAnalyticsScreen />
            </Route>
            <Route exact path="/centers/:centerId/salary">
              <SalaryScreen />
            </Route>
            <Route exact path="/centers/:centerId/viewAll">
              <ViewAllDataScreen />
            </Route>
            {/* //!rewrite process */}
            <Route exact path="/centers/:centerId/view-data/:viewId">
              <CentersViewData />
            </Route>
            {/* //!users And Memberships*/}
            <Route exact path="/centers/:centerId/view-data/:viewId/users/:id">
              <CreateUser />
            </Route>
            <Route exact path="/centers/:centerId/users/:id">
              <CreateUser />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/users/:id/purchases">
              <PurchasesScreen />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/users/:id/balances">
              <UserBalancesHistory />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/users/:id/purchases/:purchaseId">
              <PurchaseDetailsScreen />
            </Route>
            <Route
              exact
              path="/centers/:centerId/view-data/:viewId/users/:id/memberships"
            >
              <MembershipScreen />
            </Route>
            <Route
              exact
              path="/centers/:centerId/view-data/:viewId/users/:id/memberships/:membershipId"
            >
              <MembershipDetailScreen />
            </Route>

            {/* //!cafeteria */}
            <Route exact path="/centers/:centerId/cafeteria/:id">
              <CafeOrderScreen />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/cafeteria/:id">
              <CafeOrderScreen />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/physical-therapy/:id">
              <CafeOrderScreen />
            </Route>

            {/* //!course*/}
            <Route exact path="/centers/:centerId/view-data/:viewId/courses/:id">
              <CourseDetailsScreen />
            </Route>
            <Route exact path="/centers/:centerId/courses/:id">
              <CourseDetailsScreen />
            </Route>

            {/* //!expenses */}
            <Route exact path="/centers/:centerId/view-data/:viewId/expenses/:id">
              <BigExpensesDetailsScreen />
            </Route>
            <Route exact path="/centers/:centerId/expenses/:id">
              <BigExpensesDetailsScreen />
            </Route>

            <Route
              exact
              path="/centers/:centerId/view-data/:viewId/supplements/:suppId"
            >
              <SupplementOrderDetails />
            </Route>


            <Route exact path="/centers/:centerId/sellSupplements">
              <SellSupplementsScreen />
            </Route>
            <Route exact path="/centers/:centerId/sellSupplements/storage-activity">
              <StorageActivityScreen />
            </Route>
            <Route exact path="/centers/:centerId/monthly-report">
              <MonthlyReportScreen />
            </Route>

            <Route exact path="/centers/total/all">
              <TotalAnalyticsScreen />
            </Route>
            <Route exact path="/centers/total/all/monthly-report">
              <MonthlyReportScreen />
            </Route>
            <Route exact path="/centers/total/all/viewAll">
              <ViewAllDataScreen />
            </Route>
            <PrivateRoute exact path="/admin/balance" component={BalancesScreen} allowedRoles={[0]} />
            <PrivateRoute exact path="/admin/balance/:id" component={BalanceDetailsScreen} allowedRoles={[0]} />
           
            <PrivateRoute exact path="/admin/physical-therapy" component={PhysicalTherapyScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/physical-therapy/:id" component={PhysicalTherapyDetailsScreen} allowedRoles={[0]} />
            
            <PrivateRoute exact path="/admin/bigExpenses/:id" component={BigExpensesDetailsScreen} allowedRoles={[0,7]} />

            <PrivateRoute exact path="/admin/course-types" component={CourseTypesScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/course-types/:id" component={CourseTypesDetailsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/caffetteriaItem" component={CaffetteriaScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/caffetteriaItem/:id" component={CaffetteriaDetailsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/packages" component={PackagesScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/packages/:id" component={PackageDetailsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/admins" component={AdminsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/admins/:adminId" component={AdminDetailsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/center" component={CenterScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/center/:id" component={CenterDetailsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/dollar-price" component={DollarPricesScreen} allowedRoles={[0,7]} />

            <PrivateRoute exact path="/admin/dollar-price/:id" component={DollarPriceDetailsScreen} allowedRoles={[0,7]} />

            <PrivateRoute exact path="/admin/class-schedule" component={ClassSchedules} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/class-schedule/:classId" component={ClassSchedulesDetails} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/notifications" component={NotificationsScreen} allowedRoles={[0]} />

            <PrivateRoute exact path="/admin/notifications/:notificationId" component={NotificationDetailsScreen} allowedRoles={[0]} />
            
            <Route exact path="/users-feedback">
              <UsersFeedbackScreen />
            </Route>

            <Route exact path="/requests">
              <RequestsScreen />
            </Route>
            
            {/* //!Nutrition */}
            <Route exact path="/nutrition-storage">
              <NutritionScreen />
            </Route>
            <Route exact path="/nutrition-storage/stock">
              <NutritionStock />
            </Route>
            <Route exact path="/nutrition-storage/stock/:productId">
              <NutritionStockDetails />
            </Route>
            <Route exact path="/nutrition-storage/brands">
              <NutritionBrands />
            </Route>
            <Route exact path="/nutrition-storage/brands/:brandId">
              <NutritionBrandDetails />
            </Route>

            <Route exact path='/nutrition-storage/storage'>
              <NutritionStorage />
            </Route>
            <Route exact path='/nutrition-storage/storage/:id'>
              <NutritionStorageDetails />
            </Route>
            <Route exact path='/nutrition-storage/storage/view/:id'>
              <NutritionViewInvoice />
            </Route>
            <Route exact path='/nutrition-storage/return-items'>
              <ReturnNutritionItems />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/nutrition-items/:id">
              <NutritionItemDetails />
            </Route>
            <Route exact path='/nutrition-storage/return-items/:id'>
              <ReturnNutritionItemsDetails />
            </Route>
            
            <Route exact path='/nutrition-storage/return-items/view/:id'>
              <NutritionReturnsViewInvoice />
            </Route>

            {/* //!Swimming Storage */}
            <Route exact path="/swimming-storage">
              <SwimmingStorageScreen />
            </Route>
            <Route exact path="/swimming-storage/stock">
              <SwimmingStock />
            </Route>
            <Route exact path="/swimming-storage/stock/:productId">
              <SwimmingStockDetails />
            </Route>
            <Route exact path='/swimming-storage/storage'>
              <SwimmingStorage />
            </Route>
            <Route exact path='/swimming-storage/storage/:id'>
              <SwimmingStorageDetails />
            </Route>
            <Route exact path='/swimming-storage/storage/view/:id'>
              <SwimmingViewInvoice />
            </Route>
            <Route exact path='/swimming-storage/return-items'>
              <ReturnSwimmingItems />
            </Route>
            <Route exact path="/centers/:centerId/view-data/:viewId/swimming-items/:id">
              <SwimmingItemDetails />
            </Route>
            <Route exact path='/swimming-storage/return-items/:id'>
              <ReturnItemsDetails />
            </Route>
            
            <Route exact path='/swimming-storage/return-items/view/:id'>
              <SwimmingReturnsViewInvoice />
            </Route>
            {/* cafeteria storage */}
            <Route exact path="/cafeteria-storage">
              <CafeteriaStorage />
            </Route>
            <Route exact path="/cafeteria-storage/stock">
              <CafeteriaStock />
            </Route>
            <Route exact path="/cafeteria-storage/stock/:productId">
              <CafeteriaStockDetails />
            </Route>
            <Route exact path="/cafeteria-storage/purchases">
              <CafeteriaPurchases />
            </Route>
            <Route exact path="/cafeteria-storage/purchases/:purchaseId">
              <CafeteriaPurchasesDetails />
            </Route>
            <Route exact path="/cafeteria-storage/dashboard/advance">
              <CafeteriaAdvanceDashboard />
            </Route>
            <Route exact path="/cafeteria-storage/brands">
              <CafeteriaBrands />
            </Route>
            <Route exact path="/cafeteria-storage/brands/:brandId">
              <CafeteriaBrandDetails />
            </Route>
            <Route exact path="/cafeteria-storage/expired-products">
              <CafeteriaExpiredProducts />
            </Route>
            <Route exact path="/cafeteria-storage/expired-products/:expireId">
              <CafeteriaExpiredProductsDetails />
            </Route>

            <Route path="*">
              <Redirect to="/centers/62d3de07e4b5f62ba339b22b" />
            </Route>

          </Switch>
        </div>
      )}
    </Router >
  );
};

export default Routess;
