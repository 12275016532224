import { FaDharmachakra, FaSwimmingPool } from "react-icons/fa";
import { GiPillDrop } from "react-icons/gi";
import { MdAnalytics, MdOutlineFeedback } from "react-icons/md";
import { TbLivePhoto } from "react-icons/tb";
import { MdAdminPanelSettings } from "react-icons/md";
import { CgGym, CgPill } from "react-icons/cg";
import { VscGitPullRequest } from "react-icons/vsc";

import Colors from "../../constant/Colors";
import { FiSend } from "react-icons/fi";
import { Badge } from "../../ui/ui/badge";
import { t } from "i18next";
import { IoCafeOutline } from "react-icons/io5";

// statusType 0 finish, 1 in progress, 2 new
const bars = [
  {
    display: "centers",
    icon: <CgGym size={20} color={Colors.mainWhite} />,
    to: `/centers/62d3de07e4b5f62ba339b22b`,
    section: "centers",
    type: [0, 7, 12, 13, 17, 19],
  },
  {
    display: "requests",
    icon: <FiSend
      color={Colors.mainWhite}
      size={20}
    />,
    to: `/requests`,
    section: "requests",
    type: [0, 7, 13, 19],
    statusType: 1,
    hideIconOnSmallScreen: false

  },
  // {
  //   display: "administration",
  //   icon: <MdAdminPanelSettings size={25} color={Colors.mainWhite} />,
  //   to: `/administration`,
  //   section: "administration",
  //   type: [0, 7, 12, 13, 19],
  // },
  // {
  //   display: "dashboard",
  //   icon: <MdAnalytics size={25} color={Colors.mainWhite} />,
  //   to: `/dashboard`,
  //   section: "dashboard",
  //   type: [0, 7, 12, 13, 19],
  //   statusType: 1,
  //   // badgeIcon: (label) => <Badge className='text-[11px] text-yellow-400 border-yellow-300' variant="outline">{label}</Badge>,
  //   hideIconOnSmallScreen: true
  // },
  {
    display: "usersFeedback",
    icon: <MdOutlineFeedback size={25} color={Colors.mainWhite} />,
    to: `/users-feedback`,
    section: "users-feedback",
    type: [0, 7, 12, 13, 19],
  },
  {
    display: "admin",
    icon: <MdAdminPanelSettings size={25} color={Colors.mainWhite} />,
    to: `/admin`,
    section: "admin",
    type: [0, 7, 13],
  },
  {
    display: "cafeteria",
    icon: <IoCafeOutline size={25} color={Colors.mainWhite} />,
    to: `/cafeteria-storage`,
    section: "cafeteria-storage",
    type: [0, 7, 13],
  },
  {
    display: "nutrition",
    icon: <CgPill size={24} color={Colors.mainWhite} />,
    to: `/nutrition-storage`,
    section: "nutrition",
    type: [0,7],
  },
  {
    display: "Swimming Storage",
    icon: <FaSwimmingPool size={24} color={Colors.mainWhite} />,
    to: `/swimming-storage`,
    section: "swimming-storage",
    type: [0,7],
  },
  // {
  //   display: "Swimming Pool",
  //   icon: <CgPill size={24} color={Colors.mainWhite} />,
  //   to: `/swimming-pool`,
  //   section: "swimming-pool",
  //   type: [0,13],
  // },
  // {
  //   display: "Sport Wears",
  //   icon: <CgPill size={24} color={Colors.mainWhite} />,
  //   to: `/sport-wears`,
  //   section: "sport-wears",
  //   type: [0,13],
  // },
];

export default bars;