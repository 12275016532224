import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import ShowPaginationData from '../../../components/UI/TableView/ShowPaginationData';
import { IoAdd } from 'react-icons/io5';

function NutritionBrands() {
  const { columns, id, label, url, href } = {
    id: 'brands', label: 'brands',
    href: '/brands',
    columns: [
      { label: "#", value: (a) => a._id.slice(0, 10) },
      { label: "brandName", value: (a) => a.name },
      {
        label: "brandLogo",
        value: (a) => (
          <img src={a.brandLogo} alt='' className='object-cover w-[100px]' />
        ),
      },
      { label: "date", value: (a) => a.date },
    ],
    url: (centerId) => '/nutrition-brands?' + centerId,
  }

  const history = useHistory();

  const onClickItem = (_id) => {
    history.push('/nutrition-storage/brands/' + _id)
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData hrefId={'_id'} onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
          <IoAdd size={40} onClick={() => history.push('/nutrition-storage/brands/0?new=true')} />
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  );
}

export default NutritionBrands
