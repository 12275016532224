import React, { useState } from "react";
import useSwr from "swr";
import { Error, Loading, fetcher } from "../../screens/UI/CentersViewData";
import { Suspense } from "react";
import { t } from "i18next";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";

function NutritionCreateInvoice({
  onClickSubmit,
  showCustomerModal,
  children,
  showPayedPrice,
  id,
}) {
  const { data, isLoading, error } = useSwr("/cafeteria-stocks", fetcher);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [payedPriceModal, setPayedPriceModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const onClickModal = () => {
    setModal(!modal);
  };
  const onChangeSelectedProduct = (product, priceType) => {
    //! check if product is already in selectedProduct remove it else add it
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (isProductExist) {
      setSelectedProducts((prev) => prev.filter((a) => a._id !== product._id));
    } else {
      const newProduct = {
        _id: product._id,
        quantity: 1,
        price: product.prices[priceType],
        name: product.name,
      };
      setSelectedProducts((prev) => [...prev, newProduct]);
    }
  };

  const onChangeQuantity = (product, quantity) => {
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (isProductExist) {
      //!remove product when quantity is 0
      if (quantity === 0) {
        setSelectedProducts((prev) =>
          prev.filter((a) => a._id !== product._id)
        );
        return;
      }
      setSelectedProducts((prev) =>
        prev.map((a) => {
          if (a._id === product._id) {
            return { ...a, quantity };
          }
          return a;
        })
      );
    } else {
      setSelectedProducts((prev) => [...prev, { ...product, quantity }]);
    }
  };

  const onChangeExpiryOrCode = (product, key, value) => {
    setSelectedProducts((prev) =>
      prev.map((a) => {
        if (a._id === product._id) {
          return { ...a, [key]: value };
        }
        return a;
      })
    );
  };

  const onSubmit = () => {
    if (
      selectedProducts.length === 0 ||
      (showCustomerModal ? currentCustomer === null : false)
    ) {
      alert("please select products and customer");
      return;
    }
    if (showPayedPrice) {
      setPayedPriceModal(true);
      return;
    }
    onClickSubmit(selectedProducts, currentCustomer);
  };
  // console.log(currentCustomer);
  const onChangeModal = () => {
    setModal(!modal);
  };
  // console.log(first)
  const onChangePrice = (product, price) => {
    const isProductExist = selectedProducts.find((a) => a._id === product._id);
    if (isProductExist) {
      setSelectedProducts((prev) =>
        prev.map((a) => {
          if (a._id === product._id) {
            return { ...a, price };
          }
          return a;
        })
      );
    } else {
      setSelectedProducts((prev) => [...prev, { ...product, price }]);
    }
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  return (
    <div className='flex flex-1 overflow-x-hidden bg-[#f1f1f1] '>
      {modal && (
        <NutritionCustomerModal
          onSelectCustomer={(a) => {
            setCurrentCustomer(a);
            setModal(false);
          }}
          currentCustomer={currentCustomer}
          onChangeModal={onChangeModal}
        />
      )}
      {payedPriceModal && (
        <PayedPriceModal
          items={selectedProducts}
          onChangeModal={() => setPayedPriceModal(false)}
          onFinalSubmit={(payedPrice) => {
            setPayedPriceModal(false);
            onClickSubmit(selectedProducts, currentCustomer, payedPrice);
          }}
        />
      )}
      <Suspense fallback={<Loading />}>
        <div
          className={`flex w-full ${
            id === "ratio" ? "overflow-auto" : "overflow-hidden"
          } p-7`}>
          <ProductItems
            id={id}
            selectedProducts={selectedProducts}
            onChangeSelectedProduct={onChangeSelectedProduct}
            products={data}
            onChangePrice={onChangePrice}
            onChangeQuantity={onChangeQuantity}
          />
          <Menu
            currentCustomer={currentCustomer}
            onClickModal={onClickModal}
            showCustomerModal={showCustomerModal}
            onSubmit={onSubmit}
            id={id}
            onChangeExpiryOrCode={onChangeExpiryOrCode}
            selectedProducts={selectedProducts}
          />
        </div>
      </Suspense>
    </div>
  );
}

const ProductItems = ({
  products,
  selectedProducts,
  onChangeSelectedProduct,
  onChangeQuantity,
  onChangePrice,
  id,
}) => {
  const [prds, setPrds] = useState(products);
  const [search, setSearch] = useState("");
  const [priceType, setPriceType] = useState("singlePrice");

  const onSearch = (val) => {
    const newPrds = products.filter((a) =>
      a.name.toLowerCase().includes(val.toLowerCase())
    );
    setPrds(newPrds);
    setSearch(val);
  };

  return (
    <div className='w-[70%] h-[96vh] overflow-hidden'>
      <div className='w-full items-center px-3 justify-between mb-7 h-[50px] flex'>
        <input
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t("search")}
          className={`w-[${
            id === "expired-products" ? "100%" : "70%"
          }] outline-none p-2 h-full bg-white border`}
        />
        {id !== "expired-products" && (
          <div className='w-[20%] h-full'>
            <select
              onChange={(e) => setPriceType(e.target.value)}
              value={priceType}
              className='w-full h-full p-2 outline-none'>
              <option value={"singlePrice"}>{t("singlePrice")}</option>
              <option value={"wholeSalePrice"}>{t("wholeSalePrice")}</option>
            </select>
          </div>
        )}
      </div>
      <div className='flex flex-wrap pb-[150px] justify-center w-full overflow-auto h-[100vh] gap-7'>
        {prds.map((product) => (
          <div key={product._id} className='w-[250px] bg-white h-fit'>
            <img
              alt={product.name}
              src={product.imageUri}
              className='h-[200px] w-full object-contain'
            />
            <div className=''>
              <h1 className='p-1 font-medium truncate'>{product.name}</h1>
              <h4 className='p-1 text-sm font-medium opacity-70'>
                {showInIQD(product.prices[priceType], "IQD")} - (
                {product.quantity} available)
              </h4>
              <div className='flex cursor-pointer  h-[44px] '>
                {selectedProducts &&
                selectedProducts.length !== 0 &&
                selectedProducts.find((a) => a._id === product._id) ? (
                  <>
                    <div
                      className={`w-[${
                        id === "expired-products" ? "100%" : "50%"
                      }] `}>
                      <input
                        type='number'
                        onChange={(e) =>
                          onChangeQuantity(product, e.target.value * 1)
                        }
                        className='w-full text-xl outline-none text-center h-full border  bg-[#f9f9f9]'
                        placeholder={t("qty")}
                        value={
                          selectedProducts.find((a) => a._id === product._id)
                            .quantity
                        }
                      />
                    </div>
                    {id !== "expired-products" && (
                      <div className='w-[50%] '>
                        <input
                          value={
                            selectedProducts.find((a) => a._id === product._id)
                              .price
                          }
                          onChange={(e) =>
                            onChangePrice(product, e.target.value * 1)
                          }
                          type='number'
                          className='w-full text-xl outline-none text-center h-full border  bg-[#f9f9f9]'
                          placeholder={t("price")}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    onClick={onChangeSelectedProduct.bind(
                      this,
                      product,
                      priceType
                    )}
                    className='flex items-center justify-center w-full text-lg font-bold text-white bg-blue-500'>
                    Select
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Menu = ({
  selectedProducts,
  onClickModal,
  showCustomerModal,
  onSubmit,
  id,
  currentCustomer,
  onChangeExpiryOrCode,
}) => {
  return (
    <div className='w-[30%] relative h-[96vh] overflow-hidden p-2 bg-white'>
      <div className='flex items-center justify-between w-full border-b'>
        <h1 className='text-3xl font-bold'>{t("menu")}</h1>
        {showCustomerModal && (
          <div
            onClick={onClickModal}
            className='text-xl cursor-pointer truncate px-2  max-w-[300px]'>
            {currentCustomer ? currentCustomer.name : t("selectCustomer")}
          </div>
        )}
      </div>
      <div className=' max-h-[80%] overflow-auto w-full'>
        {selectedProducts &&
          selectedProducts.length !== 0 &&
          selectedProducts.map((prds) => {
            return (
              <div className='flex key={prds._id} flex-col w-full p-2 my-4 border-b'>
                <div className='text-base font-bold'>{prds.name}</div>
                <div className='text-base opacity-70'>
                  {prds.quantity} QTY -{" "}
                  {id === "expired-products"
                    ? null
                    : showInIQD(prds.price, "IQD")}
                </div>
                {id === "purchases" && (
                  <div>
                    <div>
                      <input
                        type='date'
                        className='w-full p-1 my-3 border'
                        placeholder='expiryDate'
                        value={prds.expiryDate}
                        onChange={(e) =>
                          onChangeExpiryOrCode(
                            prds,
                            "expiryDate",
                            e.target.value
                          )
                        }
                      />
                      <input
                        type='text'
                        className='w-full p-1 mb-3 border'
                        placeholder='productCode'
                        value={prds.code}
                        onChange={(e) =>
                          onChangeExpiryOrCode(prds, "code", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className='absolute bottom-0 w-full'>
        {id !== "expired-products" && (
          <div className='flex items-center justify-center w-full text-2xl font-medium '>
            <div>{t("total")}: </div>
            <div>
              {showInIQD(
                selectedProducts.reduce((a, b) => a + b.price * b.quantity, 0),
                "IQD"
              )}
            </div>
          </div>
        )}
        <div
          onClick={onSubmit}
          className='flex items-center justify-center w-full p-3 text-2xl font-bold text-white bg-blue-500 cursor-pointer'>
          Submit
        </div>
      </div>
    </div>
  );
};

export const NutritionCustomerModal = ({
  onChangeModal,
  currentData,
  onSelectCustomer,
  currentCustomer,
}) => {
  const [search, setSearch] = useState("");
  const { data } = useSwr(
    "/nutrition-customers?page=1&search=" + search,
    fetcher
  );

  return (
    <div className='absolute z-30 flex items-center justify-center w-[-webkit-fill-available] h-[100vh] bg-black/50'>
      <div className='w-[90%] relative flex flex-col z-30 bg-white rounded-lg min-h-[60%] h-fit'>
        <div className='relative h-[50px]'>
          <input
            value={search}
            onChange={(a) => setSearch(a.target.value)}
            className=' p-3 h-full outline-none w-full bg-[#f1f1f1]'
            placeholder={t("search")}
          />
          <AiOutlineCloseCircle
            onClick={onChangeModal}
            color='red'
            className='absolute right-0 -translate-y-1/2 cursor-pointer top-1/2'
            size={30}
          />
        </div>
        <div className='flex flex-wrap justify-center gap-4 my-3 overflow-auto'>
          {data &&
            data.items.length !== 0 &&
            data.items.map((customer) => (
              <div
                onClick={onSelectCustomer.bind(this, customer)}
                className={`basis-[40%] cursor-pointer p-3 bg-[#f1f1f1] items-center flex justify-center ${
                  currentCustomer?._id === customer._id &&
                  "bg-blue-500 text-white"
                }`}
                key={customer._id}>
                {customer.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
const PayedPriceModal = ({ onChangeModal, onFinalSubmit, items }) => {
  const [payedPrice, setPayedPrice] = useState(
    items.reduce((a, b) => a + b.price * b.quantity, 0)
  );

  const onSubmit = () => {
    if (payedPrice.length === 0) {
      alert("please enter payed price");
      return;
    }
    onFinalSubmit(payedPrice);
  };
  // console.log(payedPrice)
  return (
    <div className='absolute z-30 flex items-center justify-center w-[-webkit-fill-available] h-[100vh] bg-black/50'>
      <div className='w-[60%] relative flex flex-col z-30 bg-white pb-5 rounded-lg h-fit'>
        <div className='relative h-[50px]'>
          <AiOutlineCloseCircle
            onClick={onChangeModal}
            color='red'
            className='absolute right-0 -translate-y-1/2 cursor-pointer top-1/2'
            size={30}
          />
        </div>
        <div className='flex flex-wrap justify-center gap-4 my-3 overflow-auto'>
          <div className='flex p-3 text-xl font-medium justify-between w-full items-center bg-[#f1f1f1]'>
            <p>Total Price</p>
            <p>
              {showInIQD(
                items.reduce((a, b) => a + b.price * b.quantity, 0),
                "IQD"
              )}
            </p>
          </div>
          <div className='flex p-3 text-xl font-medium justify-between w-full items-center bg-[#f1f1f1]'>
            <p>Payed Price</p>
            <input
              placeholder=''
              value={payedPrice}
              onChange={(e) => setPayedPrice(e.target.value)}
              className='w-1/2 p-3 text-right bg-white'
            />
          </div>
        </div>
        <div className='flex items-center self-center justify-center my-4'>
          <div
            onClick={onSubmit}
            className='p-3 text-xl font-bold text-white bg-blue-500 cursor-pointer '>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionCreateInvoice;
