import React from "react";
import { CourseTable } from "../../../../screens/Centers/MonthlyReportScreen";
import { t } from "i18next";
import useSwr from "swr";
import { fetcher } from "../../../../screens/UI/CentersViewData";
import { showInIQD } from "../../../../customFunction/Numeral/CurrencyFormat";

const Label = [
  "#",
  "product",
  "buyPrice",
  "totalSoldQty",
  "totalSoldPrice(cash+debt)",
  "Buy Cost X Total Sold Qty",

  // "totalFreePrice",
  "Profit (Total Sold Price - Buy Cost x Total Sold Qty)",
  //   "totalPriceOfRatio",
];

function ProductSalesTable({ query }) {
  const { data, isLoading, error } = useSwr(
    "/cafeteria-dashboard/advance-dashboard" + query + "productsSold",
    fetcher
  );

  return (
    <div className='flex flex-col w-full p-2 my-5 bg-white'>
      <h1 className='mb-4 text-lg'>{t("soldProducts")}</h1>
      <p className='text-sm'>
        Free Price:{" "}
        {showInIQD(
          data?.data?.items.reduce((a, b) => a + b.freePrice, 0) || 0,
          "IQD"
        )}
      </p>
      {error && <div className='text-red-500'>{t("errorFetchingData")}</div>}
      <CourseTable isLoading={isLoading} labels={Label}>
        {data &&
          data.data &&
          data.data.items.length !== 0 &&
          data.data.items.map((product, i) => {
            return (
              <tr key={product._id} className='cursor-pointer'>
                <td className=''>{i + 1}</td>
                <td className=''>{product.name}</td>
                <td className=''>{showInIQD(product.buyPrice, "IQD")}</td>
                <td className=''>{product.totalSoldQty}</td>
                <td className=''>{showInIQD(product.totalSoldPrice, "IQD")}</td>
                <td className=''>{showInIQD(product.totalBuyPrice, "IQD")}</td>
                <td className=''>
                  {showInIQD(
                    product.totalSoldPrice -
                      product.totalBuyPrice -
                      product.freePrice,
                    "IQD"
                  )}
                </td>
                {/* 
                <td className={`${currentItemInPurchase ? "" : " opacity-40"}`}>
                  {currentItemInPurchase
                    ? currentItemInPurchase.expiryDate +
                      " - " +
                      currentItemInPurchase.code
                    : t("notSelected")}
                </td>
                <td className={`${currentItemInPurchase ? "" : " opacity-40"}`}>
                  {currentItemInPurchase
                    ? currentItemInPurchase.quantity
                    : t("notSelected")}
                </td>
                <td className={`${lastPurchase ? "" : " opacity-40"}`}>
                  {lastPurchase ? lastPurchase.date : t("notSelected")}
                </td>
                <td className={`opacity-40`}>{t("notSelected")}</td> */}
              </tr>
            );
          })}
      </CourseTable>
    </div>
  );
}

export default ProductSalesTable;
