import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import InstanceIndex from '../../../components/UI/AddingData/InstanceIndex'
import { t } from 'i18next'
import { TextInput } from '../../Users/CreateUserScreen'
import axios from 'axios'
import { useHistory } from 'react-router-dom/'
import useQuery from '../../../customFunction/useQuery'
import CommonInputsContainer from '../../../components/UI/TableView/CommonInputsContainer'
import ArrayIndex from '../../../components/UI/AddingData/ArrayIndex'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../../src/components/shadui/ui/alert-dialog';
import { buttonVariants } from '../../../src/components/shadui/ui/button'
import { IoTrashOutline } from 'react-icons/io5'

function NutritionStockDetails() {
    // productId
    const { productId } = useParams()
    const isNew = useQuery().get('new')
    const { data, isLoading, error } = useSwr('/nutrition-stocks/' + productId + '?isNew=' + isNew, fetcher)
    const { data: brands, isLoading: brandIsLoading, error: brandError } = useSwr('/nutrition-brands?all=true', fetcher)
    const [currentData, setCurrentData] = useState()
    const history = useHistory()
    const [alertOpen, setAlertOpen] = useState(false)

    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData(data[0])
        }
    }

    if (isLoading ) {
        return <Loading />
    }
    if (error ) {
        return <Error />
    }

    const onChangeValue = (key, subKey, value) => {
        if (key === 'prices') {
            setCurrentData({
                ...currentData,
                [key]: {
                    ...currentData[key],
                    [subKey]: value,
                },
            });
        } else
            setCurrentData({ ...currentData, [key]: subKey })
    }

    const onSave = async () => {
        try {
            if (!currentData.name ||!currentData.brandId|| currentData.prices.singlePrice === undefined ||currentData.imageUri === undefined ) {
                alert('Please fill all the fields')
                return
            }
            if (isNew)
                await axios.post('/nutrition-stocks', currentData)
            else
                await axios.put('/nutrition-stocks/' + productId, currentData)
            history.goBack()
        } catch (e) {
            alert(e.message)
        }

    }
    const onDeleteItem = async () => {
        try {
            await axios.delete('/nutrition-stocks/' + productId)
            history.goBack()
        } catch (e) {
            alert(e.message)
        }
    }
    return (
        <div className='flex flex-1 w-full' >
             <AlertDialog open={alertOpen} >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
                        <AlertDialogDescription>
                            {t('deleteItemDesc')}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
                        <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <CommonInputsContainer onSave={onSave} customIcon={<IoTrashOutline color="red" onClick={() => setAlertOpen(true)} size={30} />} title={t('Product')} >
                <InstanceIndex label={t("id")} value={`#${currentData && currentData._id ? currentData._id : ''}`} />
                <TextInput
                    label={t("productName")}
                    placeHolder={t("productName")}
                    value={currentData ? currentData.name : ''}
                    onChangeValue={onChangeValue.bind(this, 'name',)}
                    onlyReturnValue
                />
                <TextInput
                    label={t("singlePrice")}
                    placeHolder={t("singlePrice")}
                    value={currentData&&currentData.prices ? currentData.prices.singlePrice : ''}
                    onChangeValue={a => onChangeValue('prices', 'singlePrice', a)}
                    isNumber
                    onlyReturnValue
                />
                <TextInput
                    label={t("imageUri")}
                    placeHolder={t("imageUri")}
                    value={currentData ? currentData.imageUri : ''}
                    onlyReturnValue
                    onChangeValue={onChangeValue.bind(this, 'imageUri')}
                />
                {brands && (
                    <ArrayIndex
                        label={t("brands")}
                        data={brands}
                        valType={1}
                        onlyReturnValue
                        value={currentData?.brandId}
                        index='_id'
                        onChangeValue={onChangeValue.bind(this, 'brandId')}
                    />
                )}
            </CommonInputsContainer>
        </div>
    )
}

export default NutritionStockDetails
