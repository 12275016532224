const initialState = { loading: false };

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "HANDLE_LOADING":
            return {
                ...state,
                loading: !state.loading,
            };

        default:
            return state;
    }
};

export default authenticationReducer;
