import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../store/actions/authenticationAction";
import { handleLoading } from "../../store/actions/loadingAction";
import validator from "validator";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const onChangePassword = (val) => {
    setPassword(val);
  };

  const onChangeLoginId = (val) => {
    setEmail(val);
  };

  const onSubmit = async () => {
    try {
      handleLoading();

      await checkValidation();

      await dispatch(LOGIN(email, password));
      handleLoading();
    } catch (e) {
      console.log(e)
      handleLoading();
      alert(e == 1 ? "Email or password is invalid" : e);
    }
  };

  const checkValidation = async () => {
    if (!email || !password || !validator.isEmail(email)) {
      throw "Email or password invalid";
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-darkCardIndexColor ">
      <img
        src={require("../../assets/images/78gym.png")}
        alt="logo"
        className="object-contain"
        style={{ height: 150, width: 250,}}
      />
      <InputContainer
        onChangeLoginId={onChangeLoginId}
        email={email}
        password={password}
        onChangePassword={onChangePassword}
      />
      <SubmitButton onSubmit={onSubmit} />
    </div>
  );
}

const InputContainer = ({
  onChangeLoginId,
  onChangePassword,
  password,
  email,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <Input
        onChange={onChangeLoginId}
        value={email}
        placeholder={"Email"}
        type={0}
      />
      <Input
        onChange={onChangePassword}
        value={password}
        placeholder={"Password"}
        type={1}
      />
    </div>
  );
};

const Input = ({ placeholder, value, onChange, type }) => {
  const onChangeHandler = (val) => {
    let text = val.target.value;
    onChange(text);
  };

  return (
    <div className="w-1/2 p-2 bg-white border max-sm:w-[90%] max-md:w-2/3 border-mainDark">
      <input
        value={value}
        onChange={onChangeHandler}
        type={type === 1 ? "password" : "text"}
        placeholder={placeholder}
        className="w-full h-full border-none outline-none"
      />
    </div>
  );
};

const SubmitButton = ({ onSubmit }) => {
  return (
    <button
      className="flex items-center max-sm:w-[90%] max-md:w-2/3 justify-center w-1/2 h-10 font-bold text-white cursor-pointer mt-11 bg-mainAqwaBlue"
      onClick={onSubmit}
    >
      Login
    </button>
  );
};

export default LoginScreen;
