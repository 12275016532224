const initialState = { dollarPrices: null, currentPrice: null };

const dollarPricesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DOLLAR_PRICES": {
      const { data } = action;
      const currentDollarPrice = data.find(a => a._id === '63bbce009d907b4b917a770f')
      // let memberships = state.dollarPrices
      return {
        ...state,
        dollarPrices: data,
        currentPrice: currentDollarPrice,
      }
    };

    case "CREATE_DOLLAR_PRICE": {
      let dt = state.dollarPrices.concat(action.data);
      return {
        ...state,
        dollarPrices: dt,
      };
    }
    case "UPDATE_DOLLAR_PRICE": {
      // let dt = state.dollarPrices.map((order) => {
      //   if (order._id === action.data._id) {
      //     return action.data;
      //   }
      //   return order;
      // });
      return {
        ...state,
        currentPrice: { ...state.currentPrice, price: action.data },
      }
    };
    case "DELETE_DOLLAR_PRICE": {
      let dt = state.dollarPrices.filter((a) => a._id !== action.data);
      return {
        ...state,
        dollarPrices: dt,
        currentPrice: null
      };
    }
    default:
      return state;
  }
};

export default dollarPricesReducer;
