import React from "react";
import { useHistory } from "react-router-dom";
import ShowPaginationData from "../../../components/UI/TableView/ShowPaginationData";
import { IoAdd } from "react-icons/io5";
import { t } from "i18next";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";

function PackagesScreen() {
  const { columns, id, label, url, href } = {
    id: 'package', label: 'package',
    href: '/package',
    columns: [{ label: 'name', value: a => a.labelDetails.inEn.title },
    { label: 'duration', sort: 'duration', classnames: () => 'font-[Montserrat]', value: (a) => a.duration },
    { label: 'price',sort:'price.value', value: (a) => showInIQD(a.price.value) },
    { label: 'type', value: (a) => a.offerType === 0 ? t('normal') : t('offer') },],
    url: (centerId) => '/packages?' + centerId,
  }
  
  const history = useHistory();

  const onClickItem = (_id) => {
    history.push(`/admin/packages/${_id}`);
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full bg-white">
      <div className='flex flex-1 w-full h-full mt-10' >
        <ShowPaginationData hrefId='_id' onClickItem={(_id) => onClickItem(_id)} rightButton={<div className="cursor-pointer" >
          <IoAdd size={40} onClick={() => history.push('/admin/packages/0?isNew=true')} />
        </div>} columns={columns} href={href} id={id} label={label} url={url} />
      </div>
    </div>
  );
}

export default PackagesScreen;
