import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData';

import { t } from 'i18next';
import axios from 'axios';
import InstanceIndex from '../../../components/UI/AddingData/InstanceIndex';
import { IoAdd, IoClose } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import { PropagateLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { TextInput } from '../../Users/CreateUserScreen';

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function ClassSchedulesDetails() {
    const { classId } = useParams()
    const isNew = useQuery().get('isNew')
    const { data, isLoading, error } = useSwr('/class-schedule/' + classId + '?isNew=' + isNew, fetcher)
    const [currentData, setCurrentData] = useState({ centerId: [] })
    const history = useHistory()
    const [timeSlots, setTimeSlots] = useState([{ time: '', id: 0, class: [] }]);
    const [scheduleModal, setScheduleModal] = useState(false)
    const [showJoinedPlayer, setShowJoinedPlayer] = useState(false)
    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData({ centerId: data[0].centerIds, imageUri: data[0].imageUri, numOfPlayers: data[0].numOfPlayers })
            setTimeSlots(data[0].timeSlots)
        }
    }

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }

    const onChangeValue = (type, value) => {
        if (type === 0) {
            const index = currentData?.centerId.findIndex(a => a === value)
            if (index !== -1) {
                currentData.centerId.splice(index, 1)
            } else {
                currentData.centerId.push(value)
            }
            setCurrentData({ ...currentData })
        } else if (type === 1) {
            setCurrentData({ ...currentData, imageUri: value })
        } else if (type === 2) {
            setCurrentData({ ...currentData, numOfPlayers: value })
        }
    }

    const onSave = async () => {
        try {
            const newData = { ...currentData, timeSlots }
            if (!newData.timeSlots || newData.timeSlots.length === 0) {
                alert('Please fill all the fields')
                return
            }
            if (isNew)
                await axios.post('/class-schedule', newData)
            else
                await axios.put('/class-schedule/' + classId, newData)
            history.goBack()
        } catch (e) {
            alert(e.message)
        }
    }
    // console.log(currentData)
    return (
        <div className='relative flex flex-1 w-full' >
            {scheduleModal && <div className='absolute top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50' >
                <div className='relative flex flex-col items-center justify-center w-full h-full' >
                    <div className='flex items-center flex-col justify-between max-h-[700px] overflow-y-auto w-[96%] p-6 bg-white' >
                        <div className='flex justify-between w-full' >
                            <h1>Schedule</h1>
                            <div className='' >
                                <button onClick={() => {
                                    setScheduleModal(!scheduleModal)
                                }} className='p-2 font-medium text-white bg-gray-800' >
                                    Close
                                </button>
                            </div>
                        </div>
                        <ScheduleTable timeSlots={timeSlots} setTimeSlots={setTimeSlots} />
                    </div>
                </div>
            </div>}
            {showJoinedPlayer && <div className='absolute top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50' >
                <div className='relative flex flex-col items-center justify-center w-full h-full' >
                    <div className='flex items-center flex-col justify-between max-h-[700px] overflow-y-auto w-[96%] p-6 bg-white' >
                        <div className='flex justify-between w-full' >
                            <h1>Joined Players</h1>
                            <div className='' >
                                <button onClick={() => {
                                    setShowJoinedPlayer(!showJoinedPlayer)
                                }} className='p-2 font-medium text-white bg-gray-800' >
                                    Close
                                </button>
                            </div>
                        </div>
                        <JoinedPlayers timeSlots={timeSlots} />
                    </div>
                </div>
            </div>}
            <NutritionInputs onSave={onSave} title={t('class')} >
                <InstanceIndex label={t("id")} value={`#${currentData && currentData._id ? currentData._id : ''}`} />
                <TextInput label={'imageUri'} onChangeValue={val => onChangeValue(1, val)} value={currentData?.imageUri} placeHolder={t('imageUri')} onlyReturnValue />
                <div className='flex flex-row justify-between w-full p-6'>
                    <h1>Schedule</h1>
                    <div className='' >
                        <button onClick={() => {
                            setScheduleModal(!scheduleModal)
                        }} className='p-2 font-medium text-white bg-gray-800' >
                            Edit Schedule
                        </button>
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full p-6'>
                    <h1>Active Players</h1>
                    <div className='' >
                        <button onClick={() => {
                            setShowJoinedPlayer(!showJoinedPlayer)
                        }} className='p-2 font-medium text-white bg-gray-800' >
                            Show Joined Players
                        </button>
                    </div>
                </div>
            </NutritionInputs>
        </div>
    )
}


const JoinedPlayers = () => {
    const { data, isLoading, error } = useSwr('/class-schedule/joined-players', fetcher)

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }
    console.log({data})
    return <div className='relative mt-4 overflow-auto w-full h-[500px] z-[70] px-4'>
        {/* header: dayName, time, className, joinedPlayer,players */}
        <table style={{ fontFamily: 'Montserrat' }} className="relative w-full overflow-hidden z-[1] ">
            <thead className='font-bold' >
                <tr className='text-left' >
                    <th className={`bg-[#fff] w-1/4 uppercase border-none text-black font-bold`}>Day/Time</th> {/* Empty cell in the top-left corner */}
                    <th className={`bg-[#fff] w-1/4 uppercase border-none text-black font-bold`}>Class Name</th> {/* Empty cell in the top-left corner */}
                    <th className={`bg-[#fff] w-1/4 uppercase border-none text-black font-bold`}>Joined Players</th> {/* Empty cell in the top-left corner */}
                    <th className={`bg-[#fff] w-1/4 uppercase border-none text-black font-bold`}>Players</th> {/* Empty cell in the top-left corner */}
                </tr>
            </thead>
            <tbody className='relative border-none z-[90]' >
                {data.map((time, i) => (
                    <tr key={time.time}>
                        <td className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#ffffff]'} relative border-none text-black py-6 w-1/4 `} style={{ fontFamily: 'Montserrat' }}>
                            {time.weekName+' '+ time.classTime}
                        </td>
                        <td className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#ffffff]'} relative border-none text-black py-6 w-1/4 `} style={{ fontFamily: 'Montserrat' }}>
                            {time.className}
                        </td>
                        <td className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#ffffff]'} relative border-none text-black py-6 w-1/4 `} style={{ fontFamily: 'Montserrat' }}>
                            {time.joinedPlayers.length+'/'+time.numOfPlayers}
                        </td>
                        <td className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#ffffff]'} relative border-none text-black py-6 w-1/4 `} style={{ fontFamily: 'Montserrat' }}>
                            {time.joinedPlayers.map(a => a.data.name).join(', ')}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

    </div>
}

const COLORS = [{ bg: 'rgba(255, 103, 0, 0.3)', color: 'rgba(255, 103, 0, .9)' }, { bg: 'rgba(18, 86, 204, 0.3)', color: 'rgba(18, 86, 204, .9)' }, { bg: 'rgba(128, 58, 208, 0.3)', color: 'rgba(128, 58, 208, .9)' }, { bg: 'rgba(127, 221, 5, 0.3)', color: 'rgba(127, 221, 5, .9)' }, { bg: 'rgba(204, 39, 130, 0.3)', color: 'rgba(204, 39, 130, .9)' }, { bg: 'rgba(0, 165, 133, 0.3)', color: 'rgba(0, 165, 133, .9)' }, { bg: 'rgba(34, 188, 242, .9)' }]
const ScheduleTable = forwardRef(({
    timeSlots, setTimeSlots, isFromPrint
}, ref) => {
    const daysOfWeek = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

    const onChangeTime = (index, val) => {
        timeSlots[index].time = val
        setTimeSlots([...timeSlots])
    }
    const onChangeDayValue = (id, day, value, key) => {
        const index = timeSlots.findIndex(a => a.id === id)
        if (index !== -1) {
            const dayIndex = timeSlots[index].class.findIndex(a => a.day === day)
            if (dayIndex !== -1) {
                timeSlots[index].class[dayIndex][key] = value
            } else {
                // random bg from COLORS
                const randomIndex = Math.floor(Math.random() * COLORS.length)
                timeSlots[index].class.push({ name: '', day, bg: COLORS[randomIndex].bg, color: COLORS[randomIndex].color, })
            }
        }
        setTimeSlots([...timeSlots])
    }

    const onAddTimeSlot = () => {
        let newClass = []
        newClass = daysOfWeek.map((day) => {
            const randomIndex = Math.floor(Math.random() * COLORS.length)
            return { name: '', day, bg: COLORS[randomIndex].bg, color: COLORS[randomIndex].color, numOfPlayers: null }
        })
        if (timeSlots[0].class.length === 0) {
            setTimeSlots([{ time: '', id: Math.floor(Math.random() * 100000), class: newClass }])
            return
        }
        setTimeSlots(a => [...a, { time: '', id: Math.floor(Math.random() * 100000), class: newClass }])
    }

    const onRemoveTimeSlot = (time) => {
        const index = timeSlots.findIndex(a => a.id === time.id)
        if (index !== -1) {
            timeSlots.splice(index, 1)
            if (timeSlots.length === 0) {
                setTimeSlots([{ time: '', id: Math.floor(Math.random() * 100000), class: [] }])
                return
            }
            setTimeSlots([...timeSlots])
        }
    }

    return <div ref={ref} className='relative mt-4 overflow-auto w-full z-[70] px-4'>

        <table style={{ fontFamily: 'Montserrat' }} className="relative overflow-hidden z-[1] font-bold">
            <div className='absolute z-[999] flex items-center self-start justify-center p-[1px] bg-green-500 rounded-full cursor-pointer -left-0 -bottom-0' >
                <IoAdd size={18} color='white' onClick={onAddTimeSlot} />
            </div>

            <thead>
                <tr>
                    <th className={`bg-[#fff] uppercase border-none text-black font-bold`}>Day/Time</th> {/* Empty cell in the top-left corner */}
                    {daysOfWeek.map((day, i) => (
                        <th className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#fff] '} border-none text-black p-7 uppercase font-bold`} style={{ fontFamily: 'Montserrat' }} key={day}>{day}</th>
                    ))}
                </tr>
            </thead>

            <tbody className='relative border-none z-[90]' >

                {timeSlots[0]?.class.length !== 0 && timeSlots.map((time, i) => (
                    <tr key={time.time}>
                        <th className={`${i % 2 === 0 ? 'bg-[#f1f1f1]' : 'bg-[#ffffff]'} relative border-none text-black py-6 w-10 h-10 font-bold`} style={{ fontFamily: 'Montserrat' }}>
                            <input autoFocus value={time.time} onChange={(e) => onChangeTime(i, e.target.value)} type="time" className='w-full bg-white border' />
                            <div className='absolute z-[999] flex items-center self-start justify-center p-[1px] bg-red-500 rounded-full cursor-pointer right-0 -bottom-0' >
                                <IoClose size={18} color='white' onClick={onRemoveTimeSlot.bind(this, time)} />
                            </div>
                        </th>

                        {daysOfWeek.map((day, i) => {
                            const currentClass = time.class.find(a => a.day === day)
                            return (
                                <td className={`w-[140px] py-6 relative px-3 h-10 font-bold border-none`} style={{ fontFamily: 'Montserrat', backgroundColor: !currentClass.name ? 'rgba(232,232,232,0.7)' : currentClass.bg, color: currentClass.color }} key={day}>
                                    <input value={currentClass ? currentClass.name : ''} onChange={a => onChangeDayValue(time.id, day, a.target.value, 'name')} type="text" style={{ color: currentClass.color }} className='w-full p-1 text-sm bg-white border' />
                                    <input value={currentClass ? currentClass.numOfPlayers : ''} onChange={a => onChangeDayValue(time.id, day, a.target.value, 'numOfPlayers')} type="number" style={{ color: currentClass.color }} placeholder='numOfPlayers' className='w-full p-1 mt-1 text-sm bg-white border' />
                                </td>
                            )
                        })}
                    </tr>
                ))}

            </tbody>
        </table>
    </div>
})


function NutritionInputs({ children, title, printIcon, onSave }) {
    const [loading, setLoading] = React.useState(false);

    const onClickSave = async () => {
        setLoading(true);
        await onSave();
        setLoading(false);
    };

    if (loading) return <LoadingForSave />;

    return (
        <div className='flex items-center overflow-auto justify-center w-full border bg-[#f9f9f9]'>
            <div className='items-center justify-center w-[80%] h-fit border  bg-white '>
                <div className='flex items-center justify-between w-full p-6 '>
                    <h1 className='text-3xl font-bold'>{title}</h1>
                    <div className='flex items-center'>
                        {onSave && (
                            <div
                                onClick={onClickSave}
                                className='p-2 px-8 font-bold text-white bg-red-500 cursor-pointer'>
                                Save
                            </div>
                        )}
                        {printIcon ? (
                            <div className='w-[100px] mx-2'>{printIcon}</div>
                        ) : null}
                    </div>
                </div>
                {children}
                <div className='py-7' />
            </div>
        </div>
    );
}

export const LoadingForSave = () => (
    <div className='flex items-center justify-center flex-1 w-full'>
        <PropagateLoader size={40} color='#ff0f0f' />
    </div>
);
export default ClassSchedulesDetails
