import React from "react";
import { CourseTable } from "../../../../screens/Centers/MonthlyReportScreen";
import { t } from "i18next";
import useSwr from "swr";
import { fetcher } from "../../../../screens/UI/CentersViewData";
import { showInIQD } from "../../../../customFunction/Numeral/CurrencyFormat";

const Label = [
  "totalSales",
  "totalBuyingPrice",
  "freePrice",
  "Depreciation",
  "grossProfit",
];

function DetailsProfitTable({ query }) {
  const { data, isLoading, error } = useSwr(
    "/cafeteria-dashboard/advance-dashboard" + query + "productsSold",
    fetcher
  );
  const totalSales = data?.data?.items.reduce(
    (a, b) => a + b.totalSoldPrice,
    0
  );
  const totalBuyingPrice = data?.data?.items.reduce(
    (a, b) => a + b.totalBuyPrice,
    0
  );
  const totalFreePrice = data?.data?.items.reduce((a, b) => a + b.freePrice, 0);

  return (
    <div className='flex flex-col w-full p-2 my-5 bg-white'>
      <h1 className='mb-4 text-lg'>{t("DetailsProfitTable")}</h1>
      {error && <div className='text-red-500'>{t("errorFetchingData")}</div>}
      <CourseTable isLoading={isLoading} labels={Label}>
        {data && (
          <tr className='cursor-pointer'>
            <td className='text-blue-500'>{showInIQD(totalSales, "IQD")}</td>
            <td className='text-blue-500'>
              {showInIQD(totalBuyingPrice, "IQD")}
            </td>
            <td className='text-red-500'>{showInIQD(totalFreePrice, "IQD")}</td>
            <td className='text-red-500'>{showInIQD(300, "IQD")}</td>

            <td className='text-green-600'>
              {showInIQD(
                totalSales - totalBuyingPrice - totalFreePrice - 300,
                "IQD"
              )}
            </td>
          </tr>
        )}
      </CourseTable>
    </div>
  );
}

export default DetailsProfitTable;
