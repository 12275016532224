const initialState = {
  debts: null,
  customers: null,
  repaymentOfDebt: null,
  productReturnActivity: null,
  buySupplementActivity: null,
  sellSupplementsActivity: null,
  search: "",
  expenses: "",
  fetchedDate: null,
  brands: null,
  moneyTrans: null,
  customersRatio: null,
};

const supplementsReducer = (state = initialState, action) => {
  switch (action.type) {
    //!customers
    case "FETCH_SPECIFIC_SUPP_PROPERTY": {
      // if(action.property===)
      return {
        ...state,
        [action.property]: action.data,
      }
    };

    case "CREATE_SPECIFIC_SUPP_PROPERTY": {
      const dt = state[action.property].concat(action.data);
      return {
        ...state,
        [action.property]: dt,
      };
    }
    case "DELETE_SPECIFIC_SUPP_PROPERTY": {
      const dt = state[action.property].filter((a) => a._id !== action.id);
      return {
        ...state,
        [action.property]: dt,
      };
    }
    case "UPDATE_SPECIFIC_SUPP_PROPERTY": {
      let dt = state[action.property].map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        [action.property]: dt,
      };
    }

    case "UPDATE_SUPPLEMENT_SEARCH": {
      return {
        ...state,
        search: action.data,
      };
    }
    case "CHANGE_SELL_SUPP_SHOP_DATE": {
      return {
        ...state,
        sellSupplementsActivityDate: action.data
      }
    }
    default:
      return state;
  }
};

export default supplementsReducer;
