const initialState = { usersFeedback: null, fetchedDate: null };

const usersFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS_FEEDBACK": {
      return {
        ...state,
        usersFeedback: action.data,
      }
    };

    case "CREATE_USERS_FEEDBACK": {
      let dt = state.usersFeedback.concat(action.data);
      return {
        ...state,
        usersFeedback: dt,
      };
    }
    case "UPDATE_USERS_FEEDBACK": {
      let dt = state.usersFeedback.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        usersFeedback: dt,
      };
    }
    case "DELETE_USERS_FEEDBACK": {
      let dt = state.usersFeedback.filter((a) => a._id !== action.data);
      return {
        ...state,
        usersFeedback: dt,
      };
    }
    default:
      return state;
  }
};

export default usersFeedbackReducer;
