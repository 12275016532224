const initialState = { supplementItems: null };

const supplementItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SUPPLEMENT_ITEMS":

      return {
        ...state,
        supplementItems: action.data,
      };

    case "CREATE_SUPPLEMENT_ITEM": {
      let dt = state.supplementItems.concat(action.data);
      return {
        ...state,
        supplementItems: dt,
      };
    }
    case "UPDATE_SUPPLEMENT_ITEM": {
      let dt = state.supplementItems.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        supplementItems: dt,
      }
    };
    default:
      return state;
  }
};

export default supplementItemsReducer;
