// Aka Power Main Stock

import { store } from "../../App"

// 0 admin, 7 accountant, 14 main seller
const MAIN_STOCK = [0, 7, 14,18,19]
// const 
const getSellerDetails =  () => {
    const currentUser=store.getState().authentication.user
    try {
        const type = currentUser.type
        if (MAIN_STOCK.includes(type)) {
            return { type: 'mainStock', sellerId: '6371150d50d8a55750df0d7d' }
        } else {
            throw 'Invalid User Type'
        }
    } catch (e) {
        throw 'Invalid User Type'
    }
}


export default getSellerDetails