import React from "react";

const TextInput = ({
  label,
  value,
  placeHolder,
  onChangeValue,
  type,
  isNumber,
  paddingNum,
  onBlur,
  validation,
}) => {
  const onChangeValueHandler = (e) => {
    let tx = e.target.value;
    onChangeValue(type, tx);
  };

  return (
    <div>
      <div className={`flex flex-row justify-between w-full p-${paddingNum || 6}`}>
        <h1>{label}</h1>
        <input
          onBlur={onBlur}
          onChange={onChangeValueHandler}
          className='w-3/5 px-2 border-none outline-none h-9 bg-whiteCardColor'
          type={!isNumber ? "text" : "number"}
          placeholder={placeHolder}
          value={value}
        />
      </div>
      {validation && <p className='px-5 text-sm text-right text-red-500'>{validation}</p>}
    </div>

  );
};

export default TextInput;
