import { t } from "i18next";
import React, { useState } from "react";
import { Checkbox } from "../../ui/ui/checkbox";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineSortAscending } from "react-icons/ai";

const FilterDropdown = ({ filterOfCenter, data, setFilter, filter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState(filter);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeFilter = (item) => {
    const isSelected = selectedFilterItem.find((a) => a === item);
    if (isSelected) {
      setSelectedFilterItem(
        selectedFilterItem.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedFilterItem([...selectedFilterItem, item]);
    }
  };
  // console.log(selectedFilterItem);
  const onClickApply = () => {
    setFilter(selectedFilterItem);
    setIsOpen(false);
  };

  return (
    <div className='z-50 flex self-start mx-3 text-left w-fit'>
      <div
        onClick={toggleDropdown}
        className='z-20 flex justify-between w-full px-2 py-2 text-sm font-medium text-black bg-white border rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 '>
        <span className='mx-2'>{t("filter")}</span>
        <svg
          className='w-5 h-5 ml-2 -mr-1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='black'>
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </div>

      <div
        className={`absolute ${
          isOpen ? "opacity-100" : "opacity-0 hidden"
        } duration-300 flex left-[10%] justify-center items-center transition-all  top-0 w-full h-full bg-white/20 z-[9099] backdrop-blur-sm`}>
        <div
          className={` p-3 w-1/2 h-1/2 relative bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5`}>
          <div className='flex flex-col '>
            <div className='flex items-center justify-between'>
              <h1 className='text-3xl font-bold '>{t("filter")}</h1>
              <div onClick={() => setIsOpen(false)} className='cursor-pointer '>
                <IoCloseOutline size={28} />
              </div>
            </div>

            {data && data.items && (
              <FilterContainer
                data={data.items}
                onChangeValue={handleChangeFilter}
                filters={filterOfCenter}
                selectedFilter={selectedFilterItem}
              />
            )}
            <ApplyFilter onClickApply={onClickApply} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApplyFilter = ({ onClickApply }) => {
  return (
    <div className='absolute flex justify-center mt-4 -translate-x-1/2 bottom-4 left-1/2 '>
      <button
        onClick={onClickApply}
        className='px-4 py-2 font-semibold text-white bg-blue-500 border-none rounded-md'>
        {t("applyFilter")}
      </button>
    </div>
  );
};

export const FilterContainer = ({
  filters,
  selectedFilter,
  data,
  onChangeValue,
}) => {
  const packagesName = [
    ...new Set(
      data.map((a) => a.originalDocument.package.labelDetails.inEn.title)
    ),
  ];
  // console.log(packagesName);
  return (
    <div className='mt-4'>
      <Filter title={t("packages")}>
        {packagesName.map((filter, index) => {
          const isSelected = selectedFilter?.find((a) => a === filter);
          // const isSelected = false;
          return (
            <>
              <div
                onClick={onChangeValue.bind(this, filter)}
                className={`flex ${
                  isSelected ? "border-blue-500" : ""
                } items-center p-2 border cursor-pointer`}
                key={index}>
                <p className='mx-2 text-sm'>{filter}</p>
                <AiOutlineSortAscending size={22} />
              </div>
            </>
          );
        })}
      </Filter>
    </div>
  );
};

const Filter = ({ title, children }) => {
  return (
    <div className='flex flex-col'>
      <h1 className='pb-1 text-xl font-medium uppercase border-b'>{title}</h1>
      <div className='flex flex-wrap gap-3 my-2'>{children}</div>
    </div>
  );
};

export default FilterDropdown;
