import { t } from "i18next";
import React from "react";

const ArrayIndex = ({
  label,
  data,
  onChangeValue,
  type,
  value,
  valType,
  index,
  onlyReturnValue

}) => {
  const onChange = (val) => {
    if (
      onlyReturnValue
    ) return onChangeValue(val)
    onChangeValue(type, val);
  };
  return (
    <div className="flex flex-row justify-between w-full p-6">
      <h1 className="w-1/4">{label}</h1>
      <div className="flex flex-wrap justify-between w-3/5">
        {data.map((dt, i) => {
          return (
            <button
              key={i}
              onClick={onChange.bind(this, dt[`${index}`])}
              className={`${valType === 1
                ? value == dt[`${index}`]
                  ? "bg-mainAqwaBlue opacity-100 text-white"
                  : "bg-whiteCardColor"
                : value?.includes(dt[`${index}`])
                  ? "bg-mainAqwaBlue opacity-100 text-white"
                  : "bg-whiteCardColor"
                } p-2 font-medium opacity-50 mt-1`}
            >
              {t(dt.label || dt.name || dt.modelName)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ArrayIndex;
