const initialState = { balances: null };

const balancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BALANCES":

      return {
        ...state,
        balances: action.data,
      };

    case "CREATE_BALANCE": {
      let dt = state.balances.concat(action.data);
      return {
        ...state,
        balances: dt,
      };
    }
    case "UPDATE_BALANCE": {
      let dt = state.balances.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        balances: dt,
      }
    };
    default:
      return state;
  }
};

export default balancesReducer;
