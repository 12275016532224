import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import TextInput from "../../../components/UI/AddingData/TextInput";

import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import useSWR from "swr";
import { useQuery } from "../ClassSchedule/ClassSchedulesDetails";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import axios from "axios";

function PhysicalTherapyDetailsScreen() {
  const { id } = useParams()
  const isNew = useQuery().get('isNew')
  const { data, isLoading, error } = useSWR('/physical-therapy/' + id + '?isNew=' + isNew, fetcher)
  const [currentData, setCurrentData] = useState()
  const history = useHistory()

  useEffect(() => {
    setup()
  }, [data])

  const setup = () => {
    if (data && data[0]) {
      setCurrentData(data[0])
    }
  }

  const onChangeValue = (key, value, child) => {
    if (child) {
      setCurrentData({ ...currentData, [child]: { ...(currentData && currentData[child] ? currentData[child] : {}), [key]: value } })
      return
    }
    setCurrentData({ ...currentData, [key]: value })
  }

  const onSave = async () => {
    try {
      if (!currentData || !currentData.name.en || !currentData.name.ar || !currentData.name.ku || !currentData.price) {
        alert('Please fill all the fields')
        return
      }

      if (isNew) {
        await axios.post('/physical-therapy/', currentData)
      }
      else
        await axios.put('/physical-therapy/', currentData)
      history.goBack()
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  return (
    <div className="flex flex-1 w-full">
      <CommonInputsContainer onSave={onSave} title={t('balance')} >
        {currentData?._id && <InstanceIndex label={t("#")} value={`#${currentData._id}`} />}
        <TextInput
          placeHolder={t('english')}
          onChangeValue={(type, text) => onChangeValue('en', text, 'name')}
          label={t('enName')}
          value={currentData?.name?.en}
        />
        <TextInput
          placeHolder={t('arabic')}
          onChangeValue={(type, text) => onChangeValue('ar', text, 'name')}
          label={t('arName')}
          value={currentData?.name?.ar}
        />
        <TextInput
          placeHolder={t('kurdish')}
          onChangeValue={(type, text) => onChangeValue('ku', text, 'name')}
          label={t('kuName')}
          value={currentData?.name?.ku}
        />
        <TextInput
          placeHolder={t('price')+' ($)'}
          onChangeValue={(type, text) => onChangeValue('price', Number(text),)}
          label={t('price')}
          isNumber
          value={currentData?.price}
        />
      </CommonInputsContainer>
    </div >
  );
}
export default PhysicalTherapyDetailsScreen;

