const initialState = {
  duration: {
    startDate: new Date(),
    endDate: new Date(),
  },
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SUPPLEMENTS_DURATION": {
      return {
        ...state,
        duration: action.data,
      };
    }
    default:
      return state;
  }
};

export default analyticsReducer;
