import { t } from "i18next";
import React from "react";
import { PropagateLoader } from "react-spinners";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../src/components/shadui/ui/dropdown-menu";
import { IoIosSave } from "react-icons/io";

function CommonInputsContainer({
  children,
  title,
  menu,
  printIcon,
  onSave,
  className,
  customIcon,
}) {
  const [loading, setLoading] = React.useState(false);

  const onClickSave = async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  };

  if (loading) return <LoadingForSave />;

  return (
    <div
      className={
        "flex items-center justify-center w-full h-full overflow-auto " +
        className
      }>
      <div className='items-center justify-center w-[80%] h-fit border  bg-white '>
        <div className='flex items-center justify-between w-full p-6 '>
          {menu ? (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <h1 className='text-2xl font-bold'>{title}</h1>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {menu.map((mn, index) => (
                  <DropdownMenuItem onClick={() => mn.onClick()} key={index}>
                    <span style={{ color: mn.color || "black" }}>
                      {t(mn.title)}
                    </span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <h1 className='text-2xl font-bold'>{title}</h1>
          )}
          <div className='flex items-center'>
            {onSave && (
              <IoIosSave
                onClick={onClickSave}
                color={"#292929"}
                className='w-8 h-8 cursor-pointer'
              />
            )}
            {customIcon ? (
              <div className='mx-2 cursor-pointer'>{customIcon}</div>
            ) : null}
            {printIcon ? (
              <div className='w-[100px] mx-2'>{printIcon}</div>
            ) : null}
          </div>
        </div>
        {children}
        <div className='py-7' />
      </div>
    </div>
  );
}

export const LoadingForSave = () => (
  <div className='flex items-center justify-center flex-1 w-full'>
    <PropagateLoader size={40} color='#ff0f0f' />
  </div>
);

export default CommonInputsContainer;
