import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { handleLoading } from "../../store/actions/loadingAction";
import { useEffect } from "react";
import HeaderContainer from "../../components/UI/‌Header/HeaderContainer";
import { t } from "i18next";
import { Button, DateInput, TextInput } from "../Users/CreateUserScreen";
import { useTranslation } from "react-i18next";
import InstanceIndex from "../../components/UI/AddingData/InstanceIndex";
import ArrayIndex from "../../components/UI/AddingData/ArrayIndex";
import {
  createSupplemenet,
  deleteSupplement,
} from "../../store/actions/supplementsAction";
import useSwr from 'swr'
import { Error, Loading, fetcher } from "../UI/CentersViewData";
import CommonInputsContainer from "../../components/UI/TableView/CommonInputsContainer";
import { IoTrashOutline } from "react-icons/io5";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../src/components/shadui/ui/alert-dialog';
import { buttonVariants } from "../../src/components/shadui/ui/button";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";
import { getAmountByCustomer } from "./SwimmingItemDetails";
import axios from "axios";

const SupplementOrderDetails = () => {
  const [currentData, setCurrentData] = useState(false);
  const { suppId, centerId } = useParams();

  const { data, isLoading, error } = useSwr('/nutrition-sales/' + suppId, fetcher)
  const [alertOpen, setAlertOpen] = useState(false)

  const history = useHistory();

  const onChangeValue = (type, value) => {
    let obj = currentData;
    if (type === 0) {
      obj.name = value;
    } else if (type === 1) {
      obj.iqd = Number(value);
    }
    else if (type === 7) {
      obj.price = Number(value);
    }
    else if (type === 2) {
      obj.centerId = value;
    } else if (type === 3) {
      obj.type = value;
    } else if (type === 4) {
      obj.date = value;
      obj.createdAt = dayjs(value).valueOf();
    } else if (type === 6) {
      obj.trainerId = value;
    }

    setCurrentData({ ...obj });
  };

  const onSubmit = async () => {
    try {
      handleLoading();
      await axios.put('/nutrition-sales/update/' + suppId, currentData)
      history.goBack();
      handleLoading();
  } catch (e) {
      handleLoading();
      alert(e);
  }
  };

  const setUp = () => {
    let objData = {};
    let check = data[0]
    if (check) {
      objData = { ...check };
    }

    setCurrentData(objData);
  };

  useEffect(() => {
    if (data) setUp();
  }, [data]);

  const onDeleteItem = async () => {
    try {
      handleLoading()
      await axios.delete('/nutrition-sales/delete/' + suppId)
      history.goBack();
      handleLoading()
  } catch (e) {
      handleLoading()
      console.log({ e })
      alert(t('somethingWentWrong'))
  }
  };

  if (error) return <Error />

  if (isLoading) return <Loading />


  return (
    <div className='flex flex-col items-center flex-1 w-full '>
      <AlertDialog open={alertOpen} >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
                        <AlertDialogDescription>
                            {t('thisActionCannotBeUndone_swimming')}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
                        <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <CommonInputsContainer onSave={onSubmit} title={t('item')} customIcon={<IoTrashOutline color="red" onClick={() => setAlertOpen(true)} size={30} />} warning={t("pleaseBeCarefulOfChangingMembership")}>
                {currentData&&currentData._id && <>
                    <InstanceIndex label={t("id")} value={`#${currentData._id ? currentData._id : ''}`} />
                    <InstanceIndex label={t("name")} value={currentData.name} />
                    <InstanceIndex label={t("price")} value={(showInIQD(currentData.iqd, 'IQD') + ' - ' + showInIQD(currentData.price))} />
                    <InstanceIndex label={t("amountByCustomer")} value={getAmountByCustomer(currentData.paymentDetails, currentData.price)} />
                    <DateInput
                        label={`${t("date")}`}
                        placeHolder={t("date")}
                        value={currentData?.date}
                        onChangeValue={onChangeValue}
                        type={4}
                    />
                </>}

            </CommonInputsContainer>
    </div>
  );
};

const Container = ({
  onChangeValue,
  currentAdmins,
  currentData,
  centers,
}) => {
  return (
    <div className='w-4/5 mt-10 border max-h-max'>
      <InputContainer
        onChangeValue={onChangeValue}
        currentData={currentData}
        centers={centers}
        currentAdmins={currentAdmins}
      />
    </div>
  );
};

const InputContainer = ({
  onChangeValue,
  currentData,
  centers,
  currentAdmins,
}) => {
  const { t } = useTranslation();

  return (
    <div className='items-center justify-center bg-white'>
      {currentData._id && (
        <InstanceIndex label={t("idNumber")} value={`#${currentData._id}`} />
      )}
      <TextInput
        label={t("name")}
        placeHolder={t("name")}
        value={currentData.name}
        onChangeValue={onChangeValue}
        type={0}
      />
      <TextInput
        label={t("price") + ` IQD`}
        placeHolder={t("price")}
        value={currentData?.iqd}
        onChangeValue={onChangeValue}
        type={1}
        isNumber
      />
      <TextInput
        label={t("price") + ` $`}
        placeHolder={t("price")}
        value={currentData?.price}
        onChangeValue={onChangeValue}
        type={7}
        isNumber
      />
      {currentData.date && (
        <DateInput
          label={`${t("date")}`}
          placeHolder={t("date")}
          value={currentData?.date}
          onChangeValue={onChangeValue}
          type={4}
        />
      )}
      {centers && (
        <ArrayIndex
          label={t("center")}
          data={centers}
          type={2}
          valType={1}
          value={currentData.centerId}
          index='_id'
          onChangeValue={onChangeValue}
        />
      )}
      {currentAdmins && (
        <ArrayIndex
          label={t("center")}
          data={currentAdmins}
          type={6}
          valType={1}
          value={currentData.trainerId}
          index='_id'
          onChangeValue={onChangeValue}
        />
      )}
    </div>
  );
};

export default SupplementOrderDetails;

const styles = StyleSheet.create({});
