import React from 'react'
import NutritionViewData from '../../../components/cafeteria/NutritionViewData'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { shortIdGenerator } from '../../../customFunction/IDGenerator'

function CafeteriaBrands() {
  const history = useHistory()
  const clickAddIcon = () => {
    let id = shortIdGenerator();

    history.push('/cafeteria-storage/brands/' + id + '?new=true')
  }

  return (
    <div className='flex flex-1 w-full' >
      <NutritionViewData AddIcon={() => <AddIcon onClick={clickAddIcon} />} />
    </div>
  )
}

export const AddIcon = ({ size, onClick }) => {
  return <div className='cursor-pointer' onClick={onClick} >
    <AiOutlinePlus size={size} />
  </div>
}

export default CafeteriaBrands
