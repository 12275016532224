const initialState = { memberships: null, currentMembership: null, fetchedDate: null, expiryTodayMemberships: null };

const membershipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MEMBERSHIPS": {
      const { data } = action;

      let memberships = state.memberships || []
      let dates = state.fetchedDate || []
      data.map((membership) => {
        if (!dates.find((date) => date === membership.date)) {
          const newMembers = data.filter(a => a.date === membership.date)
          memberships = memberships.concat(newMembers)
          dates.push(membership.date)
        }
      })

      if (!dates.find((date) => date === action.endDate)) {
        dates.push(action.endDate)
      }

      return {
        ...state,
        memberships: memberships,
        fetchedDate: dates
      }
    };

    case "CREATE_MEMBERSHIP":
      let dt = state.memberships.concat(action.data);
      return {
        ...state,
        memberships: dt,
      };
    case "EDIT_MEMBERSHIP": {
      let newMessaaging = [...state.memberships];
      const currentMembership = action.data;

      newMessaaging = state.memberships.map((msg) => {
        if (msg._id === currentMembership._id) {
          msg = currentMembership;
        }
        return msg;
      });

      return { ...state, memberships: newMessaaging };
    }
    case "DELETE_MEMBERSHIP": {
      let newMemberships = [...state.memberships];

      newMemberships = state.memberships.filter((a) => a._id !== action.data);

      return { ...state, memberships: newMemberships };
    }
    case "FETCH_EXPIRY_TODAY_MEMBERSHIP": {
      // let newMemberships = [...state.memberships];

      // newMemberships = state.memberships.filter((a) => a._id !== action.data);

      return { ...state, expiryTodayMemberships: action.data };
    }
    default:
      return state;
  }
};

export default membershipsReducer;
