import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useSwr from "swr";
import {
  Error,
  Loading,
  Table,
  fetcher,
} from "../../../screens/UI/CentersViewData";
import { t } from "i18next";
import ReactPaginate from "react-paginate";
import { AiOutlineSearch } from "react-icons/ai";
import Colors from "../../../constant/Colors";

function ShowPaginationData({
  columns,
  onClickItem,
  label,
  url,
  href,
  rightButton,
  hrefId,
}) {
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const history = useHistory();
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  //   console.log(sort);
  const { data, isLoading, error } = useSwr(
    url(
      `page=${page}&search=${search}&sort=${JSON.stringify(
        sort
      )}&filter=${JSON.stringify(filter)}`
    ),
    fetcher
  );

  const language = useTranslation().i18n.language;
  const [unSelectedColumns, setUnSelectedColumns] = useState([]);
  console.log({ data });
  useEffect(() => {
    if (data) {
      setPageCount(data.pagination?.pageCount);
    }
  }, [data]);

  if (error) return <Error />;
  if (isLoading) return <Loading />;

  const handleNext = (a) => {
    const newVal = a.selected + 1;
    if (newVal > page) {
      setPage((p) => {
        return p + 1;
      });
    } else if (newVal < page) {
      setPage((p) => {
        return p - 1;
      });
    }
  };
  return (
    <div
      style={{ direction: language === "en" ? "ltr" : "rtl" }}
      className='flex items-center justify-center flex-1 w-full overflow-x-hidden '>
      <div className='flex flex-col w-[90%] h-[90%] '>
        <div className='pb-3 border-b'>
          <div className='flex items-center justify-between'>
            <div className='text-3xl font-bold'>{t(label)}</div>
            {rightButton}
          </div>
        </div>
        <div>
          {/* Table Header */}
          <div className='flex justify-between max-md:flex-col'>
            <div className='flex items-center'>
              {!isLoading && (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel={`${t("next")} >`}
                  onPageChange={handleNext}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(pageCount)}
                  initialPage={page - 1}
                  previousLabel={`< ${t("previous")}`}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  previousLinkClassName='pagination__link'
                  nextLinkClassName='pagination__link'
                  disabledClassName='pagination__link--disabled'
                  activeLinkClassName='pagination__link--active'
                />
              )}
            </div>
            <div className='flex items-center'>
              <div className='my-2 h-[36px] relative '>
                <input
                  autoFocus
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className='p-1 h-full border pr-[32px] outline-none rounded w-[250px]'
                  placeholder={t("search")}
                />
                <AiOutlineSearch
                  size={24}
                  color={Colors.mainAqwaBlue}
                  className='absolute -translate-y-1/2 top-1/2 right-1'
                />
              </div>
            </div>
          </div>
          {/* Table Body */}
          <div className='flex flex-col w-full h-full overflow-auto'>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {data && data.length !== 0 && (
                  <Table
                    unSelectedColumns={unSelectedColumns}
                    history={history}
                    sort={sort}
                    setSort={(lb) => setSort(lb)}
                    hrefId={hrefId}
                    onClickItem={(a) => onClickItem(a)}
                    isLoading={isLoading}
                    data={data}
                    columns={columns}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowPaginationData;
