import axios from 'axios';
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import StorageCreateInvoice from '../../../components/Storage/StorageCreateInvoice';

function ReturnNutritionItemsDetails() {
    const currentCenterId = '62d3de07e4b5f62ba339b22b'
    const currentUser = useSelector((state) => state.authentication.user);
    const history = useHistory()

    const onClickSubmit = async (items) => {

        try {
            const newItems = items.map(item => {
                const newItem = { ...item,itemId: item.productId}

                delete newItem.price
                delete newItem.productId
                // change quantity to negative
                newItem.quantity = -Math.abs(newItem.quantity)
                return newItem
            })
            const res = await axios.post('/nutrition-return-items/' + currentCenterId, { items: newItems, adminId: currentUser._id })
            // console.log(res.data)
            // alert('success')
            history.goBack()
        } catch (e) {
            console.log({ e })
            alert('error')
        }
    }
    return (
        <div className='flex flex-1 w-full h-full bg-white' >
            {true ? <div className='flex flex-col w-full h-full' >
                <StorageCreateInvoice stockQuery={'/nutrition-stocks'} id={'spoiled-items'} onClickSubmit={onClickSubmit} />
            </div> : null}
        </div>
    )
}

export default ReturnNutritionItemsDetails
