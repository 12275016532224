import axios from "axios";
import { store } from "../../App";

export const uri = {
    equipmentCustomers: "equipment-customers",
    equipmentRepaymentOfDebts: "equipment-repayment-of-debts",
    equipmentDebts: "equipment-debts",
    equipmentBuyActivities: "equipment-buy-activities",
    equipmentReturnActivities: "equipment-return-activities",
    equipmentSellActivities: "equipment-sell-activities",
    equipmentItems: "equipment-items",
    equipmentBrands: "equipment-brands",
    equipmentTransactions: "equipment-transactions",
    equipmentExpenses: "equipment-expenses",
    equipmentShippings: "equipment-shippings",
    equipmentCategories: "equipment-categories",
    equipmentPriceLists: "equipment-price-lists",
    'equipmentRODAkaPower': 'equipment-repayment-of-debts-aka-power',
    'equipmentDebtsAkaPower': 'equipment-debts-aka-power',
};

export const createSpecificEquipProperty = async (data, property) => {
    try {
        let CREATE_URI = "create";
        if (property === 'equipmentSellActivities')
            CREATE_URI = "sell";
        if (data._id) {
            await axios.put("/" + uri[property] + `/update/${data._id}`, data);
        } else {
            await axios.post("/" + uri[property] + "/" + CREATE_URI, data);
        }
    } catch (e) {
        throw e;
    }
};



export const createSpecificEquipPropertyReducer = (data, property, crudType) => {
    store.dispatch({
        type: crudType === 1
            ? "UPDATE_SPECIFIC_EQUIP_PROPERTY"
            : "CREATE_SPECIFIC_EQUIP_PROPERTY",
        data,
        property,
    });
}
export const deleteSpecificEquipPropertyReducer = (id, property) => {
    store.dispatch({
        type: "DELETE_SPECIFIC_EQUIP_PROPERTY",
        property,
        id,
    });
}
export const deleteSpecificEquipProperty = async (property, id) => {
    try {
        await axios.delete("/" + uri[property] + `/delete/${id}`);
        store.dispatch({
            type: "DELETE_SPECIFIC_EQUIP_PROPERTY",
            property,
            id
        });
    } catch (e) {
        throw e;
    }
};


export const fetchSpecificEquipProperty = async (property) => {
    try {
        const res = await axios.get(`/${uri[property]}`);

        store.dispatch({
            type: "FETCH_SPECIFIC_EQUIP_PROPERTY",
            data: res.data,
            property,
        });
    } catch (e) {
        throw e;
    }
};

export const getCurrentCustomerDebt = async (customerId) => {
    try {
        let totalDebts = 0;
        let totalReturnDebt = 0;
        await store.getState()?.equipments?.equipmentDebts?.map((d) => {
            if (d.customerId === customerId) {
                totalDebts += d.loan;
            }
        });
        await store.getState()?.equipments?.equipmentRepaymentOfDebts?.map((d) => {
            if (d.customerId === customerId) {
                totalReturnDebt += d.price;
            }
        });

        return totalDebts - totalReturnDebt;
    } catch (e) {
        throw e;
    }
};

export const updateEquipmentSearch = async (value) => {
    try {
        await store.dispatch({ type: "UPDATE_EQUIPMENT_SEARCH", data: value });
    } catch (e) {
        console.log(e);
    }
};
