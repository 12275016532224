import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";

const CREATE = "/credentials/create";
const DELETE = "/credentials/delete";
const UPDATE = "/credentials/update";

export const createCredential = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }

    store.dispatch({
      type: data._id ? "UPDATE_CREDENTIAL" : "CREATE_CREDENTIAL",
      data: res.data.data,
    });
  } catch (e) {
    throw e;
  }
};

export const fetchCredentials = async (data) => {
  try {
    const res = await axios.get(`/credentials`);

    store.dispatch({ type: "FETCH_CREDENTIALS", data: res.data });
  } catch (e) {
    throw e;
  }
};
export const fetchCredential = async (userId) => {
  try {
    const res = await axios.get(`/credentials?userId=${userId}`);
    return res.data
    // store.dispatch({ type: "FETCH_CREDENTIALS", data: res.data });
  } catch (e) {
    throw e;
  }
};

export const deleteCredential = async (id) => {
  try {
    await axios
      .delete(`${DELETE}/${id}`)
      .then((res) => {
        store.dispatch({ type: "DELETE_CREDENTIAL", data: id });
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (e) {
    throw e;
  }
};