import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";

const CREATE = "/courses/create";
const UPDATE = "/courses/update";

export const createCourse = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }
  } catch (e) {
    throw e;
  }
};

export const fetchRequestedCourse = async (startDate = dayjs().format('YYYY-MM-DD'), endDate) => {
  try {
    let query = `?startDate=${startDate}`;
    if (endDate) {
      query += `&endDate=${endDate}`;
    }
    const currentUser = store.getState().authentication.user
    if (currentUser.type === 17) {
      const courses = []
      //!only fetch specific center or data
      await Promise.all(currentUser.center.map(async (id) => {
        const res = await axios.get('/courses' + query + '&centerId=' + id);
        courses.push(...res.data)
      }))
      store.dispatch({ type: "FETCH_COURSES", data: courses, endDate });
      return
    }

    const res = await axios.get(`/courses${query}`);

    store.dispatch({ type: "FETCH_COURSES", data: res.data });
  } catch (e) {
    throw e;
  }
};

export const deleteCourse = async (id) => {
  try {
    await axios.delete(`/courses/delete/${id}`);
  } catch (e) {
    alert(e);
    throw e;
  }
};
