import React, { useEffect, useState } from 'react'
import { Error, fetcher, Loading } from '../UI/CentersViewData';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import CommonInputsContainer from '../../components/UI/TableView/CommonInputsContainer';
import InstanceIndex from '../../components/UI/AddingData/InstanceIndex';
import { t } from 'i18next';
import { showInIQD } from '../../customFunction/Numeral/CurrencyFormat';
import { DateInput } from '../Users/CreateUserScreen';
import { IoTrashOutline } from 'react-icons/io5';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../src/components/shadui/ui/alert-dialog';
import { buttonVariants } from '../../src/components/shadui/ui/button';
import axios from 'axios';
import { handleLoading } from '../../store/actions/loadingAction';

function SwimmingItemDetails() {
    const [currentData, setCurrentData] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false)

    const id = useParams().id;

    const { data, isLoading, error } = useSWR('/swimming-sales/' + id, fetcher)
    const history = useHistory();

    const onChangeValue = (type, value) => {
        let obj = currentData;
        if (type === 0) {
            obj.name = value;
        } else if (type === 1) {
            obj.iqd = Number(value);
        } else if (type === 2) {
            obj.centerId = value;
        } else if (type === 3) {
            obj.type = value;
        } else if (type === 4) {
            obj.date = value;
            obj.createdAt = dayjs(value).valueOf();

        } else if (type === 5) {
            obj.price = value;
        }

        setCurrentData({ ...obj });
    };

    const onSubmit = async () => {
        try {
            handleLoading();
            await axios.put('/swimming-sales/update/' + id, currentData)
            history.goBack();
            handleLoading();
        } catch (e) {
            handleLoading();
            alert(e);
        }
    };

    const setUp = () => {
        let objData = {};
        let check = data && data[0]
        if (check) {
            objData = { ...check };
        }

        setCurrentData(objData);
    };

    useEffect(() => {
        setUp();
    }, [data]);

    const onDeleteItem = async () => {
        try {
            handleLoading()
            await axios.delete('/swimming-sales/delete/' + id)
            history.goBack();
            handleLoading()
        } catch (e) {
            handleLoading()
            console.log({ e })
            alert(t('somethingWentWrong'))
        }
    };

    if (error) return <Error />

    if (isLoading) return <Loading />
    return (
        <div className='flex flex-col items-center flex-1 w-full '>
            <AlertDialog open={alertOpen} >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
                        <AlertDialogDescription>
                            {t('thisActionCannotBeUndone_swimming')}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
                        <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <CommonInputsContainer onSave={onSubmit} title={t('item')} customIcon={<IoTrashOutline color="red" onClick={() => setAlertOpen(true)} size={30} />} warning={t("pleaseBeCarefulOfChangingMembership")}>
                {currentData&&currentData._id && <>
                    <InstanceIndex label={t("id")} value={`#${currentData._id ? currentData._id : ''}`} />
                    <InstanceIndex label={t("name")} value={currentData.name} />
                    <InstanceIndex label={t("price")} value={(showInIQD(currentData.iqd, 'IQD') + ' - ' + showInIQD(currentData.price))} />
                    <InstanceIndex label={t("amountByCustomer")} value={getAmountByCustomer(currentData.paymentDetails, currentData.price)} />
                    <DateInput
                        label={`${t("date")}`}
                        placeHolder={t("date")}
                        value={currentData?.date}
                        onChangeValue={onChangeValue}
                        type={4}
                    />
                </>}

            </CommonInputsContainer>
        </div>
    );
}

export const getAmountByCustomer = (paymentDetails, regularPrice) => {
    let amount = 0
    if (paymentDetails.payedType === 0) {
        // if customer paid by dollar and have change
        let price = 0
        if (paymentDetails.changePrice !== 0) {
            if (paymentDetails.changeType === 1) {
                price = regularPrice
            } else if (paymentDetails.changeType === 0) {
                price = paymentDetails.amountByCustomer - paymentDetails.changePrice
            }
        }
        // if customer paid by dollar and have no change
        else {
            price = paymentDetails.amountByCustomer
        }
        amount = showInIQD(price, 'USD')
    } else if (paymentDetails.payedType === 1) {
        amount = showInIQD(paymentDetails.amountByCustomer - paymentDetails.changePrice)
    } else {
        amount = showInIQD(paymentDetails.iqdAmount) + ' + ' + showInIQD(paymentDetails.dollarAmount, 'USD')
    }

    return amount
}

export default SwimmingItemDetails
