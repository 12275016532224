const initialState = { supplementsStorageActivities: null };

const supplementsStorageActivities = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SUPPLEMENTS_STORAGE_ACTIVITIES":
      return {
        ...state,
        supplementsStorageActivities: action.data,
      };

    case "CREATE_SUPPLEMENTS_STORAGE_ACTIVITIES": {
      let dt = state.supplementsStorageActivities.concat(action.data);
      return {
        ...state,
        supplementsStorageActivities: dt,
      };
    }
    case "UPDATE_SUPPLEMENTS_STORAGE_ACTIVITIES": {
      let dt = state.supplementsStorageActivities.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        supplementsStorageActivities: dt,
      };
    }
    default:
      return state;
  }
};

export default supplementsStorageActivities;
