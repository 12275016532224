import React from "react";
import MainChart from "./MainChart";
import LeftPart from "./LeftPart";

const Upper = ({ data, duration }) => {
  return (
    <div className="flex flex-wrap relative p-[10px] items-center justify-between">
      {data && <LeftPart data={data} />}
      {data && (
        <MainChart
          data={data}
          duration={duration}
        />
      )}

    </div>
  );
};

export default Upper;