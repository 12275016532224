import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity } from "react-native-web";
import Colors from "../../../constant/Colors";
import { useTranslation } from "react-i18next";
import fuzzysort from "fuzzysort";
import Features from "../../../data/Features/Features";
import { t } from "i18next";
import { IoIosCloseCircleOutline } from "react-icons/io";

const SelectUserModal = ({
  customers,
  toggleUserModalHandler,
  onChangeCurrentUser,
  currentUser,
  isPackage,
  version,
  children,
  width = '70%',
  height = '70%',
}) => {
  const [currentCustomer, setCurrentCustomer] = useState(false);
  const [search, setSearch] = useState();

  const setUpCurrentCustomer = () => {
    try {
      let arr = customers;

      let newArr = [];
      let keywords = search;

      let results = [];

      if (keywords && keywords != null && keywords != "" && keywords != " ") {
        results = fuzzysort.go(keywords, arr, {
          keys: ["name", "phoneNumber"],
          threshold: false,
        });
        results.forEach((rs) => {
          newArr.push(rs.obj);
        });
      } else {
        newArr = arr;
      }
      newArr = newArr.sort((a, b) => b.createdAt - a.createdAt);
      newArr.reverse();
      setCurrentCustomer(newArr);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    setUpCurrentCustomer();
  }, [customers, search]);

  const onSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };

  return (
    <View
      style={{
        height,
        width,
        backgroundColor: "#fff",
        alignSelf: "center",
        shadowColor: Colors.mainAqwaBlue,
        marginTop: 20,
        shadowOpacity: 14.5,
        shadowRadius: 20.65,
        borderRadius: 10,
        overflow: "hidden",
      }}>
      {(!version || version === 1) && <>
        <View
          style={{
            height: "10%",
            width: "100%",
            backgroundColor: Colors.whiteCardColor,
          }}>
          <input
            placeholder='Search'
            value={search}
            onChange={onSearch}
            className='w-full h-full p-10 border-none outline-none bg-whiteCardColor'
          />
        </View>
        <View style={{ height: "70%", marginTop: 20 }}>
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}>
              {currentCustomer &&
                currentCustomer.map((dc, i) => {
                  return (
                    <CustomerIndex
                      key={i}
                      isPackage={isPackage}
                      customer={dc}
                      onChangeCurrentUser={onChangeCurrentUser}
                      currentUser={currentUser}
                    />
                  );
                })}
            </View>
          </ScrollView>
        </View>
      </>}
      {version === 2 && <>
        {children}
      </>}
      <CancelButton onClick={toggleUserModalHandler} />
    </View>
  );
};

const CancelButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className="absolute top-1 right-1" >
      <IoIosCloseCircleOutline color="red" size={28} />
    </div >
  );
};

const CustomerIndex = ({
  customer,
  isPackage,
  onChangeCurrentUser,
  currentUser,
}) => {
  const onPress = () => {
    onChangeCurrentUser(customer);
  };

  return (
    <TouchableOpacity
      style={{
        width: "40%",
        margin: 10,
        backgroundColor:
          currentUser?._id === customer?._id ? "#00ab41" : "#f0f0f0",
        borderRadius: 5,
        minHeight: 50,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
      onPress={onPress}>
      <h1
        numberOfLines={1}
        style={{
          textTransform: "capitalize",
          color: currentUser?._id === customer?._id ? "#fff" : "#292929",
          width: "50%",
        }}>
        {isPackage ? customer?.labelDetails.inEn.title + "-" : customer?.name}
        <br />
        {isPackage &&
          customer?.features.map((ft, i) => (
            <span key={i}>- {t(Features.find((a) => a.id === ft).label)}</span>
          ))}
      </h1>
    </TouchableOpacity>
  );
};

export default SelectUserModal;

const styles = StyleSheet.create({});
