import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { View, Modal, ScrollView } from 'react-native-web'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Colors from '../../constant/Colors';
import { CourseTable } from './MonthlyReportScreen';
import AdminTypes from "../../data/Admin/AdminTypes";
import i18next, { t } from 'i18next';
import dayjs from 'dayjs';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { AiOutlineCloseCircle, AiOutlineSave } from 'react-icons/ai';
import useSWR from 'swr';
import { fetcher } from '../UI/CentersViewData';
import axios from 'axios';

const SupplementsLabels = ['num', 'nameOfTheEmployee', 'fixedSalary', 'position', 'salaryByDate']

function SalaryScreen() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const { data, error, isLoading } = useSWR('/admins?activeEmployee=true', fetcher)

  const [selectedEmployee, setSelectedEmployee] = useState()
  const [totalSalary, setTotalSalary] = useState(0)
  const language = i18next.language
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (data) setup()
  }, [data, selectedEmployee])

  const setup = async () => {
    const totalSalary = data.reduce((a, b) => a + (b?.salary?.sort((a, b) => b.date - a.date)[0]?.amount * 1 || 0), 0)
    setTotalSalary(totalSalary)

  }

  const onClickEmployeeSalary = (dt) => {
    setIsOpen(!modalIsOpen)
    setSelectedEmployee(dt)
  }


  const onAddSalary = () => {
    if (selectedEmployee.salary && selectedEmployee.salary.find(a => a.isNew)) return
    let newSalary = selectedEmployee.salary

    if (!selectedEmployee.salary) {
      newSalary = [{
        amount: null,
        date: null,
        isNew: true
      }]
    } else {
      newSalary.unshift({
        amount: null,
        date: null,
        isNew: true
      })
    }

    setSelectedEmployee({ ...selectedEmployee, salary: newSalary })
  }

  const onDeleteSalary = (index) => {
    let newSalary = selectedEmployee.salary
    newSalary.splice(index, 1)
    setSelectedEmployee({ ...selectedEmployee, salary: newSalary })
    onSaveSalary()
  }

  const onSaveSalary = async () => {
    try {
      // validation
      if (selectedEmployee.salary.find(a => (a.isNew && (!a.amount || !a.amountUsd || !a.date)))) {
        alert('Please fill all fields')
        return
      }
      const data = { ...selectedEmployee, }
      data.currentPassword = selectedEmployee.password;
      data.password = ''
      data.salary = data.salary.map((p) => {
        delete p.isNew
        return p
      })

      await axios.put('/admins/', data)
      setIsOpen(false)

    } catch (e) {
      console.log(e);
      alert(e)
    }
  }

  return (
    <div className='flex-1 flex w-full h-screen bg-[#f1f1f1]'>
      <ScrollView>

        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} className="flex flex-col flex-wrap items-center w-full mt-12 justify-evenly">
          <View
            style={{
              width: '90%',
              backgroundColor: Colors.mainWhite,
              padding: 10,
              borderRadius: 5,
              marginVertical: 30
            }}
          >
            <h1 className="mb-4 text-lg" > {t('salariesOfEmployees', { price: totalSalary.toLocaleString() })}</h1>
            <CourseTable labels={SupplementsLabels} >
              {data && data.map((dt, index) => {
                const lastSalary = dt.salary && dt.salary.sort((a, b) => new Date(b.date) - new Date(a.date))[0]

                return <tr style={{ cursor: 'pointer' }} onClick={onClickEmployeeSalary.bind(this, dt)} key={index} >
                  <td>
                    {index + 1}
                  </td>
                  <td>{dt.name}</td>
                  <td>{lastSalary && lastSalary.amount ? Number(lastSalary.amount).toLocaleString() : t('salaryNotSpecified')}</td>
                  <td>{t(AdminTypes.find(a => a.type === dt.type).label)}</td>
                  <td>{lastSalary && lastSalary.date ? dayjs(lastSalary.date).format('YYYY-MM-DD') : t('dateNotSpecified')}</td>
                </tr>
              })}
            </CourseTable>
          </View>
        </div>

      </ScrollView>
      <Modal visible={modalIsOpen} transparent>
        <View
          style={{
            height: "70%",
            width: "70%",
            backgroundColor: "#fff",
            alignSelf: "center",
            shadowColor: Colors.mainRedColor,
            marginTop: 60,
            shadowOpacity: 14.5,
            shadowRadius: 20.65,
            borderRadius: 10,
            overflow: "hidden",
            padding: 10,
            // direction: 'rtl'
          }}
        >
          <div className='flex items-center justify-between mb-3' >

            <h1 style={{ marginBottom: 10 }} >{t('salaryListOf', { name: selectedEmployee?.name })}</h1>
            <div className='flex items-center justify-between'>
              <IoIosCloseCircleOutline onClick={() => setIsOpen(false)} style={{ cursor: 'pointer' }} size={28} color='red' />
              {selectedEmployee && selectedEmployee.salary && selectedEmployee.salary.find(a => a.isNew) ? <AiOutlineSave color='green' onClick={onSaveSalary} style={{ cursor: 'pointer', marginRight: 5 }} size={27} /> : <IoAddCircleOutline onClick={onAddSalary} style={{ cursor: 'pointer' }} size={28} color='green' />}
            </div>
          </div>
          <table className='my-table' >
            <thead>
              <tr>
                <th>{t('salary')}</th>
                <th>{t('salaryUSD')}</th>
                <th>{t('date')}</th>

              </tr>
            </thead>
            <tbody>
              {selectedEmployee && selectedEmployee.salary && selectedEmployee.salary.sort((a, b) => b.date - a.date).map((dt, index) => {
                const isNew = dt.isNew

                return <tr key={index} >
                  <td>
                    {!isNew ? Number(dt.amount).toLocaleString() : <input className='w-3/4 h-full p-1 border outline-none' placeholder={t('salary')} type="number" value={dt.amount} onChange={(e) => {
                      const newSalary = selectedEmployee.salary
                      newSalary[index].amount = e.target.value
                      setSelectedEmployee({ ...selectedEmployee, salary: newSalary })
                    }} />}

                  </td>
                  <td>
                    {!isNew ? Number(dt.amountUsd || 0).toLocaleString() : <input className='w-3/4 h-full p-1 border outline-none' placeholder={t('salaryUSD')} type="number" value={dt.amountUsd} onChange={(e) => {
                      const newSalary = selectedEmployee.salary
                      newSalary[index].amountUsd = e.target.value
                      setSelectedEmployee({ ...selectedEmployee, salary: newSalary })
                    }} />}

                  </td>
                  <td className='relative' >{isNew ? <input
                    className="border-none outline-none"
                    title="test"
                    type={"date"}
                    value={dayjs(dt.date).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      const newSalary = selectedEmployee.salary
                      newSalary[index].date = dayjs(e.target.value).valueOf()
                      setSelectedEmployee({ ...selectedEmployee, salary: newSalary })
                    }}
                  /> : dayjs(dt.date).format('YYYY-MM-DD')}

                    {user.type === 0 && !isNew && <AiOutlineCloseCircle onClick={onDeleteSalary.bind(this, index)} size={20} color='red' className='absolute top-[50%] cursor-pointer -translate-y-[50%] right-1' />}
                  </td>
                </tr>
              })}
            </tbody>

          </table>

        </View>
      </Modal>
    </div>
  )
}

export default SalaryScreen
