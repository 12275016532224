import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useSWR from "swr";
import { useQuery } from "../ClassSchedule/ClassSchedulesDetails";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Error, Loading, fetcher } from "../../UI/CentersViewData";
import CommonInputsContainer from "../../../components/UI/TableView/CommonInputsContainer";
import { t } from "i18next";
import InstanceIndex from "../../../components/UI/AddingData/InstanceIndex";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../src/components/shadui/ui/select";
import { DateInput, TextInput } from "../../Users/CreateUserScreen";
import dayjs from "dayjs";
import axios from "axios";
import ArrayIndex from "../../../components/UI/AddingData/ArrayIndex";

function NotificationDetailsScreen() {
  const { notificationId } = useParams();
  const isNew = useQuery().get("isNew");
  const { data, isLoading, error } = useSWR(
    "/notifications/" + notificationId + "?isNew=" + isNew,
    fetcher
  );
  const [currentData, setCurrentData] = useState();
  const history = useHistory();

  useEffect(() => {
    setup();
  }, [data]);

  const setup = () => {
    if (data && data[0]) {
      setCurrentData(data[0]);
    }
  };

  const onChangeValue = (key, value, child) => {
    if (child) {
      setCurrentData({
        ...currentData,
        [child]: {
          ...(currentData && currentData[child] ? currentData[child] : {}),
          [key]: value,
        },
      });
      return;
    }
    setCurrentData({ ...currentData, [key]: value });
  };

  const onSave = async () => {
    try {
      if (
        !currentData ||
        currentData.type === undefined ||
        !currentData.en.title ||
        !currentData.en.body ||
        (currentData.type !== 2 && !currentData.ar.body) ||
        (currentData.type !== 2 && !currentData.ar.title) ||
        (currentData.type !== 2 && !currentData.ku.title) ||
        (currentData.type !== 2 && !currentData.ku.body) ||
        (currentData.type === 1 && !currentData.sendDate) ||
        (currentData.type === 1 && currentData.sendType === undefined)
      ) {
        alert("Please fill all the fields");
        return;
      }
      if (isNew) {
        await axios.post("/notifications/", currentData);
      } else await axios.put("/notifications/", currentData);
      history.goBack();
    } catch (e) {
      console.log(e);
      alert(e.response.data.message);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <div className='flex flex-1 w-full'>
      <CommonInputsContainer
        onSave={currentData?._id ? null : onSave}
        title={t("notification")}>
        <InstanceIndex
          label={t("id")}
          value={`#${currentData && currentData._id ? currentData._id : ""}`}
        />
        <TextInput
          placeHolder={t("enTitle")}
          onChangeValue={(type, text) => onChangeValue("title", text, "en")}
          label={t("enTitle")}
          value={currentData?.en?.title}
        />
        <TextInput
          placeHolder={t("enBody")}
          onChangeValue={(type, text) => onChangeValue("body", text, "en")}
          label={t("enBody")}
          value={currentData?.en?.body}
        />
        {/* //!ku */}
        <TextInput
          placeHolder={t("kuTitle")}
          onChangeValue={(type, text) => onChangeValue("title", text, "ku")}
          label={t("kuTitle")}
          value={currentData?.ku?.title}
        />
        <TextInput
          placeHolder={t("kuBody")}
          onChangeValue={(type, text) => onChangeValue("body", text, "ku")}
          label={t("kuBody")}
          value={currentData?.ku?.body}
        />
        {/* //!ar */}
        <TextInput
          placeHolder={t("arTitle")}
          onChangeValue={(type, text) => onChangeValue("title", text, "ar")}
          label={t("arTitle")}
          value={currentData?.ar?.title}
        />
        <TextInput
          placeHolder={t("arBody")}
          onChangeValue={(type, text) => onChangeValue("body", text, "ar")}
          label={t("arBody")}
          value={currentData?.ar?.body}
        />

        <div className='flex items-center justify-between p-6'>
          <p>{t("notificationType")}</p>
          <Select
            value={currentData?.type}
            onValueChange={(a) => onChangeValue("type", Number(a))}>
            <SelectTrigger className='w-3/5 rounded-none outline-none'>
              <SelectValue placeholder={t("notificationType")} />
            </SelectTrigger>
            <SelectContent className='bg-white'>
              {[
                { type: 0, label: "direct" },
                { type: 1, label: "static" },
                { type: 2, label: "allUsers" },
              ].map((role, index) => (
                <SelectItem key={index} value={role.type}>
                  {t(role.label)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {currentData?.type === 1 && (
          <>
            <DateInput
              value={
                currentData?.sendDate
                  ? dayjs(currentData?.sendDate).format("YYYY-MM-DD")
                  : null
              }
              onChangeValue={(type, value) => onChangeValue("sendDate", value)}
              label={t("sendDate")}
            />
            <ArrayIndex
              label={t("sendType")}
              data={[
                { type: 0, label: "oneTime" },
                { type: 1, label: "everyTimeInSameMonthAndDay" },
              ]}
              valType={1}
              value={currentData?.sendType}
              index='type'
              onlyReturnValue
              onChangeValue={(a) => onChangeValue("sendType", a)}
            />
          </>
        )}
      </CommonInputsContainer>
    </div>
  );
}

export default NotificationDetailsScreen;
