export default {
  mainPurple: "#521e52", //rgba(82, 30, 82,1)
  mainDark: "#191414", //rgba(09, 09, 09,1)
  mainYellow: "#feab15", //rgba(254, 171, 21,1)
  mainWhite: "#fff",

  whiteCardColor: "#e1e1e1",
  whiteCardIndexColor: "rgba(256,256,256,0.5)",

  darkBarColor: "#292929",
  darkCardColor: "#242424",
  darkCardIndexColor: "#303030",
  darkCardExeternalIndex: "#606060",

  soldColor: "#e04a3b",
  mainAqwaBlue: "#009EDF",

  mainGreenColor: "#1dbc60",
  mainRedColor: "#e74b3c",
};
