import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderRender from "../../../components/UI/Table/HeaderRender";
import ItemRender from "../../../components/UI/Table/ItemRender";
import HeaderContainer from "../../../components/UI/‌Header/HeaderContainer";
import { shortIdGenerator } from "../../../customFunction/IDGenerator";
import { fetchCenters } from "../../../store/actions/centersAction";
import { handleLoading } from "../../../store/actions/loadingAction";

const headerLables = ["id", "name", "createdAt"];

function CenterScreen() {
  const { t } = useTranslation();
  const Ceneters = useSelector((state) => state.centers.centers);

  const history = useHistory();

  const onPressItem = (data) => {
    if (data._id) {
      history.push(`${window.location.pathname}/${data._id}`);
    } else {
      let id = shortIdGenerator();
      history.push(`${window.location.pathname}/${id}`);
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <HeaderContainer label={t("center")} addBtn onPressBtn={onPressItem} />

      <HeaderRender headerLables={headerLables} />
      <ItemRender
        onPressItem={onPressItem}
        headerLables={headerLables}
        datas={Ceneters}
        type={2}
      />
    </div>
  );
}

export default CenterScreen;
