import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { useHistory } from 'react-router-dom/'
import ViewNutritionInvoice from '../../../components/cafeteria/ViewNutritionInvoice'
import NutritionCreateInvoice from '../../../components/cafeteria/NutritionCreateInvoice'
import getSellerDetails from '../../../customFunction/nutrition/getSellerDetails'
import useQuery from '../../../customFunction/useQuery'

function CafeteriaExpiredProductsDetails() {
    const { expireId } = useParams()
    const isNew = useQuery().get('new')
    const { data, isLoading, error } = useSwr('/cafeteria-expired-products/' + expireId + '?isNew=' + isNew, fetcher)
    const [currentData, setCurrentData] = useState()
    const [isLoadingg, setIsLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData(data[0])
        }
    }

    const onClickSubmit = async (currentProducts, currentCustomer) => {
        setIsLoading(true)
        try {
            await axios.post('/cafeteria-expired-products', {
                items: currentProducts,
                sellerDetails: getSellerDetails(),
            })
            setIsLoading(false)
            history.goBack()
        } catch (e) {
            alert(e)
        }

    }
    // console.log(currentData)
    // if (isLoadingg) return <LoadingForSave />

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }

    return (
        <div className='flex flex-1 w-full' >
            {isNew ? <NutritionCreateInvoice id='expired-products' onClickSubmit={onClickSubmit} >
                <div className='flex items-center justify-between w-full text-4xl font-bold' >
                    <h1>Create Expired Products Invoice </h1>
                </div>
            </NutritionCreateInvoice> : <ViewNutritionInvoice id='sales' invoiceProducts={currentData?.items} >
                <div className='flex items-center justify-between w-full text-4xl font-bold' >
                    <h1>Expired Products Invoice </h1>
                    {/* <h1 className='text-lg opacity-50' >#{currentData?.numberId}</h1> */}
                    <button className='p-2 px-5 text-base font-medium text-white bg-red-500 cursor-pointer' >Print</button>
                </div>

            </ViewNutritionInvoice>}
        </div>
    )
}

export default CafeteriaExpiredProductsDetails
