import React, { useState, useEffect } from "react";
import sidebarItems from "../../components/utils/sidebarItems";
import { Link, useLocation } from "react-router-dom";
import i18next, { t } from "i18next";
import { useSelector } from "react-redux";
import './sidebar.module.css'
import CustomModal from "../../ui/ui/custom-modal";
import SettingModal from "../../components/UI/Sidebar/SettingModal";

function SideBar({ onChangeLanguage, currentLanguage }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const currentAdmin = useSelector((state) => state.authentication.user);
  const [toggle, setToggle] = useState(false);
  const requestsLength = useSelector(state => state.requests?.length)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const language = i18next.language
  const [modal, setModal] = React.useState(false)

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    if (!curPath) {
      setActiveIndex(0);
      return;
    }
    const activeItem = sidebarItems.findIndex(
      (item) => item.section === curPath
    );

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    }

    handleResize(); // Call it initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClickExpandButton = () => {
    const nav = document.querySelector('nav');
    nav.classList.toggle('collapsed');
    setToggle(!toggle)

    if (!toggle === true && window.innerWidth < 639) {
      const nav = document.querySelector('nav');

      nav.classList.toggle('toggle');
    } else {
      const nav = document.querySelector('nav');

      nav.classList.remove('toggle');
    }
  }

  const onChangeModal = () => {
    setModal(!modal)
  }

  return (
    <nav className={`${isCollapsed ? 'collapsed overflow-hidden max-sm:w-full max-sm:p-1 max-sm:h-14 min-w-[80px] ' : ''} `}  >
      {modal && <CustomModal className={`h-[80%] ${language === 'en' ? 'ltr' : 'rtl'} w-[80%] text-black rounded-md z-[300]`} title={t('settings')} onChangeModal={onChangeModal}>
        <SettingModal onChangeLanguage={onChangeLanguage} />
      </CustomModal>}
      <div className="sidebar-top max-sm:w-full max-sm:flex-row max-sm:justify-between">
        <div className="logo__wrapper">
          <img alt='78gym' src={require("../../assets/images/78gym.png")} width={130} height={130} />
        </div>
        <div onClick={onClickExpandButton} className="expand-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 7l5 5l-5 5"></path>
            <path d="M13 7l5 5l-5 5"></path>
          </svg>
        </div>
      </div>
      <div className={`sidebar-links`}>
        <ul>
          {sidebarItems.map((item, index) => {
            if (item.type.find((a) => a === currentAdmin?.type) !== undefined) {
              let badgeIcon

              if (item.section === 'requests' && requestsLength !== 0) {
                badgeIcon = <div style={{ height: 15, fontSize: 10, fontWeight: 'bold', width: 15 }} className="absolute flex items-center justify-center ml-2 font-bold text-white bg-red-600 rounded-full top-[2px]" >{requestsLength}</div>
              }

              return (
                <Index
                  key={index}
                  item={item}
                  toggle={toggle}
                  isCollapsed={isCollapsed}
                  onClickExpandButton={onClickExpandButton}
                  badgeIcon={badgeIcon || (item.badgeIcon && item.badgeIcon(t('beta')))}
                  isActive={activeIndex === index}
                />
              );
            }
          })}
        </ul>
      </div>
      <div className=" sidebar-links sidebar-bottom">
        <ul>
          <li>
            <a className="cursor-pointer" href={null} onClick={onChangeModal} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#91959C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
              </svg>
              <span className="link hide">{t('settings')}</span>
            </a>
          </li>
        </ul>
        <div className="divider"></div>
        <div className="sidebar__profile">
          <div className="avatar__wrapper">
            <img className="avatar" src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png" alt="Joe Doe Picture" />
          </div>
          <section className="avatar__name hide">
            <div className="user-name">{currentAdmin.name}</div>
            <div className="email">{currentAdmin.email}</div>
          </section>
        </div>
      </div>
    </nav>
  );
}

const Index = ({ item, isActive, badgeIcon, onClickExpandButton, isCollapsed, toggle }) => {

  return (
    <li onClick={window.innerWidth < 639 ? onClickExpandButton : null} className={`${isActive ? "active" : ''} !relative`}>
      <Link to={item.to} className='flex flex-wrap items-center justify-between' >
        <div className="relative flex items-center" >
          {item.icon}
          <span className="link hide">{t(item.display)}</span>
        </div>
        {(isCollapsed && item.hideIconOnSmallScreen) ? null : <div className={`max-sm:${item.hideIconOnSmallScreen ? 'hidden' : 'flex w-full h-full'}`} >
          {badgeIcon}
        </div>}
      </Link>
    </li>

  );
};

export default SideBar;
