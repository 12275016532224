

export const updateDuration = ({ startDate, endDate }) => {
    return async (dispatch, getState) => {
        console.log(startDate, endDate)
        dispatch({
            type: "UPDATE_SUPPLEMENTS_DURATION",
            data: { startDate, endDate },
        });
    };
};