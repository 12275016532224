import { showInIQD } from "../Numeral/CurrencyFormat";

export const calculateCaffetteriaTotalPrice = (data, id, currency) => {
  let val = 0;

  if (id && data) data = data.filter((a) => a.centerId === id);

  if (data)
    data.forEach((dt) => {
      let curr = "USD";
      let value = currency === 'IQD' ? dt.iqd : dt.price;

      val += showInIQD(value, currency, curr, true) || 0;
    });
  return val;
};
