export default [
  "#042940",
  "#005C53",
  "#9FC131",
  "#DBF227",
  "#D6D58E",
  "#23606E",
  "#662400",
  "#B33F00",
  "#FF6B1A",
  "#006663",
  "#00B3AD",
  "#F2668B",
  "#535923",
  "#B7BF7A",
  "#F2E9E4",
  "#BF846F",
  "#F2B8A2",
  "#F2637E",
  "#F20CCC",
  "#0476D9",
  "#F2E30F",
  "#F2CC0C",
  "#E6F699",
  "#FFC79F",
  "#FF787A",
  "#94D4C5",
  "#FF6CCD",
  "#05F2F2",
  "#F20505",
  "#F20587",
  "#F2055C",
  "#593E25",
  "#024959",
  "#7A577A",
  "#F2668B",
  "#F26800",
  "#A1C7E0",
  "#00CCC0",
  "#CDA7F2",
  "#B679F2",
  "#7386BF",
  "#026E81",
  "#520773",
  "#73022C",
  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#000', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
];
