const initialState = { credentials: null };

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CREDENTIALS":
      return {
        ...state,
        credentials: action.data,
      };

    case "CREATE_CREDENTIAL": {
      let dt = state.credentials.concat(action.data);
      return {
        ...state,
        credentials: dt,
      };
    }
    case "UPDATE_CREDENTIAL": {
      let dt = state.credentials.map((order) => {
        if (order._id === action.data._id) {
          return action.data;
        }
        return order;
      });
      return {
        ...state,
        credentials: dt,
      };
    }
    case "DELETE_CREDENTIAL": {
      let dt = state.credentials.filter((a) => a._id !== action.data);
      return {
        ...state,
        credentials: dt,
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
