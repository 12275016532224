import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { showInIQD } from "../../../customFunction/Numeral/CurrencyFormat";

const TotalBenfitCard = ({ data }) => {
  const currency = useSelector((state) => state.analytics.currency);

  const { t } = useTranslation();
  const expenses = ['gymExpenses', 'cafeteriaExpenses', 'bigExpenses'].reduce((acc, index) => acc + data.expenses[index][currency.toLowerCase()], 0)
  const revenue = ['memberships', 'courses', 'supplements', 'cafeteria', 'swimmingItems'].reduce((acc, index) => acc + data.revenue[index].prices.revenue[currency.toLowerCase()], 0)
  const newRevenueIQD = Object.keys(data.revenue).reduce((acc, index) => acc + (data.revenue[index].prices.paymentDetails?.totalAvailable.iqd || 0), 0)
  const newRevenueDOLLAR = Object.keys(data.revenue).reduce((acc, index) => acc + (data.revenue[index].prices.paymentDetails?.totalAvailable?.dollar || 0), 0)
  const newExpensesIQD = data.expenses.paymentDetails?.totalAvailable.iqd
  const newExpensesDOLLAR = data.expenses.paymentDetails?.totalAvailable.dollar
  // console.log({ data })

  return (
    <div className="w-full p-3 bg-white ">
      <h1
        style={{
          fontSize: 13,
          opacity: 0.5,
        }}
      >
        {t("reportSummary")}
      </h1>
      <div
        className="flex flex-wrap justify-between w-full"
      >
        <Index value={showInIQD(revenue, currency)} label={t("revenue")} />
        <Index
          value={showInIQD(expenses, currency)}
          label={t("expenses")}
          color={'rgba(100,0,0,1)'}
        />
        <Index value={showInIQD(Number(revenue - expenses), currency)} label={t("profit")} />
        {<Index value={showInIQD(newRevenueDOLLAR - newExpensesDOLLAR, 'USD') + ' - (' + showInIQD(newRevenueIQD - newExpensesIQD, 'IQD') + ')'} label={t("profit")} />}

      </div>
    </div>
  );
};

const Index = ({ label, value, desciption, color }) => {
  const currency = useSelector((state) => state.analytics.currency);

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <h1
        style={{
          color: color || "#292929",
          fontSize: 12,
        }}
      >
        {label}
      </h1>
      <h1
        style={{
          color: color || "#292929",
          fontSize: 12,
          opacity: 0.5,
        }}
      >
        {desciption}
      </h1>
      <h1
        style={{
          color: color || "#292929",
          opacity: 1,
          fontSize: 30,
          marginTop: 8,
          fontFamily: "Montserrat",

        }}
      >
        {value || 0}
      </h1>
    </div>
  );
};

export default TotalBenfitCard;