import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ViewCafeteriaInvoice from '../../../components/Storage/ViewStorageInvoice'
import axios from 'axios'
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuTrigger } from '../../../src/components/shadui/ui/dropdown-menu'
import { IoMdMore } from 'react-icons/io'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../../src/components/shadui/ui/alert-dialog'
import { buttonVariants } from '../../../src/components/shadui/ui/button'
import { t } from 'i18next'

function SwimmingReturnsViewInvoice() {
    const data = useLocation().state.data
    const [alertOpen, setAlertOpen] = useState(false)
    const history = useHistory()

    const columns = [
        { label: "#", value: (a) => a._id.slice(0, 10) },
        {
            label: "image",
            value: (a) => (
                <img
                    alt=''
                    className='object-contain w-20 h-20'
                    src={a.productDetails.imageUri}
                />
            ),
        },
        { label: "name", value: (a) => a.productDetails.name },
        { label: "quantity", value: (a) => Math.abs(a.quantity) },
    ]

    const onDeleteItem = async () => {
        try {
            await axios.delete('/swimming-return-items/' + data._id)
            history.goBack()

        } catch (e) {
            console.log(e)
            alert('error')
        }
    }

    return (
        <div className='flex flex-col items-center justify-center flex-1 bg-white' >
            <ViewCafeteriaInvoice columns={columns} id='swimming-return-items' invoiceProducts={data?.items} >
                <div className='flex flex-col w-full ' >
                    <div className='flex items-center justify-between' >
                        <h1 className='text-4xl font-bold' >Products </h1>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <IoMdMore size={30} />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuLabel className='text-red-500 cursor-pointer' onClick={() => setAlertOpen(true)} >{t('delete')}</DropdownMenuLabel>
                                <AlertDialog open={alertOpen} >
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>{t('pleaseMakeSureThisAction')}</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                {t('deleteItemDesc')}
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel onClick={() => { setAlertOpen(false) }} >{t('cancel')}</AlertDialogCancel>
                                            <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={onDeleteItem}>{t('delete')}</AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <h1 className='mt-5 font-medium' >By: {data.admin.name} </h1>
                </div>

            </ViewCafeteriaInvoice>
        </div>
    )
}

export default SwimmingReturnsViewInvoice
