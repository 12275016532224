import { store } from "../../App";
import { showInIQD } from "../Numeral/CurrencyFormat";

export const calculateSupplementsTotalPrice = (data, id, currency) => {
  let val = 0;

  if (id) data = data.filter((a) => a.centerId === id);

  if (data)
    data.forEach((dt) => {
      let curr = "USD";
      let value = currency === 'IQD' ? dt.iqd : dt.price;

      val += showInIQD(value, currency, curr, true) || 0;
    });
  return val;
};


export const calculateSupplementsWithAllDetail = (data, id, currency) => {
  let isAsudaCity = id === '62d3de11e4b5f62ba339b22f'
  let curr = "USD";

  const admins = store.getState().admins.admins;
  let swimmingData = []
  let alldata = []
  if (id && id !== 'total') data = data.filter((a) => a.centerId === id);
  if (isAsudaCity) {
    data = data.filter((a) => a.type !== 2 && a.type !== 3);
  }
  if (data) {
    const trainers = admins.filter(a => (id === 'total' ? true : a.center && a.center.includes(id)) && a.type === 3 && !a.isHide);
    trainers.map(trainer => {
      const TRAINER_RATIO = 15;

      let numOfSellSupplements = 0;

      let totalSellSupplements = 0;

      let totalSellSupplementsTrainerRatio = 0;

      let totalSellSupplementsOurRatio = 0

      let filteredSupplements = data.filter(a => a.trainerId === trainer._id);
      numOfSellSupplements = filteredSupplements.length;
      filteredSupplements.map(course => {
        //!Normal Course
        totalSellSupplements += currency === 'IQD' ? course.iqd : Number(course.price);

        totalSellSupplementsTrainerRatio += ((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * TRAINER_RATIO;

        totalSellSupplementsOurRatio += ((currency === 'IQD' ? course.iqd : Number(course.price)) / 100) * (100 - TRAINER_RATIO);

      })

      alldata.push({
        trainerName: trainer.name,
        trainerId: trainer._id,
        numOfSellSupplements,
        totalSellSupplements,
        totalSellSupplementsTrainerRatio,
        totalSellSupplementsOurRatio

      })
    })

    let numOfSellSupplements = 0;

    let totalSellSupplements = 0;

    let totalSellSupplementsTrainerRatio = 0;

    //!Aka Fitness supps
    data.map((supp) => {
      if (!supp.trainerId) {
        numOfSellSupplements++;
        let value = currency === 'IQD' ? supp.iqd : supp.price;
        totalSellSupplements += value
      }
    })
    alldata.push({
      trainerName: '78 Gym',
      trainerId: id,
      numOfSellSupplements,
      totalSellSupplements,
      totalSellSupplementsTrainerRatio,
      totalSellSupplementsOurRatio: totalSellSupplements

    })
    return alldata;
  }
};

//!swimming and clothes
export const calcSwimmingSupplements = (data) => {
  data = data.filter(a => a.type === 2 || a.type === 3)

  return {
    totalPrice: data.reduce((a, b) => a + b.iqd, 0),
  }
}