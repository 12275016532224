import React from 'react'
import './home.css'
import { FaEnvelope, FaFacebook, FaInstagram, FaPhoneAlt } from 'react-icons/fa'

function Home() {
  return (
    <div className='w-full h-full homee' >
      <header className='headerr'>
        <a href="/" className="header-text">78Gym</a>
      </header>

      <section class="mainn sectionn">
        <div>
          <h3>Define your fitness story beyond limits.
          </h3>
          <div class="social-icons flex">
            <a href="https://www.facebook.com/78gym.erbil/"><FaFacebook /></a>
            <a href="https://www.instagram.com/78gym.erbil/"><FaInstagram /></a>
          </div>
        </div>
      </section>

      <section class="cards contact sectionn" id="contact">
        <h2 class="titlee">Contact US</h2>
        <div class="content">
          <div class="card">
            <div class="icon flex w-full justify-center">
              <FaPhoneAlt size={40} />
            </div>
            <div class="info">
              <h3>Phone</h3>
              <p>+964 750 000 5555</p>
            </div>
          </div>
          <div class="card">
            <div class="icon flex w-full justify-center">
              <FaEnvelope size={40} />
            </div>
            <div class="info">
              <h3>Email</h3>
              <p>info@78gym.com</p>
            </div>
          </div>
        </div>
      </section>

      <footer class="footerr">
        <div class="social-icons flex">
          <a href="https://www.facebook.com/78gym.erbil/"><FaFacebook /></a>
          <a href="https://www.instagram.com/78gym.erbil/"><FaInstagram /></a>
        </div>
      </footer>
    </div>
  )
}

export default Home
