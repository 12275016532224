import axios from "axios";
import dayjs from "dayjs";
import { store } from "../../App";
const CREATE = "/balances/create";
const UPDATE = "/balances/update";

export const createBalance = async (data) => {
  try {
    let res;
    if (data._id) {
      res = await axios.put(UPDATE + `/${data._id}`, data);
    } else {
      res = await axios.post(CREATE, data);
    }

    store.dispatch({
      type: data._id ? "UPDATE_BALANCE" : "CREATE_BALANCE",
      data: res.data.data,
    });
  } catch (e) {
    throw e;
  }
};

export const fetchBalances = async (data) => {
  try {
    const res = await axios.get(`/balances`);
    store.dispatch({ type: "FETCH_BALANCES", data: res.data });
  } catch (e) {
    console.log(e)
    throw e;
  }
};
export const fetchSpecificBalancesOrder = async (bioStarID) => {
  try {
    let startDate = dayjs().startOf('month').format('YYYY-MM-DD')
    let endDate = dayjs().endOf('month').format('YYYY-MM-DD')

    const response = await axios.get('/orders?bioStarID=' + bioStarID + '&startDate=' + startDate + '&endDate=' + endDate)
    return response.data
  } catch (e) {
    alert(e)
  }
};
